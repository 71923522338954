import { useState } from "react";
import ApplicantForm from "pages/recruitment/ApplicantForm";
import Navbar from "Navbar";
import ApplicantLogin from "pages/recruitment/ApplicantLogin";
import ApplicantSummary from "pages/recruitment/ApplicantSummary";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function ApplicantIndex(){
    const [authen, setAuthen] = useState(localStorage.getItem("authen"));
    const [filled, setFilled] = useState(false);

    function handleAuthenticate(dec){
        setAuthen(dec)
    }

    function handleFilledStat(stat){
        setFilled(stat)
    }

    function handleLogout(){
        localStorage.clear()
        setAuthen(false)
        setFilled(false)
        Swal.fire(
            "Logout succesffuly",
            "Thank you. ",
            "info"
        )
    }

    return(
        <div>
            <Navbar withLogout={authen?true:false} fromApplicant={true} handleLogout={handleLogout} />
            {authen && localStorage.getItem("accountId") && localStorage.getItem("interviewId") && localStorage.getItem("applicantId") ? 
                filled?
                <ApplicantSummary />
                :
                <ApplicantForm handleAuthenticate={handleAuthenticate} handleFilledStat={handleFilledStat} />        
            :
                <ApplicantLogin handleAuthenticate={handleAuthenticate} />
            }
        </div>
    )
}