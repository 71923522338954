import { useState } from "react"

//Tab component to be used in relevant pages

function Tabs(props) {
    const {
        activeTab: [activeTab, setActiveTab]
    } = {
        activeTab: useState(props.state[0]),
        ...(props.state || {})
    }
    const tabItems = props.tabs.map((tab) =>
        <li className="nav-item" key={tab} onClick={() => setActiveTab(tab)}>
            <a className={activeTab === tab ? "nav-link active" : "nav-link"}>{tab}</a>
        </li>
    )
    return (
        <div className="tab-grid">
            <ul className="nav nav-pills mb-3 float-left">
                {tabItems}
            </ul>
            {props.children}
        </div>
        
    )
}

export default Tabs