import { useEffect, useState } from "react"
import { ApplicationSummaryOneCol, ApplicationSummaryOneCol2, ApplicationSummaryTwoCol, SummaryFormHeader } from "pages/recruitment/ApplicationSummaryCustomWidget";
import Navbar from "Navbar";
import { WidgetBackBtn } from "custom_components/Widgets";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function ApplicantSummary(){
    const [appForm, setAppForm] = useState()
    const [position, setPosition] = useState()
    const doc = new jsPDF('l', 'mm', [1000, 1000]);

    useEffect(()=>{
        if(localStorage.getItem("applicantId")){
            getApplicantRecord(localStorage.getItem("applicantId"))
        }

        if(localStorage.getItem("interviewId")){
            getInterviewPosition(localStorage.getItem("interviewId"))
        }
    },[])

    //custom parse data to objectlist
    function parseCustomData(dt){
        var temp = []
        dt = dt.trim()
        dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '').replaceAll("},{", "},,{").split(",,")
        for(var k of dt){
            k = k.replaceAll('"{',"{")
            k = k.replaceAll('}"',"}")
            temp.push(JSON.parse(k))
        }

        return temp
    }

    //retrieve interview position of applicant
    function getInterviewPosition(id){
        fetch(
            domain + "/api/RecruitmentModule/applicant.php?action=getIntPos&id="+ id,
            {
                method: "GET",
                // headers: "Content-Type: application/json",
            }
            )
            .then(res => res.text())
            .then((data)=>{
                if(data){
                    let dt = JSON.parse(data)[0]
                    setPosition(dt.apply_position)
                }else{
                    console.log("no data foundd")
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //retrieve application form data by applicant id
    function getApplicantRecord(id){
        fetch(
            domain + "/api/RecruitmentModule/applicant.php?action=getApp&id="+ id,
        {
            method: "GET",
            // headers: "Content-Type: application/json",
        }
        )
        .then(res => res.text())
        .then((data)=>{
            if(data){
                console.log("data", data)
                let dt = JSON.parse(data)[0]
                console.log("json data", dt)

                dt.applicant_education= parseCustomData(dt.applicant_education)
                dt.applicant_employment = parseCustomData(dt.applicant_employment)
                // dt.work_reference= parseCustomData(dt.work_reference)

                setAppForm(dt)
            }else{
                console.log("no data foundd")
            }
        })
        .catch((err) => {
            console.log(err);
        });

    }

    function generatePDF(){
        var tb = new jsPDF()

        autoTable(tb, {
            columnStyles: { 
                col1: { fontStyle: 'bold' }, 
                col3: {fontStyle: 'bold'}, 
            }, 
            body: [
                {col1: "Name", col2: appForm.name, col3: "Gender", col4: appForm.gender},
                {col1: "Race", col2: appForm.race, col3: "Contact No", col4: appForm.contact_number},
                {col1: "Email", col2: appForm.email, col3: "NRIC/FIN", col4: appForm.nric_fin},
                {col1: "Nationality", col2: appForm.nationality, col3: "Date of Birth", col4: appForm.date_of_birth},
                {col1: "Place of Birth", col2: appForm.place_of_birth, col3: "Marital Status", col4: appForm.marital_status},
                {col1: "Address", col2: appForm.address},
            ],
            columns: [
                { header: 'General', dataKey: 'col1' },
                { header: '', dataKey: 'col2' },
                { header: '', dataKey: 'col3' },
                { header: '', dataKey: 'col4' },
            ],
        })

        let eduBody = []
        appForm.applicant_education.map((k)=>{
            eduBody.push({col1: "Institute", col2: k.institute, col3: "Qualification", col4: k.qualification})
            eduBody.push({col1: "Start", col2: k.start, col3: "End", col4: k.end})
            eduBody.push({col1: "", col2: "", col3: "", col4: ""})
        })

        autoTable(tb, {
            columnStyles: { 
                col1: { fontStyle: 'bold' }, 
                col3: {fontStyle: 'bold'}, 
            }, 
            body: eduBody,
            columns: [
                { header: 'Education', dataKey: 'col1' },
                { header: '', dataKey: 'col2' },
                { header: '', dataKey: 'col3' },
                { header: '', dataKey: 'col4' },
            ],
        })

        let empBody = []
        appForm.applicant_employment.map((k)=>{
            empBody.push({col1: "Employer", col2: k.empName, col3: "Position", col4: k.empPosition})
            empBody.push({col1: "Start", col2: k.empStart, col3: "End", col4: k.empEnd})
            empBody.push({col1: "Last Drawn Salary", col2: k.empLastSalary, col3: "Reason of Leaving", col4: k.empLeaveReason})
            empBody.push({col1: "", col2: "", col3: "", col4: ""})
        })

        autoTable(tb, {
            columnStyles: { 
                col1: { fontStyle: 'bold' }, 
                col3: {fontStyle: 'bold'}, 
            }, 
            body: empBody,
            columns: [
                { header: 'Employment', dataKey: 'col1' },
                { header: '', dataKey: 'col2' },
                { header: '', dataKey: 'col3' },
                { header: '', dataKey: 'col4' },
            ],
        })

        let refBody = null
        if(appForm.ref_name === ""){
            refBody = [{col1: "No relevant work referer is provided. "}]
        }else{
            refBody = [
                {col1: "Referer Name", col2: appForm.ref_name, col3: "Occupation", col4: appForm.ref_occupation},
                {col1: "Contact", col2: appForm.ref_contact, col3: "Company Name", col4: appForm.ref_companyname},
            ]
        }
        autoTable(tb, {
            columnStyles: { 
                col1: { fontStyle: 'bold' }, 
                col3: {fontStyle: 'bold'}, 
            }, 
            body: refBody,
            columns: [
                { header: 'Work Reference', dataKey: 'col1' },
                { header: '', dataKey: 'col2' },
                { header: '', dataKey: 'col3' },
                { header: '', dataKey: 'col4' },
            ],
        })
        
        autoTable(tb, {
            columnStyles: { 
                col1: { fontStyle: 'bold' }, 
            }, 
            body: [
                {col1: "Any plan to further studies", col2: appForm.further_study},
                {col1: "Any major illness in last 6 months", col2: appForm.major_illness},
                {col1: "Are you in constant medication", col2: appForm.constant_medication},
                {col1: "You have not been convicted under any criminal charge", col2: appForm.criminal_charge},
                {col1: "You are not taken any hazardous drugs", col2: appForm.take_drug},
                {col1: "I hereby certify that all information provided are correct", col2: appForm.certify_correct},
            ],
            columns: [
                { header: 'Others', dataKey: 'col1' },
                { header: '', dataKey: 'col2' },
            ],
        })

        tb.save('applicationForm.pdf')
    }

    return(
        <div>
            {localStorage.getItem("fromInt") ? 
                <Navbar />
            :
                <></>
            }
            <div id="background-obj">
                <div id="left-square-obj"></div>
                <div id="right-square-obj"></div>
            </div>
            {appForm ? 
            <div>
                <div id="pdf1">
                    {position ?
                        <SummaryFormHeader position={position} />
                    :
                        <SummaryFormHeader position="" />
                    }
                    <div className="infobox">
                        <h5>General Information</h5>
                        <div className="row">
                            <ApplicationSummaryTwoCol label={"Name"} data={appForm.name} />
                            {/* <ApplicationSummaryTwoCol label={"Age"} data={appForm.age} /> */}
                            <ApplicationSummaryTwoCol label={"Gender"} data={appForm.gender} />
                            <ApplicationSummaryTwoCol label={"Race"} data={appForm.race} />
                            <ApplicationSummaryTwoCol label={"Contact Number"} data={appForm.contact_number} />
                            <ApplicationSummaryTwoCol label={"Email"} data={appForm.email} />
                            <ApplicationSummaryTwoCol label={"NRIC/FIN"} data={appForm.nric_fin} />
                            <ApplicationSummaryTwoCol label={"Nationality"} data={appForm.nationality} />
                            <ApplicationSummaryTwoCol label={"Date of Birth"} data={appForm.date_of_birth} />
                            <ApplicationSummaryTwoCol label={"Place of Birth"} data={appForm.place_of_birth} />
                            <ApplicationSummaryTwoCol label={"Marital Status"} data={appForm.marital_status} />
                            {/* <ApplicationSummaryTwoCol label={"Driving License"} data={appForm.driving_license} /> */}
                            {/* <ApplicationSummaryTwoCol label={"National Service Rank"} data={appForm.national_service} /> */}
                            <ApplicationSummaryOneCol label={"Address"} data={appForm.address} />
                        </div>
                    </div>
                
                    <div className="infobox">
                        <h5>Education History</h5>
                            {appForm.applicant_education.map((oj, idx)=>{
                                return(
                                    <div key={idx}>
                                        {idx != 0 ?
                                            <hr />
                                        :
                                            <></>
                                        }
                                        <div className="row">
                                            <ApplicationSummaryTwoCol label={"Institute"} data={oj.institute} />
                                            <ApplicationSummaryTwoCol label={"Qualification"} data={oj.qualification} />
                                        </div>
                                        <div className="row">
                                            <ApplicationSummaryTwoCol label={"Start"} data={oj.start} />
                                            <ApplicationSummaryTwoCol label={"End"} data={oj.end} />
                                        </div>
                                        {/* <div className="row">
                                            <ApplicationSummaryTwoCol label={"result"} data={oj.result} />
                                        </div> */}
                                    </div>
                                )
                            })}
                    </div>
                </div>
                
                <div id="pdf2">
                    <div className="infobox">
                        <h5>Employment History</h5>
                            {appForm.applicant_employment.map((oj, idx)=>{
                                return(
                                    <div key={idx}>
                                        {idx != 0 ?
                                            <hr />
                                        :
                                            <></>
                                        }
                                        <div className="row">
                                            <ApplicationSummaryTwoCol label={"Employer"} data={oj.empName} />
                                            <ApplicationSummaryTwoCol label={"Position"} data={oj.empPosition} />
                                        </div>
                                        <div className="row">
                                            <ApplicationSummaryTwoCol label={"Start"} data={oj.empStart} />
                                            <ApplicationSummaryTwoCol label={"End"} data={oj.empEnd} />
                                        </div>
                                        <div className="row">
                                            <ApplicationSummaryTwoCol label={"Last Drawn Salary"} data={oj.empLastSalary} />
                                            <ApplicationSummaryTwoCol label={"Reason of Leaving"} data={oj.empLeaveReason} />
                                        </div>
                                        {/* <div className="row">
                                            <ApplicationSummaryTwoCol label={"Address"} data={oj.empAddress} />
                                            <ApplicationSummaryTwoCol label={"Contact"} data={oj.empContact} />
                                        </div>
                                        <div className="row">
                                            <ApplicationSummaryOneCol label={"Achivement"} data={oj.empAchievement} />
                                        </div> */}
                                    </div>
                                )
                            })}
                    </div>
                </div>

                <div id="pdf3">
                    <div className="infobox">
                        <h5>Work References</h5>
                        {appForm.ref_name === "" ? 
                            <div>
                                <p>No relevant work referer is provided. </p>
                            </div>
                        :
                            <div className="row">
                                <ApplicationSummaryTwoCol label={"Referer Name"} data={appForm.ref_name} />
                                <ApplicationSummaryTwoCol label={"Occupation"} data={appForm.ref_occupation} />
                                <ApplicationSummaryTwoCol label={"Contact"} data={appForm.ref_contact} />
                                {/* <ApplicationSummaryTwoCol label={"Relationship"} data={appForm.refRelationship} /> */}
                                <ApplicationSummaryTwoCol label={"Company Name"} data={appForm.ref_companyname} />
                                {/* <ApplicationSummaryOneCol label={"Company Address"} data={appForm.refAddress} /> */}
                            </div>
                        }
                            {/* {appForm.work_reference.map((oj,idx)=>{
                                return(
                                    <div key={idx}>
                                        {idx != 0 ?
                                            <hr />
                                        :
                                            <></>
                                        }
                                        <div className="row">
                                            <ApplicationSummaryTwoCol label={"Referer Name"} data={oj.refName} />
                                            <ApplicationSummaryTwoCol label={"Occupation"} data={oj.refOccupation} />
                                        </div>
                                        <div className="row">
                                            <ApplicationSummaryTwoCol label={"Contact"} data={oj.refContact} />
                                            <ApplicationSummaryTwoCol label={"Relationship"} data={oj.refRelationship} />
                                        </div>
                                        <div className="row">
                                            <ApplicationSummaryTwoCol label={"Company Name"} data={oj.refCompanyName} />
                                            <ApplicationSummaryOneCol label={"Company Address"} data={oj.refAddress} />
                                        </div>
                                    </div>
                                )
                            })} */}
                    </div>

                    <div className="infobox">
                        <h5>Others</h5>
                        {/* <ApplicationSummaryOneCol2 label={"Computer Skills Acquired"} data={appForm.computer_skills} /> */}
                        <ApplicationSummaryOneCol2 label={"Any plan to futher studies"} data={appForm.further_study} />
                        <ApplicationSummaryOneCol2 label={"Any major illness in last 6 months"} data={appForm.major_illness} />
                        <ApplicationSummaryOneCol2 label={"Are you in constant medication"} data={appForm.constant_medication} />
                        <ApplicationSummaryOneCol2 label={"You have not been convicted under any criminal charge"} data={appForm.criminal_charge} />
                        <ApplicationSummaryOneCol2 label={"You are not taken any hazardous drugs"} data={appForm.take_drug} />
                        <ApplicationSummaryOneCol2 label={"I hereby certify that all information provided are correct"} data={appForm.certify_correct} />

                    </div>
                </div>
            </div>
            :
            <div></div>
            }
            {localStorage.getItem("fromInt") ? 
                <div className="form-button-grid summaryBtn">
                    <button type="button" className="btn btn-primary mt-3" onClick={generatePDF}>PDF</button>
                    <WidgetBackBtn />
                </div>
            :
                <></>
            }
        </div>
    )
}