import { CreateRequisitionForm } from "custom_components/templates/FormTemplate";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function CreateRequisition({}){
    const history = useHistory();
    const [field, setField] = useState({
        requestorName: {displayText: "Requestor", dataType:"text", val:"", required: false, disable: true},
        hardwareRequest: {displayText: "Hardware", dataType:"SelectOne", val:"", required: false, disable: false},
        hardwareOther: {displayText: "Others", dataType:"text", val:"", required: false, disable: false},
        softwareRequest: {displayText: "Software", dataType:"text", val:{
            "mo": false,
            "anti": false,
            "adobe": false,
            "sp": false,
            "team": false,
            "outlook": false,
            "other": "",
        }, required: false, disable: false},
        requestReason: {displayText: "Reason on requesting items", dataType:"textbox", val:"", required: false, disable: false},
    })

    const [requestField, setRequestField] = useState([])

    const hardwareOption = [
        "Laptop",
        "Desktop",
        "Others"
    ]
    
    function handleChanges(varName, value){
        if(varName === "hardware" || varName === "software"){
            if(varName === "hardware"){
                if(!requestField.includes("hardware")){
                    setRequestField([...requestField, "hardware"])
                }else{
                    setField({
                        ...field,
                        ["hardwareRequest"]: {...field["hardwareRequest"], val: ""}
                    })
                    if(requestField.length === 1){
                        setRequestField([])
                    }else{
                        let tmp = requestField
                        tmp = tmp.splice(tmp.indexOf("software"), 1)
    
                        setRequestField(tmp)
                    }
                }
            }else{
                if(!requestField.includes("software")){
                    setRequestField([...requestField, "software"])
                }else{
                    setField({
                        ...field,
                        ["softwareRequest"]: {...field["softwareRequest"], val: {
                            "mo": false,
                            "anti": false,
                            "adobe": false,
                            "sp": false,
                            "team": false,
                            "outlook": false,
                            "other": "",
                        }}
                    })
                    if(requestField.length === 1){
                        setRequestField([])
                    }else{
                        let tmp = requestField
                        tmp = tmp.splice(tmp.indexOf("hardware"), 1)
    
                        setRequestField(tmp)
                    }
                }
            }   
        }else if(varName === "softwareSelect"){
            if(!requestField.includes("software")){
                handleChanges("software", true)
            }
            let item = field["softwareRequest"].val

            if(["mo","anti","adobe","sp","team","outlook"].includes(value)){
                item[value] = !item[value]
            }else{
                item["other"] = value
            }
        
            setField({
                ...field,
                ["softwareRequest"]: {...field["softwareRequest"], val: item}
            })
        }else{
            setField({
                ...field,
                [varName]: {...field[varName], val: value}
            })
        }
    }

    useEffect(() => {
        fetch(
            domain + "/api/SupportModule/helpdesk.php?action=getname",
        {
            method: "GET",
        }
        )
        .then((res) => res.json())
        .then((json) => {
            handleChanges("requestorName", json["Name"])
        })
        .catch((err) => {
            console.log(err);
        });
    
      }, []);


    function handleSubmit(e){
        e.preventDefault()
        var validationCheck = true
        //validation check
        if(requestField.length === 0){
            Swal.fire(
                "Empty field submitted",
                "Take note that you must select at least a request to submit",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
        }else{
            if(requestField.includes("hardware") && (field["hardwareRequest"].val === "" || field["hardwareRequest"].val === null )){
                Swal.fire(
                    "Empty field",
                    "Please select a hardware option in the request list",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
                validationCheck = false
            }else if(requestField.includes("hardware") && (field["hardwareRequest"].val === "Others" && field["hardwareOther"].val === "" )){
                Swal.fire(
                    "Empty field in hardware's other field",
                    "Please enter the hardware that you want to request",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
                validationCheck = false
            }else if(requestField.includes("software")){
                let softwareCheck = false
                Object.keys(field["softwareRequest"].val).map((k)=>{
                    if(k !== "other" && field["softwareRequest"].val[k] === true){
                        softwareCheck = true
                    }else if(k === "other" && field["softwareRequest"].val["other"] !== ""){
                        softwareCheck = true
                    }
                })
                
                if(softwareCheck === false){
                    Swal.fire(
                        "Empty field in software's other field",
                        "Please select at least a software option",
                        // json[1],d
                        // json[0] === 1 ? "success" : "error"
                    );
                    validationCheck = false
                }
            }
            
            if(field["requestReason"].val === ""){
                Swal.fire(
                    "Empty field requesting reason",
                    "Please enter your request reason",
                    // json[1],d
                    // json[0] === 1 ? "success" : "error"
                );
                validationCheck = false
            }

            // data submission
            if(validationCheck === true){
                let inputData = {}

                Object.keys(field).map((k)=>{
                    if(k === "hardwareRequest" || k === "hardwareOther"){
                        if(field["hardwareOther"].val !== ""){
                            inputData["hardwareRequest"] = field["hardwareOther"].val;
                        }else{
                            inputData["hardwareRequest"] = field["hardwareRequest"].val
                        }
                    }else if( k === "softwareRequest"){
                        inputData[k] = JSON.stringify(field[k].val) 
                    }else{
                        inputData[k] = field[k].val 
                    }
                })

                fetch(
                    domain + "/api/SupportModule/requisition.php?action=addRequisition",
                    {
                        method: "POST",
                        // mode: "same-origin",
                        //credentials: "same-origin",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(inputData),
                    }
                )
                .then((res) => {
                    res.json()
                })
                .then((json) => {
                    Swal.fire(
                        "Success!",
                        "Successfully submitted",
                        // json[1],
                        // json[0] === 1 ? "success" : "error"
                    );
                })
                .catch((err) => {
                    console.log(err);
                });

                history.push("/itreq")

            }
            
        }
        
    }

    return(
        <CreateRequisitionForm
            module="IT Requisition"
            from="create"
            field={field}
            hardwareOption={hardwareOption}
            handleChanges={handleChanges}
            handleSubmit={handleSubmit}
            requestField={requestField}

        />
    )
}