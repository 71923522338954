import React, { useState, useEffect } from "react"
import {
    useParams,
} from "react-router-dom"
import Swal from "sweetalert2"
import { Input, SelectInput, SelectInputWithGroup, ScoreInput } from '../../custom_components/Forms.js'
import { CreateFormTemplate } from '../../custom_components/templates/FormTemplate'
import { sessionSetLoading, useFetchGet, useFetchPost, useUserPermission } from '../../custom_hooks/Actions.js'

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function ApplicationEvaluation(props) {
    const { id } = useParams()
    const [refetch, setRefetch] = useState(0)
    const evaluationData = useFetchPost(
        // window.location.toString().split(path)[0] + path +
         domain + "/api/TrainingModule/trainingEvaluation.php?action=search", 
        { trainingID: id },
        refetch
    )
    const [FeedbackID, setFeedbackID] = useState('')
    const [TrainingType, setTrainingType] = useState('')
    const [inputData, setInputData] = useState({})
    const [submitted, setSubmitted] = useState(0)
    const [validated, setValidated] = useState(0)
    const defaultSelectInput = "Select an option"
    const fields = [
        { name: "", inputname:"Q1", type: "score", required: true, options:[
            "How relevant was this training to your staff current responsibilities?",
        ]},
        { name: "Comments", inputname:"Q2", type: "textarea", required: false },
        { name: "", inputname:"Q3", type: "score", required: true, options:[
            "What do you expect your staff to achieve after having attended the training programme?",
        ]},
        { name: "", inputname:"Q4", type: "score", required: true, options:[
            "To what extend did this training meet your above-stated expectations?",
        ]},
        { name: "Comments", inputname:"Q5", type: "textarea", required: true },
        { name: "In your opinions, what changes would improve the value of this training?", inputname:"Q6", type: "textarea", required: false },
        { name: "Would you recommend this training to others?", inputname:"Q7", type: "select", required: true, options:["Yes","No"] },
        { name: "Additional Comments / Suggestions ", inputname:"Q8", type: "textarea", required: true }
    ]
    const notRequiredInputData = []
    const permission = useUserPermission(5)

    const handleChangeInput = (e) => {
        if(typeof(e) == 'object'){
            setInputData({
                ...inputData,
                [e.target.name]: e.target.type ==="checkbox" ? e.target.checked : e.target.value
            })
        }
        
    }

    function createInputObj(json) {
        let a = {}
        for (let i = 0 ;i < fields.length; i++) {
            let inputName = fields[i].inputname
            switch(fields[i].type) {
                case "score":
                    a[inputName] = json[inputName] ? json[inputName].toString() : ""
                    break
                case "label":
                    break
                default:
                    a[inputName] = json[inputName] ? json[inputName] : ""
                    break
            }
        }
        return a
    }
    
    useEffect(() => {

        setInputData(createInputObj(evaluationData.data))
        if(evaluationData.data.evalInfo){
            setFeedbackID(evaluationData.data.evalInfo.FeedbackID)
            setTrainingType(evaluationData.data.evalInfo.TrainingType)
         }
    }, [evaluationData.data,refetch])

    useEffect(() => {
        if (submitted === 1 && validated === 1) { // check whether there is existing record
            // window.location.toString().split(path)[0] + path +
            fetch( domain + "/api/TrainingModule/trainingEvaluation.php?action=search", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    trainingID: id,
                })
            })
                .then(res => res.json())
                .then(json => {
                    // window.location.toString().split(path)[0] + path + 
                    let url =  domain + "/api/TrainingModule/trainingEvaluation.php?action=add"
                    // if(json[0] == undefined){ // if undefined, update the records in payment
                    //     url = window.location.toString().split(path)[0] + path + domain + "/api/TrainingModule/trainingEvaluation.php?action=update" // feature currently not activated
                    // }
                    inputData.trainingID = id; // adding training app ID
                    sessionSetLoading(true)
                    fetch(url, {
                        method: "POST",
                        mode: "same-origin",
                        credentials: "same-origin",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(inputData)
                    })
                        .then(res => res.json())
                        .then(json => {
                            if (json[0] === 1){
                                window.scrollTo(0, 0)
                                setRefetch(1)
                            }
                            setSubmitted(0)
                            setValidated(0)
                            Swal.fire(
                                json[0] === 1 ? 'Success!' : 'Error!',
                                json[1],
                                json[0] === 1 ? 'success' : 'error'
                            )
                            sessionSetLoading()
                        })
                        .catch(err => { console.log(err) })
                })
                .catch(err => { console.log(err) })
        }
    }, [submitted, validated])


    const fieldItems = fields.map((field, index) => {
        return (
            field.type === "label" ? 
                <div key={field.name}>
                    <h6>{field.name}</h6>
                </div>
            :
                field.type === "textarea" || field.type === "select"  ?
                    <div className="form-group row" key={field.inputname} >
                        <label htmlFor={field.inputname} className="col-sm-3 ">{field.name}</label>
                        <div className="col-sm-9">
                            {
                                field.type === "select" ? 
                                    <SelectInput
                                        field={field.inputname}
                                        value={inputData[field.inputname]}
                                        options={field.options}
                                        defaultOption={defaultSelectInput}
                                        requiredOption={field.required}
                                        onChangeInput={handleChangeInput}
                                        disabled={FeedbackID && TrainingType != 'Mandatory Training' ? 0 : 1}
                                    />
                                :
                                    <Input
                                        field={field.inputname}
                                        type={field.type}
                                        value={inputData[field.inputname]}
                                        requiredOption={field.required}
                                        onChangeInput={handleChangeInput}
                                        disabled={FeedbackID && TrainingType != 'Mandatory Training'? 0 : 1}
                                    />
                            }
                            
                        </div>
                    </div>
                :
                    <div className="form-group form-score-grid mb-4" key={field.inputname} name={field.inputname} onChange={handleChangeInput} >
                        <div>
                            <h6>{field.name}</h6>
                        </div>
                        <label className="form-check-label">Excellent</label>
                        <label className="form-check-label">Good</label>
                        <label className="form-check-label">Satisfactory</label>
                        <label className="form-check-label">Poor</label>
                        <label className="form-check-label">N.A.</label>
                        {
                            field.options.map((option, optIndex) => {
                                const inputField = inputData[field.inputname] ? inputData[field.inputname].toString() : null
                                return (
                                    <React.Fragment>
                                        <label key={option} className="form-check-label">{option}</label>
                                        <input key={field.inputname} className="form-check-input mx-3" type="radio" name={field.inputname} value="4" required={field.required} defaultChecked={inputField === "4" ? true : null }  disabled={FeedbackID && TrainingType != 'Mandatory Training'? 0 : 1} ></input>
                                        <input key={field.inputname} className="form-check-input mx-3" type="radio" name={field.inputname} value="3" defaultChecked={inputField === "3" ? true : null}  disabled={FeedbackID && TrainingType != 'Mandatory Training'? 0 : 1}></input>
                                        <input key={field.inputname} className="form-check-input mx-3" type="radio" name={field.inputname} value="2" defaultChecked={inputField === "2" ? true : null}  disabled={FeedbackID && TrainingType != 'Mandatory Training'? 0 : 1}></input>
                                        <input key={field.inputname} className="form-check-input mx-3" type="radio" name={field.inputname} value="1" defaultChecked={inputField === "1" ? true : null}  disabled={FeedbackID && TrainingType != 'Mandatory Training'? 0 : 1}></input>
                                        <input key={field.inputname} className="form-check-input mx-3" type="radio" name={field.inputname} value="0" defaultChecked={inputField === "0" ? true : null}  disabled={FeedbackID && TrainingType != 'Mandatory Training'? 0 : 1}></input>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
        )
    })

    function handleSubmit(e) {
        e.preventDefault()
        for (let inputName in inputData) {
            let isAnExcept = 0
            for (let exceptNum in notRequiredInputData) {
                if (inputName === notRequiredInputData[exceptNum]) {
                    isAnExcept = 1
                }
            }
            if (isAnExcept === 0) {
                if (inputData[inputName] === defaultSelectInput || inputData[inputName] === "" || inputData[inputName] === null || inputData[inputName] === "undefined") {
                    setValidated(0)
                    setSubmitted(0)
                } else {
                    break
                }
            } else {
                break
            }
        }
        setValidated(1)
        setSubmitted(1)
    }

    return (
        permission.view ? 
            evaluationData.status === "fetched" ?
                evaluationData.data.mode === "restricted" ?
                    <div>User is unauthorized to view training application evaluation</div>
                    : 
                        <>
                            <div className="row mb-3">
                                {
                                    evaluationData.data ? 
                                        <React.Fragment>
                                            <label className="col-sm-4 form-check-label">Training Application ID: </label>
                                            <label className="col-sm-5 form-check-label">{id ? id : "Not Available"}</label>
                                            <label className="col-sm-4 form-check-label">Name of Reporting Manager: </label>
                                            <label className="col-sm-5 form-check-label">{evaluationData.data.evalInfo.rmName ? evaluationData.data.evalInfo.rmName : "Not Available"}</label>
                                            <label className="col-sm-4 form-check-label">Designation of Reporting Manager: </label>
                                            <label className="col-sm-5 form-check-label">{evaluationData.data.evalInfo.rmDesignation ? evaluationData.data.evalInfo.rmDesignation : "Not Available"}</label>
                                            <label className="col-sm-4 form-check-label">Department of Reporting Manager: </label>
                                            <label className="col-sm-5 form-check-label">{evaluationData.data.evalInfo.rmDepartment ? evaluationData.data.evalInfo.rmDepartment : "Not Available"}</label>
                                            <label className="col-sm-4 form-check-label">Evaluation Date: </label>
                                            <label className="col-sm-5 form-check-label">{evaluationData.data.evalInfo.evaluationDate ? evaluationData.data.evalInfo.evaluationDate : "Not submitted yet"}</label>
                                        </React.Fragment>
                                    : ""
                                }
                            </div>
                            {
                                permission.add && 
                                <CreateFormTemplate 
                                    handleSubmit={handleSubmit}
                                    fieldItems={fieldItems}
                                    importCsv={false}
                                    disabled={evaluationData.data.mode === "edit" ? false : true}
                                />
                            }
                        </>
            : 
                <div>Loading...</div>
        :
            <div>You do not have user role access for this component.</div>
    )
}

export default ApplicationEvaluation