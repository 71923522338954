import React,{ useState, useEffect } from "react"
import {
    Redirect,
    useParams
} from "react-router-dom"
import { Input, SelectInput, SelectInputWithGroup } from '../../custom_components/Forms.js'
import { EditFormTemplate } from '../../custom_components/templates/FormTemplate'
import "../../css/Forms.css"
import { sessionSetLoading, useUserPermission } from "custom_hooks/Actions.js"
import Swal from "sweetalert2"

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function UserAccountDetails(props) {
    let { id } = useParams()
    const [editMode, setEditMode] = useState(1)
    const [inputData, setInputData] = useState({})
    const [submitted, setSubmitted] = useState(0)
    const [validated, setValidated] = useState(0)
    const [isUserThemself, setIsUserThemself] = useState(null)
    const [formData, setFormData] = useState([])
    const [redirect, setRedirect] = useState(false)
    const fields = [
        { name: "Employee code", type: "text", required: true },
        { name: "Username", type: "text", required: true },
        { name: "Password", type: "password", required: false },
        { name: "Email", type: "email", required: false },
        { name: "Contact Number", type: "tel", required: false },
        { name: "Status", type: "select", required: false, options: ["Active", "Inactive"] },
    ]
    const defaultSelectInput = "Select an option"
    const exceptInputData = []
    const permission = useUserPermission(2)
    

    const handleChangeInput = (e) => {
        setInputData({
            ...inputData,
            [e.target.name]: e.target.value
        })
    }

    function createInputObj(json) {
       console.log("json",json[4])
        let a = {}
        for (let i = 0 ;i < fields.length; i++) {
            let defaultFieldData = json[i] ? json[i] : ""
            if (i > 2) {
                defaultFieldData = json[i-1] ? json[i-1] : ""
            }
          
            switch(i) {
                case 2:
                    defaultFieldData = ""
                    break
                case 5:
                    switch(json[4]) {
                       
                        case "-1":
                            defaultFieldData =  fields[5].options[1]
                            break
                        case "1":
                            defaultFieldData = fields[5].options[0]
                            break
                        default:
                            break
                    }
                   
                default:
                    a[fields[i].name] = defaultFieldData
                    break
            }
            a[fields[i].name] = defaultFieldData
        }
        return a
    }

    useEffect(() => {
        if (submitted === 0) {
            // window.location.toString().split(path)[0] + path +
            fetch(  domain + "/api/EmployeeModule/useraccount_details.php", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    employeeCode: id
                })
            })
                .then(res => res.json())
                .then(json => {
                    setInputData(createInputObj(json[0]))
                    setFormData(json[0])
                    setIsUserThemself(json[1])
                })
                .catch(err => { console.log(err) })
        }
    }, [])

    useEffect(() => {
        if (submitted === 1 && validated === 1 && (isUserThemself || permission.edit === 1)) {
            sessionSetLoading(true)
            // window.location.toString().split(path)[0] + path +
            fetch(  domain + "/api/EmployeeModule/useraccount_detailsupdate.php", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    getEmployeeCode: id,
                    employeeCode: inputData[fields[0].name],
                    username: inputData[fields[1].name],
                    password: inputData[fields[2].name],
                    email: inputData[fields[3].name],
                    contact: inputData[fields[4].name],
                    status: inputData[fields[5].name],
                })
            })
                .then(res => res.json())
                .then(json => {
                    setSubmitted(0)
                    setValidated(0)
                    Swal.fire(
                        json[0] === 1 ? 'Success!' : 'Error!',
                        json[1],
                        json[0] === 1 ? 'success' : 'error'
                    )
                    if (json[0] === 1) {
                        setRedirect(true)
                    }
                    sessionSetLoading()
                })
                .catch(err => { console.log(err) })
        }
    }, [submitted, validated])

    function Label({name}) {
        return (
            <label htmlFor={name} className="">{name}</label>
        )
    }

    function LabelWithTooltip({name, text}) {
        return (
            <div className="tooltip">
                <label htmlFor={name} className="">{name}</label>
                <span className="tooltip-text">{text}</span>
                <div className="gg-info"></div>
            </div>
        )
    }

    let formClassName = "form-group"
    let formDisplayWide = formClassName + " grid-wide"
    let formDisplayNone = formClassName + " d-none"
    const fieldItems = fields.map((field, index) =>
    <React.Fragment key={field.name}>
        {
            <div className={ index + 2 % 2 === 0 ? formDisplayWide : formClassName } >
                {
                    field.type === "password" ?   
                        <LabelWithTooltip name={field.name} text="Field is optional"/>
                    : field.type === "select" ?
                        <LabelWithTooltip name={field.name} text="Field is optional"/>
                    :
                        <Label name={field.name} />
                }
                {
                    field.type === "select" ?
                        <SelectInput
                            field={field.name}
                            options={field.options}
                            defaultOption={defaultSelectInput}
                            value={inputData[field.name]}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            disabled={editMode}
                        />
                    :
                        <Input
                            field={field.name}
                            type={field.type}
                            value={inputData[field.name]}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            disabled={editMode}
                        />
                }
            </div>
        }
    </React.Fragment>
    )

    function handleSubmit(e) {
        e.preventDefault()
        for (let inputName in inputData) {
            let isAnExcept = 0
            for (let exceptNum in exceptInputData) {
                if (inputName === exceptInputData[exceptNum]) {
                    isAnExcept = 1
                }
            }
            if (isAnExcept === 0) {
                if (inputData[inputName] === defaultSelectInput || inputData[inputName] === "" || inputData[inputName] === null || inputData[inputName] === "undefined") {
                    setValidated(0)
                    setSubmitted(0)
                } else {
                    break
                }
            } else {
                break
            }
        }
        setValidated(1)
        setSubmitted(1)
    }

    if (redirect === true) {
        return <Redirect to={ "/useraccount"} />
    } else {
        return (
            <EditFormTemplate
                handleSubmit={handleSubmit}
                disabled={editMode}
                setEditMode={setEditMode}
                fieldItems={fieldItems}
                editAccess={isUserThemself ? isUserThemself : permission.edit}
            />
        )
    }
}

export default UserAccountDetails