import React, { useState, useEffect } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory
} from "react-router-dom"
import Login from 'pages/Login.js'
import Test from 'pages/test.js'
// import { userRoutes, authRoutes } from "/allRoutes"
import Routes from 'Routes.js'
// import AllRoutes from 'allRoutes.js'
import Dashboard from 'pages/Dashboard.js'
import './css/App.css'
import './css/Nav.css'
import { WidgetLoader } from "custom_components/Widgets"
import TestTesseract from 'test.js'
import { Redirect } from "react-router-dom"
import Swal from "sweetalert2"
import ApplicantIndex from "pages/recruitment/ApplicantIndex.js"
import ApplicantSummary from "pages/recruitment/ApplicantSummary.js"
import HelpdeskRatingForm from "pages/support/HelpdeskRatingForm"

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function App() {
  let location = useLocation()
  let history = useHistory()
  const [appSession, setAppSession] = useState()
  const defaultLoginInfo = {
    cc: '',
    user: '',
    pass: ''
  }
  const [firstLogin, setFirstLogin] = useState(false)
  const [loginInfo, setLoginInfo] = useState(defaultLoginInfo)
  const [bookmarked, setBookmark] = useState(false)
  const [checkedBookmark, setCheckedBookmark] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [authData, setAuthData] = useState()
  const [authenticated, setAuthenicated] = useState()  
  const [authorized, setAuthorized] = useState(false)
  const [errorMsg, setErrorMsg] = useState()

  const handleChangeLoginInfo = (e) => {
    e.preventDefault()
    setLoginInfo({
        ...loginInfo,
        [e.target.name]: e.target.value
    })
  }

  const handleAuthorization = (e) => {
    e.preventDefault();
    console.log("loginInfo",loginInfo)
    // var url = window.location.toString().split(path)[0];
    var url = domain;

    fetch(domain + '/api/EmployeeModule/login.php', {
      method: "POST",
      mode: "same-origin",
      credentials: "same-origin",
      withCredentials: true,  
      crossorigin: true,  
      // mode: 'no-cors', 
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      // headers : [
      //   // ['Content-Type', 'application/x-www-form-urlencoded'],
      //   // ['Content-Type', 'multipart/form-data'],
      //   ['Content-Type', 'application/json'],
      //   ['Access-Control-Allow-Origin','*'],
        
      // ],
      body: JSON.stringify(loginInfo)
    })
    .then(res => res.json())
    .then(json => {
  
   
      setAuthorized(json[0])
      setRefetch(Math.random())
      setErrorMsg(json[0] ? null : json[1])
      if (json[1] === 1 ) {
        setLoginInfo(defaultLoginInfo)
      }
    
    })
    .catch(err => console.log("error", err))
  }


  function setSession(appSession) {
    appSession&&
      sessionStorage.setItem('userPermission', JSON.stringify(appSession))
  }

  function setBookmarkedLink(link, timestamp) {
    localStorage.setItem('bookmarkedLink', JSON.stringify(link))
    localStorage.setItem('bookmarkedLinkTimestamp', JSON.stringify(timestamp))
    // console.log(sessionStorage['bookmarkedLink'],sessionStorage['bookmarkedLinkTimestamp'])
  }

  function destroySession() {
    setAuthenicated(null)
    setAuthorized(null)
    setRefetch(Math.random())
  }

  function convertArrayItemsToInt(array) { 
    let newArray = []
    array.forEach(items => {
      let newItem = []
      items.forEach(element => {
        newItem.push(element ? 1 : 0)
      })
      newArray.push(newItem)
    })
    return newArray
  }

  useEffect(() => {
    !authorized &&
      sessionStorage.clear()
  }, [authorized])

  useEffect(() => {
    // window.location.toString().split(path)[0]+path+
    fetch(domain +  "/api/sessioncheck.php", {
      method: "GET",
      mode: "same-origin",
      credentials: "same-origin"
    })
    .then(res=>res.json())
    .then(json=>{
    
      setAuthData(json)
      setAppSession(convertArrayItemsToInt(json[4]['Permission']))
    })
    .catch(err=>err)
  }, [refetch,authorized])

  useEffect(() => {
      
    if (authenticated) {
        // window.location.toString().split(path)[0] + path +
        fetch(domain + "/api/EmployeeModule/checkLogin.php", {
            method: "GET",
            mode: "same-origin",
            // credentials: "same-origin",
            headers: { "Content-Type": "application/json" }
        })
        .then(res => res.json())
        .then(json => {
           
            setFirstLogin(json)
        })
        .catch(err => {
        })
    }
}, [authenticated])



  useEffect(() => {

    setAuthenicated(authData ? authData[0] : null)
    setAuthorized(authData ? authData[0] : null)
  }, [authData])


  useEffect(() => {
    setSession(appSession ? appSession : null)
  }, [appSession])

  useEffect(() => {
    if (!checkedBookmark) {
      let currentBookmark = JSON.parse(localStorage.getItem('bookmarkedLink'))
      setBookmark( currentBookmark ? currentBookmark : false)
      setCheckedBookmark(currentBookmark ?? true)
    }
  }, [localStorage.getItem('bookmarkedLink')])

  useEffect(() => {
    return history.listen((location) => { 
      // console.log(`You changed the page to: ${location.pathname}`) 
      if (checkedBookmark) {
        if (location.pathname !== "/" && 
        location.pathname !== "/login" && 
        location.pathname !== "/db" && 
        location.pathname !== "/logout") {
          
          setBookmarkedLink(location.pathname, Math.round(+new Date()/1000))
        }
      }
   }) 
  }, [history, checkedBookmark])

  useEffect(() => {
    let bookmarkedLinkTimestamp = JSON.parse(localStorage.getItem('bookmarkedLinkTimestamp'))
    let currentTimeStamp = Math.round(+new Date()/1000)
    // 600 seconds before bookmark doesn't work
    if (bookmarked && ((bookmarkedLinkTimestamp + 600) > currentTimeStamp)) {
      console.log(`Bookmarked link is: ${bookmarked}`)
      Swal.fire({
        title: 'Return to bookmarked session?',
        text: "Would you like to return to the previous session?",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, Return me to ${bookmarked}`
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(bookmarked)
        }
      })
    }
  }, [bookmarked])

  useEffect(() => {

    //refreshes the session to prevent timeout
    setInterval(() => {
      fetch(domain + "/api/sessioncheck.php", {
        method: "GET",
        mode: "same-origin",
        credentials: "same-origin"
      })
      .then(res=>res.json())
      .then(json=>{
        setAuthData(json)
        setAppSession(convertArrayItemsToInt(json[4]['Permission']))
      })
      .catch(err=>err)
    }, 300000);
  }, [])

  return (
    <Switch>
      <Route exact path={"/login"}>
        <Login
          authenticated={authenticated}
          loginInfo={loginInfo}
          handleOnChange={handleChangeLoginInfo}
          handleSubmit={handleAuthorization}
          errormsg={errorMsg}
          setErrorMsg={setErrorMsg}
        />
      </Route>
      <Route path={"/applicant"} >
        <ApplicantIndex />
      </Route>
      <Route path={"/helpdesk/:id"} >
        <HelpdeskRatingForm />
      </Route>           
      <Route path={"/applicationSummary"} >
        <ApplicantSummary />
      </Route>  
      <Route path={"/"}>
        <Routes 
          companyCode={loginInfo.companyCode}
          authorized={authorized}
          authenticated={authenticated}
          destroySession={destroySession}
        />
      </Route> 
  
     </Switch>

  )
}

export default App
