import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from "react-router-dom"
import MainTemplate from "custom_components/templates/MainTemplate";
import { useUserPermission } from "custom_hooks/Actions";
import { FullTabulatorTemplate } from "custom_components/templates/TabulatorTemplate";
import { useState } from "react";
import InterviewCreate from "pages/interview/InterviewCreate";
import InterviewInfo from "pages/interview/InterviewInfo";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function parseInterview(data) {
    let dataForActive = []
    let dataForInactive = []
    // console.log("123", data);
    try {
        data.forEach((number, index, array) => {
            // console.log(array[0])
            let obj = {
                no: index+1,
                applicant_name: array[index].applicant_name,
                apply_position: array[index].apply_position,
                department: array[index].department,
                interview_datetime: array[index].interview_datetime,
                interview_type: array[index].interview_type,
                interview_status: array[index].interview_status,
                interview_id: array[index].interview_id,
                manager_recommend: array[index].manager_recommend,
                // no: index+1,
                // code: array[index][0],
                // name: array[index][1],
                // nric: array[index][2],
                // pass: array[index][3],
                // passe: array[index][4],
                // desig: array[index][5],
                // dept: array[index][6],
                // manager: array[index][7]
            }
            if(obj.interview_status === "Approved" || obj.interview_status === "Rejected" || obj.interview_status === "Close"){
                dataForInactive.push(obj);
            }else{
                dataForActive.push(obj);
            }
        })
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function Interview({permission}){
    const [redirect, setRedirect] = useState({ allow: false })
    const location = useLocation()
    const tabs = ["Active", "History"];
    const columns = [
        { title: " S/no.", field: "no", headerFilter: "input"},
        {
            title: "Name", field: "applicant_name", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "applicant_name",
                urlPrefix: "interview/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.interview_id }) //here we update state
            }
        },
        { title: "Apply Position", field: "apply_position", headerFilter: "input" },
        { title: "Dept", field: "department", headerFilter: "input" },
        { title: "Manager Recommend", field: "manager_recommend", headerFilter: "input" },
        { title: "Interview Datetime", field: "interview_datetime", headerFilter: "input" },
        { title: "Interview Type", field: "interview_type", headerFilter: "input" },
        { title: "Status", field: "interview_status", headerFilter: "input" },
    ]

    return(
        redirect.allow ?
            <Redirect push to={location.pathname+"/"+redirect?.id} />
        :
        <FullTabulatorTemplate 
            permission = {permission}
            fetchURL = {domain + "/api/RecruitmentModule/interview.php?action"}
            parseFunc = {parseInterview}
            columns = {columns}
            moduleName = {"Job Interview"}
            redirect = {redirect}
            tabs={tabs}
            disableWidget = {permission.add ? false : true}
        />
    )
}

function InterviewContainer(){
    // console.log("permission", sessionStorage.getItem("userPermission"))
    const permission1 = useUserPermission(19);
    const permission2 = useUserPermission(20);
    const permission3 = useUserPermission(21);
    let perm = {...permission1, ...permission2, ...permission3}
    console.log("actual interview permission", perm)
    return(
        <MainTemplate content={
            <Switch>
                {
                    perm.add ? <Route path="/:page/create" children={<InterviewCreate />} /> : null
                }
                {/* {
                    perm.view ? <Route path="/:subdom/:page/:id/details" children={<EmployeeInfo defaultTab={"Details"} />} /> : null
                }
                {
                    perm.view ? <Route path="/:subdom/:page/:id/cert" children={<EmployeeInfo defaultTab={"Certificate"} />} /> : null
                }*/}
                {
                    perm.view || perm.viewRecommend || perm.viewApproval ? <Route path="/:page/:id" children={<InterviewInfo permission={perm} />} /> : null
                }
                {
                    perm.view || perm.viewRecommend || perm.viewApproval ? <Route path="" children={<Interview permission={perm} />} /> : null
                } 
            </Switch>
        }
        />
    )
}

export default InterviewContainer