import { CreateLoginFormTemplate } from "custom_components/templates/FormTemplate"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2"

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function ApplicantLogin({handleAuthenticate}){
    const [field, setField] = useState({
        username: {displayText: "Username", dataType:"text", val:"", require:true},
        password: {displayText: "Password", dataType:"text", val:"", require:true},
    })
    const [validated, setValidated] = useState(false)
    const history = useHistory();

    function handleChanges(varName, value){
        setField({
            ...field,
            [varName]: {...field[varName], val: value}
        })
    }
    
    function resetField(){
        setField({
            username: {displayText: "Username", dataType:"text", val:"", require:true},
            password: {displayText: "Password", dataType:"text", val:"", require:true},
        })
    }

    useEffect(()=>{
        if(validated){
            fetch(
                domain + "/api/RecruitmentModule/applicant.php?action=get",
            {
                method: "GET",
            }
            )
            .then(res => res.json())
            .then(res=> {
                compareDbData(res)
            })
            // .catch(err => {
            //     console.log(err);
            // });

        }
    }, [validated])

    function compareDbData(data){
        let found = false;
        for(const k of data){
            if(field.username.val === k.username){
                if(field.password.val === k.password){
                    localStorage.setItem("accountId", k.account_id)
                    localStorage.setItem("interviewId", k.interview_id)
                    localStorage.setItem("applicantId", k.applicant_id)
                    localStorage.setItem("authen",true)
                    found = true
                    break
                }
            }
        }

        if(found){
            handleAuthenticate(true)
            setValidated(false)
            // Swal.fire(
            //     "Welcome to GLE&C",
            //     "Please fill up your application form",
            //     "info",
            // );
        }else{
            resetField()
            setValidated(false)
            Swal.fire(
                "Opps",
                "Incorrect account credential",
                "error",
            );

        }
    }

    function inputValidate(){
        if(field["username"].val === "" || field["password"].val === ""){
            if(field["username"].val === ""){
                Swal.fire(
                    "Opps",
                    "Username is required",
                    "error",
                );
            }else if(field["password"].val === ""){
                Swal.fire(
                    "Opps",
                    "Password is required",
                    "error",
                );
            }
        }else{
            setValidated(true)
        }
    }

    return(
        <div>
            <div id="background-obj">
                <div id="left-square-obj"></div>
                <div id="right-square-obj"></div>
            </div>
            <main id="appLogContainer">
                <div id="logBox">
                    <div>
                        <h5>Applicant Portal</h5>
                    </div>
                    <CreateLoginFormTemplate field={field} handleChanges={handleChanges} />
                    <div className="loginBtn">
                        <button type="button" className="btn btn-primary mt-3" onClick={()=>inputValidate()} >Login</button>
                    </div>
                </div>
            </main>
        </div>
    )
}