import { SelectInputWithGroupFA } from "custom_components/Forms";
import { useFetchGet } from "custom_hooks/Actions";
import { useEffect, useState } from "react";
import AcknowledgementContent from "./AcknowledgementContent";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function Acknowledgement({}){
    const [refetch, setRefetch] = useState(0);

    const acknowledgementData = useFetchGet(
        domain + "/api/SupportModule/fixedAsset.php?action=getPendingAcknowledge",
        refetch
    );
    
    const [itemCat, setItemCat] = useState([])
    const [itemList, setItemList] = useState([])
    const [item, setItem] = useState([])
    const [allData, setAllData] = useState([])
    const [itemDetail, setItemDetail] = useState([])

    function setSelectedItem(field, sItem, sCat){
        for(var x =0; x<allData.length; x++){
            var tmpItem = parseCustomData(allData[x].itemDetail)

            if(tmpItem["equipmentNo"] === sItem){
                var tmpIssue = parseCustomData(allData[x].issueDetail)
                var tmpHistory = parseCustomArray(allData[x].historyUser)

                var selectedAllData = {...allData[x]}

                selectedAllData.itemDetail = tmpItem
                selectedAllData.issueDetail = tmpIssue
                selectedAllData.historyUser = tmpHistory

                setItem([sItem, sCat[0][1], selectedAllData])
                
                break;
            }

        }
        
    }

    function resetFields(){
        setAllData("")
        setItem("")

        setRefetch(Math.random() * (9999999 - 0 + 1) + 0)
    }

    function parseCustomData(dt){
        dt = dt.trim()
        dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '')
        dt = JSON.parse(dt)
       
        return dt
    }
    
    function parseCustomArray(dt){
        var temp = []
        dt = dt.trim()
        dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '').replaceAll("},{", "},,{").split(",,")
        for(var k of dt){
            k = k.replaceAll('"{',"{")
            k = k.replaceAll('}"',"}")
            temp.push(JSON.parse(k))
        }

        return temp
    }

    useEffect(()=>{
        if(acknowledgementData.status === "fetched"){
            let retrievedData = acknowledgementData.data[0]
            let tempCat = []
            let tempList = []
            
            setAllData(retrievedData)
            
            retrievedData.map((k)=>{
                if(!(tempCat.includes(k["category"]))){
                    tempCat.push(k["category"])
                }
                
                // parsing data from string to object
                let parseItem = parseCustomData(k["itemDetail"])
                setItemDetail(parseItem)

                // parsing data from string to object
                // let parseIssuer = parseCustomData(k["issueDetail"])
                // setIssuerDetail(parseIssuer)

                tempList.push([parseItem["equipmentNo"], k["category"]])
            })
            
            // Gather data group to display in selection
            setItemCat(tempCat)
            setItemList(tempList)
        } 
    },[acknowledgementData.status])

    return(
        <div>
            {
                acknowledgementData.status === "fetched" ?
                    <div>
                        <h4>Item Acknowledgement Form</h4>
                        <div className='row staffOtherSDiv'>
                            <label className='col-sm-4'>Items</label>
                            <div className='col-sm-8'>
                                <SelectInputWithGroupFA
                                    field="itemAcknowledge"
                                    value={item[0]}
                                    options={itemList}
                                    optionGroups={itemCat}
                                    defaultOption="Select an option"
                                    onChangeInput={setSelectedItem}
                                    fromApplicant={true}
                                    requiredOption={true}
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <hr /><br />
                        <div>
                            <AcknowledgementContent resetFun={resetFields} selectedItemDetail={item} />
                        </div>
                    </div>
                :
                    <>
                    </>
            }
        </div>
    )
}