import { useState } from "react";
import Tabs from '../../custom_components/Tabs.js';

import { WidgetBreadCrumbHeader } from '../../custom_components/Widgets.js'
import HelpdeskDetails from "./HelpdeskDetails.js";
import HelpdeskFeedback from "./HelpdeskFeedback.js";
import { useParams } from "react-router-dom";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function TabDisplay(props) {
    const routeParams = useParams();
    let activeTab = props.activeTab;
    let tabs = props.tabs;
    switch (activeTab) {
        case tabs[0]:
            return (<HelpdeskDetails routeParams={routeParams} perm={props.permission} userDept={props.userDept} userRole={props.userRole} />);
        case tabs[1]:
            return (<HelpdeskFeedback routeParams={routeParams} />);
        default:
            return (null);
    }
}

function HelpdeskInfo({permission, userDept, userRole}) {
    const tabs = ["Details", "Feedback"];
    const defaultTab = tabs[0];
    const [activeTab, setActiveTab] = useState(defaultTab);
    return (
        <div>
            <WidgetBreadCrumbHeader prevNav="Helpdesk Ticket" curNav="Helpdesk Information" prevLink={"#"} curLink={"#"} />
            <br />
            <Tabs tabs={tabs} state={{ activeTab: [activeTab, setActiveTab] }} onClick={() => setActiveTab(defaultTab)} />
            <TabDisplay activeTab={activeTab} tabs={tabs} permission={permission} userDept={userDept} userRole={userRole} />
        </div>
    )
}

export default HelpdeskInfo;