import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { reactFormatter } from "react-tabulator";
import MainTemplate from "../../custom_components/templates/MainTemplate.js";
import {
  TabulatorTemplate,
  FullTabulatorTemplate,
} from "custom_components/templates/TabulatorTemplate.js";
import HomeLeaveApplicationCreate from "pages/homeleave/HomeLeaveApplicationCreate.js";
import HomeLeaveApplicationInfo from "pages/homeleave/HomeLeaveApplicationInfo.js";
import {
  useFetchPost,
  useUserPermission,
  sessionSetLoading,
} from "../../custom_hooks/Actions.js";
import { flipTrueFalse } from "helper_functions/Helper.js";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function parseHomeLeave(data) {
  let dataForActive = [];
  let dataForInactive = [];
  try {
    for (let i = 0; i < data.length - 1; i++) {
      data[i].forEach((number, index, array) => {
        const obj = {
          no: index + 1,
          id: array[index]["HomeLeaveID"],
          employeeCode: array[index]["employeeCode"],
          employeeName: array[index]["employeeName"],
          department: array[index]["Department"],
          designation: array[index]["Designation"],
          leaveType: array[index]["LeaveType"],
          dateJoined: array[index]["DateJoined"],
          startDate: array[index]["startDate"],
          endDate: array[index]["endDate"],
          submitby: array[index]["submitBy"],
          remark: array[index]["Remark"],
          status: array[index]["status"],
          action: [],
        };
        if (i === 0) {
          if (data[i][0] !== 0) {
            dataForActive.push(obj);
          }
        } else {
          if (data[i][0] !== 0) {
            dataForInactive.push(obj);
          }
        }
      });
    }
  } catch {
    return ["", ""];
  }
  const parsedData = [dataForActive, dataForInactive];
  return parsedData;
}

function getRole(data) {
  const role = data;
  console.log(role);
  return role;
}

function HomeLeaveApplication({ permission }) {
  let location = useLocation();
  const [refetch, setRefetch] = useState(0);

  // window.location.toString().split(path)[0] + path +
  const homeLeaveData = useFetchPost(
    domain + "/api/HomeLeaveModule/HomeLeaveApplication.php?action",
    {},
    refetch
  );

  const parsedData = parseHomeLeave(homeLeaveData.data);
  const roleData = getRole(homeLeaveData.data[2]);
  const tabs = ["Active", "History"];
  const defaultTab = tabs[0];
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [redirect, setRedirect] = useState({ allow: false });
  const [HLID, setHLID] = useState("");
  const [status, setStatus] = useState("");
  const [role, setRole] = useState("");

  function handleRefetch() {
    setRefetch(Math.random())
  }



  function handleUserPasswordRedirect(e) {
    e.preventDefault();
    
  }

 

  const columns = [
    { title: "Sr/No.", field: "no", headerFilter: "input" },
    {
      title: "HomeLeaveID",
      field: "id",
      headerFilter: "input",
      formatter: "link",
      formatterParams: {
        labelField: "id",
        urlPrefix: "homeleaveapplication/",
      },
      //hacky way to make tabulator play well with react router
      cellClick: function (e, cell) {
        e.preventDefault();
        const rowInfo = cell._cell.row.data;
        setRedirect({ allow: true, id: rowInfo.id }); //here we update state
      },
    },
    { title: "Employee Code", field: "employeeCode", headerFilter: "input" },
    { title: "Employee Name", field: "employeeName", headerFilter: "input" },
    { title: "Department", field: "department", headerFilter: "input" },
    { title: "Designation", field: "designation", headerFilter: "input" },
    { title: "Leave Type", field: "leaveType", headerFilter: "input" },
    {
      title: "Date Joined",
      field: "dateJoined",
      headerFilter: "input",
      formatter: "datetime",
      formatterParams: {
        inputFormat: "DD/MM/YYYY",
        outputFormat: "DD/MM/YYYY",
        invalidPlaceholder: "N/A",
      },
    },
    {
      title: "Start Date",
      field: "startDate",
      headerFilter: "input",
      formatter: "datetime",
      formatterParams: {
        inputFormat: "DD/MM/YYYY",
        outputFormat: "DD/MM/YYYY",
        invalidPlaceholder: "N/A",
      },
    },
    {
      title: "End Date",
      field: "endDate",
      headerFilter: "input",
      formatter: "datetime",
      formatterParams: {
        inputFormat: "DD/MM/YYYY",
        outputFormat: "DD/MM/YYYY",
        invalidPlaceholder: "N/A",
      },
    },
    { title: "Submit by", field: "submitby", headerFilter: "input" },
    { title: "Remark", field: "remark", headerFilter: "input" },
    { title: "Status", field: "status", headerFilter: "input" },
   
  ];

  return redirect.allow ? (
    <Redirect push to={location.pathname + "/" + redirect?.id} />
  ) : homeLeaveData.status === "fetched" ? (
    
    <TabulatorTemplate
      moduleName={"HomeLeave"}
      columns={columns}
      moduleData={parsedData}
      tabs={tabs}
      disableWidget={permission.add ? flipTrueFalse(permission.add) : true}
      handleRefetch={handleRefetch}
    />
  ) : homeLeaveData.status === "fetching" ? (
    <div>Loading...</div>
  ) : (
    <div>Home Leave Application is not available</div>
  );
}

function ApplicationContainer(props) {
  const permission = useUserPermission(3);

  return (
    <MainTemplate
      content={
        <Switch>
          {permission.add ? (
            <Route
              exact
              path="/:page/create"
              children={<HomeLeaveApplicationCreate />}
            />
          ) : null}
          {permission.view ? (
            <Route path="/:page/:id" children={<HomeLeaveApplicationInfo />} />
          ) : null}
          {permission.view ? (
            <Route path="" children={<HomeLeaveApplication permission={permission} />} />
          ) : null}
        </Switch>
      }
    />
  );
}

export default ApplicationContainer;
