import { CreateRecruitmentEditForm } from "custom_components/templates/FormTemplate";
import { useFetchPost } from "custom_hooks/Actions";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function InterviewInfo({permission}){
    const history = useHistory();
    const routeParams = useParams();
    const [refetch, setRefetch] = useState(0);
    const interviewStatus = ["Pending", "Approved", "Rejected"]
    const interviewType = ["Face to face interview", "Send job application form"]
    const recommendOption = ["Recommend", "Not Recommend"]
    const evalOption = ["Not acceptable", "Below average","Average", "Good", "Excellent"]
    const [curAction, setCurAction] = useState("edit")
    var [employeeList, setEmployeeList] = useState([""])
    var [employeeCategory, setEmployeeCategory] = useState([""])
    var [curDetails, setCurDetails] = useState("");
    var [dept, setDept] = useState([])
    var [editable, setEditable] = useState(true);
    const [filled, setFilled] = useState()
    const [field, setField] = useState({
        interviewId: {displayText: "ID", dataType:"text", val:"", disable:false},
        applicantId: {displayText: "Applicant ID", dataType:"text", val:"", disable:false},
        applicantName: {displayText: "Applicant Name", dataType:"text", val:"", required: true, disable:false},
        applicantEmail: {displayText: "Applicant Email", dataType:"text", val:"", required: true, disable:false},
        applyPosition: {displayText: "Applying Position", dataType:"text", val:"", required: true, disable:false},
        department: {displayText: "Department", dataType:"selectOne", val:"", required: true, disable:false},
        reportManager: {displayText: "Reporting Manager", dataType:"selectGroup", val:"", required: true, disable:false},
        interviewDt: {displayText: "Interview Datetime", dataType:"date", val:"", required: true, disable:false},
        interviewType: {displayText: "Interview Type", dataType:"selectOne", val:"", required: true, disable:false},
        createdBy: {displayText: "Created By", dataType:"text", val:"", disable:true},
        createdDt: {displayText: "Created Datetime", dataType:"text", val:"", disable:true},
        managerRecommend: {displayText: "Manager Recommendation", dataType:"selectOne", val:"", required: false, disable:false},
        // managerComment: {displayText: "Manager Comment", dataType:"text", val:"", required: true, disable:false},
        // overallRating: {displayText: "Overall Rating", dataType:"text", val:"", required: true, disable:false},
        canCom:{displayText: "Communication skills", dataType:"selectOne", val:"", required: false, disable:false},
        canApp:{displayText: "Appearance", dataType:"selectOne", val:"", required: false, disable:false},
        canAtt:{displayText: "Attitude", dataType:"selectOne", val:"", required: false, disable:false},
        canMat:{displayText: "Maturity", dataType:"selectOne", val:"", required: false, disable:false},
        canLea:{displayText: "Leadership & teamwork", dataType:"selectOne", val:"", required: false, disable:false},
        canKnow:{displayText: "Knowledge & abilities", dataType:"selectOne", val:"", required: false, disable:false},
        strongPoint: {displayText: "Strong Point", dataType:"textarea", val:"", required: true, disable:false},
        apparentWeakness: {displayText: "Apparent Weakness", dataType:"textarea", val:"", required: true, disable:false},
        generalComments: {displayText: "General Comments", dataType:"textarea", val:"", required: true, disable:false},
        interviewStatus: {displayText: "Interview Status", dataType:"selectOne", val:"", disable:false},
        hrAction: {displayText: "Action", dataType:"checkbox", val:"", disable:false},
        hrDescription: {displayText: "Remark/Reason", dataType:"textarea", val:"", disable:false},
    })
    const interviewData = useFetchPost(
        domain + "/api/RecruitmentModule/interview.php?action=search",
        { interId: routeParams.id },
        refetch
    );

    //font style for rating bar
    const [isHover, setIsHover] = useState("0")
    const [hoverField, setHoverField] = useState()

    function handleMouseEnter(num, fieldName){
        setIsHover(num);
        setHoverField(fieldName);
    }

    function handleMouseLeave(){
        setIsHover("0");
    }

    const buttonStyle = {
        width: '20%', 
        textAlign:'center', 
        border: '1px solid grey', 
        backgroundColor: 'white', 
        cursor:'pointer'
    }

    const buttonHoverStyle = {
        width: '20%', 
        textAlign:'center', 
        border: '1px solid grey', 
        backgroundColor: '#0dcaf0', 
        cursor:'pointer',
        color: 'white',
    }

    const viewButtonStyle = {
        width: '20%', 
        textAlign:'center', 
        border: '1px solid grey', 
        backgroundColor: 'white',
    }

    const viewButtonHoverStyle = {
        width: '20%', 
        textAlign:'center', 
        border: '1px solid grey', 
        backgroundColor: '#0dcaf0', 
        color: 'white',
    }


    const ratingContainer = {
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        paddingTop: '10px'
    }
    //font style for rating bar
    
    function handleOptionsFromDb(empList, dept){
        setEmployeeList(empList)
        let catAry = []
        empList.map((dt)=>{
            if(!catAry.includes(dt[1]) && dt[1] !== "Driver"){
                catAry.push(dt[1])
            }
        })
        setEmployeeCategory(catAry)
        setDept(dept)
    }

    useEffect(() => {
        fetch(
            domain + "/api/RecruitmentModule/manpowerCreateEmployeeInfo.php",
        {
            method: "GET",
        }
        )
        .then((res) => res.json())
        .then((json) => {
            handleOptionsFromDb(json[3], json[1])
        })
        .catch((err) => {
            console.log(err);
        });

        fetch(
            domain + "/api/RecruitmentModule/interview.php?action=getname",
        {
            method: "GET",
        }
        )
        .then((res) => res.json())
        .then((json) => {
            setCurDetails(json[0])
        })
        .catch((err) => {
            console.log(err);
        });
        
        
      }, []);
    
    function handleChanges(varName, value){
        if(varName === "date" || varName === "time"){
            let items = field["interviewDt"]
            var dt = items.val.split(" ")
            if(varName === "date"){
                items.val = value +" "+ dt[1]
            }else{
                items.val = dt[0] +" "+ value+":00"
            }

            setField({
                ...field,
                ["interviewDt"]: {...items}
            })
        }else if(varName === "hrAction"){
            if(value === field["hrAction"].val){
                value = ""
            }

            let items = field[varName]
            items.val = value
            setField({
                ...field,
                [varName]: {...items}
            })
        }else{
            // if(varName === "overallRating"){
            //     // value = value.replace(" ", "")
            //     if(isNaN(value)){
            //         // value = ""
            //         return
            //     }
            // }
            let items = field[varName]
            items.val = value
            setField({
                ...field,
                [varName]: {...items}
            })
        }
    }

    function handleSubmit(e){
        e.preventDefault()

        let inputData = {}
            
        Object.keys(field).map((k)=>{
            inputData[k] = field[k].val
        })


        if(field["hrAction"].val !== "" && field["hrAction"].val !== null){
            if(field["hrDescription"].val === "" || field["hrDescription"].val === null){
                Swal.fire(
                    "Missing info!",
                    "Remark or reason is required",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
            }else{
                Swal.fire({
                title: "You are going to close this interview. Are you sure about this?",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Yes",
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    fetch(
                        domain + "/api/RecruitmentModule/interview.php?action=update",
                        {
                            method: "POST",
                            // mode: "same-origin",
                            //credentials: "same-origin",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(inputData),
                        }
                    )
                    .then((res) => {
                        res.json()
                    })
                    .then((json) => {
                        // console.log(json)
                        Swal.fire(
                            "Success!",
                            "Successfully updated",
                            // json[1],
                            // json[0] === 1 ? "success" : "error"
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            
                    setCurAction("edit")
            
                    history.push("/interview")
                }
                });
            }
        }else{
            fetch(
                domain + "/api/RecruitmentModule/interview.php?action=update",
                {
                    method: "POST",
                    // mode: "same-origin",
                    //credentials: "same-origin",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(inputData),
                }
            )
            .then((res) => {
                res.json()
            })
            .then((json) => {
                Swal.fire(
                    "Success!",
                    "Successfully updated",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
            })
            .catch((err) => {
                console.log(err);
            });

            setCurAction("edit")

            history.push("/interview")
        }
    }

    function viewApplicationForm(){
        localStorage.setItem("applicantId", field.applicantId.val)
        localStorage.setItem("interviewId", field.interviewId.val)
        localStorage.setItem("fromInt", true)
        history.push("/applicationSummary")
    }

    function handleCurAction(){
        setCurAction("submit")
    }

    useEffect(()=>{
        if(interviewData.status === "fetched" && interviewData.data[0] !== undefined){
            Object.keys(interviewData.data[0]).map((k)=>{                
                handleChanges(k, interviewData.data[0][k])
            })

            if(interviewData.data[0]["interviewStatus"] !== "Pending"){
                setEditable(false)
            }
            
            fetch(
                domain + "/api/RecruitmentModule/interview.php?action=getIntFilled&id="+ routeParams.id,
            {
                method: "GET",
            }
            )
            .then(res => res.text())
            .then((res) => {
                if(res){
                    setFilled(JSON.parse(res)[0].filled)
                // }else{
                //     console.log("failed to fetch")
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    },[interviewData.status])
    
    return(
        <div>
            {interviewData.status === "fetched"?
                <CreateRecruitmentEditForm
                    module="Job Interview"
                    field={field}
                    handleChanges={handleChanges}
                    interviewStatus={interviewStatus}
                    recommendOption={recommendOption}
                    interviewType={interviewType}
                    optionGroups={employeeCategory}
                    handleSubmit = {handleSubmit}
                    options={employeeList}
                    dept={dept}
                    filled={filled}
                    permission={permission}
                    viewApplicationForm={viewApplicationForm}
                    curAction={curAction}
                    handleCurAction={handleCurAction}
                    editable={editable}
                    curDetails = {curDetails}
                    evalOption = {evalOption}
                    ratingContainer={ratingContainer}
                    buttonStyle={buttonStyle}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    isHover={isHover}
                    hoverField={hoverField}
                    buttonHoverStyle={buttonHoverStyle}
                    viewButtonStyle={viewButtonStyle}
                    viewButtonHoverStyle={viewButtonHoverStyle}
                />
            :
                <div>
                    Loading....
                </div>
            }
        </div>
    )
}