import React, { useState, useEffect } from "react"
import MainTemplate from '../custom_components/templates/MainTemplate.js'
import {
    useHistory,
    Redirect
} from "react-router-dom"
import { useFetchGet } from 'custom_hooks/Actions.js'

const path = process.env.REACT_APP_PATH

function Logout({ destroySession }) {
    let history = useHistory()
    const logout = useFetchGet("api/EmployeeModule/logout.php")
    const [isLoggedOut, setIsLoggedOut] = useState(false)

    const doDestroySession = () => {
        destroySession()
        setIsLoggedOut(true)
    }

    useEffect(() => {
        doDestroySession()
    }, [logout.status])

    useEffect(() => {
        history.push(path)
    }, [isLoggedOut])

    return (
        <MainTemplate
            content={
                <div>
                    <h6>Log out</h6>
                    <h6>Logging out</h6>
                </div>
            }
        />
    )
}

export default Logout