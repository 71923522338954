import React, { useState, useEffect } from "react"
import {
    useLocation
} from "react-router-dom"
import { TabulatorWrapper } from 'custom_components/Tabulator.js'

const path = process.env.REACT_APP_PATH

function XlsxViewer({ moduleName, columns, moduleData, report }) {
    const location = useLocation()
    function parseColumnsForTabulator(columns) {
        const output = []
        columns.forEach(item => {
            let obj = {}
            switch(item) {
                default:
                    obj = { 
                        title: item, 
                        field: item.toLowerCase().replace(/ /g, "").replace(/[^\w\s]/gi, ""), // to lower and remove spaces then remove special characters
                        headerFilter: "input"
                    }
                    break
            }
            output.push(obj)
        })
        return output
    }
    const parseColumns = parseColumnsForTabulator(columns)

    function parseDataForTabulator(data,parseColumns) {
        let output = [];
        data.forEach((item, index) => {
            let obj = {}
            item.forEach((element, counter) => {
                obj[parseColumns[counter]['field']] = element
            })
            output.push(obj)
        })
        return output;
    }
    const parsedModuleData = parseDataForTabulator(moduleData,parseColumns)

    return (
        <div>
            <h6>{moduleName}</h6>
            <br />
            {
                <TabulatorWrapper
                    display={
                        typeof (moduleData) !== "undefined" && 
                        typeof (moduleData) !== null ? true : false
                    }
                    activeTab={moduleName}
                    data={parsedModuleData}
                    columns={parseColumns}
                    moduleName={moduleName}
                    disablePdfDownload={
                        report === "TE" || report === "TM" || report === "TACA" && true
                    }
                />
            }
        </div>
    )
}

export default XlsxViewer