//Used in reports
function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays //? diffInDays : null;
}

//Used to flip booleans in formatting inputs
function flipTrueFalse(intToBoolean) { 
    let booleanResult
    switch (intToBoolean) {
        case 0:
            booleanResult = true
            break
        case 1:
            booleanResult = false
            break
        default:
            break
    }
    return booleanResult
}

//Unused
async function fetchWithTimedOut(resource, options = {}) {
    const { timeout = 8000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal  
    });
    clearTimeout(id);
    return response;
}

export  { getNumberOfDays, flipTrueFalse }