import Grin from "../../icons/grin.png"
import Smile from "../../icons/smile.png"
import Neutral from "../../icons/neutral.png"
import Sad from "../../icons/sad.png"
import Angry from "../../icons/angry.png"
import Header from "../../icons/helpdeskheader.png"
import { useParams } from "react-router-dom"
import { WidgetSubmitBtn } from "custom_components/Widgets"
import { useEffect, useState } from "react"
import { useFetchPost } from "custom_hooks/Actions"
import Swal from "sweetalert2"

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function HelpdeskRatingForm({from}){
    const routeParams = useParams();
    const [refetch, setRefetch] = useState(0);

    const [selectedEmoji, setSelectedEmoji] = useState()
    const [userComment, setUserComment] = useState()
    const [rateStatus, setRateStatus] = useState()
    const [displayRate, setDisplayRate] = useState()
    const [expiredFeedback, setExpiredFeedback] = useState(false)

    const helpdeskData = useFetchPost(
        domain + "/api/SupportModule/helpdesk.php?action=getSpecificHelpdesk",
        { helpdeskId: routeParams.id },
        refetch
    );

    function parseCustomData(dt){
        dt = dt.trim()
        dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '')
        dt = JSON.parse(dt)
       
       return dt
   }

    function dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    useEffect(()=>{
        if(helpdeskData.status === "fetched"){
            var dt = helpdeskData.data

            Object.keys(dt).map((k)=>{
                if(k === "completedDt"){
                    if(dt["completedDt"] === null){
                        setExpiredFeedback(false)
                    }else{
                        let todayDate = new Date()

                        let completedDate = new Date(dt["completedDt"])
    
                        let differentDate = dateDiffInDays(completedDate,todayDate)
    
                        if(differentDate < -3){
                            setExpiredFeedback(true)
                        }else{
                            setExpiredFeedback(false)
                        }

                    }

                }else if(k === "rateDetail" && from === "sys"){
                    let parsedDt = parseCustomData(dt[k])

                    setDisplayRate(parsedDt)
                    setUserComment(parsedDt["comment"])
                    setSelectedEmoji(parsedDt["rate"])
                }else if(k === "rateStatus"){
                    setRateStatus(dt[k])
                }
            })
            
        }
    },[helpdeskData.status])

    const emotionArray = [
        {content: Angry, selected: false},
        {content: Sad, selected: false},
        {content: Neutral, selected: false},
        {content: Smile, selected: false},
        {content: Grin, selected: false},
    ]

    const selectedEmotion = {
        opacity: "1",
    }

    function handleChanges(itemCat, val){
        if(itemCat === "emoji"){
            setSelectedEmoji(val)
        }else if(itemCat === "comment"){
            setUserComment(val)
        }
    }

    function handleSubmit(e){
        e.preventDefault()

        if(selectedEmoji === "" || selectedEmoji === null || selectedEmoji === undefined){
            Swal.fire(
                "Empty Field Found",
                "You are required to select an expression",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
        }else{
            let inputData = {}

            inputData["helpdeskId"] = routeParams.id
            inputData["rateDetail"] = JSON.stringify({"rate": selectedEmoji, "comment": userComment})

            fetch(
                domain + "/api/SupportModule/helpdesk.php?action=updateRating",
                {
                    method: "POST",
                    // mode: "same-origin",
                    //credentials: "same-origin",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(inputData),
                }
            )
            .then((res) => {
                res.json()
            })
            .then((json) => {
                console.log(json)
                Swal.fire(
                    "Success!",
                    "Thanks for your submission",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
            })
            .catch((err) => {
                console.log(err);
            });

            setRateStatus("Rated")
        }
    }

    if(from === "sys"){
        var displayEmoji = false
        var displayComment = false

        if(expiredFeedback === true || rateStatus === "Rated"){
            displayComment = true
            displayEmoji = true
        }
        
        return(
            <div className="mainDiv">
                <div className="">
                    <h6>Helpdesk Service Rating</h6>
                </div>
                <div className="emotionDiv">
                    {emotionArray.map((k, idx)=>{
                        return(
                            <div className="unhoverableEmotionDiv">
                                <img src={k.content} style={displayEmoji === true ? parseInt(selectedEmoji) === idx? selectedEmotion : {} : {}} />
                            </div>
                        )
                    })}
                </div>
                <div>
                    <textarea rows={6} required={true} disabled={true}className="form-control" value={displayComment === true ? userComment : ""} placeholder="Type your comment here ...  (Anything to improve or complaint)" />
                </div>
                <br />
            </div>
        )
    }else if(from !== "sys"){
        return(
            <form onSubmit={handleSubmit}>
                <div className="mainDiv">
                    <div className="">
                        <img src={Header} style={{width:"100%"}} />
                    </div>
                    {expiredFeedback === false && rateStatus === "Pending" ? 
                        <>
                            <br />
                            <div className="emotionDiv">
                                {emotionArray.map((k,idx)=>{
                                    return(
                                        <div className="innerEmotionDiv">
                                            <img src={k.content} style={selectedEmoji === idx? selectedEmotion : {}} onClick={()=>handleChanges("emoji", idx)} />
                                        </div>
                                    )
                                })}
                            </div>
                            <div>
                                <textarea rows={6} required={false} disabled={false} className="form-control" value={userComment} placeholder="Type your comment here ...  (Anything to improve or complaint)" onChange={(t)=>handleChanges("comment", t.target.value)} />
                            </div>
                            <br />
                            {from !== "sys"? <WidgetSubmitBtn text="Submit" /> : null}
                        </>
                    :
                        <div>
                            <p>{rateStatus === "Pending" ? "Request Expired" : "Feedback Submitted"} </p>
                        </div>
                    }
                    
                </div>
            </form>
        )
    }
}