import { ManpowerCheckbox } from "custom_components/Forms";
import { WidgetFixedAssetBtnGroup } from "custom_components/Widgets";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function AcknowledgementContent({selectedItemDetail, resetFun}){
    const [field, setField] = useState({
        user: {displayText: "Receiver Name", dataType:"text", val:"", required: true, disable: true},
        equipmentNo: {displayText: "Equipment No.", dataType:"text", val:"", required: true, disable: false},
        model: {displayText: "Model No.", dataType:"text", val:"", required: false, disable: true}, 
        seNo: {displayText: "Serial No.", dataType:"text", val:"", required: true, disable: true}, 
        description: {displayText: "Remarks", dataType:"text", val:"", required: true, disable: true}, 
        condition: {displayText: "Condition of Laptop/Desktop", dataType:"date", val:"", required: true, disable: false},
        issuer: {displayText: "Name of Issuer", dataType:"text", val:"", required: true, disable: true},
        issueDate: {displayText: "Date of Issuance", dataType:"text", val:"", required: true, disable: true},
    })

    useEffect(()=>{
        if(selectedItemDetail.length !== 0){
            let fieldData = selectedItemDetail[2]
            Object.keys(fieldData["itemDetail"]).map((k)=>{
                if(k in field){
                    handleChanges(k, fieldData["itemDetail"][k])
                }
            })
            Object.keys(fieldData["issueDetail"]).map((k)=>{
                if(k in field){
                    handleChanges(k, fieldData["issueDetail"][k])
                }
            })
            Object.keys(fieldData["historyUser"][fieldData["historyUser"].length - 1]).map((k)=>{
                if(k in field){
                    handleChanges(k, fieldData["historyUser"][fieldData["historyUser"].length - 1][k])
                }
            })

        }
    },[selectedItemDetail])

    function handleChanges(varName, value){
        let item = field[varName]
    
        item.val = value
        
        setField({
            ...field,
            [varName]: {...item}
        })
    }

    function handleSubmit(e){
        e.preventDefault()
        let passcheck = true

        //validation check on input
        Object.keys(field).map((k)=>{
            if(field[k]["val"] === "" || field[k]["val"] === null ){
                passcheck = false
            }else{
                if(k === "user"){

                }
            }
        })

        if(passcheck != true){
            Swal.fire(
                "Error",
                "Empty field found",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
        }else{
            let updateData = selectedItemDetail[2]["itemDetail"]

            updateData = JSON.stringify({...updateData, "condition": field["condition"].val})

            selectedItemDetail[2]["itemDetail"] = updateData


            fetch(
                domain + "/api/SupportModule/fixedAsset.php?action=updateAcknowledgement",
                {
                    method: "POST",
                    // mode: "same-origin",
                    //credentials: "same-origin",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(selectedItemDetail[2]),
                }
            )
            .then((res) => {
                res.json()
            })
            .then((json) => {
                Swal.fire(
                    "Success!",
                    "Successfully submitted",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );

                resetFun();
            })
            .catch((err) => {
                console.log(err);
            });
        }
        
    }

    function labelPart(){
        return(
            <>
                <br /><hr /><br />
                <p>I have read and understood the above terms and conditions stipulated by the Company and confirm my acceptance of the above. </p>
                <form onSubmit={handleSubmit}>
                    <div className="row staffOtherSDiv">
                        {Object.keys(field).map((k)=>{
                            return(
                                <div className='row col-sm-6 faDiv'>
                                    <label className='col-sm-4'>{field[k].displayText}</label>
                                    <div className='col-sm-6'>
                                        {k === "condition" ? 
                                            <div className='row'>
                                                <div className='col-md-6' style={{ display: 'flex', justifyContent: 'center', paddingTop:'10px'}}>
                                                <div>
                                                    <ManpowerCheckbox type="checkbox" field={k} value={"New"} checked={field[k].val === "New" ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                                                </div>
                                                <div>
                                                    <p>New</p>
                                                </div>
                                                </div>
                                                <div className='col-md-6' style={{ display: 'flex', paddingTop:'10px'}}>
                                                <div>
                                                    <ManpowerCheckbox type="checkbox" field={k} value={"Used"} checked={field[k].val === "Used" ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                                                </div>  
                                                <div>
                                                    <p>Used</p>
                                                </div>
                                                </div>
                                            </div>
                                        :
                                            <input type="text" required={field[k].required} className="form-control" name={k} value={field[k].val} disabled={true} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <WidgetFixedAssetBtnGroup action="submit" />
                </form>
            </>
        )
    }

    switch(selectedItemDetail[1]){
        case "Computer":
            return(
                <div className="faBorder">
                    <h5><u>TERMS & CONDITIONS FOR ISSUANCE OF LAPTOP/DESKTOP</u></h5><br />

                    <p>All employees who have been issued with a laptop/desktop are hereby to abide by the following terms and conditions with effect from </p><br />
                    <ol>
                        <li><p>Laptop/Desktop is the property of GL Engineering & Construction Pte Ltd (“the Company”).</p></li>
                        <li><p>Upon termination of service, the laptop/desktop must be returned to the Company, failing which the employee shall pay a fine of $1,500 or a sum equivalent to the original cost of his/her set of laptop/desktop, whichever is lower, to the Company.</p></li>
                        <li>
                            <p>If the laptop/desktop is stolen/lost/misplaced, employee shall either:</p>
                            <ol type="a">
                                <li><p>pay a fine of $1,500 or a sum equivalent to the original cost of his/her set of laptop/desktop whichever is lower, to the Company; OR</p></li>
                                <li><p>replace a new laptop/desktop with the same specifications at his/her own cost to the Company. <b>(Not applicable for desktop users)</b></p></li>
                            </ol>
                        </li>
                        <li><p>Once a laptop/desktop has been issued to the employee, it is considered as the employee’s property and he/she has to take full ownership and responsibility for the laptop/desktop. Employee shall also ensure that the laptop/desktop is in good working condition except fair wear and tear upon return.</p></li>
                        <li><p>Employee is NOT allowed to transfer his/her laptop/desktop without the prior approval of Admin Department. If the transfer is made without the prior approval of Admin Department, the employee would still be held responsible for the laptop/desktop.</p></li>
                        <li>
                            <p>The Company reserves it’s right to:</p>
                            <ol type="a">
                                <li><p>withdraw/re-allocate the laptop/desktop at all times; and</p></li>
                                <li><p>revise the terms and conditions when it deem necessary.</p></li>
                            </ol>
                        </li>
                        <li><p>Each laptop/desktop has a lifespan. You may request for a new laptop/desktop (After ≥ 3yrs). BUT subject to Company approval.</p></li>
                        <li><p>Computer SHOULD BE used for work related matters only.</p></li>
                    </ol>
                    <br />
                    <p><b>Benefit of a Laptop:</b></p>
                    <ul>
                        <li>If an employee has an internet connection at home and subject to the approval by the Company, a laptop would enable him/her to work from home.</li>
                        <li>If an employee is able to do the proper work at home, he/she would be able to spend time with his/her family especially during dinner time and children’s bed time rather than working overtime in the office.</li>
                    </ul>
                    {labelPart()}
                </div>
            )
            break;
        case "Mobile Phone":
        case "IP Phone":
        case "TMS":
            return(
                <div className="faBorder">
                    <h5><u>TERMS & CONDITIONS FOR ISSUANCE OF MOBILE DEVICE</u></h5><br />

                    <p>All employees who have been issued with a mobile device are hereby to abide by the following terms and conditions with effect from <b>7 July 2023</b>:</p>
                    <ol>
                        <li><p>Mobile Device is the property of GL Engineering & Construction Pte Ltd (“the Company”).</p></li>
                        <li><p>Upon termination of service, the Mobile Device must be returned to the Company, failing which the employee shall pay a fine of $800 or a sum equivalent to the original cost of his/her set of mobile device, whichever is lower, to the Company.</p></li>
                        <li>
                            <p>If the mobile device is stolen/lost/misplaced, employee shall either:</p>
                            <ol type="a">
                                <li><p>pay a fine of 800 or a sum equivalent to the original cost of his/her set of mobile device whichever is lower, to the Company; OR</p></li>
                                <li><p>replace a new mobile device with the same specifications at his/her own cost to the Company.</p></li>
                            </ol>
                        </li>
                        <li><p>Once a mobile device has been issued to the employee, it is considered as the employee’s property and he/she has to take full ownership and responsibility for the mobile device. Employee shall also ensure that the mobile device is in good working condition except fair wear and tear upon return. (Any damages e.g. cracked display, the employee will be pay for the damages.)</p></li>
                        <li><p>Employee is <b><u>NOT</u></b> allowed to transfer his/her mobile device without the prior approval of Admin Department. If the transfer is made without the prior approval of Adm in Department, the employee would still be held responsible for the mobile device.</p></li>
                        <li>
                            <p>The Company reserves it’s right to:</p>
                            <ol type="a">
                                <li><p>withdraw/re-allocate the mobile device at all times; and</p></li>
                                <li><p>revise the terms and conditions when it deem necessary.</p></li>
                            </ol>
                        </li>
                        <li><p>Mobile Device <b><u>SHOULD BE</u></b> used for <b><u>EM Site only</u></b>.</p></li>
                    </ol>
                    {labelPart()}
                </div>
            )
            break;
        case "Walkie Talkie":
        case "Radio Talkie":
            return(
                <div className="faBorder">
                    <h5><u>LETTER OF UNDERTAKING – RADIO WALKIE TALKIE</u></h5><br />

                    <p>All employees who have been issued with <b>Radio Walkie Talkies</b> ("Radio Walkie Talkie holder") are hereby required to abide by the following terms and conditions with effect from <b>8th August 2016</b> :-</p>
                    <ol>
                        <li><p>All Radio Walkie Talkies are the properties of GL Engineering & Construction Pte Ltd ("the Company").</p></li>
                        <li><p>Upon termination of service, Radio Walkie Talkie holder must return his/her Radio Walkie Talkie to the Admin
Department or his/her Head of Department/Project/Site in-charge, failing which the Radio Walkie Talkie holder shall pay a fine of
$500 or a sum equivalent to the original cost of his/her set of Radio Walkie Talkie, whichever is lower, to the Company.</p></li>
                        <li><p>f the Radio Walkie Talkie is lost/misplaced/damaged due to negligence, Radio Walkie Talkie holder shall pay a fine of 
$500 or a sum equivalent to the original cost of his/her set of Radio Walkie Talkie, whichever is lower, to the Company.</p></li>
                        <li><p>Any repair or replacement of Radio Walkie Talkie due to fair wear and tear shall be borne by the Company. In the event of
repair or replacement of Radio Walkie Talkie due to mishandling, the Radio Walkie Talkie holder has to pay 50% of the repair 
or replacement fee.</p></li>
                        <li><p>Radio Walkie Talkie holder shall take full ownership and responsibility of the Radio Walkie Talkie and shall ensure that the 
Radio Walkie Talkie is in good working condition except fair wear and tear upon return.</p></li>
                        <li><p>Radio Walkie Talkie holder is <b><u>NOT</u></b> allowed to transfer his/her Radio Walkie Talkie without the prior approval of Admin
Department or Head of Department/Project/Site in-charge. If the transfer is made without the prior approval of Admin
Department or Head of Department/Project/Site in-charge, the original Radio Walkie Talkie holder would still be held
responsible for the Radio Walkie Talkie. If any Radio Walkie Talkie holder is found to have transferred/exchanged his/her
walkie-talkie without the prior approval of Head of Department/Project/Site in-charge/Admin in-charge, a fine of $50.00 will
be imposed on the transferor (original Radio Walkie Talkie holder) and this sum shall be deducted from his/her salary; Head 
of Department/Project/Site in-charge is excepted.</p></li>
                        <li><p>In the event that the Radio Walkie Talkie is being transferred by the Head of Department/Project/Site in-charge to another
personnel, the Head of Department/Project/Site in-charge is required to inform the Admin Department.</p></li>
                        <li>
                            <p>The Company reserves its right to:</p>
                            <ol type="a">
                                <li><p>withdraw/ re-allocate the Radio Walkie Talkie at all times;</p></li>
                                <li><p>change/modify/terminate the Radio Walkie Talkie plan at any time; and revise these terms and conditions as and </p></li>
                                <li><p>when it deems necessary.</p></li>
                            </ol>    
                        </li>
                    </ol>
                    {labelPart()}

                </div>
            )
            break;
        case "Accessories":
        case "Camera":
        case "Keyboard":
        case "Headset":
        case "Data":
        case "Projector":
        case "Rack":
        case "Server":
        case "Router":
        case "Mouse":
        case "Webcam":
            return(
                <div className="faBorder">
                    <h5><u>TERMS & CONDITIONS FOR ISSUANCE OF IT ACCESSORIES</u></h5><br />

                    <p>All employees who have been issued with IT Accessories are hereby to abide by the following terms and conditions with effect from <b>1 January 2017</b>:</p>
                    <ol>
                        <li><p>All IT Accessories is the property of GL Engineering & Construction Pte Ltd (“the Company”).</p></li>
                        <li>
                            <p>Upon termination of service, all IT accessories must be returned to the Company, failing which the employee shall pay a sum equivalent to the original cost of his/her set of IT accessories to the Company.</p><br />
                            <table className="aTable">
                                <tr>
                                    <th>S/N</th>
                                    <th>IT Accessories</th>
                                    <th>Cost</th>
                                    <th>Quantity</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>TMS</td>
                                    <td>$10000</td>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>iPhone SE cases</td>
                                    <td>$6.5</td>
                                    <td>10</td>
                                </tr>
                            </table><br /><br />
                        </li>
                        <li><p>If the IT accessories is stolen/lost/misplaced, employee shall either:</p>
                            <ol type="a">
                                <li><p>pay a sum equivalent to the original cost of his/her set of IT accessories to the Company, OR</p></li>
                                <li><p>replace a new set of IT accessories with the same specifications at his/her own cost to the Company.</p></li>
                            </ol>
                        </li>
                        <li><p>Any repair or replacement of the IT accessories due to fair wear and tear shall be borne by the Company. In the event of repair or replacement of the IT accessories due to mishandling, the IT accessories holder must pay 50% of the repair or replacement fee. </p></li>
                        <li><p>Once the IT accessories has been issued to the employee, it is considered as the employee’s property and he/she must take full ownership and responsibility for the IT accessories. Employee shall also ensure that the IT accessories is in good working condition except fair wear and tear upon return.</p></li>
                        <li><p>Employee is <b><u>NOT</u></b> allowed to transfer his/her IT accessories without the prior approval of P&I Department. If the transfer is made without the prior approval of P&I Department, the employee would still be held responsible for the IT accessories.</p></li>
                        <li><p>The Company reserves it’s right to: </p>
                            <ol type="a">
                                <li><p>withdraw/re-allocate the IT accessories at all times; and</p></li>
                                <li><p>revise the terms and conditions when it deem necessary.</p></li>
                            </ol>
                        </li>
                    </ol>
                    {labelPart()}
                </div>
            )
            break;
        default:
            return(
                <h6>You are required to select one item first. </h6>
            )
    }
}