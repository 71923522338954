import { useState } from "react"
import Tabs from '../../custom_components/Tabs.js'
import UserAccountDetails from 'pages/employee/UserAccountDetails.js'
import UserAccountAccessRights from 'pages/employee/UserAccountAccessRights.js'
import UserAccountSettings from 'pages/employee/UserAccountSettings.js'
import { WidgetBreadCrumbHeader } from '../../custom_components/Widgets.js'
import { useUserPermission } from "custom_hooks/Actions.js"

function TabDisplay(props) {
    let activeTab = props.activeTab
    let tabs = props.tabs
    switch (activeTab) {
        case tabs[0]:
            return (<UserAccountDetails />)
        case tabs[1]:
            return (<UserAccountAccessRights />)
        case tabs[2]:
            return (<UserAccountSettings />)
        default:
            return (null)
    }
}

function UserAccountInfo(props) {
    const tabs = ["Details", "Access Rights", "Settings"]
    const defaultTab = tabs[0]
    const [activeTab, setActiveTab] = useState(defaultTab)

    return (
        <div>
            <WidgetBreadCrumbHeader prevNav="User Account" curNav="User Account Information" prevLink={"#"} curLink={"#"} />
            <br />
            <Tabs tabs={tabs} state={{ activeTab: [activeTab, setActiveTab] }} onClick={() => setActiveTab(defaultTab)} />
            <TabDisplay activeTab={activeTab} tabs={tabs} />
        </div>
    )
}

export default UserAccountInfo