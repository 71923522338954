import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MainTemplate from "../../custom_components/templates/MainTemplate.js";
import { useUserPermission } from "custom_hooks/Actions.js";
import { useFetchPost, sessionSetLoading } from "../../custom_hooks/Actions.js";


function Module() {
  const permission = useUserPermission(13);
  const domain = process.env.REACT_APP_DOMAIN;

  const passCourseList = useFetchPost(
    domain +  "/api/E-Learning/elearningUtility.php",
    { option: "PassData" }
  ).data;

  const passScoreList = useFetchPost(
    domain + "/api/E-Learning/elearningUtility.php",
    { option: "ScoreData" }
  ).data;


  return (
    <MainTemplate
      content={
        <Switch>
          {permission.add ? <Route path="/:subdom/:page/:module" /> : null}
          {/* <h6> Module Test</h6> */}
            <div>
            <center><h6> Module Test</h6></center>
            <br/>
            <div class="row Module">
                <div class="col-md-4 custom">
                  <div class={`${passCourseList.includes(1) ? 'animate__animated animate bounce card lock-bg' : 'animate__animated animate bounce card unlock-bg' }`} >
                      <div class="container">
                          <img src={`${passCourseList.includes(1) ? '/uploads/elearning/lock.png' : '/uploads/elearning/unlock.png' }`} class="card-img-top img-size" alt="..." />
                      </div>
                      <div class="card-body">
                        <center>
                          <h5 class="card-title ms-1">Module 1 & 2</h5>
                          <h5> Score : { passScoreList != null && typeof passScoreList[1] != 'undefined' ? passScoreList[1] + '%' : '0%' }</h5>
                          <p class="card-text  ms-1">Module brief description will be shown here!</p>
                        </center>
                        <center> 
                          { passCourseList.includes(1) ? 
                          (
                             <button class="btn btn-primary" disabled>Take Test</button>
                          )
                          :
                          (                    
                           <Link to="/module1" ><button class="btn btn-primary" >Take Test</button></Link>
                          )
                          }
                         </center>
                      </div>
                  </div>
                </div>

                <div class="col-md-4 custom">
                <div class={`${passCourseList.includes(3) || !passCourseList.includes(1)? 'animate__animated animate bounce card lock-bg' : 'animate__animated animate bounce card unlock-bg' }`} >
                        <div class="container">
                           <img src={`${passCourseList.includes(3) || !passCourseList.includes(1) ? '/uploads/elearning/lock.png' : '/uploads/elearning/unlock.png' }`} class="card-img-top img-size" alt="..." />
                        </div>
                        <div class="card-body">
                          <center>
                              <h5 class="card-title ms-1">Module 3</h5>
                              <h5> Score : { passScoreList != null && typeof passScoreList[3] != 'undefined'? passScoreList[3] + '%': '0%' }</h5>
                              <p class="card-text ms-1">Module brief description will be shown here!</p>
                          </center>
                          <center> 
                          { passCourseList.includes(3) || !passCourseList.includes(1)? 
                          (
                             <button class="btn btn-primary" disabled>Take Test</button>
                          )
                          :
                          (                    
                           <Link to="/module3" ><button class="btn btn-primary" >Take Test</button></Link>
                          )
                          }
                         </center>
                        </div>
                  </div>
                </div>
                
                <div class="col-md-4 custom">
                <div class={`${passCourseList.includes(4) || !passCourseList.includes(3) ? 'animate__animated animate bounce card lock-bg' : 'animate__animated animate bounce card unlock-bg' }`} >
                      <div class="container">
                         <img src={`${passCourseList.includes(4) || !passCourseList.includes(3) ? '/uploads/elearning/lock.png' : '/uploads/elearning/unlock.png' }`} class="card-img-top img-size" alt="..." />
                      </div>
                      <div class="card-body">
                        <center>
                          <h5 class="card-title ms-1">Module 4</h5>
                          <h5> Score : { passScoreList != null && typeof passScoreList[4] != 'undefined'? passScoreList[4] + '%': '0%' }</h5>
                          <p class="card-text ms-1">Module brief description will be shown here!</p>
                        </center>
                        <center> 
                          { passCourseList.includes(4) || !passCourseList.includes(3)? 
                          (
                             <button class="btn btn-primary" disabled>Take Test</button>
                          )
                          :
                          (                    
                           <Link to="/module4" ><button class="btn btn-primary" >Take Test</button></Link>
                          )
                          }
                         </center>
                      </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-4 custom" >
                <div class={`${passCourseList.includes(5) || !passCourseList.includes(4) ? 'animate__animated animate bounce card lock-bg' : 'animate__animated animate bounce card unlock-bg' }`} >
                      <div class="container">
                          <img src={`${passCourseList.includes(5) || !passCourseList.includes(4) ? '/uploads/elearning/lock.png' : '/uploads/elearning/unlock.png' }`} class="card-img-top img-size" alt="..." />
                      </div>
                      <div class="card-body">
                        <center>
                           <h5 class="card-title ms-1">Module 5</h5>
                           <h5> Score : { passScoreList != null && typeof passScoreList[5] != 'undefined' ? passScoreList[5] + '%' : '0%' }</h5>
                           <p class="card-text ms-1">Module brief description will be shown here!</p>
                        </center>
                        <center> 
                          { passCourseList.includes(5) || !passCourseList.includes(4)? 
                          (
                             <button class="btn btn-primary" disabled>Take Test</button>
                          )
                          :
                          (                    
                           <Link to="/module5" ><button class="btn btn-primary" >Take Test</button></Link>
                          )
                          }
                         </center>  
                      </div>
                  </div>
                </div>

                <div class="col-md-4 custom">
                  <div class={`${passCourseList.includes(6) || !passCourseList.includes(5)? 'animate__animated animate bounce card lock-bg' : 'animate__animated animate bounce card unlock-bg' }`} >
                        <div class="container">
                            <img src={`${passCourseList.includes(6) || !passCourseList.includes(5)? '/uploads/elearning/lock.png' : '/uploads/elearning/unlock.png' }`} class="card-img-top img-size" alt="..." />
                        </div>
                        <div class="card-body">
                          <center><h5 class="card-title ms-1">Module 6</h5>
                          <h5> Score : { passScoreList != null && typeof passScoreList[6] != 'undefined' ? passScoreList[6] + '%' : '0%' }</h5>
                          <p class="card-text ms-1">Module brief description will be shown here!</p>
                          </center>
                          <center> 
                          { passCourseList.includes(6) || !passCourseList.includes(5)? 
                          (
                             <button class="btn btn-primary" disabled>Take Test</button>
                          )
                          :
                          (                    
                           <Link to="/module6" ><button class="btn btn-primary" >Take Test</button></Link>
                          )
                          }
                         </center>
                        </div>
                  </div>
                </div>
                
                {/* <div class="col-md-4 custom">
                <div class={`${passCourseList.includes(6) || !passCourseList.includes(5)? 'animate__animated animate bounce card lock-bg' : 'animate__animated animate bounce card unlock-bg' }`} >
                      <div class="container">
                         <img src={`${passCourseList.includes(6) || !passCourseList.includes(5) ? '/ECMS/uploads/elearning/lock.png' : '/ECMS/uploads/elearning/unlock.png' }`} class="card-img-top img-size" alt="..." />
                      </div>
                      <div class="card-body">
                        <h5 class="card-title ms-1">Module 6</h5>
                        <p class="card-text ms-1">Module brief description will be shown here!</p>
                        <center> 
                          { passCourseList.includes(6) || !passCourseList.includes(5)? 
                          (
                             <button class="btn btn-primary" disabled>Take Test</button>
                          )
                          :
                          (                    
                           <Link to="/ECMS/module7" ><button class="btn btn-primary" >Take Test</button></Link>
                          )
                          }
                         </center>
                      </div>
                    </div>
                </div> */}
            </div>
          </div>

          
        </Switch>
      }
    />
  );
}

export default Module;
