import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from "react"
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/airbnb.css';
import "../css/Forms.css"
import {format} from 'date-fns'



function FlatpickrWithIcon(props) { 
  const icon = <FontAwesomeIcon icon={faCalendarAlt} />;
  const appendTo = document.getElementById('flatpickr-icon-container');
  const dateNow = new Date()
	const dateMin = (dateNow.getFullYear()- 75) + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
	const dateMax = (dateNow.getFullYear()+ 75) + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
  const options = {
      altInput:true,
      altFormat:'d/m/Y',
      allowInput:true,
      enableTime:false,
      // minDate:props.min,
      // maxDate:props.max

  };
  

  return (
    <div className="flatpickr-wrapper">
      <div className="flatpickr-icon-container" id="flatpickr-icon-container">
        {icon}
      </div>
      <Flatpickr
        // {...props}
        value={props.editValue}
        options={options}
        appendTo={appendTo}
        className="flatpickr-input form-control"
        required={false}
        onChange={props.handleDateChange}
        disabled={props.disabled == 0 ? false:true}
        placeholder="dd / mm / yyyy"
        onClose={props.handleDateChange}
      />
    </div>
  );
}



export default FlatpickrWithIcon;