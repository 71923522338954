import { WidgetSubmitBtn } from "custom_components/Widgets";
import MainTemplate from "custom_components/templates/MainTemplate";
import { FullTabulatorTemplate, TabulatorTemplate } from "custom_components/templates/TabulatorTemplate";
import { sessionSetLoading, useFetchGet, useUserPermission } from "custom_hooks/Actions";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function parseManpower(data) {
    let dataForActive = []
    let dataForInactive = []

    try {
        data.forEach((number, index, array) => {
            let obj = {
                no: index+1,
                job_title: array[index].jobTitle,
                work_location: array[index].workingLoc,
                num_of_vacancy: array[index].noVacancy,
                salaryRange: array[index].salaryRange,
                projTitleDept: array[index].projTitleDept,
                request_by: array[index].requestBy,
                date_required: array[index].dateRequired,
                status: array[index].status,
                approveBy: array[index].approveBy,
                manpower_id: array[index].manpowerId,
                action: "Please select an option",
            }
            if(obj.status === "Approved" || obj.status === "Rejected"){
                dataForInactive.push(obj);
            }else{
                dataForActive.push(obj);
            }
            // if (i === 0) {
            //     if (data[i][0] !== 0) {
            //         dataForActive.push(obj);
            //     }
            // } else {
            //     if (data[i][0] !== 0) {
            //         dataForInactive.push(obj);
            //     }
            // }
        })
        
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function ManpowerApproval(){
    const [refetch, setRefetch] = useState(0);
    const [redirect, setRedirect] = useState({ allow: false });
    const approvalData = useFetchGet(
      domain + "/api/RecruitmentModule/manpower.php?action=approvalSearch",
        refetch
    );
    const parsedData = parseManpower(approvalData.data);
    const permission = useUserPermission(17);
    console.log("actual permission", permission)
    const [submitted, setSubmitted] = useState(false);
    const [inputData, setInputData] = useState();
    const tabs = ["Active", "History"];
    const columns = [
        { title: " S/no.", field: "no", headerFilter: "input"},
        {
            title: "Job Position", field: "manpower_id", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "job_title",
                urlPrefix: "manpower/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.manpower_id }) //here we update state
            }
        },
        {
          title: "Action",
          field: "action",
          name: "action",
          headerFilter: "input",
          editor: "select",
          editorParams: {
            values: ["Please select an option", "Approved", "Rejected"],
            defaultValue: "Please select an option",
            elementAttributes: {
              //style: "color:red;"
            },
          },
        },
        { title: "Location", field: "work_location", headerFilter: "input" },
        { title: "No of vacancy", field: "num_of_vacancy", headerFilter: "input" },
        { title: "Salary Range", field: "salaryRange", headerFilter: "input" },
        { title: "Department/Project", field: "projTitleDept", headerFilter: "input" },
        { title: "Request by", field: "request_by", headerFilter: "input" },
        { title: "Required Date", field: "date_required", headerFilter: "input" },
        { title: "Status", field: "status", headerFilter: "input" },
    ]

    const altColumns = [
        { title: " S/no.", field: "no", headerFilter: "input"},
        {
            title: "Job Position", field: "manpower_id", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "job_title",
                urlPrefix: "manpower/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.manpower_id }) //here we update state
            }
        },
        { title: "Location", field: "work_location", headerFilter: "input" },
        { title: "No of vacancy", field: "num_of_vacancy", headerFilter: "input" },
        { title: "Salary Range", field: "salaryRange", headerFilter: "input" },
        { title: "Department/Project", field: "projTitleDept", headerFilter: "input" },
        { title: "Request by", field: "request_by", headerFilter: "input" },
        { title: "Required Date", field: "date_required", headerFilter: "input" },
        { title: "Status", field: "status", headerFilter: "input" },
        { title: "Approve by", field: "approveBy", headerFilter: "input" },
    ]

    function handleRefetch() {
        setRefetch(Math.random());
    }    

    function handleSubmit(e){
      e.preventDefault()
      if (permission.editApproval) {
        let emptyRemark = true;
        parsedData[0].forEach((element) => {
          if (element.action !== "Please select an option") {
            emptyRemark = false;
          }
        });
        if (emptyRemark === false) {
          setInputData(parsedData[0]);
          setSubmitted(true);
        } else {
          Swal.fire(
            "Warning!",
            "At least one row with action without remark, please input remark for row(s) with action",
            "warning"
          );
        }
      }
      else{
        Swal.fire("Warning!", "User not authorised to submit request", "warning");
      }
      // console.log("pd", parsedData)
    }

    useEffect(()=>{
      if(submitted && permission.editApproval){
        function prepareData(inputData) {
          let data = [];
          inputData.forEach((element) => {
            if (element.action !== "Please select an option") {
              data.push(element);
            }
          });
          return { inputdata : data};
        }
        let data = prepareData(inputData);

        // sessionSetLoading(true);
        console.log("data",data)

        fetch(
          domain + "/api/RecruitmentModule/manpower.php?action=approvalUpdate",
          {
            method: "POST",
            // mode: "same-origin",
            //credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          }
          )
          .then(res => {
              if(res.statusText === "OK"){
                  console.log(res)
                  setSubmitted(false)
                  handleRefetch()
                  Swal.fire(
                      "Success!",
                      "Successfully submitted",
                      // json[1],
                      // json[0] === 1 ? "success" : "error"
                  );
              }else{
                  console.log("error")
              }
          })
          .catch((err) => {
              console.log(err);
          });
      }
    },[submitted])

    if (redirect.allow) {
        return (
          <Redirect push to={"/manpower/" + redirect?.id} />
        );
      } else {
        return (
          <MainTemplate
            content={
              approvalData.status === "fetched" ? (
                <form onSubmit={handleSubmit}>
                  <TabulatorTemplate
                    moduleName={"Manpower Approval"}
                    columns={columns}
                    altColumns={altColumns}
                    moduleData={parsedData}
                    tabs={tabs}
                    disableWidget={true}
                    moduleWidgets={
                      permission.editApproval ? (
                        <WidgetSubmitBtn text="Confirm Manpower approval action(s)" />
                      ) : null
                    }
                    handleRefetch={handleRefetch}
                  />
                 
                </form>
              ) : approvalData.status === "fetching" ? (
                <div>Loading...</div>
              ) : 
              // <div>Loading1...</div>
                <TabulatorTemplate
                  moduleName={"Manpower Approval"}
                  columns={columns}
                  altColumns={altColumns}
                  moduleData={parsedData}
                  tabs={tabs}
                  disableWidget={true}
                  handleRefetch={handleRefetch}
                  disablePdfDownload={true}
              />
              
            }
          />
        );
      }
}

export default ManpowerApproval