import React from "react"
import {
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom"
import ChangePassword from 'pages/ChangePassword.js'
import Test from 'pages/test.js'
import Login from 'pages/Login.js'
import Dashboard from 'pages/Dashboard.js'
import Employee from 'pages/employee/Employee.js'
import UserAccount from 'pages/employee/UserAccount.js'
import Application from 'pages/training/Application.js'
import Approval from 'pages/training/Approval.js'
import Recommend from 'pages/training/Recommend.js'
import Report from 'pages/report/Report.js'
import Sitemap from "pages/Sitemap"
import Logout from 'pages/Logout.js'
import Module from "pages/E-Learning/Module"
import Module1 from "pages/E-Learning/Module1"
import Module3 from "pages/E-Learning/Module3"
import Module4 from "pages/E-Learning/Module4"
import Module5 from "pages/E-Learning/Module5"
import Module6 from "pages/E-Learning/Module6"
import Video from "pages/E-Learning/Video"
import Hazard from "pages/HSE/Hazard.js";
import HomeLeaveApplication from "pages/homeleave/HomeLeaveApplication.js";
import HomeLeaveApproval from "pages/homeleave/HomeLeaveApproval.js";
import './css/App.css'
import './css/Nav.css'
import Manpower from "pages/manpower/Manpower.js";
import Interview from "pages/interview/Interview.js";
import ManpowerApproval from "pages/manpower/ManpowerApproval"
import InterviewApproval from "pages/interview/InterviewApproval"
import InterviewRecommend from "pages/interview/InterviewRecommend"
import StaffAppraisal from "pages/appraisal/StaffAppraisal";
import ManagerApproval from "pages/appraisal/ManagerApproval";
import DirectorApproval from "pages/appraisal/DirectorApproval";
import SupervisorAppraisal from "pages/appraisal/SupervisorAppraisal";
import AppraisalSetupContainer from "pages/appraisal/AppraisalSetup";
import Helpdesk from "pages/support/Helpdesk";
import Requisition from "pages/support/ItRequisition";
import RequisitionApproval from "pages/support/ItRequisitionApproval";
import FixedAsset from "pages/support/FixedAsset";

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

// Top level routes are contained here, other pages may have their own routes

function Routes({ authenticated,authorized, companyCode, destroySession }) {

  
  if (authorized) {
  
    return (
      <Switch>
        <Route exact path={ "/changepassword"}>
          <ChangePassword
            authorized={authenticated}
          />
        </Route>
        {/* <Route exact path={path + "/"}>
          <Redirect to={path + "/db"} />
        </Route> */}
        <Route path={"/manpower"}>
          <Manpower />
        </Route>
        <Route path={"/manpowerApproval"}>
          <ManpowerApproval />
        </Route>
        <Route path={"/interviewApproval"}>
          <InterviewApproval />
        </Route>
        <Route path={"/interviewRecommend"}>
          <InterviewRecommend />
        </Route>
        <Route path={"/interview"}>
          <Interview />
        </Route>
        <Route path={"/sa"}>
          <StaffAppraisal />
        </Route>
        <Route path={"/ma"}>
          <ManagerApproval />
        </Route>
        <Route path={"/da"}>
          <DirectorApproval />
        </Route>
        <Route path={"/supera"}>
          <SupervisorAppraisal />
        </Route>
        <Route path={"/sasetup"}>
          <AppraisalSetupContainer />
        </Route>
        <Route path={ "/db"}>
          <Dashboard />
        </Route>
        <Route path={ "/employee"}>
          <Employee />
        </Route>
        <Route path={ "/useraccount"}>
          <UserAccount />
        </Route>
        <Route path={ "/application"}>
          <Application />
        </Route>
        <Route path={ "/approval"}>
          <Approval />
        </Route>
        <Route path={ "/recommend"}>
          <Recommend />
        </Route>
        <Route path={"/hd"}>
          <Helpdesk />
        </Route>
        <Route path={"/itreq"}>
          <Requisition />
        </Route>
        <Route path={"/itapp"}>
          <RequisitionApproval />
        </Route>
        <Route path={"/fa"}>
          <FixedAsset />
        </Route>
        <Route path={ "/report"}>
          <Report />
        </Route>
        <Route path={ "/homeleaveapplication"}>
          <HomeLeaveApplication />
        </Route>
        <Route path={ "/homeleaveapproval"}>
          <HomeLeaveApproval />
        </Route>
        <Route path={ "/hazard"}>
          <Hazard />
        </Route>
        <Route path={ "/module"}>
          <Module />
        </Route>
        <Route path={"/module1"}>
          <Module1 />
        </Route>
        <Route path={"/module3"}>
          <Module3 />
        </Route>
        <Route path={"/module4"}>
          <Module4 />
        </Route>
        <Route path={"/module5"}>
          <Module5 />
        </Route>
        <Route path={"/module6"}>
          <Module6 />
        </Route>
        <Route path={"/video"}>
          <Video />
        </Route>
        <Route path={"/hazard"}>
          <Hazard />
        </Route>
        <Route path={ "/sitemap"}>
          <Sitemap />
        </Route>
        <Route exact path={ "/logout"}>
          <Logout destroySession={destroySession} />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    )
  } else {
  
    return <Redirect to={ "/login"} />
  }


}

    
function PageNotFound() {
  let location = useLocation()
  return (
    <div>
      <div className="dark-bg"></div>
      <div id="error-box">404 Page not found for <code>{location.pathname}</code></div>
    </div>
  )
}

export default Routes
