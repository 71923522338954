import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import "../css/Login.css";
import { WidgetErrorMsg } from "custom_components/Widgets";
import loginLogo from "../icons/WINS final logo.png";
import loginBg from "../icons/loginBg.png";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function Login({
  authenticated,
  loginInfo,
  handleOnChange,
  handleSubmit,
  errormsg,
  setErrorMsg,
}) {
  const [submit, OnSubmit] = useState(false);
  const [flCheck, setFlCheck] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const fields = [
    { name: "Company code", inputname: "cc", type: "text", required: true },
    { name: "Username", inputname: "user", type: "text", required: true },
    { name: "Password", inputname: "pass", type: "password", required: true },
  ];

  const handleSubmitActions = (e) => {
    handleSubmit(e);
    handleOnChangeSubmit(e);
  };

  const handleOnChangeSubmit = (e) => {
    OnSubmit(submit ? false : true);
  };

  const fieldItems = fields.map((field) => {
    return (
      <input
        key={field.name}
        className="form-control mb-1"
        type={field.type}
        placeholder={field.name}
        name={field.inputname}
        value={loginInfo[field.name]}
        onChange={handleOnChange}
        required={field.required}
      />
    );
  });

  useEffect(() => {
    if (authenticated) {
      // window.location.toString().split(path)[0] + path +
      fetch(domain + "/api/EmployeeModule/checkLogin.php", {
        method: "GET",
        // mode: "same-origin",
        //credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((json) => {
          setFirstLogin(json);
        })
        .catch((err) => {});
    }
  }, [authenticated]);

  useEffect(() => {
    if (firstLogin === 0 || firstLogin === 1) {
      setFlCheck(true);
    }
  }, [firstLogin]);

  return authenticated && flCheck ? (
    <Redirect push to={firstLogin === 0 ? "/changepassword" : "/db"} />
  ) : (
    <div>
      <div className="loginBgBase"></div>
      <div className="loginWave1"></div>
      <div className="loginWave2"></div>
      <div className="loginWave3"></div>
      <div className="loginMainContainer">
        <div className="loginLeft">
          <img src={loginBg} />
        </div>
        <div className="loginRight">
          <img src={loginLogo} />
          <form onSubmit={handleSubmitActions}>
            <div>
              <label><b>Company code</b></label>
              <input type={"text"} required={true} className="form-control" disabled={false} onChange={handleOnChange} name={"cc"}
        value={loginInfo["Company code"]} />
              <label><b>Username</b></label>
              <input type={"text"} required={true} className="form-control" disabled={false} onChange={handleOnChange} name={"user"}
        value={loginInfo["Username"]} />
              <label><b>Password</b></label>
              <input type={"password"} required={true} className="form-control" disabled={false} onChange={handleOnChange} name={"pass"}
        value={loginInfo["Password"]} />
            </div>
            <button type="submit" className="newloginBtn">
              Login
            </button>
            
          </form>
          <WidgetErrorMsg
              submit={submit} 
              submitOnChange={handleOnChangeSubmit} 
              errormsg={errormsg ? errormsg : null}
              setErrorMsg={setErrorMsg}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
