import { CreateRecruitmentEditForm } from "custom_components/templates/FormTemplate";
import { useFetchPost } from "custom_hooks/Actions";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function ManpowerInfo({permission}){
    const history = useHistory();
    const routeParams = useParams();
    const [refetch, setRefetch] = useState(0);
    var [employeeList, setEmployeeList] = useState([""])
    var [employeeCategory, setEmployeeCategory] = useState([""])
    var [dept, setDept] = useState([])
    const [curAction, setCurAction] = useState("edit")
    var [editable, setEditable] = useState(true);
    const interviewStatus = ["Pending", "Approved", "Rejected"]
    const yesAndNoOption = ["Yes", "No"]
    const [field, setField] = useState({
        manpowerId: {displayText: "ID", dataType:"text", val:"", disable:false},
        requestBy: {displayText: "Request by", dataType:"text", val:"", required: true, disable:true},
        requestDate: {displayText: "Request date", dataType:"date", val:"", disable:true},
        manpowerType: {displayText: "Manpower Type", dataType:"checkbox", val:"", required: true},
        jobTitle: {displayText: "Job Title", dataType:"text", val:"", required: true, disable:false},
        workingLoc: {displayText: "Working location", dataType:"text", val:"", required: true, disable:false},
        workingHour: {displayText: "Working hours", dataType:"time", val:"", required: true, disable:false},
        salaryRange: {displayText: "Salary Range", dataType:"text", val:"", required: true, disable:false},
        noVacancy: {displayText: "Number of vacancies", dataType:"text", val:"", required: true, disable:false},
        projTitleDept: {displayText: "Project title/department", dataType:"selectOne", val:"", required: true, disable:false},
        dateRequired: {displayText: "Date required", dataType:"date", val:"", required: true, disable:false},
        headCountNeeded: {displayText: "Is this headcount needed throughout the remaining duration of project", dataType:"selectOne", val:"", required: true, disable:false},
        remark: {displayText: "Remarks", dataType:"text", val:"", required: true, disable:false},
        status: {displayText: "Status", dataType:"selectOne", val:"", disable:false},
    })

    const manpowerData = useFetchPost(
        domain + "/api/RecruitmentModule/manpower.php?action=search",
        { manId: routeParams.id },
        refetch
    );

    function handleOptionsFromDb(empList, dept){
        setEmployeeList(empList)
        let catAry = []
        empList.map((dt)=>{
            if(!catAry.includes(dt[1]) && dt[1] !== "Driver"){
                catAry.push(dt[1])
            }
        })
        setEmployeeCategory(catAry)
        setDept(dept)
    }

    useEffect(() => {
        fetch(
            domain + "/api/RecruitmentModule/manpowerCreateEmployeeInfo.php",
        {
            method: "GET",
        }
        )
        .then((res) => res.json())
        .then((json) => {
            handleOptionsFromDb(json[3], json[1])
        })
        .catch((err) => {
            console.log(err);
        });
    
      }, []);

    function handleChanges(varName, value){
        let items = field[varName]
        items.val = value
        setField({
            ...field,
            [varName]: {...items}
        })
    }

    function handleCurAction(){
        setCurAction("submit")
    }

    useEffect(()=>{
        if(manpowerData.status === "fetched" && manpowerData.data[0] !== undefined){
            Object.keys(manpowerData.data[0]).map((k)=>{                
                handleChanges(k, manpowerData.data[0][k])
            })
            
            if(manpowerData.data[0]["status"] !== "Pending"){
                setEditable(false)
            }
        }
    },[manpowerData.status])

    function handleSubmit(e){
        e.preventDefault()
        let inputData = {}

        Object.keys(field).map((k)=>{
            inputData[k] = field[k].val
        })

        fetch(
            domain + "/api/RecruitmentModule/manpower.php?action=update",
            {
                method: "POST",
                // mode: "same-origin",
                //credentials: "same-origin",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(inputData),
            }
        )
        .then((res) => {
            res.json()
        })
        .then((json) => {
            Swal.fire(
                "Success!",
                "Successfully updated",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
        })
        .catch((err) => {
            console.log(err);
        });

        history.push("/manpower")
    }

    return(
        <div>
            {manpowerData.status === "fetched"?
                <CreateRecruitmentEditForm
                    module="Manpower Aquisition"
                    field={field}
                    handleChanges={handleChanges}
                    interviewStatus={interviewStatus}
                    // interviewType={interviewType}
                    yesAndNoOption={yesAndNoOption}
                    optionGroups={employeeCategory}
                    handleSubmit = {handleSubmit}
                    options={employeeList}
                    permission={permission}
                    dept={dept}
                    curAction={curAction}
                    handleCurAction={handleCurAction}
                    editable = {editable}
                />
            :
                <div>
                    Loading....
                </div>
            }
        </div>
    )
}