import { useState, useEffect } from "react"
import {
    BrowserRouter as Router,
    Redirect,
    useParams,
    useLocation,
    useHistory,
} from "react-router-dom"
import { WidgetCreateFormBtnGrp } from "../../custom_components/Widgets.js"
import { ImportImageForm } from '../../custom_components/Forms.js'
import { sessionSetLoading, useUserPermission } from "custom_hooks/Actions.js"
import Swal from "sweetalert2"
import  FlatpickrWithIcon1  from '../../custom_components/FlatPicker1';

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function EmployeeCertInputName(props) {
    const [employeeName, setEmployeeName] = useState("")
    
    useEffect(() => {
        if (
            employeeName === "" ||
            employeeName === null ||
            employeeName === "undefined"
        ) {
            fetch(
                // window.location.toString().split(path)[0] +
                // path +
                domain + "/api/EmployeeModule/employee_createemployeename.php",
                {
                    method: "POST",
                    mode: "same-origin",
                    credentials: "same-origin",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        employeeCode: props.employeeCode,
                    }),
                }
            )
                .then((res) => res.json())
                .then((json) => {
                    setEmployeeName(json[0]);
                })
                .catch((err) => err);
        }
    }, []);

    return (
        <div className="form-group row">
            <label className="col-sm-3">Name</label>
            <div className="col-sm-9">
                <input
                    type="text"
                    className="form-control"
                    value={employeeName}
                    disabled
                />
            </div>
        </div>
    );
}

function EmployeeCertActionCreate(props) {
    const inputNames = props.inputNames
    const inputName = props.inputName
    const inputData = props.inputData
    const handleOnChange = props.handleInputChange
    const formData = props.formData
    const expiryDateThreshold = props.expiryDateThreshold

    const [selectedDate, setSelectedDate] = useState(null);
  

    const handleDateChange = (date) => {
    
        if(date != null){
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
            const day = ("0" + dateObj.getDate()).slice(-2);
            const newDate = `${year}-${month}-${day}`;
            setSelectedDate(newDate);
        }
        
        
    };
    useEffect(() => {
        if(selectedDate){
            handleOnChange(props.inputName,selectedDate);
        }
    },[selectedDate]);

    const dateNow = new Date()
    const dateMinNow = dateNow.getFullYear() + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
	const dateMin = (dateNow.getFullYear()- 75) + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
	const dateMax = (dateNow.getFullYear()+ 75) + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
    return (
        <div className="form-group row">
            {
                inputName === inputNames[1] ?
                    null
                    :
                    <label className="col-sm-3">{inputName}</label>
            }
            <div className="col-sm-9">
                {
                    inputName === inputNames[1] ?
                        null
                    : inputName === inputNames[2] || inputName === inputNames[3] ? 
                        // <input
                        //     type="date"
                        //     className="form-control"
                        //     value={inputData[inputName]}
                        //     name={inputName}
                        //     onChange={handleOnChange}
                        //     required={inputName === inputNames[3] ? false : true}
                        //     min = {
                        //         inputName === inputNames[2] ? dateMin : 
                        //         inputName === inputNames[3] ? (expiryDateThreshold ? expiryDateThreshold : dateMin) : 
                        //         dateMin
                        //     }
                        //     max = {dateMax}
                        // />
                        <FlatpickrWithIcon1
                            editValue={inputData[inputName]}
                            name={inputName}
                            value={selectedDate}
                            handleDateChange={handleDateChange}
                            disabled={0}
                        />
                    : 
                        <input
                            type="text"
                            className="form-control"
                            value={inputData[inputName]}
                            list={inputName}
                            name={inputName}
                            onChange={handleOnChange}
                            required
                        />
                }
            </div>
            {
                formData[0] &&
                    <datalist id={inputName}>
                        {inputName === inputNames[0]
                            ? formData[0].map((data) => (
                                <option key={data} value={data}></option>
                            ))
                            : inputName === inputNames[4]
                                ? formData[1].map((data) => (
                                    <option key={data} value={data}></option>
                                ))
                                : null}
                    </datalist>
            }
            
        </div>
    )
}

//update and delete
function EmployeeCertActionUpdate(props) {
    const inputNames = props.inputNames
    const inputName = props.inputName
    const inputData = props.inputData
    const handleOnChange = props.handleInputChange
    const handleOnChangeSwap = props.handleInputSwap
    const certData = props.certData
    const formData = props.formData
    const expiryDateThreshold = props.expiryDateThreshold

    const [selectedDate, setSelectedDate] = useState(null);

	  
    const handleDateChange = (date) => {
    
        if(date != null){
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
            const day = ("0" + dateObj.getDate()).slice(-2);
            const newDate = `${year}-${month}-${day}`;
            setSelectedDate(newDate);
        }			
    };

    useEffect(() => {
        if(selectedDate){
            handleOnChange(props.inputName,selectedDate);
        }
    },[selectedDate]);

    function handleOnCertChange(e) {
        for (let i = 0; i < certData.length; i++) {
            if (e.target.value === certData[i][1]) {
                handleOnChange(e);
                handleOnChangeSwap(inputNames, certData[i]);
            }
        }
    }

    let actionIsRemove = false
    if (props.action === "remove") {
        actionIsRemove = true
    }
    let actionIsFail = false
    if (props.action === "fail") {
        actionIsFail = true
    }

    const dateNow = new Date()
    const dateMinNow = dateNow.getFullYear() + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
	const dateMin = (dateNow.getFullYear()- 75) + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
	const dateMax = (dateNow.getFullYear()+ 75) + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
    return (
        <div className="form-group row">
            <label className="col-sm-3">{inputName}</label>
            <div className="col-sm-9">
                {inputName === inputNames[0] || inputName === inputNames[1] ? (
                    <select
                        type="text"
                        className="form-control"
                        value={inputData[inputName]}
                        list={inputName}
                        name={inputName}
                        onChange={inputName === inputNames[0] ? handleOnCertChange : handleOnChange}
                        disabled={inputName === inputNames[1]  && true}
                        required
                    >
                        {certData.map((cert) => {
                            let dataField = inputName === inputNames[0] ? cert[1] : cert[0]
                            return (
                                <option key={dataField} name={dataField} value={dataField}>
                                    {dataField}
                                </option>
                            );
                        })}
                    </select>
                ) : inputName === inputNames[2] || inputName === inputNames[3] ? (
                    <FlatpickrWithIcon1
                        editValue={inputData[inputName]}
                        name={inputName}
                        value={selectedDate}
                        handleDateChange={handleDateChange}
                        disabled={0}
                        
                    />
                    // <input
                    //     type="date"
                    //     className="form-control"
                    //     value={inputData[inputName]}
                    //     list={inputName}
                    //     name={inputName}
                    //     onChange={handleOnChange}
                    //     disabled={actionIsRemove || actionIsFail}
                    //     required={inputName === inputNames[3] ? false : true}
                    //     min = {
                    //         inputName === inputNames[2] ? dateMin : 
                    //         inputName === inputNames[3] ? (expiryDateThreshold ? expiryDateThreshold : dateMin) : 
                    //         dateMin
                    //     }
                    //     max = {dateMax}
                    // />
                ) 
                // : inputName === inputNames[5]  ?  (
                //     <input
                //         type="text"
                //         className="form-control"
                //         value={inputData[inputName]}
                //         list={inputName}
                //         name={inputName}
                //         onChange={handleOnChange}
                //         disabled={0}
                //         required
                //     />
                // )
                : (
                    <input
                        type="text"
                        className="form-control"
                        value={inputData[inputName]}
                        list={inputName}
                        name={inputName}
                        onChange={handleOnChange}
                        disabled={actionIsRemove || actionIsFail || (inputName === inputNames[4])}
                        required
                    />
                )}
            </div>
            {
                formData[1] &&
                <datalist id={inputName}>
                    {inputName === inputNames[4] &&
                        formData[1].map((data) => (
                            <option key={data} value={data}></option>
                        ))}
                </datalist>
            }
            
        </div>
    );
}

function EmployeeCertActions(props) {
    const { id } = useParams();
    const [action, setAction] = useState("");
    const [certData, setCertData] = useState([""]);
    const [formData, setFormData] = useState([""]);
    const [inputData, setInputData] = useState({});
    const [submitted, setSubmitted] = useState(0);
    const [validated, setValidated] = useState(0);
    const [redirect, setRedirect] = useState(0);
    const defaultSelectInput = "Select an option";
    const inputNames = [
        "Certificates Name",
        "Cert ID",
        "Issue Date",
        "Expiry Date",
        "Training Centre",
        // "Remark",
    ];
    const exceptInputData = [inputNames[1],inputNames[2]];
    const permission = useUserPermission(1)
    const [expiryDateThreshold, setexpiryDateThreshold] = useState()

    function handleSelectChange(e) {
        setAction(e.target.value);
        document.getElementById("cert-form").reset();
    }

    const handleInputChange = (e,date) => {
        if(typeof(e) == 'object'){
            setInputData({
                ...inputData,
                [e.target.name]: e.target.value,
            });
            if (e.target.name === inputNames[2]) {
                setexpiryDateThreshold(e.target.value)
            }
        }else{
           
            setInputData({
                ...inputData,
                [e]: date,
            });
        }
    };

    const handleInputSwap = (inputNames, certData) => {
        //WHY CANT I LOOP THIS?
        setInputData({
            ...inputData,
            [inputNames[0]]: certData[1],
            [inputNames[1]]: certData[0],
            [inputNames[2]]: certData[2],
            [inputNames[3]]: certData[3],
            [inputNames[4]]: certData[4],
            // [inputNames[5]]: '',
        });
    };

    useEffect(() => {
        if (submitted === 0 && validated === 0) {
            sessionSetLoading(true)
            fetch(
                domain + "/api/EmployeeModule/employee_createcertinfo.php",
                {
                    method: "GET",
                    mode: "same-origin",
                    credentials: "same-origin",
                }
            )
                .then((res) => res.json())
                .then((json) => {
                    setFormData(json);
                })
                .catch((err) => console.log(err));

            fetch(
                // window.location.toString().split(path)[0] +
                // path +
               domain + "/api/EmployeeModule/employee_certificate.php",
                {
                    method: "POST",
                    mode: "same-origin",
                    credentials: "same-origin",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        employeeCode: id,
                    }),
                }
            )
                .then((res) => res.json())
                .then((json) => {
                    try {
                        json[0].unshift(["", defaultSelectInput, "", "", ""]);
                        setCertData(json[0]);
                    } catch {}
                    sessionSetLoading()
                })
                .catch((err) => console.log(err));
        }
    }, [submitted, validated]);

    useEffect(() => {
        if (submitted === 1 && validated === 1 && action) {
            if (
                action === "add" && permission.add ||
                action === "edit" && permission.edit ||
                action === "remove" && permission.del ||
                action === "fail" && permission.del
            ){
                // let fetchUrl = window.location.toString().split(path)[0] + path + domain + "/api/EmployeeModule/";
                let fetchUrl =   domain + "/api/EmployeeModule/";
                switch (action) {
                    case "add":
                        fetchUrl += "employee_createcert.php";
                        break;
                    case "edit":
                        fetchUrl += "employee_updatecert.php";
                        break;
                    case "remove":
                        fetchUrl += "employee_deletecert.php";
                        break;
                    case "fail":
                        fetchUrl += "employee_deletecert.php";
                        break;
                    default:
                        break;
                }
                let formData = new FormData()
                formData.append("fileType",inputData["file-uploadType"])
                formData.append("file",inputData["file-uploadData"])
                formData.append("inputData", JSON.stringify({
                    action: action,
                    employeeCode: id,
                    certID: inputData[inputNames[1]],
                    certName: inputData[inputNames[0]],
                    issueDate: inputData[inputNames[2]],
                    expiryDate: inputData[inputNames[3]],
                    trainingProvider: inputData[inputNames[4]],
                    // remark : inputData[inputNames[5]]
                }))
                fetch(fetchUrl, {
                    method: "POST",
                    mode: "same-origin",
                    credentials: "same-origin",
                    body: formData
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json[0] === 1) {
                            let forms = document.getElementsByTagName("form")
                            for (let form of forms) {
                                form.reset()
                            }
                            window.scrollTo(0, 0);
                            setRedirect(1);
                        } 
                        setSubmitted(0);
                        setValidated(0);
                        Swal.fire(
                            json[0] === 1 ? 'Success!' : 'Error!',
                            json[1],
                            json[0] === 1 ? 'success' : 'error'
                        )
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }, [submitted, validated]);

    function handleSubmit(e) {
        e.preventDefault();
        try {
            let target = document.getElementById("formFile")
            setInputData({
                ...inputData,
                [target.name]: URL.createObjectURL(target.files[0]),
                [target.name+"Type"]: target.value.split('.').pop(),
                [target.name+"Data"]: target.files[0]
            })
        } catch {
        }
        for (let inputName in inputData) {
            let isAnExcept = 0;
            for (let exceptNum in exceptInputData) {
                if (inputName === exceptInputData[exceptNum]) {
                    isAnExcept = 1;
                }
            }
            if (isAnExcept === 0) {
                if (
                    inputData[inputName] === defaultSelectInput ||
                    inputData[inputName] === "" ||
                    inputData[inputName] === null ||
                    inputData[inputName] === "undefined"
                ) {
                    setValidated(0);
                    setSubmitted(0);
                } else {
                }
            } else {
                break;
            }
        }
        
        setValidated(1);
        setSubmitted(1);
    }

    if (redirect === 1) {
        return <Redirect to={ "/employee/" + id} />;
    } else {
        return (
            <div>
                <div className="tab-grid">
                    <form id="cert-form" onSubmit={handleSubmit}>
                        <EmployeeCertInputName employeeCode={id} />
                        <div className="form-group row">
                            <label className="col-sm-3">Actions</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control"
                                    value={action}
                                    onChange={handleSelectChange}
                                    setInputData={setInputData}
                                >
                                    <option value="">Select an option</option>
                                    {
                                        permission.add ? <option value="add">Add</option> : null
                                    }
                                    {
                                        certData.length > 1 && permission.edit ? <option value="edit">Edit</option> : null
                                    }
                                    {
                                        certData.length > 1 && permission.del ? <option value="remove">Remove</option> : null
                                    }
                                    {
                                        certData.length > 1 && permission.del ? <option value="fail">Fail</option> : null
                                    }
                                </select>
                            </div>
                        </div>
                        {inputNames.map((inputName) =>
                            action === "add" && permission.add ? (
                                <EmployeeCertActionCreate
                                    key={inputName}
                                    inputName={inputName}
                                    inputNames={inputNames}
                                    formData={formData}
                                    inputData={inputData}
                                    handleInputChange={handleInputChange}
                                    expiryDateThreshold={expiryDateThreshold}
                                />
                            ) : action === "remove" && permission.del || action === "fail" && permission.del || action === "edit" && permission.edit ? (
                                <EmployeeCertActionUpdate
                                    key={inputName}
                                    action={action}
                                    inputName={inputName}
                                    inputNames={inputNames}
                                    certData={certData}
                                    formData={formData}
                                    inputData={inputData}
                                    handleInputChange={handleInputChange}
                                    handleInputSwap={handleInputSwap}
                                    expiryDateThreshold={expiryDateThreshold}
                                />
                            ) : null
                        )}
                        {
                            action && action !== "remove" && action !== "fail" &&
                                <ImportImageForm 
                                    text="Upload certificate PDF (in color)" 
                                    acceptedTypes={".jpg, .jpeg, .png, .pdf, .pdx"} 
                                    enabled={true} 
                                    onChange={handleInputChange}
                                    required={false}
                                />
                        }
                        <WidgetCreateFormBtnGrp />
                    </form>
                </div>
            </div>
        );
    }
}

export default EmployeeCertActions;