import { CreateRecruitmentForm } from "custom_components/templates/FormTemplate";
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from "react-router-dom"
import MainTemplate from "custom_components/templates/MainTemplate";
import { useUserPermission } from "custom_hooks/Actions";
import { FullTabulatorTemplate } from "custom_components/templates/TabulatorTemplate";
import { useState } from "react";

import CreateFixedAsset from "./CreateFixedAsset";
import FixedAssetDetail from "./FixedAssetDetail";
// import StaffInfo from "./StaffInfo";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

var userCode = ""
var userRole = ""

function parseCustomData(dt){
    dt = dt.trim()
    dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '')
    dt = JSON.parse(dt)
   
   return dt
}

function parseFixedAsset(data) {
    let dataForActive = []
    let dataForInactive = []

    try {
        userCode = data[2]
        userRole = data[1]
        data[0].forEach((number, index, array) => {
            let itemDetail = parseCustomData(array[index].itemDetail)
            let issueDetail = parseCustomData(array[index].issueDetail)

            let obj = {
                no: index+1,
                category: array[index].category,
                subCategory: array[index].subCategory,
                purchaseDate: itemDetail.purchaseDate,
                warrantyDate: itemDetail.warrantyDueDate,
                user: issueDetail.receiver,
                status: array[index].status,
                remark: array[index].remark,
                assetId: array[index].assetId,
                eqNo: itemDetail.equipmentNo,
            }


            dataForActive.push(obj);
        
        })
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function FixedAsset({permission}){
    const [redirect, setRedirect] = useState({ allow: false })
    const location = useLocation()
    const tabs = ["Active"];
    const columns = [
        { title: " S/no.", field: "no", headerFilter: "input"},
        {
            title: "Equipment No", field: "eqNo", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "eqNo",
                urlPrefix: "fa/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.assetId }) //here we update state
            }
        },
        { title: "Category", field: "category", headerFilter: "input" },
        { title: "Sub-Category", field: "subCategory", headerFilter: "input" },
        { title: "Purchase Date", field: "purchaseDate", headerFilter: "input" },
        { title: "Warranty Due Date", field: "warrantyDate", headerFilter: "input" },
        { title: "Remark", field: "remark", headerFilter: "input" },
        { title: "Status", field: "status", headerFilter: "input" },
    ]

    return(
        redirect.allow ?
            <Redirect push to={location.pathname+"/"+redirect?.id} />
        :
        <FullTabulatorTemplate 
            permission = {permission}
            fetchURL = {domain + "/api/SupportModule/fixedAsset.php?action=getAllFixedAsset"}
            parseFunc = {parseFixedAsset}
            columns = {columns}
            moduleName = {"Fixed Asset"}
            redirect = {redirect}
            tabs={tabs}
            disableWidget = {false}
            from="fa"
        />
    )
}

function FixedAssetContainer(){
    const perm = useUserPermission(33);

    return(
        <MainTemplate content={
            <Switch>
                 {
                    <Route path="/:page/create" children={<CreateFixedAsset />} />
                }
                {/*{
                    perm.view ? <Route path="/:page/:id/details" children={<EmployeeInfo defaultTab={"Details"} />} /> : null
                }
                {
                    perm.view ? <Route path="/:page/:id/cert" children={<EmployeeInfo defaultTab={"Certificate"} />} /> : null
                }*/}
                {
                    <Route path="/:page/:id" children={<FixedAssetDetail />} />
                }
                {
                    <Route path="" children={<FixedAsset permission={perm} />} />
                } 
            </Switch>
        }
        />
    )
}

export default FixedAssetContainer