import { useState } from "react";
import Tabs from '../../custom_components/Tabs.js';
import HomeLeaveApplicationDetails from 'pages/homeleave/HomeLeaveApplicationDetails.js';

import { WidgetBreadCrumbHeader } from '../../custom_components/Widgets.js'

function TabDisplay(props) {
    let activeTab = props.activeTab;
    let tabs = props.tabs;
    switch (activeTab) {
        case tabs[0]:
            return (<HomeLeaveApplicationDetails />);
        default:
            return (null);
    }
}

function ApplicationInfo(props) {
    const tabs = ["Details"];
    const defaultTab = tabs[0];
    const [activeTab, setActiveTab] = useState(defaultTab);
    return (
        <div>
            <WidgetBreadCrumbHeader prevNav="Home Leave Application" curNav="Information" prevLink={"#"} curLink={"#"} />
            <br />
            {/* <Tabs tabs={tabs} state={{ activeTab: [activeTab, setActiveTab] }} onClick={() => setActiveTab(defaultTab)} /> */}
            <TabDisplay activeTab={activeTab} tabs={tabs} />
        </div>
    )
}

export default ApplicationInfo;