import React, { useState, useEffect } from "react"
import {
    Redirect,
    useLocation
} from "react-router-dom"
import { useUserPermission } from "custom_hooks/Actions.js"
import Chart from "react-google-charts"
import { WidgetBackBtn } from "custom_components/Widgets"
import MainTemplate from "custom_components/templates/MainTemplate"

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function Sitemap(props) {
    let location = useLocation()
    const [redirect, setRedirect] = useState(false)

    useEffect(() => {
        const element = document.getElementById("content-box")
        element.addEventListener('click', (e) => {
            e.preventDefault()
            if (e.target.href) {
                setRedirect(e.target.href.split(domain)[1])
               
            }
        })
    }, [])

    

    if (redirect) {
        return <Redirect push to={redirect} />
    } else {
        return (
            <MainTemplate
                content={
                    <>
                    <Chart
                        chartType="OrgChart"
                        loader={<span className="gg-spinner" alt="Loading Chart" />}
                        data={[
                            ['Name', 'Parent', 'ToolTip'],
                            [{'v':"ecms", 'f':`<a href='${location.pathname.replace('/sitemap','')}'>ECMS</a>`},'', 'Test',],
                            [{'v':"dashboard", 'f':`<a href='${location.pathname.replace('/sitemap','')}'>Dashboard module'</a>`},'ecms', ''],
                            [{'v':'sitemap', 'f':`Sitemap`},'dashboard', ''],
                            [{'v':'employee', 'f':'Employee module'},'dashboard', ''],
                                [{'v':'emp', 'f':`<a href='${location.pathname.replace('/sitemap','/employee')}'>Employee list</a>`},'employee', ''],
                                    [{'v':'empCr', 'f':`<a href='${location.pathname.replace('/sitemap','/employee/create')}'>Create employee</a>`},'emp', ''],
                                    [{'v':'empDetail', 'f':'Employee details'},'emp', ''],
                                [{'v':'user', 'f':`<a href='${location.pathname.replace('/sitemap','/useraccount')}'>User account module</a>`},'employee', ''],
                                    [{'v':'userCr', 'f':`<a href='${location.pathname.replace('/sitemap','/useraccount/create')}'>Create user account</a>`},'user', ''],
                                    [{'v':'userDetail', 'f':'User account details'},'user', ''],
                            [{'v':'training', 'f':'Training module'},'dashboard', ''],
                                [{'v':'app', 'f':`<a href='${location.pathname.replace('/sitemap','/training')}'>Training application</a>`},'training', ''],
                                    [{'v':'appCr', 'f':`<a href='${location.pathname.replace('/sitemap','/training/create')}'>Create Training application</a>`},'app', ''],
                                    [{'v':'appDetail', 'f':'Training application details'},'app', ''],
                                [{'v':'aprv', 'f':`<a href='${location.pathname.replace('/sitemap','/approval')}'>Training approval</a>`},'training', ''],
                                [{'v':'reco', 'f':`<a href='${location.pathname.replace('/sitemap','/recommend')}'>Training recommend</a>`},'training', ''],
                            [{'v':'report', 'f':`<a href='${location.pathname.replace('/sitemap','/report')}'>Report module</a>`},'dashboard', ''],
                                [{'v':'reportGen', 'f':'Report details'},'report', ''],
                            [{'v':'support', 'f':`<a href='${location.pathname.replace('/sitemap','/support')}'>Support module</a>`},'dashboard', ''],
                                [{'v':'supportCr', 'f':`<a href='${location.pathname.replace('/sitemap','/support/create')}'>Create support ticket</a>`},'support', ''],
                                [{'v':'supportDetail', 'f':'Support details'},'support', ''],
                            ['Login and Logout', 'dashboard', ''],
                        ]}
                        options={{
                            allowHtml: true,
                            vAxis: {
                                minValue: 0, gridlines: {
                                    color: 'transparent'
                                }
                            },
                            legend: 'none',
                            animation: {
                                startup: true,
                                easing: 'linear',
                                duration: 1500,
                            },
                            enableInteractivity: false,
                        }}
                    />
                    <WidgetBackBtn />
                    </>
            }
            />
        )
    }
}

export default Sitemap