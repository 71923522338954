import React, { useState, useEffect } from "react"
import {
    useParams,
} from "react-router-dom"
import Swal from "sweetalert2"
import { Input, SelectInput, SelectInputWithGroup, ScoreInput } from '../../custom_components/Forms.js'
import { CreateFormTemplate } from '../../custom_components/templates/FormTemplate'
import { sessionSetLoading, useFetchPost, useUserPermission } from '../../custom_hooks/Actions.js'

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function ApplicationFeedback(props) {
    const { id } = useParams()
    const [refetch, setRefetch] = useState(0)
    const feedbackData = useFetchPost(
        // window.location.toString().split(path)[0] + path +
          domain + "/api/TrainingModule/trainingFeedback.php?action=search", 
        { trainingID: id },
        refetch
    )
    const [inputData, setInputData] = useState({})
    const [submitted, setSubmitted] = useState(0)
    const [validated, setValidated] = useState(0)
    const defaultSelectInput = "Select an option"
    const fields = [
        { name: "Rating of Programme", inputname:"programme", type: "score", required: true, options:[
            "Achievement of programme objectives",
            "Achievement of personal objectives",
            "Amount of knowledge of topics acquired",
            "Relevance of course materials",
            "Design and Organisation",
            "Overall rating of the course materials"
        ]},
        { name: "Effectiveness of Trainer(s)", inputname:"trainer", type: "score", required: true, options:[
            "Trainer's preparation",
            "Trainer's knowledge of topic",
            "Ability to hold your interest",
            "Effective use of teaching aids",
            "Interaction with group",
            "Overall rating of the Trainer"
        ]},
        { name: "Rating of Facilities", inputname:"facilities", type: "score", required: true, options:[
            "Audio-visual equipment",
            "Classroom environment (lighting, air-con, etc)",
            "Computer equipment",
            "Overall rating of the training facilities"
        ]},
        { name: "Participant's Attitude", inputname:"attitude", type: "score", required: true, options:[
            "As a learner, I was",
            "My learning attitude during the course was"
        ]},
        { name: "Other Comments", type: "label"},
        { name: "What did you find particularly useful/not useful about the programme?", inputname:"other1", type: "textarea", required: false },
        { name: "Would you recommend this course for others? Why?", inputname:"other2", type: "textarea", required: false },
        { name: "How did you find the course duration?", inputname:"other3", type: "select", required: false, options:["Too Short","Just Right","Too Long"] },
        { name: "Other comments about the course, if any", inputname:"other4", type: "textarea", required: false }
    ]
    const notRequiredInputData = []
    const permission = useUserPermission(4)

    const handleChangeInput = (e) => {
        if(typeof(e) == 'object'){
            setInputData({
                ...inputData,
                [e.target.name]: e.target.type ==="checkbox" ? e.target.checked : e.target.value
            })
        }
        
    }

    function createInputObj(json) {
        let a = {}
        for (let i = 0 ;i < fields.length; i++) {
            let inputName = fields[i].inputname
            switch(fields[i].type) {
                case "score":
                    fields[i].options.forEach((field, index) => {
                        let inputName = fields[i].inputname + index
                        a[inputName] = json[inputName] ? json[inputName].toString() : ""
                    })
                    break
                case "label":
                    break
                default:
                    a[inputName] = json[inputName] ? json[inputName] : ""
                    break
            }
        }
        return a
    }

    useEffect(() => {
        setInputData(createInputObj(feedbackData.data))
    }, [feedbackData.data,refetch])

    useEffect(() => {
        if (submitted === 1 && validated === 1) { // check whether there is existing record
            sessionSetLoading(true)
            fetch(
                // window.location.toString().split(path)[0] + path + 
                 domain + "/api/TrainingModule/trainingFeedback.php?action=search", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    trainingID: id,
                })
            })
                .then(res => res.json())
                .then(json => {
                    // window.location.toString().split(path)[0] + path +
                    let url =   domain + "/api/TrainingModule/trainingFeedback.php?action=add"
                    if(json[0] == undefined){ // if undefined, update the records in payment
                        //feature not implemented
                        //url = window.location.toString().split(path)[0] + path +  domain + "/api/TrainingModule/trainingFeedback.php?action=update"
                    }
                    inputData.trainingID = id; // adding training app ID
                    fetch(url, {
                        method: "POST",
                        mode: "same-origin",
                        credentials: "same-origin",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(inputData)
                    })
                        .then(res => res.json())
                        .then(json => {
                            if (json[0] === 1){
                                window.scrollTo(0, 0)
                                setRefetch(1)
                            }
                            setSubmitted(0)
                            setValidated(0)
                            Swal.fire(
                                json[0] === 1 ? 'Success!' : 'Error!',
                                json[1],
                                json[0] === 1 ? 'success' : 'error'
                            )
                            sessionSetLoading()
                        })
                        .catch(err => { console.log(err) })
                })
                .catch(err => { console.log(err) })
        }
    }, [submitted, validated])


    const fieldItems = fields.map((field, index) => {
        return (
            field.type === "label" ? 
                <div key={field.name}>
                    <h6>{field.name}</h6>
                </div>
            :
                field.type === "textarea" || field.type === "select"  ?
                    <div className="form-group row" key={field.inputname} >
                        <label htmlFor={field.inputname} className="col-sm-3 ">{field.name}</label>
                        <div className="col-sm-9">
                            {
                                field.type === "select" ? 
                                    <SelectInput
                                        field={field.inputname}
                                        value={inputData[field.inputname]}
                                        options={field.options}
                                        defaultOption={defaultSelectInput}
                                        requiredOption={field.required}
                                        onChangeInput={handleChangeInput}
                                    />
                                :
                                    <Input
                                        field={field.inputname}
                                        type={field.type}
                                        value={inputData[field.inputname]}
                                        requiredOption={field.required}
                                        onChangeInput={handleChangeInput}
                                    />
                            }
                            
                        </div>
                    </div>
                :
                    <div className="form-group form-score-grid mb-4" key={field.inputname} name={field.inputname} onChange={handleChangeInput} >
                        <div>
                            <h6>{field.name}</h6>
                        </div>
                        <label className="form-check-label">Excellent</label>
                        <label className="form-check-label">Good</label>
                        <label className="form-check-label">Satisfactory</label>
                        <label className="form-check-label">Poor</label>
                        <label className="form-check-label">N.A.</label>
                        {
                            field.options.map((option, optIndex) => {
                                const inputField = inputData[field.inputname + optIndex] ? inputData[field.inputname + optIndex].toString() : null
                                return (
                                    <React.Fragment>
                                        <label key={option} className="form-check-label">{option}</label>
                                        <input key={field.inputname + 4} className="form-check-input mx-3" type="radio" name={field.inputname + optIndex} value="4" required={field.required} defaultChecked={inputField === "4" ? true : null}></input>
                                        <input key={field.inputname + 3} className="form-check-input mx-3" type="radio" name={field.inputname + optIndex} value="3" defaultChecked={inputField === "3" ? true : null}></input>
                                        <input key={field.inputname + 2} className="form-check-input mx-3" type="radio" name={field.inputname + optIndex} value="2" defaultChecked={inputField === "2" ? true : null}></input>
                                        <input key={field.inputname + 1} className="form-check-input mx-3" type="radio" name={field.inputname + optIndex} value="1" defaultChecked={inputField === "1" ? true : null}></input>
                                        <input key={field.inputname + 0} className="form-check-input mx-3" type="radio" name={field.inputname + optIndex} value="0" defaultChecked={inputField === "0" ? true : null}></input>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
        )
    })

    function handleSubmit(e) {
        e.preventDefault()
        for (let inputName in inputData) {
            let isAnExcept = 0
            for (let exceptNum in notRequiredInputData) {
                if (inputName === notRequiredInputData[exceptNum]) {
                    isAnExcept = 1
                }
            }
            if (isAnExcept === 0) {
                if (inputData[inputName] === defaultSelectInput || inputData[inputName] === "" || inputData[inputName] === null || inputData[inputName] === "undefined") {
                    setValidated(0)
                    setSubmitted(0)
                } else {
                    break
                }
            } else {
                break
            }
        }
        setValidated(1)
        setSubmitted(1)
    }

    return (
        permission.view ? 
            feedbackData.status === "fetched" ?
                feedbackData.data.mode === "restricted" ?
                    <div>User is unauthorized to view training application feedback</div>
                :
                    <>
                        {
                            <div className="row mb-3">
                                <label className="col-sm-4 form-check-label">Training Centre: </label>
                                <label className="col-sm-5 form-check-label">{feedbackData.data.feedbackInfo.trainingCentre ? feedbackData.data.feedbackInfo.trainingCentre : null}</label>
                                <label className="col-sm-4 form-check-label">Employee Feedback Date: </label>
                                <label className="col-sm-5 form-check-label">{feedbackData.data.feedbackInfo.feedbackDate ? feedbackData.data.feedbackInfo.feedbackDate : null}</label>
                            </div>
                        }
                        {
                            permission.add &&
                            <CreateFormTemplate 
                                handleSubmit={handleSubmit}
                                fieldItems={fieldItems}
                                importCsv={false}
                                disabled={feedbackData.data.mode === "edit" ? false : true}
                            />
                        }
                    </>
            :
                <div>Loading...</div>
        :
            <div>You do not have user role access for this component.</div>
    )
}

export default ApplicationFeedback