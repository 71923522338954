import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import MainTemplate from "../../custom_components/templates/MainTemplate.js";
import { TabulatorTemplate } from "custom_components/templates/TabulatorTemplate.js";
import {
  sessionSetLoading,
  useFetchGet,
  useUserPermission,
} from "../../custom_hooks/Actions.js";
import { WidgetSubmitBtn } from "../../custom_components/Widgets.js";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function parseHomeLeave(data) {
  let dataForActive = [];
  let dataForInactive = [];
  try {
    for (let i = 0; i < data.length; i++) {
      data[i].forEach((number, index, array) => {
        let tempItem = array[index];
        if (i === 0) {
          const obj = {
            no: index + 1,
            id: tempItem["HLID"],
            action: "Please select an option",
            Status: tempItem["Status"],
            EmployeeCode: tempItem["EmployeeCode"],
            EmployeeName: tempItem["EmployeeName"],
            Department: tempItem["Department"],
            Designation: tempItem["Designation"],
            LeaveType: tempItem["LeaveType"],
            StartDate: tempItem["StartDate"],
            EndDate: tempItem["EndDate"],
            DateJoined: tempItem["DateJoined"],
            Remark: tempItem["Remark"],
            submitby: tempItem["AccountSubmitFrom_Name"],
            remarks: "",
          };
          if (data[i][0] !== 0) {
            dataForActive.push(obj);
          }
        } else {
          const obj = {
            no: index + 1,
            id: tempItem["HLID"],
            action: "Please select an option",
            Status: tempItem["Status"],
            EmployeeCode: tempItem["EmployeeCode"],
            EmployeeName: tempItem["EmployeeName"],
            Department: tempItem["Department"],
            Designation: tempItem["Designation"],
            LeaveType: tempItem["LeaveType"],
            StartDate: tempItem["StartDate"],
            EndDate: tempItem["EndDate"],
            DateJoined: tempItem["DateJoined"],
            Remark: tempItem["Remark"],
            submitby: tempItem["AccountSubmitFrom_Name"],
            remarks: "",
          };
          if (data[i][0] !== 0) {
            dataForInactive.push(obj);
          }
        }
      });
    }
  } catch {
    return ["", ""];
  }
  const parsedData = [dataForActive, dataForInactive];
  return parsedData;
}

function Approval(props) {
  const [refetch, setRefetch] = useState(0);
  // window.location.toString().split(path)[0] + path +
  const approvalData = useFetchGet(
    domain + "/api/HomeLeaveModule/HomeLeaveApproval.php",
    refetch
  );
  const parsedData = parseHomeLeave(approvalData.data);
  const tabs = ["Active", "History"];
  const [inputData, setInputData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [redirect, setRedirect] = useState({ allow: false });
  // const permission = useUserPermission(2)
  const permission = useUserPermission(15);

  function handleRefetch() {
    setRefetch(Math.random());
  }

  useEffect(() => {
    if (submitted === true && permission.editLeaveApproval) {
      function prepareData(inputData) {
        let data = [];
        inputData.forEach((element) => {
          if (element.action !== "Please select an option") {
            data.push(element);
          }
        });
        return { inputData: data };
      }
      let data = prepareData(inputData);
      sessionSetLoading(true);
      // window.location.toString().split(path)[0] + path +
      fetch(
        domain + "/api/HomeLeaveModule/HomeLeaveApproval.php?action=update",
        {
          method: "POST",
          // mode: "same-origin",
          //credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (json[0] === 1) {
            handleRefetch();
          }
          setSubmitted(false);
          Swal.fire(
            json[0] === 1 ? "Success!" : "Error!",
            json[1],
            json[0] === 1 ? "success" : "error"
          );
          sessionSetLoading();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [submitted]);

  const columns = [
    { title: "Sr/No.", field: "no", headerFilter: "input" },
    {
      title: "HomeLeaveID",
      field: "id",
      headerFilter: "input",
      formatter: "link",
      formatterParams: {
        labelField: "id",
        urlPrefix: "application/",
      },
      //hacky way to make tabulator play well with react router
      cellClick: function (e, cell) {
        e.preventDefault();
        const rowInfo = cell._cell.row.data;
        setRedirect({ allow: true, id: rowInfo.id }); //here we update state
      },
    },
    {
      title: "Action",
      field: "action",
      name: "action",
      headerFilter: "input",
      editor: "select",
      editorParams: {
        values: ["Please select an option", "Approve", "Reject", "Withdraw"],
        defaultValue: "Please select an option",
        elementAttributes: {
          //style: "color:red;"
        },
      },
    },

    { title: "Status", field: "Status", headerFilter: "input" },
    { title: "Employee Code", field: "EmployeeCode", headerFilter: "input" },
    { title: "Employee Name", field: "EmployeeName", headerFilter: "input" },
    { title: "Department", field: "Department", headerFilter: "input" },
    { title: "Designation", field: "Designation", headerFilter: "input" },
    { title: "LeaveType", field: "LeaveType", headerFilter: "input" },
    { title: "StartDate", field: "StartDate", headerFilter: "input" },
    { title: "EndDate", field: "EndDate", headerFilter: "input" },
    { title: "DateJoined", field: "DateJoined", headerFilter: "input" },
    { title: "Submit by", field: "AccountSubmitFrom_Name", headerFilter: "input" },
    {
      title: "Remarks",
      field: "Remark",
      name: "Remark",
      headerFilter: "input",
      formatter: "textarea",
      editor: "textarea",
      editorParams: {
        elementAttributes: {
          maxlength: "150",
        },
        verticalNavigation: "editor",
      },
    },
  ];

  const altColumns = [
    { title: "Sr/No.", field: "no", headerFilter: "input" },
    {
      title: "HomeLeaveID",
      field: "id",
      headerFilter: "input",
      formatter: "link",
      formatterParams: {
        labelField: "id",
        urlPrefix: "application/",
      },
      cellClick: function (e, cell) {
        e.preventDefault();
        const rowInfo = cell._cell.row.data;
        setRedirect({ allow: true, id: rowInfo.id }); //here we update state
      },
    },
    { title: "Status", field: "Status", headerFilter: "input" },
    { title: "Employee Code", field: "EmployeeCode", headerFilter: "input" },
    { title: "Employee Name", field: "EmployeeName", headerFilter: "input" },
    { title: "Department", field: "Department", headerFilter: "input" },
    { title: "Designation", field: "Designation", headerFilter: "input" },
    { title: "LeaveType", field: "LeaveType", headerFilter: "input" },
    { title: "StartDate", field: "StartDate", headerFilter: "input" },
    { title: "EndDate", field: "EndDate", headerFilter: "input" },
    { title: "DateJoined", field: "DateJoined", headerFilter: "input" },
    { title: "Submit by", field: "AccountSubmitFrom_Name", headerFilter: "input" },
  ];

  function handleSubmit(e) {
    e.preventDefault();
    if (permission.editLeaveApproval) {
      let emptyRemark = true;
      parsedData[0].forEach((element) => {
        if (element.action !== "Please select an option") {
          emptyRemark = false;
        }
      });
      if (emptyRemark === false) {
        setInputData(parsedData[0]);
        setSubmitted(true);
      } else {
        Swal.fire(
          "Warning!",
          "At least one row with action without remark, please input remark for row(s) with action",
          "warning"
        );
      }
    } else {
      Swal.fire("Warning!", "User not authorised to submit request", "warning");
    }
  }

  if (redirect.allow) {
    return (
      <Redirect from={path} push to={ "/homeleaveapplication/" + redirect?.id} />
    );
  } else {
    return (
      <MainTemplate
        content={
          approvalData.status === "fetched" ? (
            <form onSubmit={handleSubmit}>
              <TabulatorTemplate
                moduleName={"Home Leave Approval"}
                columns={columns}
                altColumns={altColumns}
                moduleData={parsedData}
                tabs={tabs}
                disableWidget={true}
                moduleWidgets={
                  permission.editLeaveApproval ? (
                    <WidgetSubmitBtn text="Confirm Home Leave approval action(s)" />
                  ) : null
                }
                handleRefetch={handleRefetch}
              />
             
            </form>
          ) : approvalData.status === "fetching" ? (
            <div>Loading...</div>
          ) : (
            <div>Home Leave Approval is not available</div>
          )
        }
      />
    );
  }
}

export default Approval;
