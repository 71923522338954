import React, { useState, useEffect } from "react"
import {
    Switch,
    Route,
    Redirect,
    useLocation
} from "react-router-dom"
import MainTemplate from '../../custom_components/templates/MainTemplate.js'
import { TabulatorTemplate } from 'custom_components/templates/TabulatorTemplate.js'
import { sessionSetLoading, useFetchGet, useUserPermission } from '../../custom_hooks/Actions.js';
import { WidgetSubmitBtn } from '../../custom_components/Widgets.js'
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function parseTraining(data) {
    let dataForActive = []
    let dataForInactive = []
    try {
        for (let i = 0; i < data.length; i++) {
            data[i].forEach((number, index, array) => {
                let tempItem = array[index]
                if (i === 0) {
                    const obj = {
                        no: index+1,
                        id: tempItem['TAID'],
                        status: tempItem['Status'],
                        code: tempItem['EmployeeCode'],
                        name: tempItem['Name'],
                        course: tempItem['CourseName'],
                        duration: tempItem['duration'],
                        action: "Please select an option",
                        remarks: ""
                    }
                    if (data[i][0] !== 0) {
                        dataForActive.push(obj);
                    }
                } else {
                    const obj = {
                        no: index+1,
                        id: tempItem['TAID'],
                        status: tempItem['Status'],
                        code: tempItem['EmployeeCode'],
                        name: tempItem['Name'],
                        course: tempItem['CourseName'],
                        remarks: tempItem['Remarks']
                    }
                    if (data[i][0] !== 0) {
                        dataForInactive.push(obj);
                    }
                }
            })
        }
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function Recommend(props) {
    let location = useLocation()
    const [refetch, setRefetch] = useState(0)
    // window.location.toString().split(path)[0] + path + 
    const recommendData = useFetchGet( domain + "/api/TrainingModule/trainingRecommend.php?action", refetch)
    const parsedData = parseTraining(recommendData.data)
    const tabs = ["Active", "History"]
    const [inputData,setInputData] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [redirect, setRedirect] = useState({ allow: false })
    const permission = useUserPermission(8)

    function handleRefetch() {
        setRefetch(Math.random())
    }
    
    useEffect(() => {
        if (submitted === true && permission.edit) {
            function prepareData(inputData) {
                let data = []
                inputData.forEach(element => {
                    if (element.action !== "Please select an option") {
                        data.push(element)
                    }
                })
                return {inputData: data}
            }
            let data = prepareData(inputData)
            sessionSetLoading(true)
            // window.location.toString().split(path)[0] + path + 
            fetch( domain + "/api/TrainingModule/trainingRecommend.php?action=update", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
                .then(res => res.json())
                .then(json => {
                    if (json[0] === 1) {
                        handleRefetch()
                    }
                    setSubmitted(false)
                    Swal.fire(
                        json[0] === 1 ? 'Success!' : 'Error!',
                        json[1],
                        json[0] === 1 ? 'success' : 'error'
                    )
                    sessionSetLoading()
                })
                .catch(err => { console.log(err) })
        }
    }, [submitted])

    const columns = [
        { title: "Sr/No.", field: "no" , headerFilter: "input"},
        {
            title: "Training Application ID", field: "id", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "id",
                urlPrefix: "application/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.id }) //here we update state
            }
        },
        { title: "Employee Code", field: "code", headerFilter: "input" },
        { title: "Employee Name", field: "name", headerFilter: "input" },
        { title: "Course Name", field: "course", headerFilter: "input" },
        { title: "Action", field: "action", name: "action", headerFilter: "input", editor:"select", editorParams:{
            values:["Please select an option", "Recommend", "Reject", "Withdraw"],
            defaultValue:"Please select an option",
            elementAttributes:{
                //style: "color:red;"
            }
        }},
        { title: "Remarks", field: "remarks", name: "remarks", headerFilter: "input", formatter:"textarea", editor:"textarea", editorParams:{
            elementAttributes:{
                maxlength:"150",
            },
            verticalNavigation:"editor",
        }}
    ]

    const altColumns = [
        { title: "Sr/No.", field: "no" , headerFilter: "input"},
        {
            title: "Training Application ID", field: "id", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "id",
                urlPrefix: "application/"
            }
        },
        { title: "Employee Code", field: "code", headerFilter: "input" },
        { title: "Employee Name", field: "name", headerFilter: "input" },
        { title: "Course Name", field: "course", headerFilter: "input" },
        { title: "Status", field: "course", headerFilter: "input" },
        { title: "Remarks", field: "remarks", headerFilter: "input" },
    ]

    function handleSubmit(e) {
        e.preventDefault()
        if (permission.edit) {
            // let emptyRemark = false
            // parsedData[0].forEach(element => {
            //     if (element.action !== "Please select an option" && element.remarks === "") {
            //         emptyRemark = true
            //     }
            // })
            // if (emptyRemark === false) {
            //     setInputData(parsedData[0])
            //     setSubmitted(true)
            // } else {
            //     Swal.fire(
            //         'Warning!',
            //         'At least one row with action without remark, please input remark for row(s) with action',
            //         'warning'
            //     )
            // }
            let emptyRemark = true
            parsedData[0].forEach(element => {
               
                if (element.action !== "Please select an option" ) {
                    emptyRemark = false
                }
            })
            if (emptyRemark === false) {
                
                setInputData(parsedData[0])
                setSubmitted(true)
            } else {
               
                Swal.fire(
                    'Warning!',
                    'Please choose at least one training to make recommend.',
                    'warning'
                )
            }
        } else {
            Swal.fire(
                'Warning!',
                'User not authorised to submit request',
                'warning'
            )
        }
    }

    if (redirect.allow) {
        return <Redirect from={path} push to={"/application/"+redirect?.id} />
    } else {
        return (
            <MainTemplate
                content={
                    recommendData.status === "fetched" ?
                        <form onSubmit={handleSubmit}>
                            <TabulatorTemplate 
                                moduleName={"Training Recommend"}
                                columns={columns}
                                altColumns={altColumns}
                                moduleData={parsedData}
                                tabs={tabs}
                                disableWidget={true}
                                moduleWidgets={permission.edit ? <WidgetSubmitBtn text="Confirm training recommend action(s)" /> : null}
                                handleRefetch={handleRefetch}
                            />
                              {/* {permission.edit ? <WidgetSubmitBtn text="Confirm training recommend action(s)" /> : null} */}
                        </form>
                    : recommendData.status === "fetching" ?
                        <div>Loading...</div>
                    :
                        <div>Recommend is not available</div>
                }
            />
        )
    }
    
}

export default Recommend;