import { TabulatorTemplate } from "custom_components/templates/TabulatorTemplate"
import { WidgetBackBtn } from "custom_components/Widgets"
import { useFetchGet } from "custom_hooks/Actions"
import React, { useState, useEffect } from "react"
import {
	Link,
	Redirect,
	useParams,
	useLocation
} from "react-router-dom"
import "../css/Dashboard.css"

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function DashboardDetails(props) {
	let { option } = useParams()
	let location = useLocation()
	const [refetch, setRefetch] = useState(false)
	const jsonData = useFetchGet(
		// window.location.toString().split(path)[0] + path +
		domain + "/api/DashboardModule/dashboard.php?action=" + option, refetch)
	const [moduleData, setModuleData] = useState(null)
	const [tabs, setTabs] = useState(null)
	const [columns, setColumns] = useState(null)
	const [redirect, setRedirect] = useState({ allow: false })

	useEffect(() => {
		switch (option) {
			case "expire":
				setTabs(["Expiring Certificates", "Expiring Passes"])
				setColumns([
					[
						{
							title: "ID", field: "id", headerFilter: "input"
						},
						{ title: "Employee Name", field: "emp", headerFilter: "input" },
						{ title: "Certificates", field: "cert", headerFilter: "input" },
						{
							title: "Issued Date", field: "idate", headerFilter: "input", formatter: "datetime", formatterParams: {
								inputFormat: "YYYY-MM-DD",
								outputFormat: "DD/MM/YYYY",
								invalidPlaceholder: "N/A",
							}
						},
						{
							title: "Expiry Date", field: "edate", headerFilter: "input", formatter: "datetime", formatterParams: {
								inputFormat: "YYYY-MM-DD",
								outputFormat: "DD/MM/YYYY",
								invalidPlaceholder: "N/A",
							}
						},
						{ title: "Training Centre", field: "tcentre", headerFilter: "input" },
						{ title: "Category", field: "cate", headerFilter: "input" }
					],
					[
						{
							title: "Employee Code", field: "code", headerFilter: "input"
						},
						{ title: "Name", field: "name", headerFilter: "input" },
						{ title: "NRIC", field: "nric", headerFilter: "input" },
						{ title: "Pass Type", field: "pass", headerFilter: "input" },
						{
							title: "Pass Expiry Date", field: "passe", headerFilter: "input", formatter: "datetime", formatterParams: {
								inputFormat: "YYYY-MM-DD",
								outputFormat: "DD/MM/YYYY",
								invalidPlaceholder: "N/A",
							}
						},
						{ title: "Designation", field: "desig", headerFilter: "input" },
						{ title: "Department", field: "dept", headerFilter: "input" },
						{ title: "Reporting Manager", field: "manager", headerFilter: "input" }
					]
				])
				break
			case "pending":
				setTabs(["Pending Recommend", "Pending Approval"])
				setColumns([
					[
							{
							title: "Training Application ID", field: "id", headerFilter: "input"
						},
						{ title: "Employee Code", field: "code", headerFilter: "input" },
						{ title: "Employee Name", field: "name", headerFilter: "input" },
						{ title: "Course Name", field: "course", headerFilter: "input" },
						{ title: "Action", field: "action", name: "action", headerFilter: "input", editor:"select", editorParams:{
							values:["Please select an option", "Recommend", "Reject", "Withdraw"],
							defaultValue:"Please select an option",
							elementAttributes:{
								//style: "color:red;"
							}
						}},
						{ title: "Remarks", field: "remarks", name: "remarks", headerFilter: "input", formatter:"textarea", editor:"textarea", editorParams:{
							elementAttributes:{
								maxlength:"150",
							},
							verticalNavigation:"editor",
						}}
					],
					[
						{
							title: "Training Application ID", field: "id", headerFilter: "input"
						},
						{ title: "Status", field: "status", headerFilter: "input" },
						{ title: "Employee Code", field: "code", headerFilter: "input" },
						{ title: "Employee Name", field: "name", headerFilter: "input" },
						{ title: "Course Name", field: "course", headerFilter: "input" },
						{ title: "Recommended by", field: "recommendby", headerFilter: "input" },
						{ title: "Submit by", field: "submitby", headerFilter: "input" },
						{ title: "Action", field: "action", name: "action", headerFilter: "input", editor:"select", editorParams:{
							values:["Please select an option", "Approve", "Reject", "Withdraw"],
							defaultValue:"Please select an option",
							elementAttributes:{
								//style: "color:red;"
							}
						}},
						{ title: "Remarks", field: "remarks", name: "remarks", headerFilter: "input", formatter:"textarea", editor:"textarea", editorParams:{
							elementAttributes:{
								maxlength:"150",
							},
							verticalNavigation:"editor",
						}}
					]
				])
				break
			default :
				setTabs(["Primary tab","Secondary tab"])
				break
		}
	}, [jsonData.status])

	useEffect(() => {
		try {
			switch (option) {
				case "expire":
					setModuleData([parseEmployeeCertificate(jsonData.data[0]), parseEmployee(jsonData.data[1])])
					break
				case "pending":
					setModuleData([parseTrainingRecommend(jsonData.data[0]), parseTrainingApproval(jsonData.data[1])] )
					break
				default:
					setModuleData([[],[]])
					break
			}
		} catch {}
	}, [jsonData.data])

	// useEffect(()=>{
	// 	console.log(moduleData)
	// }, [moduleData])

	function handleRefetch() {
        setRefetch(Math.random())
    }

	function parseEmployeeCertificate(data) {
		let arr = [];
		data.forEach((element) => {
			arr.push({
				id: element['ID'],
				emp: element['EmployeeName'],
				cert: element['Name'],
				idate: element['IssueDate'],
				edate: element['ExpiryDate'],
				tcentre: element['TrainingCentre'],
				cate: element['Category'],
			});
		})
		return arr;
	}

	function parseEmployee(data) {
		let parsedData = []

		data.forEach((element) => {
			let obj = {
				code: element['EmployeeCode'],
				name: element['Name'],
				nric: element['NRIC/FIN'],
				pass: element['PassType'],
				passe: element['PassExpiryDate'],
				desig: element['Designation'],
				dept: element['Department'],
				manager: element['ReportingManager']
			}
			parsedData.push(obj);
		})

		return parsedData
	}

	function parseTrainingRecommend(data) {
		let parsedData = []

		data.forEach((element) => {
			const obj = {
				id: element['trainingID'],
				status: element['status'],
				code: element['employeeCode'],
				name: element['employeeName'],
				course: element['courseName'],
				duration: element['duration'],
				submitby: element['submitBy']
			}
			parsedData.push(obj);
		})
		return parsedData
	}

	function parseTrainingApproval(data) {
		let parsedData = []

		data.forEach((element) => {
			const obj = {
				id: element['trainingID'],
				status: element['status'],
				code: element['employeeCode'],
				name: element['employeeName'],
				course: element['courseName'],
				duration: element['duration'],
				recommendby: element['recommendBy'],
				submitby: element['submitBy'],
			}
			parsedData.push(obj);
		})
		return parsedData
	}


	return (
		<>
			{
				!option ?
					<div>Invalid dashboard details requested</div>
				: redirect.allow ?
            		<Redirect to={location.pathname+"/"+redirect?.code} />
				: jsonData.status === "fetched" ?
					<TabulatorTemplate 
						moduleName={"Dashboard Details"}
						columns={columns[0] ? columns[0] : null}
						altColumns={columns[1] ? columns[1] : null}
						moduleData={moduleData}
						tabs={tabs}
						disableWidget={true}
						handleRefetch={handleRefetch}
					/>
				: jsonData.status === "fetching" ?
					<div>Loading...</div>
				:
					<div>Dashboard details is not available</div>
			}
			<WidgetBackBtn />
		</>
	)
}
export default DashboardDetails