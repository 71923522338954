import { CreateRecruitmentForm } from "custom_components/templates/FormTemplate";
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from "react-router-dom"
import MainTemplate from "custom_components/templates/MainTemplate";
import { useUserPermission } from "custom_hooks/Actions";
import { FullTabulatorTemplate } from "custom_components/templates/TabulatorTemplate";
import { useState } from "react";
import CreateHelpdesk from "./CreateHelpdesk";
import HelpdeskDetails from "./HelpdeskDetails";
import HelpdeskInfo from "./HelpdeskInfo";
// import StaffInfo from "./StaffInfo";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

var userDept = ""
var userRole = ""

function parseHelpdesk(data) {
    let dataForActive = []
    let dataForInactive = []

    try {
        userDept = data[2]
        userRole = data[1]
        data[0].forEach((number, index, array) => {
            let obj = {
                no: index+1,
                requestorName: array[index].requestorName,
                subject: array[index].subject,
                submitDt: array[index].submitDt,
                status: array[index].status,
                helpdeskId: array[index].helpdeskId,
            }

            if(obj.status.includes("Completed by")){
                dataForInactive.push(obj);
            }else{
                dataForActive.push(obj);
            }
        
        })
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function Helpdesk({permission}){
    const [redirect, setRedirect] = useState({ allow: false })
    const location = useLocation()
    const tabs = ["Active", "History"];
    const columns = [
        { title: " S/no.", field: "no", headerFilter: "input"},
        {
            title: "Name", field: "requestorName", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "requestorName",
                urlPrefix: "hd/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.helpdeskId }) //here we update state
            }
        },
        { title: "Subject", field: "subject", headerFilter: "input" },
        { title: "Submission Datetime", field: "submitDt", headerFilter: "input" },
        { title: "Status", field: "status", headerFilter: "input" },
    ]

    return(
        redirect.allow ?
            <Redirect push to={location.pathname+"/"+redirect?.id} />
        :
        <FullTabulatorTemplate 
            permission = {permission}
            fetchURL = {domain + "/api/SupportModule/helpdesk.php?action=getAllHelpdesk"}
            parseFunc = {parseHelpdesk}
            columns = {columns}
            moduleName = {"Helpdesk"}
            redirect = {redirect}
            tabs={tabs}
            disableWidget = {false}
        />
    )
}

function HelpdeskContainer(){
    const perm = useUserPermission(30);

    return(
        <MainTemplate content={
            <Switch>
                 {
                    <Route path="/:page/create" children={<CreateHelpdesk />} />
                }
                {/*{
                    perm.view ? <Route path="/:page/:id/details" children={<EmployeeInfo defaultTab={"Details"} />} /> : null
                }
                {
                    perm.view ? <Route path="/:page/:id/cert" children={<EmployeeInfo defaultTab={"Certificate"} />} /> : null
                }*/}
                {
                    <Route path="/:page/:id" children={<HelpdeskInfo permission={perm} userDept={userDept} userRole={userRole} />} />
                }
                {
                    <Route path="" children={<Helpdesk permission={perm} />} />
                } 
            </Switch>
        }
        />
    )
}

export default HelpdeskContainer