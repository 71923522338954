import Chart from 'chart.js/auto';
import { WidgetBreadCrumbHeader, WidgetSubmitBtn } from "custom_components/Widgets"
import { useFetchGet } from "custom_hooks/Actions"
import { useEffect, useState } from "react";
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SelectInput } from 'custom_components/Forms';

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function CustomHSEReport(){
    const [refetch, setRefetch] = useState(0);
    const [yMCount, setYMCount] = useState();
    const [yMDetail, setYMDetail] = useState();
    var listCount = 0;
    const allHazard = useFetchGet(
        domain + "/api/HSE/hazard.php?action=getAll",
        refetch
    );
    var pdfTableData = []
    let observationName = ["Access & Egress", "Confined Space", "Hot Works", "Housekeeping", "Lifting Ops", "Line of Fire", "Machine Ops", "Ergonomic", "PPE", "Safety Rules & Reg", "Tools & Equipment", "Traffic", "Work Env", "Work Proc", "WAH", "Hygiene", "Checklist", "Environment"]
    let monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]
    
    var dateNow = new Date()
    Chart.register(ChartDataLabels)
    const [selectedYear, setSelectedYear] = useState(dateNow.getFullYear())
    const [selectedMonth, setSelectedMonth] = useState(monthArray[dateNow.getMonth()]+ " "+ dateNow.getFullYear())
    const [compareMonth, setCompareMonth] = useState([monthArray[dateNow.getMonth()]+ " "+ dateNow.getFullYear(), monthArray[dateNow.getMonth()]+ " "+ dateNow.getFullYear()])

    const [yearOption, setYearOption] = useState()
    const [monthOption, setMonthOption] = useState()

    const [chartList, setChartList] = useState([])

    const plugin = {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart) => {
            const {ctx} = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    function getTopSafeUnsafeChart(year){
         // process data for safe and unsafe top 5 data
        let yearSafeData = {}
        let yearUnsafeData = {}

        Object.keys(yMDetail[year]).map((k)=>{
            yMDetail[year][k].map((j) =>{
                let observation = parseCustomData(j.observationData)
    

                observation.map((x, idx)=>{
                    if(x["checked"] === true){
                        if(j["Safe/Unsafe"] === "Safe"){
                            if(yearSafeData[observationName[idx]]){
                                yearSafeData[observationName[idx]] +=1
                            }else{
                                yearSafeData[observationName[idx]] = 1
                            }
                        }else if(j["Safe/Unsafe"] === "Unsafe"){
                            if(yearUnsafeData[observationName[idx]]){
                                yearUnsafeData[observationName[idx]] +=1
                            }else{
                                yearUnsafeData[observationName[idx]] = 1
                            }
                        }
                    }
                })
    
            })
        })

        // sort on two safe and unsafe top 5 observations
        yearSafeData = [...Object.entries(yearSafeData)].sort((([,a],[,b])=>{ return b-a }))
        yearUnsafeData = [...Object.entries(yearUnsafeData)].sort((([,a],[,b])=>{ return b-a }))
        yearSafeData.length = 5
        yearUnsafeData.length = 5

        return [yearSafeData, yearUnsafeData]
    }

    function getMonthlyData(year, month){
        let safeUnsafeMonthlyData = {}

        yMDetail[year][month].map((k)=>{
            let observation = parseCustomData(k.observationData)

            observation.map((x, idx)=>{
                if(x["checked"] === true){
                    if(safeUnsafeMonthlyData[observationName[idx]]){

                        safeUnsafeMonthlyData[observationName[idx]][k["Safe/Unsafe"]] += 1
                    
                    }else{
                        safeUnsafeMonthlyData[observationName[idx]] = {
                            [k["Safe/Unsafe"] === "Safe" ? "Safe" : "Unsafe"]: 1,
                                [k["Safe/Unsafe"] === "Safe" ? "Unsafe" : "Safe"]: 0
                        }
                    }
                }
            })

        })

        observationName.map((k)=>{
            if(!(k in safeUnsafeMonthlyData)){
                safeUnsafeMonthlyData[k] = {"Safe":0, "Unsafe": 0}
            }
        })

        let safeData = Object.keys(safeUnsafeMonthlyData).filter((x)=>{
            return safeUnsafeMonthlyData[x]
        }).map((y)=>{
            return safeUnsafeMonthlyData[y]["Safe"]
        })
        let unsafeData = Object.keys(safeUnsafeMonthlyData).filter((x)=>{
            return safeUnsafeMonthlyData[x]
        }).map((y)=>{
            return safeUnsafeMonthlyData[y]["Unsafe"]
        })

        return [safeData, unsafeData, safeUnsafeMonthlyData]
    }

    function get2MonthsData(data){
        let cmp1 = data[0].split(" ")
        let cmp2 = data[1].split(" ")
        let firstMonthData = {}
        
        yMDetail[cmp1[1]][cmp1[0]].map((k)=>{
            let observation = parseCustomData(k.observationData)

            observation.map((x, idx)=>{
                if(x["checked"] === true){
                    if(firstMonthData[observationName[idx]]){

                        firstMonthData[observationName[idx]][k["Safe/Unsafe"]] += 1
                    
                    }else{
                        firstMonthData[observationName[idx]] = {[k["Safe/Unsafe"] === "Safe" ? "Safe" : "Unsafe"]: 1,
                        [k["Safe/Unsafe"] === "Safe" ? "Unsafe" : "Safe"]: 0}
                    }
                }
            })

        })
    
        let secondMonthData = {}
        yMDetail[cmp2[1]][cmp2[0]].map((k)=>{
            let observation = parseCustomData(k.observationData)

            observation.map((x, idx)=>{
                if(x["checked"] === true){
                    if(secondMonthData[observationName[idx]]){
                        secondMonthData[observationName[idx]][k["Safe/Unsafe"]] += 1
                    }else{
                        secondMonthData[observationName[idx]] = {[k["Safe/Unsafe"] === "Safe" ? "Safe" : "Unsafe"]: 1,
                        [k["Safe/Unsafe"] === "Safe" ? "Unsafe" : "Safe"]: 0}
                    }
                }
            })

        })
        
        let firstSafe = []
        let firstUnsafe = []
        let secondSafe = []
        let secondUnsafe = []
        
        let reconstructedLabel = [...observationName]
        // let reconstructedLabel = [...new Set([...Object.keys(firstMonthData),...Object.keys(secondMonthData)])]
        // console.log("rl",reconstructedLabel)
        reconstructedLabel.map((k)=>{
            if(!(k in firstMonthData)){
                firstMonthData[k] = {"Safe": 0, "Unsafe": 0}
            }
            
            if(!(k in secondMonthData)){
                secondMonthData[k] = {"Safe": 0, "Unsafe": 0}
            }

            firstSafe.push(firstMonthData[k]["Safe"])
            firstUnsafe.push(firstMonthData[k]["Unsafe"])
            secondSafe.push(secondMonthData[k]["Safe"])
            secondUnsafe.push(secondMonthData[k]["Unsafe"])
        })
        console.log("rl",reconstructedLabel)


        if(data[0] === data[1]){
            secondSafe=[]
            secondUnsafe=[]
        }

        return [firstSafe, firstUnsafe, secondSafe, secondUnsafe, reconstructedLabel]
    }

    function handleChartUpdate(selection, val){
        if(selection === "HSEyear"){
            // Update first yearly chart
            let crt = chartList[0]

            crt.data.labels = Object.keys(yMCount[val])
            crt.data.datasets[0]["data"] = Object.values(yMCount[val])

            crt.update()

            // Update top 5 charts
            let top5datas = getTopSafeUnsafeChart(val)
            let yearSafeData = top5datas[0]
            let yearUnsafeData = top5datas[1]

            let crt2 = chartList[1]
            let crt3 = chartList[2]

            crt2.data.labels = yearSafeData.filter((x)=>{ return x[0]}).map((y)=>y[0])
            crt2.data.datasets[0]["data"] = yearSafeData.filter((x)=>{ return x[0]}).map((y)=>y[1])

            crt3.data.labels = yearUnsafeData.filter((x)=>{ return x[0]}).map((y)=>y[0])
            crt3.data.datasets[0]["data"] = yearUnsafeData.filter((x)=>{ return x[0]}).map((y)=>y[1])

            crt2.update()
            crt3.update()

        }else if(selection === "HSEmonth"){
            // Construct monthly data

            let m = val.split(" ")[0]
            let y = val.split(" ")[1]
            let singleMonthDatas = getMonthlyData(y, m)
            
            // Update monthly chart
            let crt4 = chartList[3]
            crt4.data.labels = Object.keys(singleMonthDatas[2])
            crt4.data.datasets = [
                {
                    label: 'Safe',
                    data: singleMonthDatas[0],
                    borderColor: 'blue',
                    backgroundColor: 'cyan'
                // fill: true,
                // backgroundColor:  'cyan',
                // label: 'Number of observation',
                // data: Object.values(yMCount[dateNow.getFullYear()]),
                // borderWidth: 1
                },{
                    label: 'Unsafe',
                    data: singleMonthDatas[1],
                    borderColor: 'black',
                    backgroundColor: 'red',
                }
            ]


            crt4.update()
        }else if(selection === "HSEcmpMonth1" || selection === "HSEcmpMonth2"){
            let cmp = []

            if(selection === "HSEcmpMonth1"){
                cmp = [val, compareMonth[1]]
            }else{
                cmp = [compareMonth[0], val]
            }
            
            let get2MonthsDatas = get2MonthsData(cmp)
            let firstSafe = get2MonthsDatas[0]
            let firstUnsafe = get2MonthsDatas[1]
            let secondSafe = get2MonthsDatas[2]
            let secondUnsafe = get2MonthsDatas[3]
            let reconstructedLabel = get2MonthsDatas[4]
            
            let crt5 = chartList[4]
            crt5.data.labels = reconstructedLabel
            crt5.data.datasets = [
                {
                    label: 'Month1 Safe',
                    data: firstSafe,
                    borderColor: 'blue',
                    backgroundColor: 'cyan'
                },{
                    label: 'Month1 Unsafe',
                    data: firstUnsafe,
                    borderColor: 'black',
                    backgroundColor: 'red',
                },{
                    label: 'Month2 Safe',
                    data: secondSafe,
                    borderColor: 'blue',
                    backgroundColor: '#99FF33',
                },{
                    label: 'Month2 Unsafe',
                    data: secondUnsafe,
                    borderColor: 'black',
                    backgroundColor: '#FF8D33',
                }
            ]

            crt5.update()
        }
    }

    function handleChanges(field, val){
        if(field === "HSEyear"){
            setSelectedYear(val)
            handleChartUpdate(field, val)
        }else if(field === "HSEmonth"){
            setSelectedMonth(val)
            handleChartUpdate(field, val)
        }else if(field === "HSEcmpMonth1"){
            if(compareMonth.length === 2){
                let cmp2 = compareMonth[1]

                setCompareMonth([val, cmp2])
            }else{
                setCompareMonth([val, val])
            }
            handleChartUpdate(field, val)

        }else if(field === "HSEcmpMonth2"){
            if(compareMonth.length === 2){
                let cmp1 = compareMonth[0]

                setCompareMonth([cmp1, val])
            }else{
                setCompareMonth([val, val])
            }
            handleChartUpdate(field, val)
        }
    }

    //custom parse data to objectlist
    function parseCustomData(dt){
        var temp = []
        dt = dt.trim()
        dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '').replaceAll("},{", "},,{").split(",,")
        for(var k of dt){
            k = k.replaceAll('"{',"{")
            k = k.replaceAll('}"',"}")
            temp.push(JSON.parse(k))
        }

        return temp
    }
    
    // useEffect(()=>{
    //     if(Chart.getChart("yearlyChart") !== undefined){
    //         Chart.getChart("yearlyChart").destroy()
    //     }
    // },[])

    useEffect(()=>{
        let dataYearlyMonthlyCount = {}
        let dataYearlyMonthlyDetail = {}
        let preYearOption = []
        let preMonthOption = []

        if(allHazard.status === "fetched"){
            // var yearlyChart = document.getElementById('yearlyChart')
            // yearlyChart.parentNode.removeChild(yearlyChart)

            allHazard.data[0].map((k)=>{
                let tmpDate = new Date(k.Date)
                let year = tmpDate.getFullYear()
                let month = tmpDate.getMonth()
                
                if(dataYearlyMonthlyCount[year]){
                    if(dataYearlyMonthlyCount[year][monthArray[month]]){
                        dataYearlyMonthlyCount[year][monthArray[month]] += 1
                    }else{
                        if(!(monthArray[month]+ " " + year in preMonthOption)){
                            preMonthOption.push(monthArray[month]+ " " + year)
                        }
                        dataYearlyMonthlyCount[year][monthArray[month]] = 1
                    }
                }else{
                    preYearOption.push(year)
                    dataYearlyMonthlyCount[year]= {}
                }

                if(dataYearlyMonthlyDetail[year]){
                    if(dataYearlyMonthlyDetail[year][monthArray[month]]){
                        dataYearlyMonthlyDetail[year][monthArray[month]].push(k)
                    }else{
                        dataYearlyMonthlyDetail[year][monthArray[month]] = [k]
                    }
                }else{
                    dataYearlyMonthlyDetail[year]= {}
                }
            })

            setYMCount(dataYearlyMonthlyCount)
            setYMDetail(dataYearlyMonthlyDetail)
            setYearOption(preYearOption)
            setMonthOption(preMonthOption)
        }


    },[allHazard.status])

    useEffect(()=>{
        if(yMCount !== undefined && yMDetail !== undefined){
            console.log(yMDetail)
            createChart()
        }
    },[yMCount, yMDetail])

    function createChart(){

        // Yearly chart
        let chart1 = new Chart(document.getElementById('yearlyChart'), {
            type: 'line',
            data: {
            labels: Object.keys(yMCount[dateNow.getFullYear()]),
            datasets: [{
                fill: true,
                backgroundColor:  'cyan',
                label: 'Number of observation',
                data: Object.values(yMCount[dateNow.getFullYear()]),
                borderWidth: 1
            }]
            },
            options: {
                plugins:{
                    title: {
                        display: true,
                        text: "Monthly HSE Observations"
                    },
                    datalabels: {
                        labels: {
                            title:{
                                font:{
                                    weight:'bold',
                                    size: '15px',
                                },
                                color: "black",
                            },
                        }
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Months',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 20, left: 0, right: 0, bottom: 0}
                        }
                    }, 
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Number of Observations',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 30, left: 0, right: 0, bottom: 0}
                        },
                        ticks: {
                            stepSize: 2
                        },
                        grid:{
                            display: false
                        }
                    },
                },
            },
            plugins: [plugin]
        });


        // Monthly Chart
        let singleMonthDatas = getMonthlyData(selectedYear, selectedMonth.split(" ")[0])

        let chart4 = new Chart(document.getElementById('monthlyChart'), {
            type: 'bar',
            data: {
            labels: Object.keys(singleMonthDatas[2]),
            datasets: [
                {
                    label: 'Safe',
                    data: singleMonthDatas[0],
                    borderColor: 'blue',
                    backgroundColor: 'cyan'
                // fill: true,
                // backgroundColor:  'cyan',
                // label: 'Number of observation',
                // data: Object.values(yMCount[dateNow.getFullYear()]),
                // borderWidth: 1
                },{
                    label: 'Unsafe',
                    data: singleMonthDatas[1],
                    borderColor: 'black',
                    backgroundColor: 'red',
                }
            ]
            },
            options: {
                plugins:{
                    legend: {
                        position: 'top'
                    },
                    title: {
                        display: true,
                        text: "Month Observation"
                    },
                    datalabels: {
                        labels: {
                            title:{
                                font:{
                                    weight:'bold',
                                    size: '15px',
                                },
                                color: "black",
                            },
                        }
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Observations Name',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 20, left: 0, right: 0, bottom: 0}
                        }
                    }, 
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Number of Safe/Unsafe Observations',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 30, left: 0, right: 0, bottom: 0}
                        },
                        ticks: {
                            stepSize: 2
                        },
                        grid:{
                            display: false
                        }
                    },
                },
            },
            plugins: [plugin]
        });

        // 2 Months comparison
        let get2MonthsDatas = get2MonthsData(compareMonth)
        let firstSafe = get2MonthsDatas[0]
        let firstUnsafe = get2MonthsDatas[1]
        let secondSafe = get2MonthsDatas[2]
        let secondUnsafe = get2MonthsDatas[3]
        let reconstructedLabel = get2MonthsDatas[4]


        let chart5 = new Chart(document.getElementById('comparisonChart'), {
            type: 'bar',
            data: {
            labels: reconstructedLabel,
            datasets: [
                {
                    label: 'Month1 Safe',
                    data: firstSafe,
                    borderColor: 'blue',
                    backgroundColor: 'cyan'
                // fill: true,
                // backgroundColor:  'cyan',
                // label: 'Number of observation',
                // data: Object.values(yMCount[dateNow.getFullYear()]),
                // borderWidth: 1
                },{
                    label: 'Month1 Unsafe',
                    data: firstUnsafe,
                    borderColor: 'black',
                    backgroundColor: 'red',
                },{
                    label: 'Month2 Safe',
                    data: secondSafe,
                    borderColor: 'blue',
                    backgroundColor: '#99FF33',
                },{
                    label: 'Month2 Unsafe',
                    data: secondUnsafe,
                    borderColor: 'black',
                    backgroundColor: '#FF8D33',
                }
            ]
            },
            options: {
                plugins:{
                    legend: {
                        position: 'top'
                    },
                    title: {
                        display: true,
                        text: "Observation Comparison"
                    },
                    datalabels: {
                        labels: {
                            title:{
                                font:{
                                    weight:'bold',
                                    size: '15px',
                                },
                                color: "black",
                            },
                        }
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Observations Name',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 20, left: 0, right: 0, bottom: 0}
                        }
                    }, 
                    y: {
                        // beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Number of Safe/Unsafe Observations',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 30, left: 0, right: 0, bottom: 0}
                        },
                        ticks: {
                            stepSize: 2,

                        },
                        grid:{
                            display: false
                        }
                    },
                },
            },
            plugins: [plugin]
        });

        // process data for safe and unsafe top 5 data
        let top5datas = getTopSafeUnsafeChart(dateNow.getFullYear())
        let yearSafeData = top5datas[0]
        let yearUnsafeData = top5datas[1]

        // top 5 safe observation
        let chart2 = new Chart(document.getElementById('top5safe'), {
            type: 'pie',
            data: {
            labels: yearSafeData.filter((x)=>{ return x[0]}).map((y)=>y[0]),
            datasets: [{
                label: 'Times',
                data: yearSafeData.filter((x)=>{ return x[0]}).map((y)=>y[1]),
                borderWidth: 1
            }]
            },
            options: {
                responsive: true,
                plugins:{
                    title: {
                        display: true,
                        text: "Safest Observations of the Year"
                    },
                },
            },
            plugins: [plugin]
        });


        // top 5 unsafe observation
        let chart3 = new Chart(document.getElementById('top5unsafe'), {
            type: 'pie',
            data: {
            labels: yearUnsafeData.filter((x)=>{ return x[0]}).map((y)=>y[0]),
            datasets: [{
                label: 'Times',
                data: yearUnsafeData.filter((x)=>{ return x[0]}).map((y)=>y[1]),
                borderWidth: 1
            }]
            },
            options: {
                responsive: true,
                plugins:{
                    title: {
                        display: true,
                        text: "Unsafe Observations of the Year"
                    },
                },
            },
            plugins: [plugin]
        });


        setChartList([chart1, chart2, chart3, chart4, chart5])

    }

    function downloadPDF(e){
        e.preventDefault()
        const canvas = document.getElementById('yearlyChart');
        const canvasImage = canvas.toDataURL('image/jpeg', 1.0);
        let pdf = new jsPDF({
          orientation: 'landscape'
        });
        pdf.setFontSize(20);
        pdf.addImage(canvasImage, 'jpeg', 5,20,285,160);
        
        pdf.addPage()

        const canvas2 = document.getElementById('top5safe');
        const canvasImage2 = canvas2.toDataURL('image/jpeg', 1.0);
        pdf.addImage(canvasImage2, 'jpeg', 0,30,150,150);

        const canvas3 = document.getElementById('top5unsafe');
        const canvasImage3 = canvas3.toDataURL('image/jpeg', 1.0);
        pdf.addImage(canvasImage3, 'jpeg', 150,30,150,150);

        pdf.addPage()

        const canvas4 = document.getElementById('monthlyChart');
        const canvasImage4 = canvas4.toDataURL('image/jpeg', 1.0);
        pdf.addImage(canvasImage4, 'jpeg', 5,20,285,160);

        pdf.addPage()

        const canvas5 = document.getElementById('comparisonChart');
        const canvasImage5 = canvas5.toDataURL('image/jpeg', 1.0);
        pdf.addImage(canvasImage5, 'jpeg', 5,20,285,160);

        pdf.addPage()

        autoTable(pdf, {
            columnStyles:{
                col1: {fontStyle:'bold'}, 
                col2: {fontStyle:'bold'}, 
                col3: {fontStyle:'bold'}, 
                col4: {fontStyle:'bold'}, 
                col5: {fontStyle:'bold'}, 
                col6: {fontStyle:'bold'}, 
                // col11: {fontStyle:'bold', cellWidth: 100},
            },
            body: pdfTableData,
            columns: [
                { header: "S/No", dataKey:'col1'},
                { header: "Job Title", dataKey:'col2'},
                { header: "Description", dataKey:'col3'},
                { header: "Observation", dataKey:'col4'},
                { header: "Action Taken", dataKey:'col5'},
                { header: "Date/Time", dataKey:'col6'},
                // { header: "Director's Comment", dataKey:'col11'},
            ]
        })

        pdf.save('HazardReport.pdf');    }

    return(
        <>
            <>
                <WidgetBreadCrumbHeader prevNav="Report" curNav="HSE Report" prevLink={"#"} curLink={"#"} />
                <br />
                <div style={{backgroundColor: "white", paddingTop: "3%", paddingBottom: "3%"}}>
                    <form className='hazardFormBtn' onSubmit={downloadPDF}>
                        <WidgetSubmitBtn text="Download PDF" />
                    </form>
                    <div>
                        <div className='canvaHeader'>
                            <h5>Yearly Chart Information</h5>
                            <div className='row staffOtherSDiv'>
                                <label className='col-sm-4'>Select Year</label>
                                <div className='col-sm-8'>
                                    <SelectInput options={yearOption} defaultOption="Select an option" value={selectedYear} disabled={false} field={"HSEyear"} fromApplicant={true} requiredOption={false} onChangeInput={handleChanges} />                  
                                </div>
                            </div>
                        </div>
                        <canvas id="yearlyChart"></canvas>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <canvas id="top5safe"></canvas>
                        </div>
                        <div className='col-md-6'>
                            <canvas id="top5unsafe"></canvas>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='canvaHeader'>
                            <h5>Monthly Chart Information</h5>
                            <div className='row staffOtherSDiv'>
                                <label className='col-sm-4'>Select Month</label>
                                <div className='col-sm-8'>
                                    <SelectInput options={monthOption} defaultOption="Select an option" value={selectedMonth} disabled={false} field={"HSEmonth"} fromApplicant={true} requiredOption={false} onChangeInput={handleChanges} />                  
                                </div>
                            </div>
                        </div>
                        <canvas id="monthlyChart"></canvas>
                    </div>
                    <hr />
                    <div>
                        <div className='canvaHeader'>
                            <h5>Months Comparison Information</h5>
                            <div className='row staffOtherSDiv'>
                                <div className='row col-sm-6'>   
                                    <label className='col-sm-4'>Select Month 1</label>
                                    <div className='col-sm-8'>
                                        <SelectInput options={monthOption} defaultOption="Select an option" value={compareMonth[0]} disabled={false} field={"HSEcmpMonth1"} fromApplicant={true} requiredOption={false} onChangeInput={handleChanges} />                  
                                    </div>
                                </div>
                                <div className='row col-sm-6'>
                                    <label className='col-sm-4'>Select Month 2</label>
                                    <div className='col-sm-8'>
                                        <SelectInput options={monthOption} defaultOption="Select an option" value={compareMonth[1]} disabled={false} field={"HSEcmpMonth2"} fromApplicant={true} requiredOption={false} onChangeInput={handleChanges} />                  
                                    </div>
                                </div>
                            </div>
                        </div>
                        <canvas id="comparisonChart"></canvas>
                    </div>
                    <div className='hseDiv'>
                        <table className='hseTable'>
                            <tr>
                                <th>S/No</th>
                                <th>Job Title</th>
                                <th>Description</th>
                                <th>Observation</th>
                                <th>Action Taken</th>
                                <th>Date/Time</th>
                            </tr>
                            {yMDetail !== "" && yMDetail !== undefined ? 
                                Object.keys(yMDetail[selectedYear]).map((k)=>{
                                    if(yMDetail[selectedYear][k].length != 0){
                                        return(
                                            yMDetail[selectedYear][k].map((y)=>{
                                                listCount+=1
    
                                                let observation = parseCustomData(y["observationData"])
                                                let observationList = ""
    
                                                observation.map((x, idx)=>{
                                                    if(x["checked"] === true){
                                                        if(observationList === ""){
                                                            observationList = observationName[idx]
                                                        }else{
                                                            observationList += ", "+ observationName[idx]
                                                        }
                                                    }
                                                })
                                                
                                                pdfTableData.push({
                                                    col1: listCount, 
                                                    col2: y["JobTitle"],
                                                    col3: y["Description"],
                                                    col4: observationList,
                                                    col5: y["ActionTaken"],
                                                    col6: y["SubmitDateTime"]})

                                                return(
                                                    <tr>
                                                        <td>{listCount}</td>
                                                        <td>{y["JobTitle"]}</td>
                                                        <td>{y["Description"]}</td>
                                                        <td>{observationList}</td>
                                                        <td>{y["ActionTaken"]}</td>
                                                        <td>{y["SubmitDateTime"]}</td>
                                                    </tr>
                                                )
                                                
                                            })

                                        )
                                    }
                                })
                                :
                                <></>
                            }
                        </table>
                    </div>
                </div>
            </>
        </>
    )
}