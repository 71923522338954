import React, { useState, useEffect } from "react"
import { jsPDF } from "jspdf"
import loginLogo from '../icons/logo.png'
import { WidgetLoader } from "./Widgets"

//todo add check for src whether src is avail

function PdfViewer({ targetPath }) {
    const [data, setData] = useState(targetPath)
    const [loaded, setLoaded] = useState(false)

    function handleOnLoad() {
        setLoaded(true)
    }

    useEffect(() => {
        // const doc = new jsPDF({})

        // //doc.addImage(loginLogo, "png", 75, 30, 100, 25)

        // let lineHeight = 0
        // let lineWidth = 0
        // for (let i=0; i<5; i++) {
        //     doc.setTextColor(100)
        //     doc.text(lineWidth+=5, lineHeight+=10, 'This is gray.')
        //     doc.setTextColor(150)
        //     doc.text(lineWidth+=5, lineHeight+=10, 'This is light gray.')
        //     doc.setTextColor(255,0,0)
        //     doc.text(lineWidth+=5, lineHeight+=10, 'This is red.')
        //     doc.setTextColor(0,255,0)
        //     doc.text(lineWidth+=5, lineHeight+=10, 'This is green.')
        //     doc.setTextColor(0,0,255)
        //     doc.text(lineWidth+=5, lineHeight+=10, 'This is blue.')
        // }
        // setData(doc.output('datauristring'))
    }, [])

    return (
        <div>
            <WidgetLoader loaded={loaded} />
            <iframe
                id="pdf-viewer"
                className={ loaded === false && "d-none" }
                src={data}
                alt="Loading..."
                onLoad={handleOnLoad}
            ></iframe>
        </div>
        
    )
}

export default PdfViewer
