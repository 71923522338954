import React, { useState, useEffect } from "react"
import {
    Switch,
    Route,
    Redirect,
    useParams,
    useLocation,
    useHistory
} from "react-router-dom"
import { reactFormatter } from 'react-tabulator'
import MainTemplate from '../../custom_components/templates/MainTemplate.js'
import { FullTabulatorTemplate } from 'custom_components/templates/TabulatorTemplate.js'
import UserAccountCreate from 'pages/employee/UserAccountCreate.js'
import UserAccountInfo from 'pages/employee/UserAccountInfo.js'
import { useUserPermission } from '../../custom_hooks/Actions.js'
import { flipTrueFalse } from "helper_functions/Helper.js"

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function parseUserAccount(data, permission) {
    let dataForActive = []
    let dataForInactive = []
    try {
        for (let i = 0; i < data.length; i++) {
            data[i].forEach((number, index, array) => {
                let obj = {
                    no: index+1,
                    code: array[index][0],
                    name: array[index][1],
                    uname: array[index][2],
                    action: permission.edit ? "" : null
                }
                if (i === 0) {
                    if (data[i][0] !== 0) {
                        dataForActive.push(obj);
                    }
                } else {
                    if (data[i][0] !== 0) {
                        dataForInactive.push(obj);
                    }
                }
            });
        }
    } catch {
        return ["", ""];
    }
    const parsedData = [dataForActive, dataForInactive];
    return parsedData;
}

function UserPasswordButton(props) {
    const cellData = props.cell._cell.row.data;
    return (        
        <a onClick={props.handleUserPasswordRedirect} href={props.pathname + "/updatepassword/" + cellData.code}>
            <button type='button' className='btn btn-primary btn-sm'>{props.activeTab === props.tabs[0] ? "Change Password" : "Reactivate"}</button>
        </a>
    )
}

function UserUpdatePassword(props) {
    let { id } = useParams();
    let history = useHistory();
    let location = useLocation();
    const [msg, setMsg] = useState("");
    // window.location.toString().split(path)[0] + path 
    let fetchUrl =  "/api/EmployeeModule/"
    let msgSubject = "";

    function checkUrlForAction() {
        alert(location.pathname);
        if (location.pathname.split("/")[2] === "updatepassword") {
            fetchUrl += "useraccount_changepasswordbysystem.php";
            msgSubject = "Password change";
        }
        if (location.pathname.split("/")[2] === "reactivate") {
            fetchUrl += "useraccount_reactivate.php";
            msgSubject = "Account reactivation";
        }
    }

    useEffect(() => {
        checkUrlForAction();
        fetch(fetchUrl, {
            method: "POST",
            mode: "same-origin",
            credentials: "same-origin",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: id
            })
        })
            .then(res => res.json())
            .then(json => {
                if (json[0] === 1) {
                    setMsg(msgSubject + " has been successfully executed");
                } else {
                    setMsg(msgSubject + " has not been successfully executed, please contact Administrator");
                }
            })
            .catch(err => err);
    }, [])
    
    if (msg !== "" && msg) {
        return (
            <div className="form-group row">
                <label className="col-sm-12">{msg}</label>
                <button type="button" className="btn btn-secondary col-sm-2 mt-3 center" onClick={() => history.goBack()}>Back</button>
            </div>
        )
    } else {
        return <div>Loading...</div>
    }
    
}

function UserAccount({ permission }) {
    const location = useLocation()
    const tabs = ["Active", "Inactive"]
    const defaultTab = tabs[0]
    const [activeTab, setActiveTab] = useState(defaultTab)
    const [redirect, setRedirect] = useState({ allow: false })
    const [redirectPW, setRedirectPW] = useState(false)

    function handleUserPasswordRedirect(e) {
        e.preventDefault()
        setRedirectPW((e.target.parentElement.href).split(domain)[1])
    }

    const columns = [
        {title: "Sr/No.", field: "no", headerFilter: "input"},
        {
            title: "Employee Code", field: "code", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "code",
                urlPrefix: "useraccount/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, code: rowInfo.code }) //here we update state
            }
        },
        { title: "Name", field: "name", headerFilter: "input" },
        { title: "Username", field: "uname", headerFilter: "input" },
        permission.edit ?
        {
            title: "Action", field: "action", formatter: reactFormatter(
                <UserPasswordButton 
                    pathname={location.pathname} 
                    tabs={tabs} 
                    activeTab={activeTab} 
                    permission={permission} 
                    handleUserPasswordRedirect={handleUserPasswordRedirect} 
                />
            )
        }
        : { title: "Action", field: "action", visible: false }
    ];

    if (redirectPW) {
        return <Redirect push to={redirectPW} />
    } else {
        // window.location.toString().split(path)[0] + path +
        return (
            <FullTabulatorTemplate 
                permission = {permission}
                fetchURL = {  "/api/EmployeeModule/useraccount.php"}
                parseFunc = {parseUserAccount}
                tabs = {tabs}
                columns = {columns}
                moduleName = {"User Account"}
                redirect = {redirect}
                disableWidget = {permission.add ? false : true}
            />
        )
    }
    
}

function UserAccountContainer (props) {
    const permission = useUserPermission(2)

    return (
        <MainTemplate
            content={
                <Switch>
                    {
                        permission.add ? <Route path="/:page/create" children={<UserAccountCreate />} /> : null
                    }
                    {
                        permission.edit ? <Route path="/:page/updatepassword/:id" children={<UserUpdatePassword />} /> : null
                    }
                    {
                        permission.edit ? <Route path="/:page/reactivate/:id" children={<UserUpdatePassword />} /> : null
                    }
                    {
                        permission.view ? <Route path="/:page/:id" children={<UserAccountInfo />} /> : null
                    }
                    {
                        permission.view ? <Route exact path="" children={<UserAccount permission={permission} />} /> : null
                    }
                </Switch>
            }
        />
    )
}

export default UserAccountContainer;