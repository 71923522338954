import { useState, useEffect } from "react"
import {
    Redirect,
    useParams
} from "react-router-dom"
import Swal from "sweetalert2"
import { Input, SelectInput, SelectInputWithGroup } from '../../custom_components/Forms.js'
import { EditFormTemplate } from '../../custom_components/templates/FormTemplate'
import { sessionSetLoading, useUserPermission } from '../../custom_hooks/Actions.js'

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function EmployeeDetails(props) {
    let { id } = useParams()
    const [role, setRole] = useState([""])
    const [passtypeData, setPasstypeData] = useState([""])
    const [dataList, setDataList] = useState([""])
    const [dataList2, setDataList2] = useState([""])
    const [editMode, setEditMode] = useState(1)
    const [inputData, setInputData] = useState({})
    const [submitted, setSubmitted] = useState(0)
    const [validated, setValidated] = useState(0)
    const [formData, setFormData] = useState([])
    const [runOnce, setRunOnce] = useState(0)
    const [redirect, setRedirect] = useState(false)
    const fields = [
        { name: "Employee code", type: "text", required: true },
        { name: "Employee Category", type: "select", required: true ,options: formData[5]},
        { name: "Name", type: "text", required: true },
        { name: "NRIC / FIN", type: "text", required: true },
        { name: "Nationality", type: "text", required: true },
        { name: "Pass Type", type: "select", required: true, options: formData[0] },
        { name: "Pass Expiry Date", type: "date", required: true },
        { name: "Date of Birth", type: "date", required: true },
        { name: "Employment Date", type: "date", required: true },
        { name: "Department", type: "select", required: true, options: formData[1] },
        { name: "Designation Category", type: "text", required: true },
        { name: "Designation", type: "text", required: true },
        { name: "Contact Number", type: "tel", required: false },
        { name: "Email", type: "email", required: false },
        { name: "Qualification", type: "text", required: false },
        { name: "Status", type: "select", required: true, options: ["Active","Inactive"] },
        { name: "Reporting Manager", type: "select", required: false, options: formData[3] }
    ]
    const defaultSelectInput = "Select an option"
    const exceptInputData = [fields[6], fields[10], fields[11], fields[12], fields[13]]
    const permission = useUserPermission(0)

    const handleChangeInput = (e,date) => {
        if(typeof(e) == 'object'){
            setInputData({
                ...inputData,
                [e.target.name]: e.target.value
            });
       }else{
            setInputData({
                ...inputData,
                [e]: date
            });
       }
    }

    function createInputObj(json) {
        let a = {}
        for (let i = 0; i < fields.length; i++) {
            if (json[i] == "" || json[i] == null || json[i] === undefined) {
                switch (i) {
                    default:
                        a[fields[i].name] = "";
                        break;
                }
            } else {
                a[fields[i].name] = json[i];
            }
        }
        return a;
    }

    useEffect(() => {
        if (submitted === 0 && runOnce === 0) {
            // window.location.toString().split(path)[0] + path + 
            fetch( domain + "/api/EmployeeModule/employee_details.php", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    employeeCode: id
                })
            })
                .then(res => res.json())
                .then(json => {
                    setInputData(createInputObj(json[0]));

                    function spliceEmployeeData(data) {
                        let x = data;
                        let y = data.splice(0, 1);
                        return x;
                    }
                    let slicedData = spliceEmployeeData(json);
                    setPasstypeData(json[0]);
                    setDataList(json[2]);
                    setDataList2(json[4].slice(1));
                    setFormData(json);
                    setRunOnce(1);
                    setRole(json[6]);
                })
                .catch(err => { console.log(err) });
        }
    }, []);

    useEffect(() => {
        if (submitted === 1 && validated === 1 && permission.edit === 1) {
            sessionSetLoading(true)
            // window.location.toString().split(path)[0] + path +
            fetch(  domain + "/api/EmployeeModule/employee_detailsupdate.php", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    getEmployeeCode: id,
                    employeeCode: inputData[fields[0].name],
                    employeeCategory: inputData[fields[1].name],
                    name: inputData[fields[2].name],
                    nric: inputData[fields[3].name],
                    nationality: inputData[fields[4].name],
                    passType: inputData[fields[5].name],
                    passExpiryDate: inputData[fields[6].name],
                    dob: inputData[fields[7].name],
                    dateJoin: inputData[fields[8].name],
                    dept: inputData[fields[9].name],
                    designation_cat: inputData[fields[10].name],
                    designation: inputData[fields[11].name],
                    contact: inputData[fields[12].name],
                    email: inputData[fields[13].name],
                    qualification: inputData[fields[14].name],
                    status: inputData[fields[15].name],
                    reportingManager: inputData[fields[16].name]
                })
            })
                .then(res => res.json())
                .then(json => {
                    if (json[0] === 1) {
                        setRedirect(true)
                    } else {
                        setSubmitted(0);
                        setValidated(0);
                    }
                    Swal.fire(
                        json[0] === 1 ? 'Success!' : 'Error!',
                        json[1],
                        json[0] === 1 ? 'success' : 'error'
                    )
                    sessionSetLoading()
                })
                .catch(err => { console.log(err) });
        }
    }, [submitted, validated]);

    const dateNow = new Date()
	const dateMin = dateNow.getFullYear() + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
    let formClassName = "form-group"
    let formDisplayWide = formClassName + " grid-wide"
    let formDisplayNone = formClassName + " d-none"
    const fieldItems = fields.map((field, index) =>
    <div className={
        //HARDCODED AS ADDING NEW ITEMS AND SORTING MAY STILL BREAK LOGIC
        index === 6 && inputData[fields[5].name] === "" ? formDisplayNone :
            index === 6 && inputData[fields[5].name] === "NRIC" ? formDisplayNone :
                index === 6 && inputData[fields[5].name] === "FIN" ? formDisplayNone : formDisplayWide
               
                       
    }
        // <div className={
        //     //HARDCODED AS ADDING NEW ITEMS AND SORTING MAY STILL BREAK LOGIC
        //     index === 6 && inputData[fields[5].name] === "" ? formDisplayNone :
        //         index === 6 && inputData[fields[5].name] === "NRIC" ? formDisplayNone :
        //             index === 6 && inputData[fields[5].name] === "FIN" ? formDisplayNone :
        //             (index === 7 || index === 3 || index === 4 || index === 5 || index === 9 || index === 10 || index === 11 || index === 12 || index === 13 ) && (inputData[fields[5].name] !== "NRIC" && inputData[fields[5].name] !== "FIN")?
        //                     formClassName : formDisplayWide
        // }
            key={field.name} >
            <label htmlFor={field.name} className="">{field.name}</label>
            {
                index === 1 || index === 5 ?
                <SelectInput
                    field={field.name}
                    value={inputData[field.name]}
                    options={field.options}
                    defaultOption={defaultSelectInput}
                    requiredOption={field.required}
                    onChangeInput={handleChangeInput}
                    disabled={role == "Sadmin"?editMode:1}
                />
                :
                index === 9 || index === 15 ?
                <SelectInput
                    field={field.name}
                    value={inputData[field.name]}
                    options={field.options}
                    defaultOption={defaultSelectInput}
                    requiredOption={field.required}
                    onChangeInput={handleChangeInput}
                    disabled={editMode}
                />
                :
                index === 16 ?
                    <SelectInputWithGroup
                        field={field.name}
                        value={inputData[field.name]}
                        options={field.options}
                        optionGroups={dataList}
                        defaultOption={defaultSelectInput}
                        requiredOption={field.required}
                        onChangeInput={handleChangeInput}
                        disabled={editMode}
                    />
                    :
                    index === 0 || index === 2 || index === 3 || index === 4 || index === 7  || index === 6 || index === 8 ?
                    <Input
                        field={field.name}
                        type={field.type}
                        value={inputData[field.name]}
                        dataList={
                            index === 10 ? dataList
                                : index === 4 ? dataList2 : null
                        }
                        requiredOption={
                            index === 6 && inputData[fields[5].name] === "NRIC" ?
                                false
                                    : index === 6 && inputData[fields[5].name] === "FIN" ?
                                        false
                                        :
                                            field.required
                        }
                        min={index === 6 ? dateMin : null}
                        onChangeInput={handleChangeInput}
                        disabled={role == "Sadmin" ?editMode:1}
                    />
                    :
                    <Input
                        field={field.name}
                        type={field.type}
                        value={inputData[field.name]}
                        dataList={
                            index === 10 ? dataList
                                : index === 4 ? dataList2 : null
                        }
                        requiredOption={
                            index === 6 && inputData[fields[5].name] === "NRIC" ?
                                false
                                    : index === 6 && inputData[fields[5].name] === "FIN" ?
                                        false
                                        :
                                            field.required
                        }
                        min={index === 6 ? dateMin : null}
                        onChangeInput={handleChangeInput}
                        disabled={editMode}
                    />
            }
        </div>
    )

    function handleSubmit(e) {
        e.preventDefault();
        for (let inputName in inputData) {
            let isAnExcept = 0;
            for (let exceptNum in exceptInputData) {
                if (inputName === exceptInputData[exceptNum]) {
                    isAnExcept = 1;
                }
            }
            if (isAnExcept === 0) {
                if (inputData[inputName] === defaultSelectInput || inputData[inputName] === "" || inputData[inputName] === null || inputData[inputName] === "undefined") {
                    setValidated(0);
                    setSubmitted(0);
                } else {
                    break;
                }
            } else {
                break;
            }
        }
        setValidated(1);
        setSubmitted(1);
    }

    if (redirect === true) {
        return <Redirect to={ "/employee"} />
    } else {
        return (
            <EditFormTemplate
            handleSubmit={handleSubmit}
            disabled={editMode}
            setEditMode={setEditMode}
            fieldItems={fieldItems}
            editAccess={permission.edit}
            />
        )
    }
    
}

export default EmployeeDetails;