import { useState, useEffect } from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useParams,
    useLocation
} from "react-router-dom"
import { TabulatorWrapper } from '../../custom_components/Tabulator.js'
import EmployeeCertActions from 'pages/employee/EmployeeCertActions.js'
import EmployeeCertPreview from 'pages/employee/EmployeeCertPreview.js'
import { useUserPermission } from '../../custom_hooks/Actions.js'
import Swal from "sweetalert2"

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function parseEmployee(data) {
    let arr = [];
    data[0].forEach(element => {
        arr.push({
            id: element[0],
            cert: element[1],
            idate: element[2],
            edate: element[3],
            tcentre: element[4],
            cate: element[5],
            file: element[6]
        });
    })
    return arr;
}

function EmployeeCertificates({ regen, permission }) {
    let { id } = useParams()
    let location = useLocation()
    const [refetch, setRefetch] = useState(0)
    let data = []
    const [isDataEmpty, setIsDataEmpty] = useState(1)
    const [employeeData, setEmployeeData] = useState([])
    const [certDir, setCertDir] = useState(false)
    const [redirect, setRedirect] = useState({ allow: false })

    function handleRefetch() {
        setRefetch(Math.random())
    }

    function handleDownload(selectedRows) {
        function checkRowsHaveFile(selectedRows) {
            let results = {
                rowsHasFile: false,
                totalRows: selectedRows.length,
                totalFiles : 0,
                files: []
            }
            selectedRows.forEach((row) => {
                employeeData.forEach((element, index) => {
                    if (row._row.data.id === element.id) {
                        if (element.file !== "N.A.") {
                            results.rowsHasFile = true
                            results.files.push({
                                id: element.id,
                                file: element.file
                            })
                        }
                    }
                })
            })  
            results.totalFiles = results.files.length
            return results
        }

        let resultStatus = false
        let resultMsg = "No file(s) associated with the selected certificates"
        let checkResults = checkRowsHaveFile(selectedRows)
        if(checkResults.rowsHasFile) {
            try {
                checkResults.files.forEach((fileItem) => {
                    let src = certDir+fileItem.file //todo add check for src whether src is avail
                    let downloadLink = document.createElement("a")
                    downloadLink.href = src
                    downloadLink.download = `${fileItem.id}.${fileItem.file.split(".")[fileItem.file.split(".").length - 1]}`
                    downloadLink.click()
                    downloadLink.remove()
                })
                resultStatus = true
                resultMsg = `Download started for ${checkResults.totalFiles} out of ${checkResults.totalRows} selected certificates`
            } catch {
                resultStatus = false
                resultMsg = "Download has failed"
            }
        }
        
        Swal.fire(
            resultStatus ? 'Success!' : 'Error!',
            resultMsg,
            resultStatus ? 'success' : 'error'
        )
        

        return resultStatus
    }

    useEffect(() => {
        // window.location.toString().split(path)[0] + path +
        fetch(  domain + "/api/EmployeeModule/employee_certificate.php", {
            method: "POST",
            mode: "same-origin",
            credentials: "same-origin",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                employeeCode: id
            })
        })
            .then(res => res.json())
            .then(json => {
                if (json[0] === false) {
                    setIsDataEmpty(0);
                } else {
                    data = parseEmployee(json);
                    setEmployeeData(data);
                    setCertDir(json[1])
                }
            })
            .catch(err => {
                return err;
            });
    }, [refetch, regen])

    const columns = [
        {   formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center", headerSort:false, cellClick:function(e, cell){
                cell.getRow().toggleSelect();
            }
        },
        {
            title: "ID", field: "id", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "id",
                urlPrefix: id+"/cert/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.id }) //here we update state
            }
        },
        { title: "Certificates", field: "cert", headerFilter: "input" },
        {
            title: "Issued Date", field: "idate", headerFilter: "input", formatter: "datetime", formatterParams: {
                inputFormat: "YYYY-MM-DD",
                outputFormat: "DD/MM/YYYY",
                invalidPlaceholder: "N/A",
            }
        },
        {
            title: "Expiry Date", field: "edate", headerFilter: "input", formatter: "datetime", formatterParams: {
                inputFormat: "YYYY-MM-DD",
                outputFormat: "DD/MM/YYYY",
                invalidPlaceholder: "N/A",
            }
        },
        { title: "Training Centre", field: "tcentre", headerFilter: "input" },
        { title: "Category", field: "cate", headerFilter: "input" },
        { title: "File", field: "file", headerFilter: "input" }
    ];

    useEffect(() => {
        try{
            const tableElement = document.getElementsByClassName("tabulator-table")[0]
            const tableElementChildren = tableElement.children
            for (let i = 0; i < tableElementChildren.length; i++) {
                const currentRow = tableElementChildren[i]
                const currentRowChildren = currentRow.children
                if (currentRowChildren[7].innerText === "N.A.") {
                    currentRowChildren[0].children[0].hidden = true
                }
            }
        } catch {}
        
    }, [columns])

    if (redirect.allow) {
        return <Redirect push to={location.pathname+'/cert/'+redirect?.id} />
    } else {
        return (
            <>
                <TabulatorWrapper
                    data={employeeData}
                    columns={columns}
                    activeTab={"Employee Certificates"}
                    //display={isDataEmpty !== 0 ? true : false}
                    children={
                        permission.add || permission.edit || permission.del ? // not sure if logic should split into add and edit or remain together
                            <Link to={location.pathname + "/control"}>
                                <button type="button" className="btn btn-primary mt-3 float-right center" >
                                    {isDataEmpty !== 0 ? "Edit" : "Add"}
                            </button>
                            </Link>
                        : null
                    }
                    handleRefetch={handleRefetch}
                    handleDownload={handleDownload}
                    disableComponentUpdate={true}
                />
            </>
        )
    }
    
}

function EmployeeCertificatesContainer(props) {
    const permission = useUserPermission(1)
    
    return (
        <Switch>
            {
                permission.add || permission.edit || permission.del ? <Route path="/:page/:id/cert/control" children={<EmployeeCertActions />} /> : null
            }
            {
                permission.view ? <Route path="/:page/:id/cert/:cert" children={<EmployeeCertPreview />} /> : null
            }
            {
                permission.add || permission.edit || permission.del ? <Route path="/:page/:id/control" children={<EmployeeCertActions />} /> : null
            }
            {
                permission.view ? <Route path="/:page/:id/" children={<EmployeeCertificates regen={Math.random()} permission={permission} />} /> : null
            }
        </Switch>
    )
    //regen prop is for forcing rerender, otherwise table will not refetch when redirected from EmployeeCertActions
    //downside is that every route after from this route will force rerender
}

export default EmployeeCertificatesContainer;