import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Input,
  SelectInput,
  SelectInputWithGroup,
} from "../../custom_components/Forms.js";
import { CreateFormTemplate } from "../../custom_components/templates/FormTemplate";
import { useFetchPost, sessionSetLoading } from "../../custom_hooks/Actions.js";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function ApplicationCreate(props) {

  const dataList = useFetchPost(
   domain + "/api/HomeLeaveModule/homeLeaveUtility.php",
    { option: "option" }
  ).data;
  const [inputData, setInputData] = useState({});
  const [submitted, setSubmitted] = useState(0);
  const [validated, setValidated] = useState(0);
  const [empName, setempName] = useState("");
  const defaultSelectInput = "Select an option";
  const [endDateThreshold, setEndDateThreshold] = useState();
  const fields = [
    {
      name: "Employee Name",
      inputname: "employeeName",
      type: "select",
      required: true,
      options: dataList[0],
      optionGroups: dataList[1],
    },
    {
      name: "Deisgnation",
      inputname: "designation",
      type: "text",
      required: true,
      datalist: dataList[1],
    },
    {
      name: "Project Site",
      inputname: "projectSite",
      type: "text",
      required: true,
      datalist: dataList[2],
    },
    {
      name: "Date Joined",
      inputname: "dateJoined",
      type: "date",
      required: true,
    },
    {
      name: "Date the Last Time Returned from Holiday/Home Leave",
      inputname: "lastDate",
      type: "date",
      required: true,
    },
    {
      name: "From Date",
      inputname: "startDate",
      type: "date",
      required: true,
    },
    {
      name: "To Date",
      inputname: "endDate",
      type: "date",
      required: true,
    },
    
    {
      name: "Leave Type",
      inputname: "leaveType",
      type: "select",
      required: true,
      options: ["Holiday Leave", "Home Leave","Others"],
    },
    {
      name: "Airport Destination",
      inputname: "destination",
      type: "text",
      required: true,
    },
    {
      name: "Remarks",
      inputname: "remarks",
      type: "text",
      required: true,
    },
    {
      name: "Project Manager",
      inputname: "projectManager",
      type: "text",
      required: true,
      datalist: dataList[3],
    },
   
  ];
  const notRequiredInputData = [];

  const handleChangeInput = (e, date) => {
   if (
      (e === "startDate" || e === "endDate" || e === "dateJoined" || e === "lastDate") &&
      date != null &&
      typeof e != "object"
    ) {
      setInputData({
        ...inputData,
        [e]: date,
      });
    } else {
      if (typeof e == "object") {
        setInputData({
          ...inputData,
          [e.target.name]: e.target.value,
        });
      }
    }

  };

  function createInputObj() {
    let a = {};
    for (let i = 0; i < fields.length; i++) {
      switch (i) {
        case 0:
          a[fields[i].inputname] = false;
          break;
        case 16:
          a[fields[i].inputname] = false;
          break;
        case 13:
          a[fields[i].inputname] = false;
          break;
        default:
          a[fields[i].inputname] = "";
          break;
      }
    }

    return a;
  }

  useEffect(() => {
    setInputData(createInputObj());
  }, []);

  useEffect(() => {}, [empName]);

  useEffect(() => {
    if (submitted === 1 && validated === 1) {
      
      sessionSetLoading(true);
      const inputUpload = inputData["file-uploadData"] ? true : false;
      let formData = JSON.stringify(inputData);
      // window.location.toString().split(path)[0] + path
      let url = domain + "/api/HomeLeaveModule/HomeLeaveApplication.php?action=add";
     
      let options = 
       {
            method: "POST",
            // mode: "same-origin",
            //credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            body: formData,
        };

      fetch(url, options)
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          if (json[2]) {
            let src =
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
              json[2];
            let downloadLink = document.createElement("a");
            downloadLink.href = src;
            downloadLink.download =
              "excelOutput." + inputData["file-uploadType"];
            downloadLink.click();
            downloadLink.remove();
          }

          if (json[0] === 1) {
            let forms = document.getElementsByTagName("form");
            for (let form of forms) {
              form.reset();
            }
            window.scrollTo(0, 0);
            setInputData(createInputObj());
          }
          setSubmitted(0);
          setValidated(0);
          Swal.fire(
            inputUpload
              ? json[3] === true
                ? "Error!"
                : "Success!"
              : json[0] === 1
              ? "Success!"
              : "Error!",
            json[1],
            inputUpload
              ? json[3] === true
                ? "error"
                : "success"
              : json[0] === 1
              ? "success"
              : "error"
          );
          sessionSetLoading();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [submitted, validated]);

  const dateNow = new Date();
  let formClassName = "form-group row";
  let formDisplayNone = formClassName + " d-none";
  const dateMin =
    dateNow.getFullYear() +
    "-" +
    (dateNow.getMonth() + 1) +
    "-" +
    dateNow.getDate();
  const fieldItems = fields.map((field, index) => (
    <div className="form-group row" key={field.inputname}>
      <label htmlFor={field.inputname} className="col-sm-3 ">
        {field.name}
      </label>
      <div className="col-sm-9">
        {field.type === "select" && field.optionGroups ? (
          <SelectInputWithGroup
            field={field.inputname}
            options={field.options}
            value={inputData[field.inputname]}
            optionGroups={field.optionGroups}
            defaultOption={defaultSelectInput}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
          />
        ) : field.type === "select" ? (
          <SelectInput
            field={field.inputname}
            value={inputData[field.inputname]}
            options={field.options}
            defaultOption={defaultSelectInput}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
         
          />
        ) : (
          <Input
            field={field.inputname}
            type={field.type}
            value={inputData[field.inputname]}
            dataList={field.datalist ? field.datalist : null}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            min={
              index === 6
                ? dateMin
                : index === 7
                ? endDateThreshold
                  ? endDateThreshold
                  : dateMin
                : null
            }
          />
        )}
      </div>
    </div>
  ));

  function handleSubmit(e) {
    e.preventDefault();
    if (e.target[0].type === "file") {
      let target = e.target[0];
      setInputData({
        ...inputData,
        [target.name]: URL.createObjectURL(target.files[0]),
        [target.name + "Type"]: target.value.split(".").pop(),
        [target.name + "Data"]: target.files[0],
      });
      setValidated(1);
      setSubmitted(1);
    } else {
      for (let inputName in inputData) {
        let isAnExcept = 0;
        for (let exceptNum in notRequiredInputData) {
          if (inputName === notRequiredInputData[exceptNum]) {
            isAnExcept = 1;
          }
        }
        if (isAnExcept === 0) {
          if (
            inputData[inputName] === defaultSelectInput ||
            inputData[inputName] === "" ||
            inputData[inputName] === null ||
            inputData[inputName] === "undefined"
          ) {
            setValidated(0);
            setSubmitted(0);
          } else {
            break;
          }
        } else {
          break;
        }
      }
      setValidated(1);
      setSubmitted(1);
    }
  }

  return (
    <CreateFormTemplate
      module="HomeLeave"
      handleSubmit={handleSubmit}
      fieldItems={fieldItems}
    />
  );
}

export default ApplicationCreate;
