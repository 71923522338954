import { useState, useEffect } from "react"
import {
    useParams,
} from "react-router-dom"
import Swal from "sweetalert2"
import { Input, SelectInput, SelectInputWithGroup } from '../../custom_components/Forms.js'
import { CreateFormTemplate } from '../../custom_components/templates/FormTemplate'
import { sessionSetLoading, useFetchPost, useUserPermission } from '../../custom_hooks/Actions.js'

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function ApplicationPayment(props) {
    const { id } = useParams()
    const [refetch, setRefetch] = useState(0)
    // window.location.toString().split(path)[0] + path +
    const paymentData = useFetchPost(
         domain + "/api/TrainingModule/trainingPayment.php?action=search", 
        { trainingID: id },
        refetch
    )
    const [grantApplicable, setGrantApplicable] = useState("")
    const [inputData, setInputData] = useState({})
    const [submitted, setSubmitted] = useState(0)
    const [validated, setValidated] = useState(0)
    const defaultSelectInput = "Select an option"
    const fields = [
        { name: "Training Application ID", inputname:"trainingID", type: "text", required: false, editMode:1},
        { name: "Training Grant", inputname:"grant", type: "select", required: true, options: ["Claimable","Non-Claimable"]},
        { name: "Total Payable Fee", inputname:"Tfee", type: "currency", required: false },
        { name: "Training Grant Amount", inputname:"Cfee", type: "currency", required: false },
        { name: "Absentee Payroll Amount", inputname:"Proll", type: "currency", required: false },
        { name: "Net Course Fee", inputname:"tBondCost", type: "currency", required: true },
        { name: "Remarks", inputname:"Remarks", type: "text", required: false },
        { name: "Bond", inputname:"bond", type: "select", required: true, options: ["Training Bond","No Service Bond Required"]},
        { name: "Training Bond Period", inputname:"duration", type: "text", required: false },
        { name: "Training Bond Period Type", inputname:"durationType", type: "select", required: false, options: ["weeks","months","years"]},
        { name: "Effective From", inputname:"effectiveDate", type: "date", required: false },
    ]
    const notRequiredInputData = []
    const permission = useUserPermission(10)

    const handleChangeInput = (e,date) => {
        if(typeof(e) == 'object'){
            setInputData({
                ...inputData,
                [e.target.name]: e.target.type ==="checkbox" ? e.target.checked : e.target.value
            })
        }else{
            setInputData({
                ...inputData,
                [e]: date
            });
        }
    }

    function createInputObj(json) {
        let a = {}
        for (let i = 0 ;i < fields.length; i++) {
            let inputName = fields[i].inputname
            a[inputName] = json[inputName] ? json[inputName] : ""
            if (i === 0) {
                a[inputName] = id
            }

        }
        return a
    }
    
    useEffect(() => {
        if(paymentData.data.length > 1){
           
            setGrantApplicable(paymentData.data[1]['grantApplicable']);
            paymentData.data['grantApplicable'] = paymentData.data[1]['grantApplicable'];
            if(paymentData.data[1]['grantApplicable'] == "Yes"){
                paymentData.data['Tfee'] = paymentData.data[1]['tBondCost'];
                paymentData.data['tBondCost'] = null;
                paymentData.data['grant'] = "Claimable"
            }else{
                paymentData.data['grant'] = "Non-Claimable"
                paymentData.data['tBondCost'] = paymentData.data[1]['tBondCost'];
            }
          
        }else{
            setGrantApplicable(paymentData.data['grantApplicable']);
            paymentData.data['grantApplicable'] = paymentData.data['grantApplicable'];
            if(paymentData.data['grantApplicable'] == "Yes"){
                paymentData.data['Tfee'] = paymentData.data['Tfee'];
                paymentData.data['tBondCost'] = paymentData.data['tBondCost'];
                paymentData.data['grant'] = "Claimable"
            }else{
                paymentData.data['grant'] = "Non-Claimable"
                paymentData.data['tBondCost'] = paymentData.data['tBondCost'];
            }
        }
        setInputData(createInputObj(paymentData.data))
    }, [paymentData.data, refetch])

    useEffect(() => {
        if (submitted === 1 && validated === 1) { // check whether there is existing record
            sessionSetLoading(true)
            // window.location.toString().split(path)[0] + path + 
            fetch( domain + "/api/TrainingModule/trainingPayment.php?action=search", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    trainingID: id,
                })
            })
                .then(res => res.json())
                .then(json => {
                    // window.location.toString().split(path)[0] + path + 
                    let url =  domain + "/api/TrainingModule/trainingPayment.php?action=add"
                    if(json[0] == undefined){ // if undefined, update the records in payment
                        // window.location.toString().split(path)[0] + path + 
                        url =  domain + "/api/TrainingModule/trainingPayment.php?action=update"
                    }
                    fetch(url, {
                        method: "POST",
                        mode: "same-origin",
                        credentials: "same-origin",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(inputData)
                    })
                        .then(res => res.json())
                        .then(json => {
                            if (json[0] === 1){
                                window.scrollTo(0, 0)
                                setRefetch(1)
                            }
                            setSubmitted(0)
                            setValidated(0)
                            Swal.fire(
                                json[0] === 1 ? 'Success!' : 'Error!',
                                json[1],
                                json[0] === 1 ? 'success' : 'error'
                            )
                            sessionSetLoading()
                        })
                        .catch(err => { console.log(err) })
                })
                .catch(err => { console.log(err) })
        }
    }, [submitted, validated])


    const fieldItems = fields.map((field, index) =>
        <div className={
            index === 2 && inputData[fields[1].inputname] === fields[1].options[1] ? "form-group row d-none" :
                index === 3 && inputData[fields[1].inputname] === fields[1].options[1] ? "form-group row d-none" :
                    index === 4 && inputData[fields[1].inputname] === fields[1].options[1] ? "form-group row d-none" :
                    (index === 8 || index === 9 || index === 10 ) && inputData[fields[7].inputname] === 'No Service Bond Required' ? "form-group row d-none": 
                            "form-group row"
        } key={field.inputname} >
            <label htmlFor={field.inputname} className="col-sm-3 ">{field.name}</label>
            <div className="col-sm-9">
                {
                    field.type === "select" && field.optionGroups ?
                        <SelectInputWithGroup
                            field={field.inputname}
                            options={field.options}
                            value={inputData[field.inputname]}
                            optionGroups={field.optionGroups}
                            defaultOption={defaultSelectInput}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                        />
                    : field.type === "select" ?
                        <SelectInput
                            field={field.inputname}
                            value={inputData[field.inputname]}
                            options={field.options}
                            defaultOption={defaultSelectInput}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            disabled={index === 1 ? 1 : field.editMode}
                        />
                    : field.type === "currency" && grantApplicable == "Yes" ?
                        <Input
                            field={field.inputname}
                            type={field.type}
                            value={inputData[field.inputname]}
                            dataList={field.datalist ? field.datalist : null}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            disabled={index === 2 ? 1 : field.editMode}
                        />
                    : field.type === "currency" && grantApplicable == "No"?
                        <Input
                            field={field.inputname}
                            type={field.type}
                            value={inputData[field.inputname]}
                            dataList={field.datalist ? field.datalist : null}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            disabled={ index === 5 ? 1 :  field.editMode}
                        />
                    :
                    
                    <Input
                        field={field.inputname}
                        type={field.type}
                        value={inputData[field.inputname]}
                        dataList={field.datalist ? field.datalist : null}
                        requiredOption={field.required}
                        onChangeInput={handleChangeInput}
                        disabled={field.editMode}
                    />
                }
            </div>
        </div>
    )

    function handleSubmit(e) {
        e.preventDefault()
        for (let inputName in inputData) {
            let isAnExcept = 0
            for (let exceptNum in notRequiredInputData) {
                if (inputName === notRequiredInputData[exceptNum]) {
                    isAnExcept = 1
                }
            }
            if (isAnExcept === 0) {
                if (inputData[inputName] === defaultSelectInput || inputData[inputName] === "" || inputData[inputName] === null || inputData[inputName] === "undefined") {
                    setValidated(0)
                    setSubmitted(0)
                } else {
                    break
                }
            } else {
                break
            }
        }
        setValidated(1)
        setSubmitted(1)
    }

    return (
        paymentData.status === "fetched" ?
            paymentData.data[0] === 0 && paymentData.data[1] === "Unauthorized to view training payment" ?
                <div>User is unauthorized to view training application payment</div>
            :
                <CreateFormTemplate 
                    handleSubmit={handleSubmit}
                    fieldItems={fieldItems}
                    importCsv={false}
                    editAccess={permission.add} // add and edit not differentiated here
                />
        :
            <div>Loading...</div>
        
    )
}

export default ApplicationPayment