import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { reactFormatter } from "react-tabulator";
import MainTemplate from "../../custom_components/templates/MainTemplate.js";
import {
  TabulatorTemplate,
  FullTabulatorTemplate,
} from "custom_components/templates/TabulatorTemplate.js";
import HazardCreate from "pages/HSE/HazardCreate.js";
import HazardInfo from "pages/HSE/HazardCreate.js";
import {
  useFetchPost,
  useUserPermission,
  sessionSetLoading,
} from "../../custom_hooks/Actions.js";
import { flipTrueFalse } from "helper_functions/Helper.js";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function parsesHazard(data) {
  let dataForActive = [];
  let dataForInactive = [];

 
  try {

    for (let i = 0; i < data.length ; i++) {

      data[i].forEach((number, index, array) => {
        const obj = {
          no: index + 1,
          hid: array[index]["HID"],
          jobTitle: array[index]["JobTitle"],
          workActivities: array[index]["WorkActivities"],
          area: array[index]["Area"],
          eused: array[index]["EquipmentUsed"],
          workLocation: array[index]["WorkLocation"],
          // closingDate: array[index]["ClosingDate"],
          status: array[index]['Status'],
        };
        if (i === 0) {
        
          if (data[i][0] !== 0) {
            dataForActive.push(obj);
          }
        } else {
          if (data[i][0] !== 0) {
            dataForInactive.push(obj);
          }
        }
      });
    }
  } catch {
    return ["", ""];
  }
  const parsedData = [dataForActive, dataForInactive];
  return parsedData;
}

function getRole(data) {
  const role = data;
  console.log(role);
  return role;
}

function Hazard({ permission }) {
  let location = useLocation();
  const [refetch, setRefetch] = useState(0);

  // window.location.toString().split(path)[0] + path +
  const hazardData = useFetchPost(
    domain + "/api/HSE/Hazard.php?action",
    {},
    refetch
  );

  const parsedData = parsesHazard(hazardData.data);
  const tabs = ["Active", "History"];
  const defaultTab = tabs[0];
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [redirect, setRedirect] = useState({ allow: false });
  const [HID, setHID] = useState("");
  const [status, setStatus] = useState("");
  const [role, setRole] = useState("");

  function handleRefetch() {
    setRefetch(Math.random())
  }

  function handleUserPasswordRedirect(e) {
    e.preventDefault();
  }

  const columns = [
    { title: "Sr/No.", field: "no", headerFilter: "input" },
    {
      title: "Hazard ID",
      field: "hid",
      headerFilter: "input",
      formatter: "link",
      formatterParams: {
        labelField: "hid",
        urlPrefix: "hazard/",
      },
      //hacky way to make tabulator play well with react router
      cellClick: function (e, cell) {
        e.preventDefault();
        const rowInfo = cell._cell.row.data;
        setRedirect({ allow: true, id: rowInfo.hid }); //here we update state
      },
    },
    { title: "Job Title", field: "jobTitle", headerFilter: "input" },
    { title: "Work Activities", field: "workActivities", headerFilter: "input" },
    { title: "Area", field: "area", headerFilter: "input" },
    { title: "Equipment Used", field: "eused", headerFilter: "input" },
    { title: "Work Location", field: "workLocation", headerFilter: "input" },
    { title: "Status", field: "status", headerFilter: "input" },
    // { title: "Closing Date", field: "closingDate", headerFilter: "input" },
   
  ];

  return redirect.allow ? (
    <Redirect push to={location.pathname + "/" + redirect?.id} />
  ) : hazardData.status === "fetched" ? (
    
    <TabulatorTemplate
      moduleName={"Hazard"}
      columns={columns}
      moduleData={parsedData}
      tabs={tabs}
      disableWidget={permission.add ? flipTrueFalse(permission.add) : true}
      handleRefetch={handleRefetch}
    />
  ) : hazardData.status === "fetching" ? (
    <div>Loading...</div>
  ) : (
    <div>Hazard Application is not available</div>
  );
}

function HazardContainer(props) {
  const permission = useUserPermission(3);

  return (
    <MainTemplate
      content={
        <Switch>
          {permission.add ? (
            <Route
              exact
              path="/:page/create"
              children={<HazardCreate />}
            />
          ) : null}
          {permission.view ? (
            <Route path="/:page/:id" children={<HazardInfo />} />
          ) : null}
          {permission.view ? (
            <Route path="" children={<Hazard permission={permission} />} />
          ) : null}
        </Switch>
      }
    />
  );
}

export default HazardContainer;
