import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import MainTemplate from "../../custom_components/templates/MainTemplate.js";
import {
  TabulatorTemplate,
  FullTabulatorTemplate,
} from "custom_components/templates/TabulatorTemplate.js";
import {
  useFetchPost,
  useUserPermission,
  sessionSetLoading,
} from "../../custom_hooks/Actions.js";
import { flipTrueFalse } from "helper_functions/Helper.js";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN
function parseScore(data) {
  let dataForActive = [];
  let dataForInactive = [];
  try {
    for (let i = 0; i < data.length; i++) {
  
      data[i].forEach((number, index, array) => {
      
        const obj = {
          no: index + 1,
          moduleID:  array[index]["courseID"] != 1 ?  "Module" + array[index]["courseID"] : "Module1&2",
          employeeCode: array[index]["EmployeeCode"],
          employeeName: array[index]["Name"],
          score:  array[index]["Score"]/10 * 100 + "%",
          result: array[index]["Result"],
          submittedDate : array[index]["SubmittedDate"]
        };
        if (i === 0) {
          if (data[i][0] !== 0) {
            dataForActive.push(obj);
          }
        } else  if (i === 1) {
          if (data[i][0] !== 0) {
            dataForInactive.push(obj);
          }
        }
      });
    }
  } catch {
    return ["", ""];
  }
  const parsedData = [dataForActive,dataForInactive];
  return parsedData;
}



function ScoreApplication({ permission }) {
  let location = useLocation();
  const [refetch, setRefetch] = useState(0);

  // window.location.toString().split(path)[0] + path +
  const scoreData = useFetchPost(
    domain + "/api/E-Learning/Elearning.php?action=score",
    {},
    refetch
  );

  const parsedData = parseScore(scoreData.data);
  const tabs = ["Pass", "Fail"];
  const defaultTab = tabs[0];
  const [redirect, setRedirect] = useState({ allow: false });

  function handleRefetch() {
    setRefetch(Math.random())
  }

  function handleUserPasswordRedirect(e) {
    e.preventDefault();
    
  }

  const columns = [
    { title: "Sr/No.", field: "no", headerFilter: "input" },
    { title: "Module ID", field: "moduleID", headerFilter: "input" },
    { title: "Employee Code", field: "employeeCode", headerFilter: "input" },
    { title: "Employee Name", field: "employeeName", headerFilter: "input" },
    { title: "Score", field: "score", headerFilter: "input" },
    { title: "Result", field: "result", headerFilter: "input" },  
    {
      title: "Submitted Date",
      field: "submittedDate",
      headerFilter: "input",
      formatter: "datetime",
      formatterParams: {
        inputFormat: "DD/MM/YYYY",
        outputFormat: "DD/MM/YYYY",
        invalidPlaceholder: "N/A",
      },
    },
  ];

  return redirect.allow ? (
    <Redirect push to={location.pathname + "/" + redirect?.id} />
  ) : scoreData.status === "fetched" ? (
    
    <TabulatorTemplate
      moduleName={"ModuleScore"}
      columns={columns}
      moduleData={parsedData}
      tabs={tabs}
      disableWidget={true}
      handleRefetch={handleRefetch}
    />
  ) : scoreData.status === "fetching" ? (
    <div>Loading...</div>
  ) : (
    <div>Elearning Score is not available</div>
  );
}

function ApplicationContainer(props) {
  const permission = useUserPermission(3);

  return (
    <MainTemplate
      content={
        <Switch>
          {permission.view ? (
            <Route path="" children={<ScoreApplication permission={permission} />} />
          ) : null}
        </Switch>
      }
    />
  );
} 

export default ApplicationContainer;
