import { useFetchPost } from "custom_hooks/Actions";
import { useEffect, useState } from "react";
import HelpdeskRatingForm from "./HelpdeskRatingForm";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function HelpdeskFeedback({routeParams}){
    const [refetch, setRefetch] = useState(0);

    const [field, setField] = useState({
        rateStatus: {displayText: "Rate Status", dataType:"text", val:"", required: true, disable: true},
        rateDetail: {displayText: "Rate Detail", dataType:"text", val:"", required: true, disable: true},
        completedDt: {displayText: "Completed Date", dataType:"text", val:"", required: true, disable: true},
        
    })
    
    function parseCustomData(dt){
        dt = dt.trim()
        dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '')
        dt = JSON.parse(dt)
       
       return dt
   }

    const helpdeskData = useFetchPost(
        domain + "/api/SupportModule/helpdesk.php?action=getSpecificHelpdesk",
        { helpdeskId: routeParams.id },
        refetch
    );
    
    function handleChanges(varName, value){
        let items = field[varName]
        items.val = value
        setField({
            ...field,
            [varName]: {...items}
        })
    }

    useEffect(()=>{
        if(helpdeskData.status === "fetched"){
            var dt = helpdeskData.data

            Object.keys(dt).map((k)=>{
                if(k === "rateStatus" || k === "rateDetail" || k === "completedDt"){
                    if(k === "rateDetail"){
                        let parsedDt = parseCustomData(dt[k])

                        handleChanges(k, parsedDt)
                    }else{
                        handleChanges(k, dt[k])
                    }
                }
            })
        }
    }, [helpdeskData.status])

    return(
        <HelpdeskRatingForm field={field} from="sys" />
    )
}