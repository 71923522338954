import { useState, useEffect } from "react"
import {
    useParams
} from "react-router-dom"
import { ImportImageForm } from '../../custom_components/Forms.js'
import { WidgetCreateFormBtnGrp } from '../../custom_components/Widgets.js'
import "../../css/Forms.css"
import { useUserPermission, useFetchPost, sessionSetLoading } from "custom_hooks/Actions.js"
import Swal from "sweetalert2"

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function UserAccountSettings(props) {
    let { id } = useParams()
    const [inputData, setInputData] = useState({})
    const [submitted, setSubmitted] = useState(0)
    const [validated, setValidated] = useState(0)
    const [isUserThemself, setIsUserThemself] = useState(null)
    let permission = false
    permission = useUserPermission(2)

    const handleChangeInput = (e) => {
        setInputData({
            ...inputData,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
            [e.target.name+"Type"]: e.target.value.split('.').pop(),
            [e.target.name+"Data"]: e.target.files[0]
        })
    }

    useEffect(() => {
        fetch(
            // window.location.toString().split(path)[0] + path +
            domain + "/api/EmployeeModule/useraccount_settings.php", {
            method: "POST",
            mode: "same-origin",
            credentials: "same-origin",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({employeeCode: id})
        })
            .then(res => res.json())
            .then(json => {
                setIsUserThemself(json)
            })
            .catch(err => { console.log(err) })
    }, [id])

    useEffect(() => {
        if (submitted === 1 && validated === 1 && (isUserThemself || permission.edit === 1)) {
            let formData = new FormData()
            formData.append("employeeCode",id)
            formData.append("fileType",inputData["file-uploadType"])
            formData.append("file",inputData["file-uploadData"])
            sessionSetLoading(true)
            fetch(
                // window.location.toString().split(path)[0] + path +
                  domain + "/api/EmployeeModule/useraccount_settingsupdate.php", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                body: formData
            })
                .then(res => res.json())
                .then(json => {
                    if (json[0] === 1) {
                        document.getElementsByTagName("form")[0].reset()
                        window.scrollTo(0, 0)
                    }
                    setSubmitted(0)
                    setValidated(0)
                    Swal.fire(
                        json[0] === 1 ? 'Success!' : 'Error!',
                        json[1],
                        json[0] === 1 ? 'success' : 'error'
                    )
                    sessionSetLoading()
                })
                .catch(err => { console.log(err) })
        }
    }, [submitted, validated])

    function handleSubmit(e) {
        e.preventDefault()
        setValidated(1)
        setSubmitted(1)
    }

    return (
        !permission.edit && !isUserThemself ?
            <div className="tab-grid">You do not have user role access for this component.</div>
        : 
        <div className="tab-grid">
            <form id="csv-form" onSubmit={handleSubmit}>
                <ImportImageForm 
                    text="Upload profile image" 
                    acceptedTypes={"image/*"} 
                    enabled={true} 
                    onChange={handleChangeInput} 
                    preview={inputData["file-upload"]}
                />
                <WidgetCreateFormBtnGrp />
            </form>
        </div>
    )
}

export default UserAccountSettings