import { CreateHelpdeskForm } from "custom_components/templates/FormTemplate";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function CreateHelpdesk({}){
    const history = useHistory();
    const [field, setField] = useState({
        requestorName: {displayText: "Requestor", dataType:"text", val:"", required: false, disable: true},
        subject: {displayText: "Issue", dataType:"SelectOne", val:"", disable: false},
        description: {displayText: "Description", dataType:"textbox", val:"", required: true, disable: false},
    })
    const subjectOption = [
        "Antivirus",
        "Computer related",
        "Email related",
        "Hardware related",
        "Hardware request",
        "Internet related",
        "Password reset",
        "Printer related",
        "Sharepoint",
        "Sharepoint Access",
        "Software Request",
        "Taskhub",
        "TMS",
        "WhyzeHR",
        "WINS",
        "ChummyHR",
        "Others"
    ]
    
    function handleChanges(varName, value){
        setField({
            ...field,
            [varName]: {...field[varName], val: value}
        })
    }

    useEffect(() => {
        fetch(
            domain + "/api/SupportModule/helpdesk.php?action=getname",
        {
            method: "GET",
        }
        )
        .then((res) => res.json())
        .then((json) => {
            handleChanges("requestorName", json["Name"])
        })
        .catch((err) => {
            console.log(err);
        });
    
      }, []);


    function handleSubmit(e){
        e.preventDefault()

        //validation check
        if(field["subject"].val !== "" && field["description"].val !== "" ){
            let inputData = {}

            Object.keys(field).map((k)=>{
                inputData[k] = field[k].val 
            })
            
            fetch(
                domain + "/api/SupportModule/helpdesk.php?action=addhelpdesk",
                {
                    method: "POST",
                    // mode: "same-origin",
                    //credentials: "same-origin",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(inputData),
                }
            )
            .then((res) => {
                res.json()
            })
            .then((json) => {
                Swal.fire(
                    "Success!",
                    "Successfully submitted",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
            })
            .catch((err) => {
                console.log(err);
            });

            history.push("/hd")
        }else{
            Swal.fire(
                "Alert",
                "Please complete all required field",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
        }
    }

    return(
        <CreateHelpdeskForm
            module="Helpdesk Ticket"
            from="create"
            field={field}
            subjectOption={subjectOption}
            handleChanges={handleChanges}
            handleSubmit={handleSubmit}
        />
    )
}