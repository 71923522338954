import { sessionSetLoading, useUserPermission } from "custom_hooks/Actions.js";
import { useState, useEffect } from "react"
import { Input, SelectInput, SelectInputWithGroup } from '../../custom_components/Forms.js'
import { CreateFormTemplate } from '../../custom_components/templates/FormTemplate'
import Swal from 'sweetalert2'

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function EmployeeCreate(props) {
    const [passData, setPassData] = useState([""]);
    const [dataList, setDataList] = useState([""]);
    const [dataList2, setDataList2] = useState([""]);
    const [formData, setFormData] = useState([""]);
    const [inputData, setInputData] = useState({});
    const [submitted, setSubmitted] = useState(0);
    const [validated, setValidated] = useState(0);
    const [empUpdate, setEmpUpdate] = useState(0);
    const fields = [
        { name: "Employee code", type: "text", required: true },
        { name: "Employee Category", type: "select", required: true ,options: formData[5]},
        { name: "Name", type: "text", required: true },
        { name: "NRIC / FIN", type: "text", required: true },
        { name: "Nationality", type: "text", required: true },
        { name: "Pass Type", type: "select", required: true, options: formData[0] },
        { name: "Pass Expiry Date", type: "date", required: true },
        { name: "Date of Birth", type: "date", required: true },
        { name: "Employment Date", type: "date", required: true },
        { name: "Dept / Project Code", type: "select", required: true, options: formData[1] },
        { name: "Designation Category", type: "text", required: true },
        { name: "Designation", type: "text", required: true },
        { name: "Contact Number", type: "tel", required: false },
        { name: "Email", type: "email", required: false },
        { name: "Qualification", type: "text", required: false },
        { name: "Reporting Manager", type: "select", required: false, options: formData[3] }
    ]
    const defaultSelectInput = "Select an option";
    const exceptInputData = [fields[6], fields[10],  fields[11], fields[12], fields[13]];
    const permission = useUserPermission(0)

    const handleChangeInput = (e,date) => {
       
        if(typeof(e) == 'object'){
            setInputData({
                ...inputData,
                [e.target.name]: e.target.value
            });
       }else{
            setInputData({
                ...inputData,
                [e]: date
            });
       }
    }

    function createDefaultsForInputs() {
        let a = {}
        for (let i = 0; i < fields.length; i++) {
            switch (i) {
                case 4:
                    a[fields[i].name] = defaultSelectInput;
                default:
                    a[fields[i].name] = "";
                    break;
            }
        }
        return a;
    }

    useEffect(() => {
        if (submitted === 0) {
            // window.location.toString().split(path)[0] + path +
            fetch(  domain + "/api/EmployeeModule/employee_createemployeeinfo.php", {
                method: "GET",
                mode: "same-origin",
                credentials: "same-origin"
            })
                .then(res => res.json())
                .then(json => {
                    setPassData(json[0].slice(1));
                    // setDataList(json[2].slice(1));
                    setDataList(json[2]);
                    setDataList2(json[4].slice(1));
                    setFormData(json);
                    console.log("json",json);
                    setInputData(createDefaultsForInputs());
                })
                .catch(err => { console.log(err) });
        }
    }, []);

    useEffect(() => {
        if (submitted === 1 && validated === 1 && permission.add ) {

            if(empUpdate == 1){
                const inputUpload = inputData["file-uploadData"] ? true : false
                let formData = JSON.stringify(inputData)
                let url =   domain + "/api/EmployeeModule/employee_updateemployeebatch.php"
                console.log(inputData);
                if (inputData["file-uploadData"]) {
                    console.log("file")
                    // window.location.toString().split(path)[0] + path +
                   
                    formData = new FormData()
                    formData.append("fileType",inputData["file-uploadType"])
                    formData.append("file",inputData["file-uploadData"])
                }

                let options =  {
                    method: "POST",
                    // mode: "same-origin",
                    credentials: "same-origin",
                    body: formData
                }

                sessionSetLoading(true)
                fetch(url, options)
                        .then(res => res.json())
                        .then(json => {
                            console.log(json);
                            if (json[2]) {
                                let src = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ json[2]
                                let downloadLink = document.createElement("a")
                                downloadLink.href = src
                                downloadLink.download = "excelOutput." + inputData["file-uploadType"]
                                downloadLink.click()
                                downloadLink.remove()
                            }

                            if (json[0] === 1) {
                                setInputData(createDefaultsForInputs());
                                setSubmitted(0);
                                setValidated(0);
                                let forms = document.getElementsByTagName("form")
                                for (let form of forms) {
                                    form.reset()
                                }
                                window.scrollTo(0, 0);
                            }
                            setSubmitted(0)
                            setValidated(0)
                            Swal.fire(
                                inputUpload ? json[3] === true ? 'Error!' : 'Success!'
                                    :json[0] === 1 ? 'Success!' : 'Error!'
                                ,
                                json[1],
                                inputUpload ? json[3] === true ? 'error' : 'success'
                                    :json[0] === 1 ? 'success' : 'error'
                            )
                            sessionSetLoading(false)
                        })

                        .catch(err => { console.log(err) })
               
            }else{
                const inputUpload = inputData["file-uploadData"] ? true : false
                let formData = JSON.stringify(inputData)
                // window.location.toString().split(path)[0] + path +
                let url =   domain + "/api/EmployeeModule/employee_createemployee.php"
                if (inputData["file-uploadData"]) {
                    // window.location.toString().split(path)[0] + path +
                    url =   domain + "/api/EmployeeModule/employee_createemployeebatch.php"
                    formData = new FormData()
                    formData.append("fileType",inputData["file-uploadType"])
                    formData.append("file",inputData["file-uploadData"])
                }

                let options = inputData["file-uploadData"] ?
                {
                    method: "POST",
                    mode: "same-origin",
                    credentials: "same-origin",
                    body: formData
                }
                :
                    {
                        method: "POST",
                        mode: "same-origin",
                        credentials: "same-origin",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            employeeCode: inputData[fields[0].name],
                            employeeCategory: inputData[fields[1].name],
                            name: inputData[fields[2].name],
                            nric: inputData[fields[3].name],
                            nationality: inputData[fields[4].name],
                            passType: inputData[fields[5].name],
                            passExpiryDate: inputData[fields[6].name],
                            dob: inputData[fields[7].name],
                            dateJoin: inputData[fields[8].name],
                            dept: inputData[fields[9].name],
                            designation_cat: inputData[fields[10].name],
                            designation: inputData[fields[11].name],
                            contact: inputData[fields[12].name],
                            email: inputData[fields[13].name],
                            qualification: inputData[fields[14].name],
                            reportingManager: inputData[fields[15].name]
                        })
                    }
                    sessionSetLoading(true)
                    fetch(url, options)
                        .then(res => res.json())
                        .then(json => {
                            if (json[2]) {
                                let src = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ json[2]
                                let downloadLink = document.createElement("a")
                                downloadLink.href = src
                                downloadLink.download = "excelOutput." + inputData["file-uploadType"]
                                downloadLink.click()
                                downloadLink.remove()
                            }

                            if (json[0] === 1) {
                                setInputData(createDefaultsForInputs());
                                setSubmitted(0);
                                setValidated(0);
                                let forms = document.getElementsByTagName("form")
                                for (let form of forms) {
                                    form.reset()
                                }
                                window.scrollTo(0, 0);
                            }
                            setSubmitted(0)
                            setValidated(0)
                            Swal.fire(
                                inputUpload ? json[3] === true ? 'Error!' : 'Success!'
                                    :json[0] === 1 ? 'Success!' : 'Error!'
                                ,
                                json[1],
                                inputUpload ? json[3] === true ? 'error' : 'success'
                                    :json[0] === 1 ? 'success' : 'error'
                            )
                            sessionSetLoading(false)
                        })
        
                        .catch(err => { console.log(err) })
            }

            }
            
    }, [submitted, validated]);

    let formClassName = "form-group row"
    let formDisplayWide = formClassName + " grid-wide"
    let formDisplayNone = formClassName + " d-none"
    const dateNow = new Date()
	const dateMin = dateNow.getFullYear() + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
    const fieldItems = fields.map((field, index) =>
        <div className={
            //HARDCODED AS ADDING NEW ITEMS AND SORTING MAY STILL BREAK LOGIC
            index === 6 && inputData[fields[5].name] === "" ? formDisplayNone :
                index === 6 && inputData[fields[5].name] === "NRIC" ? formDisplayNone :
                    index === 6 && inputData[fields[5].name] === "FIN" ? formDisplayNone :
                        formClassName
        }
            key={index} >
            <label htmlFor={field.name} className="col-sm-3 ">{field.name}</label>
            <div className="col-sm-9">
                {
                   index === 1 || index === 5 || index === 9 ?
                        <SelectInput
                            field={field.name}
                            value={inputData[field.name]}
                            options={field.options}
                            defaultOption={defaultSelectInput}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                        />
                        : index === 15 ?
                            <SelectInputWithGroup
                                field={field.name}
                                value={inputData[field.name]}
                                options={field.options}
                                optionGroups={dataList}
                                defaultOption={defaultSelectInput}
                                requiredOption={field.required}
                                onChangeInput={handleChangeInput}
                            />
                            : <Input
                                field={field.name}
                                type={field.type}
                                value={inputData[field.name]}
                                dataList={
                                    index === 10 ? dataList
                                    : index === 4 ? dataList2 : null
                                }
                                requiredOption={
                                    index === 6 && inputData[fields[5].name] === "NRIC" ?
                                        false
                                            : index === 6 && inputData[fields[5].name] === "FIN" ?
                                                false
                                                :
                                                    field.required
                                }
                                min={index === 6 ? dateMin : null}
                                onChangeInput={handleChangeInput}
                            />
                }
            </div>
        </div>
    )

    function handleSubmit(e) {
        e.preventDefault()
        if (e.target[0].type === "file") {
            let target = e.target[0]
            setInputData({
                ...inputData,
                [target.name]: URL.createObjectURL(target.files[0]),
                [target.name+"Type"]: target.value.split('.').pop(),
                [target.name+"Data"]: target.files[0]
            })
            setValidated(1)
            setSubmitted(1)
        } else {
          
            for (let inputName in inputData) {
                let isAnExcept = 0
                for (let exceptNum in exceptInputData) {
                    if (inputName === exceptInputData[exceptNum]) {
                        isAnExcept = 1
                    }
                }
                if (isAnExcept === 0) {
                    if (inputData[inputName] === defaultSelectInput || inputData[inputName] === "" || inputData[inputName] === null || inputData[inputName] === "undefined") {
                        setValidated(0)
                        setSubmitted(0)
                    } else {
                        break
                    }
                } else {
                    break
                }
            }
        }

        setValidated(1)
        setSubmitted(1)
    }

    function handleEmployeeUpdate(e){
        
        e.preventDefault()
        if (e.target[0].type === "file") {
            let target = e.target[0]
            setInputData({
                ...inputData,
                [target.name]: URL.createObjectURL(target.files[0]),
                [target.name+"Type"]: target.value.split('.').pop(),
                [target.name+"Data"]: target.files[0]
            })
            setEmpUpdate(1)
            setValidated(1)
            setSubmitted(1)
        }
    }

    return (
       <CreateFormTemplate
            module="Employee"
            handleSubmit={handleSubmit}
            handleEmployeeUpdate={handleEmployeeUpdate}
            fieldItems={fieldItems}
       />
    )
}

export default EmployeeCreate;