import { CreateRecruitmentForm } from "custom_components/templates/FormTemplate";
import { sessionSetLoading } from "custom_hooks/Actions";
import { useEffect, useState } from "react";
import {useHistory} from "react-router-dom"
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function InterviewCreate({}){
    const history = useHistory();
    const [field, setField] = useState({
        interviewId: {displayText: "ID", dataType:"text", val:""},
        applicantId: {displayText: "Applicant ID", dataType:"text", val:""},
        applicantName: {displayText: "Applicant Name", dataType:"text", val:"", required: true},
        applicantEmail: {displayText: "Applicant Email", dataType:"text", val:"", required: true},
        applyPosition: {displayText: "Applying Position", dataType:"text", val:"", required: true},
        department: {displayText: "Department", dataType:"text", val:"", required: true},
        reportManager: {displayText: "Reporting Manager", dataType:"text", val:"", required: true},
        // managerRecommend: {displayText: "Manager Recommendation", dataType:"text", val:"", required: true},
        // managerComment: {displayText: "Manager Comment", dataType:"text", val:"", required: true},
        interviewDt: {displayText: "Interview Datetime", dataType:"text", val:"", required: true},
        interviewType: {displayText: "Interview Type", dataType:"text", val:"", required: true},
        interviewStatus: {displayText: "Interview Status", dataType:"text", val:"Pending"},
        createdBy: {displayText: "Created By", dataType:"text", val:""},
        createdDt: {displayText: "Created Datetime", dataType:"text", val:""},
    })

    var [inputTime, setInputTime] = useState()
    var [inputDate, setInputDate] = useState()
    var [employeeList, setEmployeeList] = useState([""])
    var [employeeCategory, setEmployeeCategory] = useState([""])
    var [dept, setDept] = useState([])
    var [submitted, setSubmmited] = useState(0)
    var [validated, setValidated] = useState(0)

    const interviewType = ["Face to face interview", "Send job application form"]

    function handleOptionsFromDb(empList, dept){
        setEmployeeList(empList)
        let catAry = []
        empList.map((dt)=>{
            if(!catAry.includes(dt[1]) && dt[1] !== "Driver"){
                catAry.push(dt[1])
            }
        })
        setEmployeeCategory(catAry)
        setDept(dept)
    }

    useEffect(() => {
        fetch(
            domain + "/api/RecruitmentModule/manpowerCreateEmployeeInfo.php",
        {
            method: "GET",
        }
        )
        .then((res) => res.json())
        .then((json) => {
            handleOptionsFromDb(json[3], json[1])
        })
        .catch((err) => {
            console.log(err);
        });
    
      }, []);

    function handleChanges(varName, value){
        if(varName === "date" || varName === "time"){
            if(varName === "date"){
                setInputDate(value)
            }else{
                setInputTime(value)
            }

            // setField({
            //     ...field,
            //     ["interviewDt"]: {...field["interviewDt"], val: combinedDt}
            // })

        }else{
            setField({
                ...field,
                [varName]: {...field[varName], val: value}
            })

        }
    }

    function handleSubmit(e){
        e.preventDefault()
        let passCheck = true
        for(const k of Object.keys(field)){
            if(k === "applicantName" || k==="applicantEmail" || k==="applyPosition" || k==="department" || k==="reportMananger" || k==="interviewDt" || k==="interviewType"){
                if(k === "interviewDt"){
                    if(inputDate === "" || inputTime === ""){
                        passCheck = false
                        break;
                    }
                }else{
                    if(field[k].val === "" || field[k].val === null){
                        passCheck = false
                        break;
                    }
                }
            }
        }

        if(passCheck){
            setValidated(1)
        }
    }

    useEffect(()=>{
        if(validated==1){

            let inputData = {}

            Object.keys(field).map((k)=>{
                if(k === "interviewDt"){
                    inputData[k] = inputDate+" "+inputTime+":00"
                }else{
                    inputData[k] = field[k].val
                }
            })

            fetch(
                domain + "/api/RecruitmentModule/interview.php?action=add",
                {
                    method: "POST",
                    // mode: "same-origin",
                    //credentials: "same-origin",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(inputData),
                }
            )
            .then(res => 
                res.text()
            )
            .then(res => {
                if(res){
                    Swal.fire(
                        "Success!",
                        "Successfully submitted",
                        // json[1],
                        // json[0] === 1 ? "success" : "error"
                    );
                }else{
                    console.log("error")
                }
            })
            .catch((err) => {
                console.log(err);
            });

            setValidated(0)
            history.goBack()
            // history.push("/interview")
        }
    }, [validated])

    return(
        <CreateRecruitmentForm
            module="Job Interview"
            field={field}
            handleChanges={handleChanges}
            interviewType={interviewType}
            optionGroups={employeeCategory}
            handleSubmit = {handleSubmit}
            options={employeeList}
            dept={dept}
        />
    )
}