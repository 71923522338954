import { useState } from "react";
import Tabs from '../../custom_components/Tabs.js';
import EmployeeDetails from 'pages/employee/EmployeeDetails.js';
import EmployeeCertificates from 'pages/employee/EmployeeCertificates.js';
//import EmployeeApprovalLevel from 'pages/employee/EmployeeApprovalLevel.js';
import { WidgetBreadCrumbHeader } from '../../custom_components/Widgets.js'

function TabDisplay(props) {
    let activeTab = props.activeTab;
    let tabs = props.tabs;
    switch (activeTab) {
        case tabs[0]:
            return (<EmployeeDetails />);
        case tabs[1]:
            return (<EmployeeCertificates />);
        // case tabs[2]:
        //     return (<EmployeeApprovalLevel />);
        default:
            return (null);
    }
}

function EmployeeInfo({ defaultTab }) {
    const tabs = ["Details", "Certificate"];
    const [activeTab, setActiveTab] = useState(defaultTab ? defaultTab : tabs[0])
    return (
        <div>
            <WidgetBreadCrumbHeader prevNav="Employee" curNav="Employee Information" prevLink={"#"} curLink={"#"} />
            <br />
            <Tabs tabs={tabs} state={{ activeTab: [activeTab, setActiveTab] }} onClick={() => setActiveTab(defaultTab)} />
            <TabDisplay activeTab={activeTab} tabs={tabs} />
        </div>
    )
}

export default EmployeeInfo;