import React, { useEffect, useState } from "react"
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.min.css";
import { ReactTabulator } from 'react-tabulator';
import { WidgetColumnFilter, WidgetDownloadItem, WidgetFixedAssetAcknowledge, WidgetReload } from 'custom_components/Widgets.js'
import { useFetchPost } from '../custom_hooks/Actions.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactModal from "react-modal";
import Acknowledgement from "pages/support/Acknowledgement.js";

//TabulatorWrapper component used to display tabulator tables, data is passed as props and all tables use this config
//TabulatorWrapper > Tabulator 
//TabulatorWrapper > TableIsEmpty

function TableIsEmpty({ activeTab }) {
    return (
        <div>No data is found in {activeTab.toLowerCase()} tab</div>
    )
}



function TabulatorWrapper({ 
        key, 
        display, 
        activeTab,
        data, 
        columns, 
        moduleName, 
        moduleWidgets, 
        children, 
        handleRefetch, 
        handleDownload, 
        disablePdfDownload = false, 
        disableComponentUpdate = false, //when set to true, disables the component from updating, used in EmployeeCertificate.js to run DOM updates
        showFaComponent
    }) {
    useEffect(()=>{
        columns.forEach(element => {
            element.headerFilter =  ""
        })
    }, [])

    if (display === false) {
        return (
            <TableIsEmpty activeTab={activeTab ? activeTab : null} />
        )
    } else {
        return (
            <div>
                <Tabulator 
                    key={key} 
                    data={data} 
                    columns={columns} 
                    moduleName={moduleName} 
                    children={moduleWidgets} 
                    handleRefetch={handleRefetch} 
                    handleDownload={handleDownload}
                    disablePdfDownload={disablePdfDownload}
                    disableComponentUpdate={disableComponentUpdate}
                    showFaComponent={showFaComponent === true ? showFaComponent : false}
                />
                {children}
            </div>
        )
    }
}

class Tabulator extends React.Component {
    constructor(props) {
        super(props)
        this.reloadRef = React.createRef()
        let ref = null
        // let columns = this.props.columns
        
        this.state = {
            toggle: false,
            columns: this.props.columns,
            modalIsOpen: false,

        }
        this.handleToggle() 
        // this.resetToggle()
    }

    componentDidMount() {
        try {
            this.reloadRef.click()
        } catch {}
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.disableComponentUpdate) {
            if (this.props.columns !== this.state.columns) {
                this.setState({columns: this.props.columns})
            }
        }
    }

    getDate() {
        const today = new Date().toLocaleDateString().replaceAll("/", "-")
        return today
    }

    downloadAsPDF= () => {
       
        // const date = this.getDate()
        // this.ref.table.download("pdf", this.props.moduleName + "-" +  date + ".pdf")
        const date = this.getDate()
        // this.ref.table.download("pdf", this.props.moduleName + "-" +  date + ".pdf")

        const header = [];
        const field = [];
        this.ref.state.columns.map(x=>{
            header.push(x['title']);
            field.push(x['field']);
        })
       
        const data = [];
        this.ref.state.data.map((x) =>{
            const row = [];
            field.map(f=>{
                row.push(x[f]);
            })
           
            data.push(row);
            
          })   
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = this.props.moduleName;
        const headers = [header];

        let content = {
        startY: 50,
        head: headers,
        body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(this.props.moduleName + "-" +  date + ".pdf")
    }

  

    downloadAsXLSX= () => {
        // const date = this.getDate()
        // this.ref.table.download("xlsx", this.props.moduleName + "-" +  date + ".xlsx")
        const date = this.getDate()
        const data = [];
        const header = [];
        const field = [];
    
          this.ref.state.columns.map(x=>{
            header.push(x['title']);
            field.push(x['field']);
          })
          data.push([this.props.moduleName,'',''])
          data.push([]);
          data.push(header);
          this.ref.state.data.map((x) =>{
            const row = [];
            field.map(f=>{
                row.push(x[f]);
            })
           
            data.push(row);
            
          })   
          
        
          // Create a new workbook and worksheet
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.aoa_to_sheet(data);
       
          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        
          // Set the column widths (optional)
          worksheet['!cols'] = [{ width: 10 }, { width: 20 }, { width: 20 },{ width: 20 },{ width: 20 }];
          
          // Merge cells for the title row
          worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];
        
        //   Convert the workbook to a buffer
          const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        
        //   Download the file using FileSaver.js
          saveAs(new Blob([buffer]), this.props.moduleName + "-" +  date + ".xlsx");

        // XLSX.writeFile(workbook, this.props.moduleName + "-" +  date + ".xlsx");
    }

    handleDownloadCertificates= () => {
        this.props.handleDownload(this.ref.table.getSelectedRows())
    }

    resetToggle() {
        let columns = this.props.columns
        columns.forEach(element => {
            element.headerFilter =  ""
        })
        this.setState({
            toggle: false,
            columns: columns
        })
    }

    handleToggle= () => {
        const newToggle = this.state.toggle ? false : true
        const newColumns = this.props.columns
        newColumns.forEach(element => {
            element.headerFilter = newToggle ? "input" : ""
        })
        this.setState({
            toggle: newToggle,
            columns: newColumns
        })
    }

    // Modal function

    openModal=()=>{
        this.setState({modalIsOpen: true})
    }

    closeModal=()=>{
        this.setState({modalIsOpen: false})
    }

    render() {
        const options = {
            autoResize: false,
            placeholder: "No Data Available",
            layout: "fitDataStretch",
            movableColumns: "true",
            pagination: "local",
            paginationSize: 15,
            paginationSizeSelector: [5, 10, 15, 30],
            downloadReady: (fileContents, blob) => blob,
        }

        const customStyles = {
            content: {
              top: '50%',
              left: '50%',
            //   right: 'auto',
            //   bottom: 'auto',
              width: '90%',
              height: '80%',
              marginRight: '-50%',
              marginTop: '3%',
              transform: 'translate(-50%, -50%)',
              overflowY: 'scroll',
              padding: '3%',
            },
            overlay: {
                zIndex: 999,
                backgroundColor: 'rgb(178, 190, 181, 0.5)',
            }
          };

        return (
            <div>
                {
                    this.props.handleDownload &&
                    <WidgetDownloadItem onClick={this.handleDownloadCertificates}   text="Download Certificates" />
                }
                <WidgetColumnFilter onClick={this.handleToggle}/>
                { 
                    !this.props.disablePdfDownload &&
                    <WidgetDownloadItem onClick={this.downloadAsPDF} text="Download PDF" />
                }
               
                <WidgetDownloadItem onClick={this.downloadAsXLSX} text="Download XLSX" />

                {/* Show Fixed asset component if it is from fixed asset view */}
                { 
                    this.props.showFaComponent === true &&
                    <WidgetFixedAssetAcknowledge onClick={this.openModal} text="Item Acknowledgement" />
                }

                <ReactModal 
                    isOpen={this.state.modalIsOpen} 
                    onRequestClose={this.closeModal} 
                    ariaHideApp={false}
                    contentLabel="Fixed Asset Acknowledgement" 
                    style={customStyles} 
                    closeTimeoutMS={200}
                >
                    <Acknowledgement />

                </ReactModal>
                {/* End of showing fixed asset component */}


                <WidgetReload ref={this.reloadRef} onClick={this.props.handleRefetch} />
                {this.props.children}   
                <ReactTabulator
                    key={this.state.toggle} //force render
                    ref={ref => (this.ref = ref)}
                    className=""
                    data={this.props.data}
                    columns={this.state.columns}
                    options={options}
                    
                />
            </div>
        ) 
    }
}

export { Tabulator, TabulatorWrapper }