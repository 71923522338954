import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

//Actual app is here, do not modify unless you need to
//General Naming Convention:
//ClassComponents and FunctionalComponents MUST be in pascal caps
//componentClassFunctions MUST be in camel caps
//folder_names should be in snake caps
//FileNames should be in pascal caps

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
