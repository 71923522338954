import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import Navbar from 'Navbar.js';
import Sidenav from 'Sidebar.js';
import profile_base from 'icons/profile_base.jpg';
import { WidgetLoaderOverlay } from "custom_components/Widgets";

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function MainTemplate(props) {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(null)

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [toggle, setToggle] = useState(false);

  function navToggle(e){
    setToggle(toggle => !toggle);
  }

  useEffect(() => {
    setInterval(function(){
      const status = sessionStorage.getItem('loading') === 'true' ? true : false
      setLoading(status)
    }, 300)
  
    fetch(domain + "/api/sessioncheck.php", {
      method: "GET",
      mode: "same-origin",
      credentials: "same-origin"
    })
    .then(res=>res.json())
    .then(json=>{
      setData({
        companyId: json[1],
        employeeCode: json[2],
        profilePicture: json[3],
        profilePictureExist: json[3].split("/")[4]
      })
    })
    .catch(err=>err)
  },[]);

  return (
    <div id="app">
        <Navbar />
        <div className="container-fluid">
            <div className="row">
                {/* <Sidenav /> */}
                {/* <Sidenav toggle={toggle} setToggle={setToggle} navToggle={navToggle} /> */}
                <div id="background-obj">
                  <div id="left-square-obj"></div>
                  <div id="right-square-obj"></div>
                </div>
                <div id="content-box" className={`col-md-9 ml-sm-auto col-lg-9 px-md-4 ${toggle ? 'hide-main-box' : 'show-main-box' }`}>
                <div>
                  {
                    // window.location.toString().split(path)[1] === "/db" && 
                    window.location.toString().includes("/db")  &&
                      <h6 className="float-left">Welcome back, <span className="ccolor">{capitalizeFirstLetter(props.userName)}</span></h6>
                  }
                </div>
                  <main id="content-box">
                    {props.content}
                    {loading && <WidgetLoaderOverlay loading={loading} />}
                  </main>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MainTemplate;