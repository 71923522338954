import { useState } from "react";
import Tabs from '../../custom_components/Tabs.js';
import ApplicationDetails from 'pages/training/ApplicationDetails.js';
import ApplicationPayment from 'pages/training/ApplicationPayment.js';
import ApplicationFeedback from 'pages/training/ApplicationFeedback.js';
import ApplicationEvaluation from 'pages/training/ApplicationEvaluation.js';
import { WidgetBreadCrumbHeader } from '../../custom_components/Widgets.js'

function TabDisplay(props) {
    let activeTab = props.activeTab;
    let tabs = props.tabs;
    switch (activeTab) {
        case tabs[0]:
            return (<ApplicationDetails />);
        case tabs[1]:
            return (<ApplicationPayment />);
        case tabs[2]:
            return (<ApplicationFeedback />);
        case tabs[3]:
            return (<ApplicationEvaluation />);
        default:
            return (null);
    }
}

function ApplicationInfo(props) {
    const tabs = ["Details", "Payment", "Feedback", "Evaluation"];
    const defaultTab = tabs[0];
    const [activeTab, setActiveTab] = useState(defaultTab);
    return (
        <div>
            <WidgetBreadCrumbHeader prevNav="Application" curNav="Application Information" prevLink={"#"} curLink={"#"} />
            <br />
            <Tabs tabs={tabs} state={{ activeTab: [activeTab, setActiveTab] }} onClick={() => setActiveTab(defaultTab)} />
            <TabDisplay activeTab={activeTab} tabs={tabs} />
        </div>
    )
}

export default ApplicationInfo;