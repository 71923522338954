import { CreateApplicantFormTemplate } from "custom_components/templates/FormTemplate";
import ProgressBarTemplate from "custom_components/templates/ProgressBarTemplate";
import "../../css/progressBar.css"
import { useEffect, useState } from "react";
import { JobApplicationBtnGrp } from "custom_components/Widgets";
import Swal from "sweetalert2";
import { te } from "date-fns/locale";
import { useHistory } from "react-router-dom";


const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function ApplicantForm({handleAuthenticate, handleFilledStat}){
    const sectionList = ["General", "Education","Employment", "Reference","Others"]
    const yesAndNoOption = ["Yes", "No"]
    const haveOrNotOption = ["Yes, I have", "No, I do not"]
    const genderOption = ["Male", "Female"]
    const history = useHistory();
    const [currentIdx, setCurrentIdx] = useState(1)
    
    //General Section
    const [generalField, setGeneralField] = useState({
        name: {displayText: "Name", dataType:"text", val:"", require:true},
        // age: {displayText: "Age", dataType: "text", val:"", require:true},
        nric: {displayText: "NRIC/FIN", dataType:"text", val:"", require:true},
        gender: {displayText: "Gender", dataType:"text", val:"", require:true},
        race: {displayText: "Race", dataType:"text", val:"", require:true},
        email: {displayText: "Email", dataType:"text", val:"", require:true},
        contact: {displayText: "Contact no", dataType:"text", val:"", require:true},
        pob: {displayText: "Place of birth", dataType:"text", val:"", require:true},
        dob: {displayText: "Date of birth", dataType:"date", val:"", require:true},
        address: {displayText: "Address", dataType:"text", val:"", require:true},
        nationality: {displayText: "Nationality", dataType:"text", val:"", require:true},
        marital: {displayText: "Marital status", dataType:"text", val:"", require:true},
        // driving: {displayText: "Driving licenses (class)", dataType:"text", val:"", require:true},
        // serviceRank: {displayText: "National service rank", dataType:"text", val:"", require:true},
        // photo: {displayText: "Your recent photo", dataType:"text", val:"", require:true},
    })

    //Education Section
    const [educationField, setEducationField] =useState([
        {
            institute: {displayText: "Institute name", dataType:"text", val:"", require:true},
            start: {displayText: "Start date", dantaType:"text", val:"", require:true},
            end: {displayText: "End date", dataType:"text", val:"", require:true},
            qualification: {displayText: "Qualification obtained", dataType:"text", val:"", require:true},
            // result: {displayText: "Result", dataType:"text", val:"", require:true},
        }
    ])

    //Employment Section
    const [employmentField, setEmploymentField] = useState([
        {
            empName: {displayText: "Employer name", dataType:"text", val:"", require:true},
            // empContact: {displayText: "Contact number", dataType: "text", val:"", require:true},
            // empAddress: {displayText: "Company address", dataType:"text", val:"", require:true},
            empStart: {displayText: "Start date", dataType:"text", val:"", require:true},
            empEnd: {displayText: "End date", dataType:"text", val:"", require:true},
            empPosition: {displayText: "Position", dataType:"text", val:"", require:true},
            // empAchievement: {displayText: "Achievement", dataType:"text", val:"", require:true},
            empLastSalary: {displayText: "Last drawn salary", dataType:"text", val:"", require:true},
            empLeaveReason: {displayText: "Reason of leaving", dataType:"text", val:"", require:true}
        }
    ])

    const [referenceField, setReferenceField] = useState({
            refName :{displayText: "Referer name", dataType:"text", val:"", require:true},
            refContact :{displayText: "Referer contact", dataType:"text", val:"", require:true},
            refCompanyName :{displayText: "Company name", dataType:"text", val:"", require:true},
            // refAddress :{displayText: "Company address", dataType:"text", val:"", require:true},
            refOccupation :{displayText: "Referer occupation", dataType:"text", val:"", require:true},
            // refRelationship :{displayText: "Referer relationship", dataType:"text", val:"", require:true},
    })

    const [otherField, setOtherField] = useState({
        // compSkill : {displayText: "Computer skills acquired", dataType:"text", val:"", require:true},
        furPlan : {displayText: "Any plan to futher studies", dataType:"text", val:"", require:true},
        majorIll : {displayText: "Major illness in last 6 months", dataType:"text", val:"", require:true},
        constMed : {displayText: "Are you in constant medication", dataType:"text", val:"", require:true},
        criminalCharge : {displayText: "You have not been convicted under any criminal charge", dataType:"text", val:"", require:true},
        takeDrug : {displayText: "You are not taken any hazardous drugs", dataType:"text", val:"", require:true},
        certInfo : {displayText: "I hereby certify that all information provided are correct", dataType:"text", val:"", require:true},
    })

    //Handle Changes functions
    //Change for fields data
    function handleGenChanges(varName, value, idx){
        if(currentIdx === 1){
            if(varName === "contact"){
                value = value.replace(" ", "")
                if(isNaN(value)){
                    // value = ""
                    return
                }
            }
            setGeneralField({
                ...generalField,
                [varName]: {...generalField[varName], val: value}
            })
        }else if(currentIdx === 2){
            let data = [...educationField]
            data[idx][varName]["val"] = value
            setEducationField(data)
        }else if(currentIdx === 3){
            let data = [...employmentField]
            data[idx][varName]["val"] = value
            setEmploymentField(data)
        }else if(currentIdx === 4){
            if(varName === "refContact"){
                value = value.replace(" ", "")
                if(isNaN(value)){
                    // value = ""
                    return
                }
            }
            setReferenceField({
                ...referenceField,
                [varName]: {...referenceField[varName], val: value}
            })
            // let data = [...referenceField]
            // data[idx][varName]["val"] = value
            // setReferenceField(data)
        }else if(currentIdx === 5){
            setOtherField({
                ...otherField,
                [varName]: {...otherField[varName], val: value}
            })
        }
    }

    //validation check for empty field
    function checkInput(){
        let checkInputPass = true
        switch (currentIdx) {
            case 1:
                for(const k of Object.keys(generalField)){
                    if(k != "photo" && generalField[k].val === ""){
                        checkInputPass = false
                        break;
                    }
                }
                break;
            case 2:
                educationField.map((data)=>{
                    for(const k of Object.keys(data)){
                        if(data[k].val === ""){
                            checkInputPass = false
                            break;
                        }
                        if(!checkInputPass){
                            break;
                        }
                    }
                })
                break;
        
            case 3:
                employmentField.map((data)=>{
                    for(const k of Object.keys(data)){
                        if(data[k].val === ""){
                            checkInputPass = false
                            break;
                        }
                        if(!checkInputPass){
                            break;
                        }
                    }
                })
                break;

            case 4:
                // for(const k of Object.keys(referenceField)){
                //     if(referenceField[k].val === ""){
                //         checkInputPass = false
                //         break;
                //     }
                // }
                // referenceField.map((data)=>{
                //     for(const k of Object.keys(data)){
                //         if(data[k].val === ""){
                //             checkInputPass = false
                //             break;
                //         }
                //         if(!checkInputPass){
                //             break;
                //         }
                //     }
                // })
                break;

            case 5:
                for(const k of Object.keys(otherField)){
                    if(otherField[k].val === ""){
                        checkInputPass = false
                        break;
                    }
                }
                break;
        }

        return checkInputPass
    }

    //Handle Submission
    function handleSubmit(){
        if(otherField["certInfo"].val != "Yes"){
            Swal.fire(
                "Incomplete",
                "You must ensure you are providing the correct information",
                "warning"
            );
        }else{
            let inputData = {}
    
            Object.keys(generalField).map((k)=>{
                inputData[k] = generalField[k].val
            })
    
            let temp=[]
            educationField.map((data)=>{
                let obj = {}
                Object.keys(data).map((k)=>{
                    obj[k] = data[k].val
                })
                temp.push(obj)
            })
            inputData["education"] = JSON.stringify(temp)
    
            temp=[]
            employmentField.map((data)=>{
                let obj = {}
                Object.keys(data).map((k)=>{
                    obj[k] = data[k].val
                })
                temp.push(obj)
            })
            inputData["employment"] = JSON.stringify(temp)
    
            // temp=[]
            // referenceField.map((data)=>{
            //     let obj = {}
            //     Object.keys(data).map((k)=>{
            //         obj[k] = data[k].val
            //     })
            //     temp.push(obj)
            // })
            // inputData["reference"] = JSON.stringify(temp)
            Object.keys(referenceField).map((k)=>{
                inputData[k] = referenceField[k].val
            })
    
            Object.keys(otherField).map((k)=>{
                inputData[k] = otherField[k].val
            })
            inputData["applicant_id"] = localStorage.getItem("applicantId")
    
            fetch(
                domain + "/api/RecruitmentModule/applicant.php?action=submitApp",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(inputData),
            }
            )
            .then(res => res.json())
            // .then(res=> {
            //     console.log(res)
            // })
            .catch(err => {
                console.log(err);
            })
    
            Swal.fire(
                "Submit successfully",
                "Thank you for filling up the application form!",
                "success"
            );
            // history.push(path + "/applicant")
            handleFilledStat(true)
        }
    }

    //Change for section
    function handleSectionChanges(action){
        if(action == "back" || checkInput()){
            var idx = currentIdx
            if(action === "next" && currentIdx < 5){
                setCurrentIdx(idx+=1)
            }else if(action ==="back" && currentIdx > 1){
                setCurrentIdx(idx-=1)
            }else if(action === "finish" && currentIdx ===5){
                handleSubmit()
            }
        }else{
            Swal.fire(
                "Incomplete field",
                "Please fill up every field",
                "warning"
            );
        }
    }

    //Handle dynamic add on for education and employment part
    function handleDynamicAddOn(){
        if(currentIdx === 2){
            setEducationField([
                ...educationField, {
                    institute: {displayText: "Institute name", dataType:"text", val:"", require:true},
                    start: {displayText: "Start date", dataType:"text", val:"", require:true},
                    end: {displayText: "End date", dataType:"text", val:"", require:true},
                    qualification: {displayText: "Qualification obtained", dataType:"text", val:"", require:true},
                    // result: {displayText: "Result", dataType:"text", val:"", require:true},
                }
            ])
        }else if(currentIdx === 3){
            setEmploymentField([
                ...employmentField,{
                    empName: {displayText: "Employer name", dataType:"text", val:"", require:true},
                    // empContact: {displayText: "Contact number", dataType: "text", val:"", require:true},
                    // empAddress: {displayText: "Company address", dataType:"text", val:"", require:true},
                    empStart: {displayText: "Start date", dataType:"text", val:"", require:true},
                    empEnd: {displayText: "End date", dataType:"text", val:"", require:true},
                    empPosition: {displayText: "Position", dataType:"text", val:"", require:true},
                    // empAchievement: {displayText: "Achievement", dataType:"text", val:"", require:true},
                    empLastSalary: {displayText: "Last drawn salary", dataType:"text", val:"", require:true},
                    empLeaveReason: {displayText: "Reason of leaving", dataType:"text", val:"", require:true}
                }
            ])
        // }else if(currentIdx === 4){
        //     setReferenceField([
        //         ...referenceField, {
        //             refName :{displayText: "Referer name", dataType:"text", val:"", require:true},
        //             refContact :{displayText: "Referer contact", dataType:"text", val:"", require:true},
        //             refCompanyName :{displayText: "Company name", dataType:"text", val:"", require:true},
        //             refAddress :{displayText: "Referer address", dataType:"text", val:"", require:true},
        //             refOccupation :{displayText: "Referer occupation", dataType:"text", val:"", require:true},
        //             refRelationship :{displayText: "Referer relationship", dataType:"text", val:"", require:true},
        //         }
        //     ])
        }
    }

    //handle dynamic remove on education and employment part
    function handleDynamicRemove(idx){
        if(currentIdx === 2){
            let data = [...educationField]
            data.splice(idx, 1)
            setEducationField(data)
        }else if(currentIdx === 3){
            let data = [...employmentField]
            data.splice(idx, 1)
            setEmploymentField(data)
        // }else if(currentIdx ===4 ){
        //     let data = [...referenceField]
        //     data.splice(idx, 1)
        //     setReferenceField(data)
        }
    }

    //Get preset information and check if application filled when HR create the interview
    useEffect(()=>{
        fetch(
            domain + "/api/RecruitmentModule/applicant.php?action=getApp&id="+ localStorage.getItem("applicantId"),
            {
                method: "GET",
            }
            )
            .then(res => res.text())
            .then(res =>{
                if(res){
                    return JSON.parse(res)
                }else{
                    return null
                }
            })
            .then(res=> {
                if(res !== null && res.length >0){
                    if(res[0].filled === "true"){
                        handleFilledStat(true)
                    }else{
                        setGeneralField({
                            ...generalField,
                            ["name"]: {...generalField["name"], val: res[0].name},
                            ["email"]: {...generalField["email"], val: res[0].email},
                        })
                        Swal.fire(
                            "Welcome to GLE&C",
                            "Please fill up your application form",
                            "info",
                        );
                    }
                }else{
                    //do something if no data found
                    console.log("0")
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },[])

    return(
        <div className="container-fluid applicantPageLayout">
            <div id="background-obj">
                <div id="left-square-obj"></div>
                <div id="right-square-obj"></div>
            </div>
            <main id="content-box">
                <h5>Job Application Form</h5>
                <ProgressBarTemplate sectionList={sectionList} secIdx={currentIdx} />
                <div className="inputsLayout">
                    <CreateApplicantFormTemplate 
                        genField={generalField} 
                        eduField={educationField}
                        empField={employmentField}
                        refField={referenceField}
                        otherField={otherField}
                        secIdx={currentIdx}
                        secList={sectionList}
                        handleGenChanges={handleGenChanges} 
                        handleDynamicRemove={handleDynamicRemove}
                        yesAndNoOption={yesAndNoOption}
                        haveOrNotOption={haveOrNotOption}
                        genderOption={genderOption} 
                    />
                </div>
                {currentIdx === 2 || currentIdx === 3 ?
                    <div className="jobApplicationAddBtn">
                        <button type="button" className="btn btn-sm mb-3" onClick={handleDynamicAddOn}>
                            <svg className="me-1" width="16" height="16" viewBox="0 0 24 24" fill="None" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z" fill="currentColor"></path>
                            </svg>
                            { currentIdx === 2 ? 
                                "Add Education" 
                            : 
                                "Add Employment" }
                        </button>
                    </div>
                    :
                    <></>
                }
                <JobApplicationBtnGrp secIdx={currentIdx} changeIdx={handleSectionChanges} />
            </main>
        </div>
    )
}