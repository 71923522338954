import { useFetchPost } from "custom_hooks/Actions"
import { useEffect, useState } from "react"
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function DirectDownloadReport(){
    var apYear = localStorage.getItem("apYear")
    const history = useHistory();

    let fetchData = useFetchPost(domain + "/api/AppraisalModule/appraisal.php?action=getDataBasedOnYear",{ aYear: apYear })

    function parseCustomData(dt){
        console.log("parseData", dt)
        dt = dt.trim()
        dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '')
        dt = JSON.parse(dt)
       
       return dt
   }

   useEffect(()=>{
    if(fetchData.status === "fetched"){
        if(fetchData.data[0] === 0){
            Swal.fire(
                "Data Not Found",
                "No data found for selected year. Please try another year. ",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
        }else{
            generateExcelReport(fetchData.data)
            Swal.fire(
                "Data Found",
                "The data will be downloaded shortly...",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
        }
        history.push("/report")
    }

   }, [fetchData])

    function generateExcelReport(fetched){
        var tb = new jsPDF('l', 'mm', [500, 500])

        const ratingSelect = ["Unsatisfactory", "Less than satisfactory", "Satisfactory", "More than satisfactory", "Well ahead of standard"]
        
        var tbData = [{
            col1: ratingSelect[0],
            col2: ratingSelect[1],
            col3: ratingSelect[2],
            col4: ratingSelect[3],
            col5: ratingSelect[4],

        }]

        autoTable(tb, {
            columnStyles:{
                col1: {fontStyle:'bold', halign:'center', valign: 'middle'}, 
                col2: {fontStyle:'bold', halign:'center', valign: 'middle'}, 
                col3: {fontStyle:'bold', halign:'center', valign: 'middle'}, 
                col4: {fontStyle:'bold', halign:'center', valign: 'middle'}, 
                col5: {fontStyle:'bold', halign:'center', valign: 'middle'},  
                // col11: {fontStyle:'bold', cellWidth: 100},
            },
            headStyles:{
                halign: "center",
                fillColor: "#7FFFD4",
                textColor: "black"
            },
            body: tbData,
            columns: [
                { header: "1", dataKey:'col1'},
                { header: "2", dataKey:'col2'},
                { header: "3", dataKey:'col3'},
                { header: "4", dataKey:'col4'},
                { header: "5", dataKey:'col5'},
            ]
        })
        
        var data = []
        fetched.map((dt)=>{
            if(dt && dt !== null){
                console.log("DT", dt)
                let pDt = null
                var supervisorComment = "N.A"
                var directorComment = "N.A"
    
                if(dt["otherComment"] && dt["otherComment"] !== null){
                    pDt = parseCustomData(dt["otherComment"])
    
                    supervisorComment = (pDt["managerComment"] === null ? "N.A" : pDt["managerComment"])
                    // directorComment = (pDt["directorComment"] === null ? "N.A" : pDt["directorComment"])
                }
    
                var todayDate = new Date()
                var firstJoin = new Date(dt["JoinDate"])
    
                var lengthServiceMonth = todayDate.getMonth() - firstJoin.getMonth() + (12 * (todayDate.getFullYear() - firstJoin.getFullYear()))
                var lengthServiceYear = lengthServiceMonth / 12
                var actualMonth = lengthServiceMonth % 12
    
                var inputDataForServerDuration = parseInt(lengthServiceYear) + " Years " + parseInt(actualMonth) + " Months"
                
                data.push({
                    col1: dt["aOwner"],
                    col2: dt["aStatus"],
                    col3: dt["aName"],
                    col4: dt["department"],
                    col5: dt["designation"],
                    col6: dt["JoinDate"],
                    col7: inputDataForServerDuration,
                    col8: dt["reportManagerName"],
                    col9: pDt === null ? "N.A" : ratingSelect[parseInt(pDt["overallMarking"])-1],
                    col10: supervisorComment,
                    // col11: directorComment
                })
            }else{

            }
        })

        autoTable(tb, {
            columnStyles:{
                col1: {fontStyle:'bold'}, 
                col2: {fontStyle:'bold'}, 
                col3: {fontStyle:'bold'}, 
                col4: {fontStyle:'bold'}, 
                col5: {fontStyle:'bold'}, 
                col6: {fontStyle:'bold'}, 
                col7: {fontStyle:'bold'}, 
                col8: {fontStyle:'bold'}, 
                col9: {fontStyle:'bold'}, 
                col10: {fontStyle:'bold', cellWidth: 100}, 
                // col11: {fontStyle:'bold', cellWidth: 100},
            },
            body: data,
            columns: [
                { header: "Employee Code", dataKey:'col1'},
                { header: "Appraisal Status", dataKey:'col2'},
                { header: "Name", dataKey:'col3'},
                { header: "Department", dataKey:'col4'},
                { header: "Position", dataKey:'col5'},
                { header: "Join Date", dataKey:'col6'},
                { header: "Length of Service as of "+apYear, dataKey:'col7'},
                { header: "Supervisor", dataKey:'col8'},
                { header: "Overall Performance", dataKey:'col9'},
                { header: "Supervisor's Comment", dataKey:'col10'},
                // { header: "Director's Comment", dataKey:'col11'},
            ]
        })

        tb.save('staffAppraisal' +apYear+ '.pdf')
        localStorage.removeItem("apYear")

        // history.push(path + "/report")
        // console.log(reportData)
        // var data =[]
        // var header = ["EmployeeCode", "Appraisal Status", "Name", "Department", "Position", "Join Date", "Length of Service as of "+apYear, "Supervisor", "Overall Performance", "Supervisor's Comment", "Director's Comment"]

        // // data.push(header)

        // const ratingSelect = ["Unsatisfactory", "Less than satisfactory", "Satisfactory", "More than satisfactory", "Well ahead of standard"]

        // reportData.map((dt)=>{
        //     let pDt = parseCustomData(dt["otherComment"])
        //     var todayDate = new Date()
        //     var firstJoin = new Date(dt["JoinDate"])

        //     var lengthServiceMonth = todayDate.getMonth() - firstJoin.getMonth() + (12 * (todayDate.getFullYear() - firstJoin.getFullYear()))
        //     var lengthServiceYear = lengthServiceMonth / 12
        //     var actualMonth = lengthServiceMonth % 12

        //     var inputDataForServerDuration = lengthServiceYear + " Years " + actualMonth + " Months"

        //     // console.log(lengthServiceMonth)
        //     var supervisorComment = (pDt["managerComment"] === null ? "N.A" : pDt["managerComment"])
        //     var directorComment = (pDt["directorComment"] === null ? "N.A" : pDt["directorComment"])

        //     // data.push([dt["aOwner"], dt["aStatus"], dt["aName"], dt["department"], dt["designation"], dt["JoinDate"], lengthServiceYear + " Years " + actualMonth + " Months", dt["reportManagerName"], ratingSelect[parseInt(pDt["overallMarking"])], supervisorComment, directorComment])

        //     data.push([
        //         {value: dt["aOwner"], style: {font: {sz:"24", bold: true}}},
        //         {value: dt["aStatus"], style: {font: {sz:"24", bold: true}}},
        //         {value: dt["aName"], style: {font: {sz:"24", bold: true}}},
        //         {value: dt["department"], style: {font: {sz:"24", bold: true}}},
        //         {value: dt["designation"], style: {font: {sz:"24", bold: true}}},
        //         {value: dt["JoinDate"], style: {font: {sz:"24", bold: true}}},
        //         {value: inputDataForServerDuration, style: {font: {sz:"24", bold: true}}},
        //         {value: dt["reportManagerName"], style: {font: {sz:"24", bold: true}}},
        //         {value: dt["overallMarking"], style: {font: {sz:"24", bold: true}}},
        //         {value: supervisorComment, style: {font: {sz:"24", bold: true}}},
        //         {value: directorComment, style: {font: {sz:"24", bold: true}}},
        //     ])

            // Object.keys(dt).map((k)=>{
            //     console.log(k)
                // if(k === "otherComment"){
                //     directorComment =dt["directorComment"]
                //     supervisorComment = dt["managerComment"]
                //     // let pDt = parseCustomData(dt[k])

                //     // Object.keys(pDt).map((pK)=>{
                //     //     wholeSent += pK + ": " + pDt[pK] + "\r\n"
                //     // })

                //     // field.push(wholeSent)
                // }else{
                //     field.push(dt[k])
                // }
            // })
        // })

        // // Create a new workbook and worksheet
        // const workbook = XLSX.utils.book_new();
        // const worksheet = XLSX.utils.aoa_to_sheet(data);
    
        // // Add the worksheet to the workbook
        // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // // Set the column widths (optional)
        // worksheet['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 },{ width: 20 },{ width: 25 },{ width: 20 },{ width: 30 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 }];

        // // Convert the workbook to a buffer
        // const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
    
        // // Download the file using FileSaver.js
        // saveAs(new Blob([buffer]), "Staff_Appraisal - " +  apYear + ".xlsx");



        // downloadAsXLSX= () => {
        //     const date = this.getDate()
        //     // this.ref.table.download("xlsx", this.props.moduleName + "-" +  date + ".xlsx")
        //     const data = [];
        //     const header = [];
        //     const field = [];
        
        //     this.ref.state.columns.map(x=>{
        //         header.push(x['title']);
        //         field.push(x['field']);
        //     })
        //     data.push([this.props.moduleName,'',''])
        //     data.push([]);
        //     data.push(header);
        //     this.ref.state.data.map((x) =>{
        //         const row = [];
        //         field.map(f=>{
        //             row.push(x[f]);
        //         })
            
        //         data.push(row);
                
        //     })   
            
            
        //     // Create a new workbook and worksheet
        //     const workbook = XLSX.utils.book_new();
        //     const worksheet = XLSX.utils.aoa_to_sheet(data);
        
        //     // Add the worksheet to the workbook
        //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            
        //     // Set the column widths (optional)
        //     // worksheet['!cols'] = [{ width: 10 }, { width: 20 }, { width: 20 },{ width: 20 },{ width: 20 }];
            
        //     // Merge cells for the title row
        //     // worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];
            
        //     //   Convert the workbook to a buffer
        //     const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
            
        //     //   Download the file using FileSaver.js
        //     saveAs(new Blob([buffer]), this.props.moduleName + "-" +  date + ".xlsx");
    
        //     // XLSX.writeFile(workbook, this.props.moduleName + "-" +  date + ".xlsx");
        // }

    }

    return(
        <>
            {/* {fetchData.status === "fetched"?
                generateExcelReport()
            :
                <p>Loading...</p>
            } */}
        </>
    )   
}