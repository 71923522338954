import Chart from 'chart.js/auto';
import { WidgetBreadCrumbHeader, WidgetSubmitBtn } from 'custom_components/Widgets';
import { useFetchPost } from "custom_hooks/Actions";
import jsPDF from 'jspdf';
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function CustomFixedAssetReport(){
    var faYear = localStorage.getItem("faYear")
    const history = useHistory();

    let fetchData = useFetchPost( domain + "api/SupportModule/helpdesk.php?action=getAllHelpdeskByYear",{ faYear: faYear })

    function parseCustomData(dt){
        dt = dt.trim()
        dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '')
        dt = JSON.parse(dt)
       
       return dt
   }

   const plugin = {
    id: 'custom_canvas_background_color',
    beforeDraw: (chart) => {
      const {ctx} = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  };

    useEffect(()=>{
        if(fetchData.status === "fetched"){

            if(fetchData.data.length === 0){
                Swal.fire(
                    "Data Not Found",
                    "No data found for selected year. Please try another year. ",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
                localStorage.removeItem("faYear")
                history.push(path + "/report")
            }else{
                generateChart()
                // Swal.fire(
                //     "Data Found",
                //     "The data will be downloaded shortly...",
                //     // json[1],
                //     // json[0] === 1 ? "success" : "error"
                // );

            }

        }
    
    }, [fetchData])

    function generateChart(){
        let allData = fetchData.data        
        
        let recordCount = {
            "Jan": 0,
            "Feb": 0,
            "Mar": 0,
            "Apr": 0,
            "May": 0, 
            "Jun": 0,
            "Jul": 0,
            "Aug": 0,
            "Sep": 0,
            "Oct": 0,
            "Nov": 0,
            "Dec": 0,
        }

        let individualTimeTakenRecord = {}
        let subjectCount = {}
        let rateCount = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
        }

        allData.map((k)=>{
            let submitDate = new Date(k["submitDt"])
            let completeDate = new Date(k["completedDt"])
            let submitMonth = submitDate.toLocaleString('default', {month: 'short'})
            
            let timeDif = (completeDate.getTime()/1000 - submitDate.getTime()/1000)/3600
            
            if(k["completedBy"] in individualTimeTakenRecord){
                individualTimeTakenRecord[k["completedBy"]] += timeDif
            }else{
                individualTimeTakenRecord[k["completedBy"]] = timeDif
            }
            
            if(k["subject"] in subjectCount){
                subjectCount[k["subject"]] += 1
            }else{
                subjectCount[k["subject"]] = 1
            }

            let parsedData = parseCustomData(k["rateDetail"])

            rateCount[parsedData["rate"]] += 1
                
            recordCount[submitMonth] += 1
        })


        new Chart(document.getElementById('chart1'), {
            type: 'line',
            data: {
            labels: Object.keys(recordCount),
            datasets: [{
                fill: true,
                backgroundColor:  'cyan',
                label: 'Number of helpdesk request',
                data: Object.values(recordCount),
                borderWidth: 1
            }]
            },
            options: {
                plugins:{
                    title: {
                        display: true,
                        text: "Monthly Helpdesk Request"
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Months',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 20, left: 0, right: 0, bottom: 0}
                        }
                    }, 
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Number of Request',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 30, left: 0, right: 0, bottom: 0}
                        }
                    },
                }
            },
            plugins: [plugin]
        });

        var dataKey = []
        var dataValue = []

        Object.entries(individualTimeTakenRecord)
        .sort(([,a],[,b]) => {return b-a})
        .map((k)=>{            
            dataKey.push(k[0])
            dataValue.push(parseInt(k[1]))
        })

        new Chart(document.getElementById('chart2'), {
            type: 'bar',
            data: {
            labels: dataKey,
            datasets: [{
                label: 'Hours',
                data: dataValue,
                borderWidth: 1
            }]
            },
            options: {
                plugins:{
                    title: {
                        display: true,
                        text: "Time Spent Summary Chart (Hourly)"
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Hours',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 20, left: 0, right: 0, bottom: 0}
                        }
                    }, 
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'User Name',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 30, left: 0, right: 0, bottom: 0}
                        }
                    },
                },
            },
            plugins: [plugin]
        });

        new Chart(document.getElementById('chart4'), {
            type: 'pie',
            data: {
            labels: Object.keys(subjectCount),
            datasets: [{
                label: 'Times',
                data: Object.values(subjectCount),
                borderWidth: 1
            }]
            },
            options: {
                responsive: false,
                plugins:{
                    title: {
                        display: true,
                        text: "Subject Request Ranking"
                    },
                },
                scales: {
                    y: {
                    beginAtZero: true
                    }
                },
            },
            plugins: [plugin]
        });

        new Chart(document.getElementById('chart3'), {
            type: 'bar',
            data: {
            labels: Object.keys(rateCount),
            datasets: [{
                label: 'Record',
                data: Object.values(rateCount),
                borderWidth: 1
            }]
            },
            options: {
                plugins:{
                    title: {
                        display: true,
                        text: "User Feedback Score"
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Rating',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 20, left: 0, right: 0, bottom: 0}
                        }
                    }, 
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Number of Record',
                            color: '#191',
                            font: {
                              size: 15,
                              style: 'normal',
                              lineHeight: 1.2
                            },
                            padding: {top: 30, left: 0, right: 0, bottom: 0}
                        }
                    },
                },
            },
            plugins: [plugin]
        });
    }

    function downloadPDF(e){
        e.preventDefault()
        const canvas = document.getElementById('chart1');
        const canvasImage = canvas.toDataURL('image/jpeg', 1.0);
        let pdf = new jsPDF({
          orientation: 'landscape'
        });
        pdf.setFontSize(20);
        pdf.addImage(canvasImage, 'jpeg', 40,5,200,90);

        const canvas2 = document.getElementById('chart2');
        const canvasImage2 = canvas2.toDataURL('image/jpeg', 1.0);
        pdf.addImage(canvasImage2, 'jpeg', 40,100,200,90);

        pdf.addPage()

        const canvas3 = document.getElementById('chart3');
        const canvasImage3 = canvas3.toDataURL('image/jpeg', 1.0);
        pdf.addImage(canvasImage3, 'jpeg', 40,5,200,90);

        const canvas4 = document.getElementById('chart4');
        const canvasImage4 = canvas4.toDataURL('image/jpeg', 1.0);
        pdf.addImage(canvasImage4, 'jpeg', 100,110,90,90);

        pdf.save('Helpdesk Summary.pdf');
    }

    return(
        <>
            <>
                <WidgetBreadCrumbHeader prevNav="Report" curNav="Helpdesk Summary" prevLink={"#"} curLink={"#"} />
                <br />
            </>
            <div id="reportDiv">
                <div className='row'>
                    <div className="col-md-6">
                        <canvas id="chart1">
                        </canvas>
                    </div>
                    <div className="col-md-6">
                        <canvas id="chart2">
                        </canvas>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-6">
                        <canvas id="chart3">
                        </canvas>
                    </div>
                    <div className="col-md-6">
                        <canvas id="chart4">
                        </canvas>
                    </div>
                </div>
            </div>
            <form onSubmit={downloadPDF}>
                <WidgetSubmitBtn text="Download PDF" />
            </form>
        </>
    )
}