import { CreateRecruitmentForm } from "custom_components/templates/FormTemplate";
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from "react-router-dom"
import MainTemplate from "custom_components/templates/MainTemplate";
import { useUserPermission } from "custom_hooks/Actions";
import { FullTabulatorTemplate } from "custom_components/templates/TabulatorTemplate";
import { useState } from "react";
import CreateHelpdesk from "./CreateHelpdesk";
import HelpdeskDetails from "./HelpdeskDetails";
import CreateRequisition from "./CreateRequisition";
import RequisitionDetails from "./RequisitionDetails";
// import StaffInfo from "./StaffInfo";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

var userCode = ""
var userRole = ""
var userDept = ""

function parseCustomData(dt){
    dt = dt.trim()
    dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '')
    dt = JSON.parse(dt)
   
   return dt
}


function parseRequisitionApproval(data) {
    let dataForActive = []
    let dataForInactive = []

    try {
        userDept = data[3]
        userCode = data[2]
        userRole = data[1]
        data[0].forEach((number, index, array) => {
            var requestString = ""
            let parsedSoftware = parseCustomData(array[index].softwareRequest)
            let softwareExist = Object.values(parsedSoftware).includes(true)
            if(parsedSoftware["other"] !== ""){
                softwareExist = true
            }
            if(softwareExist === true && array[index].hardwareRequest !== null && array[index].hardwareRequest !== ""){
                requestString = "Hardware & Software"
            }else if(softwareExist === false && array[index].hardwareRequest !== null && array[index].hardwareRequest !== ""){
                requestString = "Hardware"
            }else if(softwareExist === true && (array[index].hardwareRequest === null || array[index].hardwareRequest === "")){
                requestString = "Software"
            }

            let obj = {
                no: index+1,
                requestorName: array[index].requestorName,
                request: requestString,
                submitDt: array[index].submitDt,
                status: array[index].status,
                requisitionId: array[index].requisitionId,
            }

            if(obj.status !== "Pending for PIT Manager Approval"){
                dataForInactive.push(obj);
            }else{
                dataForActive.push(obj);
            }
        
        })
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function RequisitionApproval({permission}){
    const [redirect, setRedirect] = useState({ allow: false })
    const location = useLocation()
    const tabs = ["Active", "History"];
    const columns = [
        { title: " S/no.", field: "no", headerFilter: "input"},
        {
            title: "Name", field: "requestorName", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "requestorName",
                urlPrefix: "itreq/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.requisitionId }) //here we update state
            }
        },
        { title: "Request", field: "request", headerFilter: "input" },
        { title: "Submission Datetime", field: "submitDt", headerFilter: "input" },
        { title: "Status", field: "status", headerFilter: "input" },
    ]

    return(
        redirect.allow ?
            <Redirect push to={location.pathname+"/"+redirect?.id} />
        :
        <FullTabulatorTemplate 
            permission = {permission}
            fetchURL = {domain + "/api/SupportModule/requisition.php?action=getAllRequisition"}
            parseFunc = {parseRequisitionApproval}
            columns = {columns}
            moduleName = {"IT Requisition Approval"}
            redirect = {redirect}
            tabs={tabs}
            disableWidget = {true}
        />
    )
}

function ItRequsitionApprovalContainer(){
    const perm = useUserPermission(32);

    return(
        <MainTemplate content={
            <Switch>
                {/*{
                    <Route path="/:page/create" children={<CreateRequisition />} />
                }
                {
                    perm.view ? <Route path="/:page/:id/details" children={<EmployeeInfo defaultTab={"Details"} />} /> : null
                }
                {
                    perm.view ? <Route path="/:page/:id/cert" children={<EmployeeInfo defaultTab={"Certificate"} />} /> : null
                }*/}
                {
                    <Route path="/:page/:id" children={<RequisitionDetails permission={perm} userCode={userCode} userRole={userRole} from={"approval"} userDept={userDept} />} />
                }
                {
                    <Route path="" children={<RequisitionApproval permission={perm} />} />
                } 
            </Switch>
        }
        />
    )
}

export default ItRequsitionApprovalContainer