import React, { useState, useEffect } from "react"
import {
    Switch,
    Route,
    Redirect,
    useLocation
} from "react-router-dom"
import MainTemplate from 'custom_components/templates/MainTemplate.js'
import { FullTabulatorTemplate } from 'custom_components/templates/TabulatorTemplate.js'
import EmployeeCreate from 'pages/employee/EmployeeCreate.js'
import EmployeeInfo from 'pages/employee/EmployeeInfo.js'
import { useUserPermission, useFetchGet } from '../../custom_hooks/Actions.js'
import { flipTrueFalse } from "helper_functions/Helper.js"

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function parseEmployee(data) {
    let dataForActive = []
    let dataForInactive = []
    try {
        for (let i = 0; i < data.length; i++) {
            data[i].forEach((number, index, array) => {
                
                let obj = {
                    no: index+1,
                    code: array[index][0],
                    name: array[index][1],
                    nric: array[index][2],
                    pass: array[index][3],
                    passe: array[index][4],
                    desig: array[index][5],
                    dept: array[index][6],
                    manager: array[index][7]
                }
                if (i === 0) {
                    if (data[i][0] !== 0) {
                        dataForActive.push(obj);
                    }
                } else {
                    if (data[i][0] !== 0) {
                        dataForInactive.push(obj);
                    }
                }
            })
        }
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function Employee({ permission }) {
    const [redirect, setRedirect] = useState({ allow: false })
    const columns = [
        { title: " S/no.", field: "no", headerFilter: "input"},
        {
            title: "Employee Code", field: "code", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "code",
                urlPrefix: "employee/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, code: rowInfo.code }) //here we update state
            }
        },
        { title: "Name", field: "name", headerFilter: "input" },
        { title: "NRIC", field: "nric", headerFilter: "input" },
        { title: "Pass Type", field: "pass", headerFilter: "input" },
        {
            // title: "Pass Expiry Date", field: "passe", headerFilter: "input", formatter: "datetime", formatterParams: {
            //     inputFormat: "YYYY-MM-DD",
            //     outputFormat: "DD/MM/YYYY",
            //     invalidPlaceholder: "N/A",
            // }
        },
        { title: "Designation", field: "desig", headerFilter: "input" },
        { title: "Department", field: "dept", headerFilter: "input" },
        { title: "Reporting Manager", field: "manager", headerFilter: "input" }
    ]
    
    // window.location.toString().split(path)[0] + path
    return (
        <FullTabulatorTemplate 
            permission = {permission}
            fetchURL = { domain + "/api/EmployeeModule/employee.php"}
            parseFunc = {parseEmployee}
            columns = {columns}
            moduleName = {"Employee"}
            redirect = {redirect}
            disableWidget = {permission.add ? false : true}
        />
    )
}

function EmployeeContainer(props) {
    const permission = useUserPermission(0)

    return (
        <MainTemplate
            content={
                <Switch>
                    {
                        permission.add ? <Route path="/:page/create" children={<EmployeeCreate />} /> : null
                    }
                    {
                        permission.view ? <Route path="/:page/:id/details" children={<EmployeeInfo defaultTab={"Details"} />} /> : null
                    }
                    {
                        permission.view ? <Route path="/:page/:id/cert" children={<EmployeeInfo defaultTab={"Certificate"} />} /> : null
                    }
                    {
                        permission.view ? <Route path="/:page/:id" children={<EmployeeInfo />} /> : null
                    }
                    {
                        permission.view ? <Route path="" children={<Employee permission={permission} />} /> : null
                    }
                </Switch>
            }
        />
    )
}

export default EmployeeContainer