import { CreateRecruitmentForm } from "custom_components/templates/FormTemplate";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function ManpowerCreate({}){
    const history = useHistory();
    const [field, setField] = useState({
        manpowerId: {displayText: "ID", dataType:"text", val:""},
        requestBy: {displayText: "Request by", dataType:"text", val:"", required: true},
        requestDate: {displayText: "Request date", dataType:"text", val:""},
        manpowerType: {displayText: "Manpower Type", dataType:"checkbox", val:"", required: true},
        jobTitle: {displayText: "Job Title", dataType:"text", val:"", required: true},
        workingLoc: {displayText: "Working location", dataType:"text", val:"", required: true},
        workingHour: {displayText: "Working hours", dataType:"time", val:"", required: true},
        salaryRange: {displayText: "Salary Range", dataType:"text", val:"", required: true},
        noVacancy: {displayText: "Number of vacancies", dataType:"text", val:"", required: true},
        projTitleDept: {displayText: "Project title/department", dataType:"text", val:"", required: true},
        dateRequired: {displayText: "Date required", dataType:"text", val:"", required: true},
        headCountNeeded: {displayText: "Is this headcount needed throughout the remaining duration of project", dataType:"text", val:"", required: true},
        remark: {displayText: "Remarks", dataType:"text", val:"", required: true},
        status: {displayText: "Status", dataType:"text", val:""},
    })
    var [employeeList, setEmployeeList] = useState([""])
    var [employeeCategory, setEmployeeCategory] = useState([""])
    var [dept, setDept] = useState([])
    var [validated, setValidated] = useState(0)
    var [startTime, setStartTime] = useState()
    var [endTime, setEndTime] = useState()

    const yesAndNoOption = ["Yes", "No"]
    
    function handleChanges(varName, value){
        if(varName === "startTime" || varName === "endTime"){
            if(varName === "startTime"){
                setStartTime(value)
            }else{
                setEndTime(value)
            }            

        }else{
            setField({
                ...field,
                [varName]: {...field[varName], val: value}
            })
        }
    }

    function handleOptionsFromDb(empList, dept){
        setEmployeeList(empList)
        let catAry = []
        empList.map((dt)=>{
            if(!catAry.includes(dt[1]) && dt[1] !== "Driver"){
                catAry.push(dt[1])
            }
        })
        setEmployeeCategory(catAry)
        setDept(dept)
    }

    useEffect(() => {
        fetch(
            domain + "/api/RecruitmentModule/manpowerCreateEmployeeInfo.php",
        {
            method: "GET",
        }
        )
        .then((res) => res.json())
        .then((json) => {
            handleOptionsFromDb(json[3], json[1])
        })
        .catch((err) => {
            console.log(err);
        });
    
      }, []);


      function handleSubmit(e){
        e.preventDefault()
        let passCheck = true
        for(const k of Object.keys(field)){
            if( k==="jobTitle" || k==="workingLoc" || k==="salaryRange" || k==="workingHour" || k==="noVacancy" || k==="projTitleDept" || k==="dateRequired" || k ==="headCountNeeded"){
                if(k === "workingHour"){
                    if(startTime === "" || endTime === ""){
                        passCheck = false
                        break
                    }
                }else if(field[k].val === "" || field[k].val === null){
                    passCheck = false
                    break;
                }
            }
        }

        if(passCheck){
            setValidated(1)
        }
    }

    useEffect(()=>{
        if(validated===1){
            let inputData = {}

            Object.keys(field).map((k)=>{
                if(k === "workingHour"){
                    inputData[k] = startTime+","+endTime
                }else{
                    inputData[k] = field[k].val
                }
            })

            fetch(
                domain + "/api/RecruitmentModule/manpower.php?action=add",
                {
                    method: "POST",
                    // mode: "same-origin",
                    //credentials: "same-origin",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(inputData),
                }
            )
            .then((res) => {
                res.json()
            })
            .then((json) => {
                Swal.fire(
                    "Success!",
                    "Successfully submitted",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
            })
            .catch((err) => {
                console.log(err);
            });

            setValidated(0)
            history.goBack()
            // history.push("/manpower")
        }
    }, [validated])


    return(
        <CreateRecruitmentForm
            module="Manpower Acquisition"
            field={field}
            handleChanges={handleChanges}
            handleSubmit={handleSubmit}
            yesAndNoOption={yesAndNoOption}
            optionGroups={employeeCategory}
            options={employeeList}
            dept={dept}
        />
    )
}