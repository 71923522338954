import { CreateRequisitionForm } from "custom_components/templates/FormTemplate";
import { useFetchPost } from "custom_hooks/Actions";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function RequisitionDetails({perm,userCode, userRole, from, userDept}){
    const history = useHistory();
    const routeParams = useParams();
    const [refetch, setRefetch] = useState(0);
    const [act, setAct] = useState("editApp");
    const [editable, setEditable] = useState(true);

    const [requestField, setRequestField] = useState([])

    const [field, setField] = useState({
        requisitionId: {displayText: "Requisition ID", dataType:"text", val:"", required: false, disable: true},
        requestorName: {displayText: "Requestor", dataType:"text", val:"", required: false, disable: true},
        hardwareRequest: {displayText: "Hardware", dataType:"SelectOne", val:"", required: false, disable: true},
        hardwareOther: {displayText: "Others", dataType:"text", val:"", required: false, disable: false},
        softwareRequest: {displayText: "Software", dataType:"text", val:"", required: false, disable: true},
        requestReason: {displayText: "Reason on requesting items", dataType:"textbox", val:"", required: false, disable: true},
        submitDt: {displayText: "Submit Datetime", dataType:"text", val:"", required: false, disable: true},
        remark: {displayText: "Remark", dataType:"text", val:"", required: false, disable: false},
        budget: {displayText: "Budget", dataType:"text", val:"", required: false, disable: false},
        status: {displayText: "Status", dataType:"SelectOne", val:"", required: false, disable: false},
        
    })
    const hardwareOption = [
        "Laptop",
        "Monitor",
        "Others"
    ]
    const approvalOption = [
        "Pending for PIT Manager Approval",
        "Approved",
        "Rejected"
    ]
    const requisitionData = useFetchPost(
        domain + "/api/SupportModule/requisition.php?action=getSpecificRequisition",
        { requisitionId: routeParams.id },
        refetch
    );

    function handleChanges(varName, value, subVar){
        let items = field[varName]
        items.val = value
        setField({
            ...field,
            [varName]: {...items}
        })
        
    }

    function handleEditApproval(){
        setAct("submit")
    }

    function parseCustomData(dt){
        dt = dt.trim()
        dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '')
        dt = JSON.parse(dt)
       
       return dt
    }

    useEffect(()=>{
        if(requisitionData.status === "fetched"){
            var dt = requisitionData.data

            if(dt["status"] !== "Pending for PIT Manager Approval"){
                setEditable(false)
            }

            Object.keys(dt).map((k)=>{
                if(dt[k]){
                    if(k === "softwareRequest"){
                        let parsedSoftware = parseCustomData(dt[k])
                        if(Object.values(parsedSoftware).includes(true) || parsedSoftware["other"] !== ""){
                            requestField.push("software")
                        }
                        handleChanges(k, parsedSoftware)
                    }else if(k === "hardwareRequest"){
                        if(dt[k] !== null){
                            requestField.push("hardware")
                        }

                        if(dt[k] !== "Laptop" && dt[k] !== "Monitor"){
                            handleChanges(k, "Others")
                            handleChanges("hardwareOther", dt[k])
                        }else{
                            handleChanges(k, dt[k])
                        }

                    }else{
                        handleChanges(k, dt[k])
                    }
                }
            })
        }
    }, [requisitionData.status])

    function handleSubmit(e){
        e.preventDefault()

        let inputData = {}
            
        Object.keys(field).map((k)=>{
                inputData[k] = field[k].val
        })

        if(field["remark"].val !== "" && field["remark"].val !== null && (field["status"].val === "Approved" || field["status"].val === "Rejected")){
            fetch(
                domain + "/api/SupportModule/requisition.php?action=updateRequisition",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(inputData),
            }
            )
            .then((res) => res.json())
            .then((json) => {
                if(json[0] === "success"){
                    Swal.fire(
                        "Success!",
                        "Successfully updated",
                        // json[1],
                        // json[0] === 1 ? "success" : "error"
                    );
                }else{
                    Swal.fire(
                        "Fail!",
                        "Fail to update",
                        // json[1],
                        // json[0] === 1 ? "success" : "error"
                    );
                }
    
            })
            .catch((err) => {
                console.log(err);
            });

            Swal.fire(
                "Update successfully",
                "Application updated",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
            
            history.push("/itapp")
        }else{
            Swal.fire(
                "Changes Required",
                "Please fill up all the required field or select a different decision",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
        }

        
        
    }

    return(
        <CreateRequisitionForm
            module={userCode === "GLOS003" || userCode === "SYSADMIN" ? "IT Requisition Approval" : "IT Requisition"}
            field={field}
            from={from}
            userCode={userCode}
            userRole={userRole}
            userDept={userDept}
            hardwareOption={hardwareOption}
            approvalOption={approvalOption}
            handleChanges={handleChanges}
            handleSubmit={handleSubmit}
            handleEditApproval={handleEditApproval}
            action={act}
            editable={editable}
            requestField={requestField}
        />
    )
}