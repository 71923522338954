import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from "react-router-dom"
import MainTemplate from "custom_components/templates/MainTemplate";
import { useUserPermission } from "custom_hooks/Actions";
import ManpowerCreate from "pages/manpower/ManpowerCreate";
import { FullTabulatorTemplate } from "custom_components/templates/TabulatorTemplate";
import { useState } from "react";
import ManpowerInfo from "pages/manpower/ManpowerInfo";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function parseManpower(data) {
    let dataForActive = []
    let dataForInactive = []

    try {
        data.forEach((number, index, array) => {
            let obj = {
                no: index+1,
                job_title: array[index].jobTitle,
                work_location: array[index].workingLoc,
                num_of_vacancy: array[index].noVacancy,
                request_by: array[index].requestBy,
                date_required: array[index].dateRequired,
                status: array[index].status,
                manpower_id: array[index].manpowerId,
            }
            if(obj.status === "Approved" || obj.status === "Rejected"){
                dataForInactive.push(obj);
            }else{
                dataForActive.push(obj);
            }
            // if (i === 0) {
            //     if (data[i][0] !== 0) {
            //         dataForActive.push(obj);
            //     }
            // } else {
            //     if (data[i][0] !== 0) {
            //         dataForInactive.push(obj);
            //     }
            // }
        })
        
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function Manpower({permission}){
    const [redirect, setRedirect] = useState({ allow: false })
    const location = useLocation()
    const tabs = ["Active", "History"];
    const columns = [
        { title: " S/no.", field: "no", headerFilter: "input"},
        {
            title: "Job Position", field: "manpower_id", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "job_title",
                urlPrefix: "manpower/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.manpower_id }) //here we update state
            }
        },
        { title: "Location", field: "work_location", headerFilter: "input" },
        { title: "No of vacancy", field: "num_of_vacancy", headerFilter: "input" },
        { title: "Request by", field: "request_by", headerFilter: "input" },
        { title: "Required Date", field: "date_required", headerFilter: "input" },
        { title: "Status", field: "status", headerFilter: "input" },
    ]

    return(
        redirect.allow ?
            <Redirect push to={location.pathname+"/"+redirect?.id} />
        :
        <FullTabulatorTemplate 
            permission = {permission}
            fetchURL = { domain + "/api/RecruitmentModule/manpower.php?action"}
            parseFunc = {parseManpower}
            columns = {columns}
            moduleName = {"Manpower Acquisition"}
            redirect = {redirect}
            tabs={tabs}
            disableWidget = {permission.add ? false : true}
        />
    )
}

function ManpowerContainer(){
    const permission = useUserPermission(16);
    const permission2 = useUserPermission(17);
    let perm = {...permission, ...permission2}

    return(
        <MainTemplate content={
            <Switch>
                {
                    perm.add ? <Route path="/:page/create" children={<ManpowerCreate />} /> : null
                }
                {/* {
                    perm.view ? <Route path="/:page/:id/details" children={<EmployeeInfo defaultTab={"Details"} />} /> : null
                }
                {
                    perm.view ? <Route path="/:page/:id/cert" children={<EmployeeInfo defaultTab={"Certificate"} />} /> : null
                }*/}
                {
                    perm.view || perm.viewApproval ? <Route path="/:page/:id" children={<ManpowerInfo permission={perm} />} /> : null
                }
                {
                    perm.view || perm.viewApproval? <Route path="" children={<Manpower permission={perm} />} /> : null
                } 
            </Switch>
        }
        />
    )
}

export default ManpowerContainer