import { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  Input,
  SelectInput,
  SelectInputWithGroup,
} from "../../custom_components/Forms.js";
import {
  sessionSetLoading,
  useFetchGet,
  useFetchPost,
  useUserPermission,
} from "../../custom_hooks/Actions.js";
import {
  EditFormTemplate,
  EditFormTemplateHomeLeaveTraining,
} from "../../custom_components/templates/FormTemplate";
import { flipTrueFalse } from "helper_functions/Helper.js";
import { ImportImageForm } from "../../custom_components/Forms.js";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function ApplicationDetails(props) {
  // window.location.toString().split(path)[0] + path +

  let { id } = useParams();
  const dataList = useFetchPost(
    domain + "/api/HomeLeaveModule/homeLeaveUtility.php",
    { option: "option" }
  ).data;

  const [editMode, setEditMode] = useState(1);
  const [inputData, setInputData] = useState({});
  const [submitted, setSubmitted] = useState(0);
  const [validated, setValidated] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const defaultSelectInput = "Select an option";
  const [endDateThreshold, setEndDateThreshold] = useState();
  const fields = [
    {
      name: "Employee Name",
      inputname: "EmployeeName",
      type: "select",
      required: true,
      options: dataList[0],
      optionGroups: dataList[1],
    },
    {
      name: "Deisgnation",
      inputname: "Designation",
      type: "text",
      required: true,
      datalist: dataList[1],
    },
    {
      name: "Project Site",
      inputname: "Department",
      type: "text",
      required: true,
      datalist: dataList[2],
    },
    {
      name: "Date Joined",
      inputname: "DateJoined",
      type: "date",
      required: true,
    },
    {
      name: "Last Date",
      inputname: "LastDate",
      type: "date",
      required: true,
    },
    {
      name: "From Date",
      inputname: "StartDate",
      type: "date",
      required: true,
    },
    {
      name: "To Date",
      inputname: "EndDate",
      type: "date",
      required: true,
    },
    {
      name: "Airport Destination",
      inputname: "Destination",
      type: "text",
      required: true,
    },    
    {
      name: "Leave Type",
      inputname: "LeaveType",
      type: "select",
      required: true,
      options: ["Holiday Leave", "Home Leave","Others"],
    },
    {
      name: "Remarks",
      inputname: "Remark",
      type: "text",
      required: true,
    },
    {
      name: "Project Manager",
      inputname: "ProjectManager",
      type: "text",
      required: true,
      datalist: dataList[3],
    },
   
  ];
  const notRequiredInputData = [];
  const permission = useUserPermission(3);
  const courseApplyPermission = useUserPermission(7);

  const handleChangeInput = (e, date) => {
    if (typeof e == "object") {
     
      setInputData({
        ...inputData,
        [e.target.name]: e.target.value,
      });
    
    } else {
      if (typeof e != "object") {
        setInputData({
          ...inputData,
          [e]: date,
        });
      }
    }
  };

  function createInputObj(json) {
    let a = {};
    for (let i = 0; i < fields.length; i++) {
      let inputName = fields[i].inputname;
      if (fields[i].type === "date" && json[inputName]) {
        let splitDate = json[inputName].split("-");
        let date = new Date(
          splitDate[2],
          parseInt(splitDate[1]) - 1,
          parseInt(splitDate[0]) + 1
        );
        a[inputName] = date.toISOString().split("T")[0];
      } else {
        a[inputName] = json[inputName] ? json[inputName] : "";
      }
    }
    return a;
  }

  const handleInputChange = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (submitted === 0) {
      // window.location.toString().split(path)[0] + path +
      fetch(
        domain + "/api/HomeLeaveModule/HomeLeaveApplication.php?action=search",
        {
          method: "POST",
          // mode: "same-origin",
          //credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            homeLeaveID: id,
          }),
        }
      )
        .then((res) => res.json())
        .then((json) => {
          setInputData(createInputObj(json));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);



  useEffect(() => {
    if (submitted === 1 && validated === 1 && permission.edit) {

      let formData = new FormData();
      
      formData.append(
        "inputData",
        JSON.stringify({
          HomeLeaveID: id,
          EmployeeCode: inputData["EmployeeCode"],
          EmployeeName: inputData["EmployeeName"],
          Department: inputData["Department"],
          Designation: inputData["Designation"],
          LeaveType: inputData["LeaveType"],
          DateJoined: inputData["DateJoined"],
          StartDate: inputData["StartDate"],
          EndDate: inputData["EndDate"],
          Remark: inputData["Remark"],
          Destination : inputData["Destination"],
          LastDate: inputData["LastDate"],
          ProjectManager: inputData["ProjectManager"]
        })
      );

      sessionSetLoading(true);
     console.log(formData);
      // window.location.toString().split(path)[0] + path +
      fetch(
       domain + "/api/HomeLeaveModule/HomeLeaveApplication.php?action=update",
        {
          method: "POST",
          // mode: "same-origin",
          //credentials: "same-origin",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            HomeLeaveID: id,
            EmployeeCode: inputData["EmployeeCode"],
            EmployeeName: inputData["EmployeeName"],
            Department: inputData["Department"],
            Designation: inputData["Designation"],
            LeaveType: inputData["LeaveType"],
            DateJoined: inputData["DateJoined"],
            StartDate: inputData["StartDate"],
            EndDate: inputData["EndDate"],
            Remark: inputData["Remark"],
            Destination: inputData["Destination"],
            LastDate: inputData["LastDate"],
            ProjectManager: inputData["ProjectManager"]
          }),
          // body: formData,
        }
      )
        .then((res) => res.json())
        .then((json) => {
          Swal.fire(
            json[0] === 1 ? "Success!" : "Error!",
            json[1],
            json[0] === 1 ? "success" : "error"
          );
          if (json[0] === 1) {
            setRedirect(true);
          } else {
            setSubmitted(0);
            setValidated(0);
          }
          sessionSetLoading();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return null;
  }, [submitted, validated]);

  const dateNow = new Date();
  const dateMin =
    dateNow.getFullYear() +
    "-" +
    (dateNow.getMonth() + 1) +
    "-" +
    dateNow.getDate();
  let formClassName = "form-group row";
  let formDisplayWide = formClassName + " grid-wide";
  let formDisplayNone = formClassName + " d-none";
  const fieldItems = fields.map((field, index) => (
    <div
      className={formDisplayWide}
      key={field.inputname}
    >
      <label htmlFor={field.inputname} className="col-sm-3 ">
        {field.name}
      </label>
      <div className="col-sm-9">
        {field.type === "select" && field.optionGroups ? (
          <SelectInputWithGroup
            field={field.inputname}
            options={field.options}
            value={inputData[field.inputname]}
            optionGroups={field.optionGroups}
            defaultOption={defaultSelectInput}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={editMode}
          />
        ) : field.type === "select" ? (
          <SelectInput
            field={field.inputname}
            value={inputData[field.inputname]}
            options={field.options}
            defaultOption={defaultSelectInput}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={editMode}
          />
        ) : (
          <Input
            field={field.inputname}
            type={field.type}
            value={inputData[field.inputname]}
            dataList={field.datalist ? field.datalist : null}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={editMode}
            
          />
        )}
      </div>
    </div>
  ));

  function handleSubmit(e) {
    e.preventDefault();

    try {
      let target = document.getElementById("formFile");
      setInputData({
        ...inputData,
        [target.name]: URL.createObjectURL(target.files[0]),
        [target.name + "Type"]: target.value.split(".").pop(),
        [target.name + "Data"]: target.files[0],
      });
    } catch {}
    for (let inputName in inputData) {
      let isAnExcept = 0;
      for (let exceptNum in notRequiredInputData) {
        if (inputName === notRequiredInputData[exceptNum]) {
          isAnExcept = 1;
        }
      }
      if (isAnExcept === 0) {
        if (
          inputData[inputName] === defaultSelectInput ||
          inputData[inputName] === "" ||
          inputData[inputName] === null ||
          inputData[inputName] === "undefined"
        ) {
          setValidated(0);
          setSubmitted(0);
        } else {
          break;
        }
      } else {
        break;
      }
    }
    setValidated(1);
    setSubmitted(1);
  }

  if (redirect) {
    return <Redirect to={ "/homeleaveapplication"} />;
  } else {
 

    return (
      <div>
        {
          <EditFormTemplateHomeLeaveTraining
            module="HomeLeaveApplication"
            handleSubmit={handleSubmit}
            disabled={editMode}
            setEditMode={setEditMode}
            fieldItems={fieldItems}
            editAccess={permission.edit}
            // dataList={dataList[0]}
            handleChangeInput={handleChangeInput}
          />
        }
      </div>
    );
  }
}

export default ApplicationDetails;
