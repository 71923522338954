import React, { useState, useEffect } from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useHistory
} from "react-router-dom"
import MainTemplate from '../../custom_components/templates/MainTemplate.js'
import GenerateReport from 'pages/report/GenerateReport.js'
import { useUserPermission } from "custom_hooks/Actions.js"
import Swal from "sweetalert2"
import DirectDownloadReport from "pages/report/DirectDownloadReport.js";
import CustomFixedAssetReport from "pages/report/CustomFixedAssetReport.js";
import CustomHSEReport from "pages/report/CustomHSEReport.js";

const domain = process.env.REACT_APP_DOMAIN

function Report(props) {
    
    let location = useLocation()
    let history = useHistory()
    const [inputData, setInputData] = useState({})
    const [yearData, setYearData] = useState({})
    const permission = useUserPermission(13)
    const [role, setRole] = useState({})
    const [userDept, setUserDept] = useState();


    const handleChangeInput = (e) => {
     
        setInputData({
            ...inputData,
            [e.target.name]: e.target.value
        })
        var year =  document.getElementById('year');
        if(e.target.value == "TACA"){
           year.removeAttribute("hidden");
           year.value = "";
        }else{
           year.setAttribute("hidden", true);
           year.value = "";
        }
        localStorage.removeItem('year');

        var hr_year =  document.getElementById('hr_year');
        var hr_month =  document.getElementById('hr_month');
        if(e.target.value == "HMR"){
            hr_year.removeAttribute("hidden");
            hr_year.value = "";
            hr_month.removeAttribute("hidden");
            hr_month.value = "";
        }else{
            hr_year.setAttribute("hidden", true);
            hr_year.value = "";
            hr_month.setAttribute("hidden", true);
            hr_month.value = "";

        }
        localStorage.removeItem('hr_year');
        localStorage.removeItem('hr_month');

        // Appraisal
        var apYear = document.getElementById("apYear");
        if(e.target.value === "SA"){
            apYear.removeAttribute("hidden");
        }else{
            apYear.setAttribute("hidden", true);
            if(localStorage.getItem("apYear")){
                localStorage.removeItem("apYear")
            }
        }


        //helpdesk
        var faYear = document.getElementById("faYear");
        if(e.target.value === "FA"){
        faYear.removeAttribute("hidden");
        }else{
        faYear.setAttribute("hidden", true);
        if(localStorage.getItem("faYear")){
            localStorage.removeItem("faYear")
        }
        }

        // HSE
        if(e.target.value === "HSE"){
            localStorage.setItem("hseReport", "selected")
            }else{
            if(localStorage.getItem("hseReport")){
                localStorage.removeItem("hseReport")
            }
        }
    }

    const handleChangeYear = (e) => {
       
        // setYearData({
        //     ...yearData,
        //     [e.target.name]: e.target.value
        // })
        localStorage.setItem('year',e.target.value);
    }

    const handleApYear = (e)=>{
        localStorage.setItem("apYear", e.target.value);
    }

    const handleFaYear = (e)=>{
        localStorage.setItem("faYear", e.target.value);
      }

    const handleChangeHRYear = (e) => {
       
        localStorage.setItem('hr_year',e.target.value);
    }

    const handleChangeHRMonth = (e) => {
       
        localStorage.setItem('hr_month',e.target.value);
    }

    function handleSubmit(e) {
      
        e.preventDefault()
        var selectedYear = localStorage.getItem('year');
        var selectedHRYear = localStorage.getItem('hr_year');
        var selectedHRMonth = localStorage.getItem('hr_month');
      
        if(inputData["generate-report"] == 'TACA'){
            if (inputData["generate-report"] !== "" && inputData["generate-report"] !== null && inputData["generate-report"] !== undefined && selectedYear !== "" && selectedYear !== null && selectedYear !== undefined) {
               
                history.push(location.pathname + "/" + inputData["generate-report"] )
            } else {
                Swal.fire(
                    'Warning!',
                    'Please complete option',
                    'warning'
                )
            }

        }else  if(inputData["generate-report"] == 'HMR'){
            if (inputData["generate-report"] !== "" && inputData["generate-report"] !== null && inputData["generate-report"] !== undefined ) {
               if((selectedHRYear !== "" && selectedHRYear !== null && selectedHRYear !== undefined && (selectedHRMonth == "" || selectedHRMonth == null || selectedHRMonth == undefined)) || 
                  (selectedHRMonth !== "" && selectedHRMonth !== null && selectedHRMonth !== undefined && (selectedHRYear == "" || selectedHRYear == null || selectedHRYear == undefined))) {
                    Swal.fire(
                        'Warning!',
                        'Please complete option',
                        'warning'
                    )
               }else{
                 history.push(location.pathname + "/" + inputData["generate-report"] )
               }
               
            } else {
                Swal.fire(
                    'Warning!',
                    'Please complete option',
                    'warning'
                )
            }

        }else if (inputData["generate-report"] == "SA") { 
            ///Report generation for Staff appraisal report
            if(localStorage.getItem("apYear") && inputData["generate-report"]){
              history.push(location.pathname + "/" + inputData["generate-report"]);
            }else{
              Swal.fire("Warning!", "Please complete option", "warning");
            }
        }else if(inputData["generate-report"] == "FA"){
            if(localStorage.getItem("faYear") && inputData["generate-report"]){
              history.push(location.pathname + "/" + inputData["generate-report"]);
            }else{
              Swal.fire("Warning!", "Please complete option", "warning");
            }
        }else if(inputData["generate-report"] === "HSE"){
            // console.log(inputData["generate-report"])
            // localStorage.setItem("hseReport", "selected")d
            history.push(location.pathname + "/" + inputData["generate-report"])
        }else{
            if (inputData["generate-report"] !== "" && inputData["generate-report"] !== null && inputData["generate-report"] !== undefined) {
              
                history.push(location.pathname + "/" + inputData["generate-report"] )
            } else {
                Swal.fire(
                    'Warning!',
                    'Please complete option',
                    'warning'
                )
            }
        }
       
    }

    useEffect(() => {
        
        fetch(domain + "/api/EmployeeModule/user_role.php", {
            method: "GET",
            mode: "same-origin",
            credentials: "same-origin"
        })
            .then(res => res.json())
            .then(json => {
               setRole(json);
              
            })
            .catch(err => { console.log(err) })

        fetch(domain + "/api/SupportModule/helpdesk.php?action=getname", {
            method: "GET",
        })
        .then((res) => res.json())
        .then((json) => {
            setUserDept(json["Department"])
        })
        .catch((err) => {
            console.log(err);
        });
    }, [])

    return (
        <MainTemplate
            content={
                <Switch>
                {
                    // permission.view  ? inputData["generate-report"] == 'TACA' ? <Route exact path="/:subdom/:page/:report/:year" children={<GenerateReport />} /> : <Route exact path="/:subdom/:page/:report" children={<GenerateReport />} /> : null
                    permission.view  ?  <Route
                    exact
                    path="/:page/:report"
                    children={localStorage.getItem("apYear") ? <DirectDownloadReport /> : localStorage.getItem("faYear") ? <CustomFixedAssetReport /> : localStorage.getItem("hseReport") ? <CustomHSEReport /> : <GenerateReport />} /> : null
                }
                {
                    permission.view && (role == 'Sadmin' || role == 'Admin' || role == 'HR Manager' || role == 'HR' || role === 'Coordinator' || role === "HSE Manager") ?
                        <Route exact path="" children={
                            <div>
                                <h6>Report</h6>
                                <form onSubmit={handleSubmit}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Select report to generate: 
                                                </th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <select id="generate-report" className="form-select form-select-sm" name="generate-report" onChange={handleChangeInput}>
                                                        <option value="" defaultValue="">Please select an option</option>
                                                        <option value="TATH">Training Application - Total no of hr per month</option>
                                                        <option value="TAH">Training Application - No of hr per employee</option>
                                                        <option value="TAC">Training Application - Cost fee per employee</option>
                                                        <option value="TACA">Training Details Report </option>
                                                        <option value="TATT">Training Application - Training Type</option>
                                                        <option value="TE">Training Evaluation</option>
                                                        <option value="TM">Training Matrix</option>
                                                        <option value="HMR" id="HMR">HR Monthly Report</option>
                                                        {
                                                            role === "Sadmin" || role === "Admin" || role === "Director" || role === "HR Manager" ?
                                                            <option value="SA" id="SA">
                                                            Yearly Staff Appraisal
                                                            </option>
                                                            :
                                                            <></>
                                                        }
                                                        {
                                                            userDept === "5" ? 
                                                            <option value="FA" id="FA">
                                                                Yearly Helpdesk Report
                                                            </option>
                                                            :
                                                            <></>
                                                        }
                                                        { role === "Sadmin" || role === "Admin" || role === "Director" || role === "HR Manager" || role === "HSE Manager" || role === "Coordinator" ?
                                                            <option value="HSE" id="HSE">
                                                            HSE Report
                                                            </option>
                                                        :
                                                            <></>
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr ><td> </td></tr>
                                            <tr>
                                                <td>
                                                    <select
                                                    hidden
                                                    id="faYear"
                                                    className="form-select form-select-sm"
                                                    name="year"
                                                    onChange={handleFaYear}
                                                    >
                                                    <option value="" defaultValue="">
                                                        Select Year
                                                    </option>
                                                    <option value="2027">2027</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2021">2021 </option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <select
                                                    hidden
                                                    id="apYear"
                                                    className="form-select form-select-sm"
                                                    name="year"
                                                    onChange={handleApYear}
                                                    >
                                                    <option value="" defaultValue="">
                                                        Select Year
                                                    </option>
                                                    <option value="2027">2027</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2021">2021 </option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr > 
                                                <td>
                                                <select hidden id="year" className="form-select form-select-sm" name="year" onChange={handleChangeYear}>
                                                        <option value="" defaultValue="">Select Year</option>
                                                        <option value="2024">2024</option>
                                                        <option value="2023">2023</option>
                                                        <option value="2022">2022</option>
                                                        <option value="2021">2021 </option>
                                                        <option value="2020">2020</option>
                                                        <option value="2019">2019</option>
                                                        <option value="2018">2018</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr > 
                                                <td>
                                                <select hidden id="hr_year" className="form-select form-select-sm" name="year" onChange={handleChangeHRYear}>
                                                        <option value="" defaultValue="">Select Year</option>
                                                        <option value="2024">2024</option>
                                                        <option value="2023">2023</option>
                                                        <option value="2022">2022</option>
                                                        <option value="2021">2021 </option>
                                                        <option value="2020">2020</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr > 
                                                <td>
                                                <select hidden id="hr_month" className="form-select form-select-sm" name="hr_month" onChange={handleChangeHRMonth}>
                                                        <option value="" defaultValue="">Select Month</option>
                                                        <option value="01">January</option>
                                                        <option value="02">February</option>
                                                        <option value="03">March</option>
                                                        <option value="04">April </option>
                                                        <option value="05">May</option>
                                                        <option value="06">June</option>
                                                        <option value="07">July</option>
                                                        <option value="08">August </option>
                                                        <option value="09">September</option>
                                                        <option value="10">October</option>
                                                        <option value="11">November</option>
                                                        <option value="12">December</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><button className="btn btn-primary mt-3 float-right center">Generate</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                                
                            </div>
                        } />
                    : permission.view ?
                        <Route exact path="" children={
                            <div>
                                <h6>Report</h6>
                                <form onSubmit={handleSubmit}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Select report to generate: 
                                                </th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <select id="generate-report" className="form-select form-select-sm" name="generate-report" onChange={handleChangeInput}>
                                                        <option value="" defaultValue="">Please select an option</option>
                                                        <option value="TATH">Training Application - Total no of hr per month</option>
                                                        <option value="TAH">Training Application - No of hr per employee</option>
                                                        <option value="TAC">Training Application - Cost fee per employee</option>
                                                        <option value="TACA">Training Details Report </option>
                                                        <option value="TATT">Training Application - Training Type</option>
                                                        <option value="TE">Training Evaluation</option>
                                                        <option value="TM">Training Matrix</option>
                                                        {/* <option value="HMR" id="HMR">HR Monthly Report</option> */}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr ><td> </td></tr>
                                            <tr > 
                                                <td>
                                                <select hidden id="year" className="form-select form-select-sm" name="year" onChange={handleChangeYear}>
                                                        <option value="" defaultValue="">Select Year</option>
                                                        <option value="2024">2024</option>
                                                        <option value="2023">2023</option>
                                                        <option value="2022">2022</option>
                                                        <option value="2021">2021 </option>
                                                        <option value="2020">2020</option>
                                                        <option value="2019">2019</option>
                                                        <option value="2018">2018</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr > 
                                                <td>
                                                <select hidden id="hr_year" className="form-select form-select-sm" name="year" onChange={handleChangeHRYear}>
                                                        <option value="" defaultValue="">Select Year</option>
                                                        <option value="2024">2024</option>
                                                        <option value="2023">2023</option>
                                                        <option value="2022">2022</option>
                                                        <option value="2021">2021 </option>
                                                        <option value="2020">2020</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr > 
                                                <td>
                                                <select hidden id="hr_month" className="form-select form-select-sm" name="hr_month" onChange={handleChangeHRMonth}>
                                                        <option value="" defaultValue="">Select Month</option>
                                                        <option value="01">January</option>
                                                        <option value="02">February</option>
                                                        <option value="03">March</option>
                                                        <option value="04">April </option>
                                                        <option value="05">May</option>
                                                        <option value="06">June</option>
                                                        <option value="07">July</option>
                                                        <option value="08">August </option>
                                                        <option value="09">September</option>
                                                        <option value="10">October</option>
                                                        <option value="11">November</option>
                                                        <option value="12">December</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><button className="btn btn-primary mt-3 float-right center">Generate</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                                
                            </div>
                        } />
                    : null
                }
                
                </Switch>
            }
        />
    )
}

export default Report