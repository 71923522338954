import { sessionSetLoading } from "custom_hooks/Actions"
import React, { useState, useEffect } from "react"
import {
    Redirect
} from "react-router-dom"
import Swal from "sweetalert2"
import "../css/Login.css"
import { WidgetErrorMsg } from '../custom_components/Widgets.js'
import loginLogo from '../icons/logo.png'

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

class ChangePassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            passwordCheck: '',
            errormsg: '',
            submitted: false,
            redirect: false
        }
        this.handleChangePass = this.handleChangePass.bind(this)
        this.handleChangePassCheck = this.handleChangePassCheck.bind(this)
        this.handleOnChangeSubmit = this.handleOnChangeSubmit.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChangePass(e) {
        this.setState({ password: e.target.value })
    }

    handleChangePassCheck(e) {
        this.setState({ passwordCheck: e.target.value })
    }

    handleOnChangeSubmit(props) {
        this.setState({ submitted: props })
    }

    handleOnChangePassword(password) {
        sessionSetLoading(true)
        fetch( domain + "/api/EmployeeModule/login_changepassword.php", {
            method: "POST",
            mode: "same-origin",
            credentials: "same-origin",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                pass: password
            })
        })
            .then(res => res.json())
            .then(json => {
                Swal.fire(
                    json[0] === 1 ? 'Success!' : 'Error!',
                    json[1],
                    json[0] === 1 ? 'success' : 'error'
                )
                this.setState({ redirect: true })
                sessionSetLoading()
            })
            .catch(err => err)
    }

    //takes event object
    handleSubmit(e) {
        e.preventDefault()
        this.setState({ errormsg: "" })
        this.setState({ submitted: true })

        if (this.state.password === this.state.passwordCheck) {
            this.handleOnChangePassword(this.state.password)
        } else {
            this.setState({ errormsg: "Passwords do not match, please confirm passwords match." })
        }
    }

    render() {
        if (this.state.redirect === true) {
            return <Redirect to={ "/login"} />
            // return <Redirect to={path + "/db"} />
        }
        return (
            <div id="login">
                <div className="dark-bg"></div>
                <div className="grid-login">
                    <form className="login-form" onSubmit={this.handleSubmit}>
                        <img className="mb-4" src={loginLogo} height="80" width="290"></img>
                        <h6>Change your password, 
                        Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters</h6>
                        <input
                            className="form-control mb-1"
                            type="password"
                            placeholder="New password"
                            name="password1"
                            value={this.state.password}
                            onChange={this.handleChangePass}
                            pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}' title='Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'
                            required
                        />
                        <input
                            className="form-control mb-1"
                            type="password"
                            placeholder="Confirm new password"
                            name="password2"
                            value={this.state.passwordCheck}
                            onChange={this.handleChangePassCheck}
                            pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}' title='Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'
                            required
                        />
                        <input
                            className="form-control btn btn-md btn-primary btn-block mt-3"
                            type="submit"
                            value="Change password"
                        />
                    </form>
                    <WidgetErrorMsg
                        submit={this.state.submitted} 
                        submitOnChange={this.handleOnChangeSubmit} 
                        errormsg={this.state.errormsg}
                    />
                </div>
            </div>
        )
    }
}

export default ChangePassword