import { useState, useEffect } from "react"
import {
    Redirect,
    useParams
} from "react-router-dom"
import { Input, SelectInput, SelectInputWithGroup } from '../../custom_components/Forms.js'
import { sessionSetLoading, useFetchGet, useFetchPost, useUserPermission } from '../../custom_hooks/Actions.js'
import { EditFormTemplate, EditFormTemplateTraining } from '../../custom_components/templates/FormTemplate'
import { flipTrueFalse } from "helper_functions/Helper.js"
import Swal from "sweetalert2"

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function ApplicationDetails(props) {
    // window.location.toString().split(path)[0] + path +
    let { id } = useParams()
    const dataList = useFetchPost( domain + "/api/TrainingModule/trainingUtility.php", {option: "TADL"}).data
    const formData = useFetchPost( domain + "/api/TrainingModule/trainingUtility.php", {option: "TAOption"}).data
    const [editMode, setEditMode] = useState(1)
    const [invoice, setInvoiceFile] = useState(0)
    const [projectCode, setProjectCode] = useState(null)
    const [inputData, setInputData] = useState({})
    const [submitted, setSubmitted] = useState(0)
    const [validated, setValidated] = useState(0)
    const [renew, setRenew] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const defaultSelectInput = "Select an option"
    const [endDateThreshold, setEndDateThreshold] = useState()
    const fields = [
        { name: "Employee Name", inputname:"employeeName", type: "text"},
        { name: "Course Name", inputname:"courseName", type: "text", required: true, datalist: dataList[0] },
        { name: "Exam", inputname:"exam", type: "select", required: true, options: ["Examinable","Non-Examinable"]},
        { name: "Training Provider", inputname:"trainingProvider", type: "text", required: true, datalist: dataList[2] },
        { name: "Language Preferred", inputname:"languagePreferred", type: "text", required: true, datalist: dataList[1] },
        { name: "Training Venue", inputname:"venue", type: "text", required: true, datalist: dataList[3] },
        { name: "Training Start Date", inputname:"startDate", type: "date", required: true },
        { name: "Training End Date", inputname:"endDate", type: "date", required: true },
        { name: "Training Start Time", inputname:"startTime", type: "time", required: true },
        { name: "Training End Time", inputname:"endTime", type: "time", required: true },
        { name: "Duration", inputname:"duration", type: "number", required: true },
        { name: "Duration Type", inputname:"durationType", type: "select", required:  true , options: ["hours","days","weeks"] },
        { name: "Total Payable Fee", inputname:"totalFee", type: "currency", required: true },
        { name: "Grant Applicable", inputname:"grantApplicable", type: "checkbox", required: false },
        { name: "Training Type", inputname:"trainingType", type: "select", required: true, options: formData[2] },
        { name: "Training Objective", inputname:"trainingObjective", type: "text", required: true },
        { name: "Course Applied", inputname:"courseApply", type: "checkbox", required: false },
    ]
    const notRequiredInputData = []
    const permission = useUserPermission(3)
    const courseApplyPermission = useUserPermission(7)

    const handleChangeInput = (e,date) => {
        
        if(typeof(e) == 'object'){
            if(e.target.name == 'projectCode'){
                setProjectCode(e.target.value);
            }
            setInputData({
                ...inputData,
                [e.target.name]: e.target.type ==="checkbox" ? e.target.checked : e.target.value
            })
            if (e.target.name === fields[6].inputname) {
                setEndDateThreshold(e.target.value)
            }            
        }else{
            if(typeof(e) != 'object'){
                setInputData({
                    ...inputData,
                    [e]:date
                })  
            }
        }       
    }

    function createInputObj(json) {
        let a = {}
        for (let i = 0 ;i < fields.length; i++) {
            let inputName = fields[i].inputname
            if (fields[i].type === "date" && json[inputName]) {
                let splitDate = json[inputName].split("-")
                let date = new Date(splitDate[2],parseInt(splitDate[1])-1,parseInt(splitDate[0])+1)
                a[inputName] = date.toISOString().split('T')[0]
            } else if (fields[i].type === "checkbox") {
                a[inputName] = json[inputName] === "Yes" ? true : false
            } else {
                a[inputName] = json[inputName] ? json[inputName] : ""
            }
        }
        return a
    }

    useEffect(() => {
        if (submitted === 0) {
            // window.location.toString().split(path)[0] + path +
            fetch(  domain + "/api/TrainingModule/trainingApplication.php?action=search", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    trainingID: id
                })
            })
                .then(res => res.json())
                .then(json => {
                    setInputData(createInputObj(json))
                    if(json['invoice_file'] != null && json['invoice_file'] != '' ){
                        setInvoiceFile(1)
                    }else{
                        setInvoiceFile(0);
                    }

                    if(json['projectCode'] != null && json['projectCode'] != ''){
                        setProjectCode(json['projectCode']);
                        
                    }
                })
                .catch(err => { console.log(err) })
        }
    }, [])

    useEffect(() => {
        if (renew) {
            sessionSetLoading(true)
            // window.location.toString().split(path)[0] + path + 
            fetch( domain + "/api/TrainingModule/trainingApplication.php?action=reset", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    trainingID: id
                })
            })
            .then(res => res.json())
            .then(json => {
                setRenew(false)
                Swal.fire(
                    json[0] === 1 ? 'Success!' : 'Error!',
                    json[1],
                    json[0] === 1 ? 'success' : 'error'
                )
                if (json[0] === 1) {
                    setRedirect(true)
                }
                sessionSetLoading()
            })
            .catch(err => { console.log(err) })
        }
    }, [renew])

    useEffect(() => {
        if (submitted === 1 && validated === 1 && permission.edit) {
            sessionSetLoading(true)
            // let formData = inputData
            // formData['trainingID'] = id;

            let formData = new FormData()
            if (typeof inputData["file-uploadType"] != "undefined") {
              
                formData.append("fileType",inputData["file-uploadType"])
                formData.append("file",inputData["file-uploadData"])
            }
            formData.append("inputData", JSON.stringify({
                trainingID: id,
                courseName: inputData['courseName'],
                exam: inputData['exam'],
                languagePreferred: inputData['languagePreferred'],
                trainingProvider: inputData['trainingProvider'],
                venue: inputData['venue'],
                startDate: inputData['startDate'],
                endDate: inputData['endDate'],
                startTime: inputData['startTime'],
                endTime: inputData['endTime'],
                duration: inputData['duration'],
                durationType: inputData['durationType'],
                totalFee: inputData['totalFee'],
                grantApplicable: inputData['grantApplicable'],
                trainingObjective: inputData['trainingObjective'],
                courseApply: inputData['courseApply'],
                trainingType: inputData['trainingType'],
                projectCode: projectCode
            }))
            
         
            fetch(  domain + "/api/TrainingModule/trainingApplication.php?action=update", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                // headers: { 'Content-Type': 'application/json' },
                body: formData
            })
            .then(res => res.json())
            .then(json => {
                Swal.fire(
                    json[1] === "Training Application's detail is updated. The Course isn't approved yet, hence course cannot be applied"  ||
                    json[1] === "Training Application's detail is updated. The Course isn't approved yet, hence course cannot be applied and invoice file cannot be attached!" ||
                    json[1] === "Training Application's detail is updated., invoice file must either be pdf file type." || 
                    json[1] === "Date Format is Invalid in Start/End Date!" || 
                    json[1] === "Training Application's detail is updated., invoice file is above file size limit of 100KB" ? 'Error!' : json[0] === 1 ? 'Success!' : 'Error!',
                    json[1],
                    json[1] === "Training Application's detail is updated. The Course isn't approved yet, hence course cannot be applied" ||
                    json[1] === "Training Application's detail is updated. The Course isn't approved yet, hence course cannot be applied and invoice file cannot be attached!" ||
                    json[1] === "Training Application's detail is updated., invoice file must either be pdf file type." || 
                    json[1] === "Date Format is Invalid in Start/End Date!" || 
                    json[1] === "Training Application's detail is updated., invoice file is above file size limit of 100KB" ? 'error' : json[0] === 1 ? 'success' : 'error'
                )
                if (json[0] === 1) {
                    setRedirect(true)
                } else {
                    setSubmitted(0)
                    setValidated(0)
                }
                sessionSetLoading()
            })
            .catch(err => { console.log(err) })
        }
        return null
    }, [submitted, validated])


    const dateNow = new Date()
	const dateMin = dateNow.getFullYear() + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
    let formClassName = "form-group row"
    let formDisplayWide = formClassName + " grid-wide"
    let formDisplayNone = formClassName + " d-none"
    const fieldItems = fields.map((field, index) =>
        <div className={ 
                index === 16 && !courseApplyPermission.use ? formDisplayNone : 
                index === 3 || index === 4 || index === 5 || index === 9 || index === 10 || index === 11 || index === 12 ?
                 formDisplayWide : formDisplayWide
                // index === 13 && !courseApplyPermission.use ? formDisplayNone : 
                // index === 2 || index === 3 || index === 4 || index === 8 || index === 9 || index === 10 ?
                //     formClassName : formDisplayWide
            } key={field.inputname} >
            <label htmlFor={field.inputname} className="col-sm-3 ">{field.name}</label>
            <div className="col-sm-9">
                {
                    field.type === "select" && field.optionGroups ?
                        <SelectInputWithGroup
                            field={field.inputname}
                            options={field.options}
                            value={inputData[field.inputname]}
                            optionGroups={field.optionGroups}
                            defaultOption={defaultSelectInput}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            disabled={editMode}
                        />
                    : field.type === "select" ?
                        <SelectInput
                            field={field.inputname}
                            value={inputData[field.inputname]}
                            options={field.options}
                            defaultOption={defaultSelectInput}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            disabled={editMode}
                        />
                    : field.type === "text" && index === 0 ?
                        <Input
                            field={field.inputname}
                            type={field.type}
                            value={inputData[field.inputname]}
                            dataList={field.datalist ? field.datalist : null}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            disabled={1}
                            min={
                                index === 6 ? dateMin : 
                                index === 7 ? (endDateThreshold ? endDateThreshold : dateMin) :
                                null
                            }
                        />
                    :
                        <Input
                            field={field.inputname}
                            type={field.type}
                            value={inputData[field.inputname]}
                            dataList={field.datalist ? field.datalist : null}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            disabled={editMode}
                            min={
                                index === 6 ? dateMin : 
                                index === 7 ? (endDateThreshold ? endDateThreshold : dateMin) :
                                null
                            }
                        />
                    
                }
            </div>
        </div>
    )

    function handleSubmit(e) {
        e.preventDefault()
        try {
            let target = document.getElementById("formFile")
            setInputData({
                ...inputData,
                [target.name]: URL.createObjectURL(target.files[0]),
                [target.name+"Type"]: target.value.split('.').pop(),
                [target.name+"Data"]: target.files[0]
            })
        } catch {
        }
        for (let inputName in inputData) {
            let isAnExcept = 0
            for (let exceptNum in notRequiredInputData) {
                if (inputName === notRequiredInputData[exceptNum]) {
                    isAnExcept = 1
                }
            }
            if (isAnExcept === 0) {
                if (inputData[inputName] === defaultSelectInput || inputData[inputName] === "" || inputData[inputName] === null || inputData[inputName] === "undefined") {
                    setValidated(0)
                    setSubmitted(0)
                } else {
                    break
                }
            } else {
                break
            }
        }
        setValidated(1)
        setSubmitted(1)
    }

    if (redirect) {
        return <Redirect to={"/application"} />
    } else {
        // return (
        //     <EditFormTemplateTraining
        //         module="Application"
        //         handleSubmit={handleSubmit}
        //         disabled={editMode}
        //         setEditMode={setEditMode}
        //         fieldItems={fieldItems}
        //         setRenew={setRenew}
        //         editAccess={permission.edit}
        // />
        // )
        return (
            <div>
                    {
                        <EditFormTemplateTraining
                                module="Application"
                                handleSubmit={handleSubmit}
                                disabled={editMode}
                                setEditMode={setEditMode}
                                fieldItems={fieldItems}
                                setRenew={setRenew}
                                editAccess={permission.edit}
                                courseApplyStatus={inputData['courseApply']}
                                invoiceFile={invoice}
                                courseApplyPermission={courseApplyPermission.use}
                                dataList={formData[5]}
                                handleChangeInput={handleChangeInput}
                                projectCode={projectCode}
                        />
                    }
            </div>
        );
    }
    
}

export default ApplicationDetails