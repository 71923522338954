import { CreateRecruitmentForm } from "custom_components/templates/FormTemplate";
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from "react-router-dom"
import MainTemplate from "custom_components/templates/MainTemplate";
import { useUserPermission } from "custom_hooks/Actions";
import { FullTabulatorTemplate } from "custom_components/templates/TabulatorTemplate";
import { useState } from "react";
import StaffInfo from "pages/appraisal/StaffInfo";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN
var empCode = ""
function parseStaffAppraisal(data) {
    let dataForActive = []
    let dataForInactive = []

    try {
        empCode = data[2]
        data[0].forEach((number, index, array) => {
            let obj = {
                no: index+1,
                appraisalName: array[index].aName,
                appraisalCode: array[index].aOwner,
                appraisalYear: array[index].aYear,
                appraisalStatus: array[index].aStatus,
                appraisalFilled: array[index].aFilled,
                appraisalId: array[index].aId,
                appraisalDueDate: array[index].aDueDate,
            }

            var dueDate = new Date(obj.appraisalDueDate)
            dueDate.setHours(23,59,59)
            var todayDate = new Date()

            if(dueDate<todayDate && obj.appraisalStatus !== "Approved"){
                obj.appraisalStatus = "Appraisal Expired"
            }

            if(obj.appraisalFilled === "Complete" || dueDate < todayDate ){
                dataForInactive.push(obj);
            }else{
                dataForActive.push(obj);
            }
        
        })
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function StaffAppraisal({permission}){
    const [redirect, setRedirect] = useState({ allow: false })
    const location = useLocation()
    const tabs = ["Active", "History"];
    const columns = [
        { title: " S/no.", field: "no", headerFilter: "input"},
        {
            title: "Name", field: "appraisalId", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "appraisalName",
                urlPrefix: "sa/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.appraisalId }) //here we update state
            }
        },
        { title: "Appraisal Year", field: "appraisalYear", headerFilter: "input" },
        { title: "Appraisal Filled", field: "appraisalFilled", headerFilter: "input" },
        { title: "Appraisal Status", field: "appraisalStatus", headerFilter: "input" },
    ]

    return(
        redirect.allow ?
            <Redirect push to={location.pathname+"/"+redirect?.id} />
        :
        <FullTabulatorTemplate 
            permission = {permission}
            fetchURL = {domain + "/api/AppraisalModule/appraisal.php?action=getPersonal"}
            parseFunc = {parseStaffAppraisal}
            columns = {columns}
            moduleName = {"Staff Appraisal"}
            redirect = {redirect}
            tabs={tabs}
            disableWidget = {true}
        />
    )
}

function StaffAppraisalContainer(){
    const permission1 = useUserPermission(25);
    const permission2 = useUserPermission(26);
    const permission3 = useUserPermission(27);
    const permission4 = useUserPermission(28);
    let perm = {...permission1, ...permission2, ...permission3, ...permission4}
    return(
        <MainTemplate content={
            <Switch>
                {/* {
                    perm.add ? <Route path="/:page/create" children={<InterviewCreate />} /> : null
                }
                {
                    perm.view ? <Route path="/:page/:id/details" children={<EmployeeInfo defaultTab={"Details"} />} /> : null
                }
                {
                    perm.view ? <Route path="/:page/:id/cert" children={<EmployeeInfo defaultTab={"Certificate"} />} /> : null
                }*/}
                {
                    <Route path="/:page/:id" children={<StaffInfo permission={perm} from="staff" empCode={empCode} />} />
                }
                {
                    <Route path="" children={<StaffAppraisal permission={perm} />} />
                } 
            </Switch>
        }
        />
    )
}

export default StaffAppraisalContainer