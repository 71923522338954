import React, { useState, useEffect } from "react"
import {
    Redirect,
    useLocation
} from "react-router-dom"
import Tabs from 'custom_components/Tabs.js'
import { TabulatorWrapper } from '../Tabulator.js'
import { WidgetAddItem } from 'custom_components/Widgets.js'
import { useFetchGet } from "custom_hooks/Actions.js"
import { flipTrueFalse } from "helper_functions/Helper.js"

const path = process.env.REACT_APP_PATH

function TabulatorTemplate({ moduleName, columns, altColumns, moduleData, moduleWidgets, tabs, disableWidget, handleRefetch, disablePdfDownload, showFaComponent }) {
    const location = useLocation()
    const defaultTab = tabs[0] ? tabs[0] : null
    const [activeTab, setActiveTab] = useState(defaultTab)
    
    return (
        <div>
            <h6>{moduleName}</h6>
            <br />
            {
                <Tabs
                    tabs={tabs}
                    state={{ activeTab: [activeTab, setActiveTab] }}
                    onClick={() => setActiveTab(defaultTab)}
                    children={
                        <>
                            {
                                activeTab === tabs[0] && disableWidget !== true &&
                                <WidgetAddItem
                                    link={location.pathname + "/create"}
                                    text={"Add " + moduleName}
                                />
                            }
                        </>
                    }
                />
            }
            {
                <TabulatorWrapper
                    data={
                        activeTab === tabs[0] ?
                            moduleData[0]
                        :
                            moduleData[1]
                    }
                    columns={
                        activeTab === tabs[0] ?
                            columns
                        :
                            altColumns ?? columns
                    }
                    activeTab={activeTab}
                    display={
                        moduleData[0] ? 
                            activeTab === tabs[0] ?
                                typeof (moduleData[0][0]) !== "undefined" && typeof (moduleData[0][0]) !== null ? true : false
                            :
                                typeof (moduleData[1][0]) !== "undefined" && typeof (moduleData[1][0]) !== null ? true : false
                        :
                            false
                    }
                    moduleName={moduleName}
                    showFaComponent={showFaComponent}
                    // moduleWidgets={activeTab === tabs[0] && moduleWidgets ? moduleWidgets : null}
                    handleRefetch={handleRefetch}
                    disablePdfDownload={disablePdfDownload}
                />
            }
            {
              activeTab === tabs[0] && moduleWidgets && moduleData[0][0] ? moduleWidgets : null
            }
        </div>
    )
}

function FullTabulatorTemplate({ permission, fetchURL, parseFunc, tabs = ["Active", "Inactive"], columns, moduleName, redirect, disableWidget,disablePdfDownload = false, from }) {
    const location = useLocation()
    const [refetch, setRefetch] = useState(false)
    const moduleData = useFetchGet(fetchURL, refetch)
    const parsedData = parseFunc(moduleData.data, permission)
    var showFaComponent = false


    if(from === "fa"){
        showFaComponent = true
    }

    function handleRefetch() {
        setRefetch(Math.random())
    }
    
    useEffect(()=>{
        setRefetch(Math.random())
    },[])

    return (
        redirect.allow ?
            <Redirect push to={location.pathname+"/"+redirect?.code} />
        :
            <>
                {
                moduleData.status === "fetched" ?
                    <TabulatorTemplate 
                        moduleName={moduleName}
                        columns={columns}
                        moduleData={parsedData}
                        tabs={tabs}
                        disableWidget={ from === "fa" ? moduleData.data[3] === "5" ? false : true : disableWidget}
                        handleRefetch={handleRefetch}
                        disablePdfDownload={disablePdfDownload}
                        showFaComponent={showFaComponent}

                    />
                : moduleData.status === "fetching" ?
                    <div>Loading...</div>
                :
                    // <div>Employee is not available</div>
                    <TabulatorTemplate
                        moduleName={moduleName}
                        columns={columns}
                        moduleData={parsedData}
                        tabs={tabs}
                        disableWidget={ from === "fa" ? moduleData.data[3] === "5" ? false : true : disableWidget}
                        handleRefetch={handleRefetch}
                        disablePdfDownload={disablePdfDownload}
                        showFaComponent={showFaComponent}

                    />
                }
            </>
            
    )
}

export { TabulatorTemplate, FullTabulatorTemplate }