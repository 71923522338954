import { useState, useEffect } from "react"
import {
    Redirect,
    useParams
} from "react-router-dom"
import { Input, SelectInput } from '../../custom_components/Forms.js'
import { EditFormTemplateUserAccount } from '../../custom_components/templates/FormTemplate'
import "../../css/Forms.css"
import { sessionSetLoading, useUserPermission } from "custom_hooks/Actions.js"
import Swal from "sweetalert2"

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function UserAccountAccessRights(props) {
    let { id } = useParams()
    const [formData, setFormData] = useState([""])
    const [dataList, setDataList] = useState([""])
    const [editMode, setEditMode] = useState(1)
    const [modTypes, setModTypes] = useState([""])
    const [modData, setModData] = useState([[""]])
    const [inputData, setInputData] = useState({})
    const [submitted, setSubmitted] = useState(0)
    const [validated, setValidated] = useState(0)
    const [redirect, setRedirect] = useState(false)
    const defaultSelectInput = "Select an option"
    const fields = [
        { name: "Account Name", type: "text", required: false },
        { name: "Manager Status", type: "text", required: true, options: ["Yes", "No"] },
        { name: "Department", type: "text", required: true, options: formData[1] },
        // { name: "Sub Department", type: "text", required: false, options: formData[1] },
        { name: "Account Type", type: "select", required: true, options: formData[2] }
    ]
    const exceptInputData = [fields[0].name]
    const permission = useUserPermission(11)

    const handleChangeInput = (e) => {
        if (e.target.name === fields[3].name && permission.use === 1) {
            setInputData({
                ...inputData,
                [e.target.name]: e.target.value,
                // Employee Module
                [modData[0][0]]: formData[3][e.target.value][0][0] ? true : false,
                [modData[0][1]]: formData[3][e.target.value][0][1] ? true : false,
                [modData[0][2]]: formData[3][e.target.value][0][2] ? true : false,
                [modData[0][3]]: formData[3][e.target.value][0][3] ? true : false,
                [modData[0][4]]: formData[3][e.target.value][1][0] ? true : false,
                [modData[0][5]]: formData[3][e.target.value][1][1] ? true : false,
                [modData[0][6]]: formData[3][e.target.value][1][2] ? true : false,
                [modData[0][7]]: formData[3][e.target.value][1][3] ? true : false,
                [modData[0][8]]: formData[3][e.target.value][2][0] ? true : false,
                [modData[0][9]]: formData[3][e.target.value][2][1] ? true : false,
                [modData[0][10]]: formData[3][e.target.value][2][2] ? true : false,
                [modData[0][11]]: formData[3][e.target.value][2][3] ? true : false,
                [modData[0][12]]: formData[3][e.target.value][11][0] ? true : false,

                // Training Module
                [modData[1][0]]: formData[3][e.target.value][3][0] ? true : false,
                [modData[1][1]]: formData[3][e.target.value][3][1] ? true : false,
                [modData[1][2]]: formData[3][e.target.value][3][2] ? true : false,
                [modData[1][3]]: formData[3][e.target.value][4][0] ? true : false,
                [modData[1][4]]: formData[3][e.target.value][4][1] ? true : false,
                [modData[1][5]]: formData[3][e.target.value][5][0] ? true : false,
                [modData[1][6]]: formData[3][e.target.value][5][1] ? true : false,
                [modData[1][7]]: formData[3][e.target.value][7][0] ? true : false,
                [modData[1][8]]: formData[3][e.target.value][8][0] ? true : false,
                [modData[1][9]]: formData[3][e.target.value][8][1] ? true : false,
                [modData[1][10]]: formData[3][e.target.value][9][0] ? true : false,
                [modData[1][11]]: formData[3][e.target.value][9][1] ? true : false,
                [modData[1][12]]: formData[3][e.target.value][10][0] ? true : false,
                [modData[1][13]]: formData[3][e.target.value][10][1] ? true : false,
                [modData[1][14]]: formData[3][e.target.value][10][2] ? true : false,

                // Report
                [modData[2][0]]: formData[3][e.target.value][13][0] ? true : false,
                [modData[2][1]]: formData[3][e.target.value][13][1] ? true : false,

                // HomeLeave
                [modData[3][0]]: formData[3][e.target.value][14][0] ? true : false,
                [modData[3][1]]: formData[3][e.target.value][14][1] ? true : false,
                [modData[3][2]]: formData[3][e.target.value][14][2] ? true : false,
                [modData[3][3]]: formData[3][e.target.value][15][0] ? true : false,
                [modData[3][4]]: formData[3][e.target.value][15][1] ? true : false,

                // Manpower
                [modData[4][0]]: formData[3][e.target.value][16][0] ? true : false,
                [modData[4][1]]: formData[3][e.target.value][16][1] ? true : false,
                [modData[4][2]]: formData[3][e.target.value][16][2] ? true : false,
                [modData[4][3]]: formData[3][e.target.value][17][0] ? true : false,
                [modData[4][4]]: formData[3][e.target.value][17][1] ? true : false,
                [modData[4][5]]: formData[3][e.target.value][18][0] ? true : false,

                // Interview
                [modData[5][0]]: formData[3][e.target.value][19][0] ? true : false,
                [modData[5][1]]: formData[3][e.target.value][19][1] ? true : false,
                [modData[5][2]]: formData[3][e.target.value][19][2] ? true : false,
                [modData[5][3]]: formData[3][e.target.value][20][0] ? true : false,
                [modData[5][4]]: formData[3][e.target.value][20][1] ? true : false,
                [modData[5][5]]: formData[3][e.target.value][21][0] ? true : false,
                [modData[5][6]]: formData[3][e.target.value][21][1] ? true : false,
                [modData[5][7]]: formData[3][e.target.value][22][0] ? true : false,
                
                // Elearning
                [modData[6][0]]: formData[3][e.target.value][23][0] ? true : false,
                [modData[6][1]]: formData[3][e.target.value][24][0] ? true : false,

                // Appraisal
                [modData[7][0]]: formData[3][e.target.value][25][0] ? true : false,
                [modData[7][1]]: formData[3][e.target.value][25][1] ? true : false,
                [modData[7][2]]: formData[3][e.target.value][26][0] ? true : false,
                [modData[7][3]]: formData[3][e.target.value][26][1] ? true : false,
                [modData[7][4]]: formData[3][e.target.value][27][0] ? true : false,
                [modData[7][5]]: formData[3][e.target.value][27][1] ? true : false,
                [modData[7][6]]: formData[3][e.target.value][28][0] ? true : false,
                [modData[7][7]]: formData[3][e.target.value][28][1] ? true : false,
                [modData[7][8]]: formData[3][e.target.value][29][0] ? true : false,

                // Support
                [modData[8][0]]: formData[3][e.target.value][30][0] ? true : false,
                [modData[8][1]]: formData[3][e.target.value][30][1] ? true : false,
                [modData[8][2]]: formData[3][e.target.value][31][0] ? true : false,
                [modData[8][3]]: formData[3][e.target.value][31][1] ? true : false,
                [modData[8][4]]: formData[3][e.target.value][32][0] ? true : false,
                [modData[8][5]]: formData[3][e.target.value][32][1] ? true : false,
                [modData[8][6]]: formData[3][e.target.value][33][0] ? true : false,
                [modData[8][7]]: formData[3][e.target.value][33][1] ? true : false,

                // HSE
                [modData[9][0]]: formData[3][e.target.value][34][0] ? true : false,
                [modData[9][1]]: formData[3][e.target.value][34][1] ? true : false,
                [modData[9][2]]: formData[3][e.target.value][34][2] ? true : false,
                
            })
        } else {
            setInputData({
                ...inputData,
                [e.target.name]: e.target.type ==="checkbox" ? e.target.checked : e.target.value
            })
        }
    }

    function createInputObj(modTypes,modData,fields,fieldValues) { 
        function prepareData(fieldValues) {
            let data = []
            for (let values in fieldValues) {
                for (let items in fieldValues[values]) {
                    let item = fieldValues[values][items]
                    if (item !== null) {
                        data.push(item)
                    }
                }
            }
            return data
        }
        fieldValues['Permission'] = prepareData(fieldValues['Permission'])
        let a = {}
        for (let i = 0; i < fields.length; i++) {
            a[fields[i].name] = fieldValues[fields[i].name]
        }
        let counter = 0
        modData.forEach((modD, dIndex) => { 
            // modD.forEach((data, index) => { 
            //     a[data] = fieldValues['Permission'][counter] ? true : false
            //     counter += 1
            // })
            modD.forEach((data, index) => { 
                if(dIndex == 0 && index == 12){
                   
                    a[data] = fieldValues['Permission'][27] ? true : false
                }else if(dIndex == 1){
                    a[data] = fieldValues['Permission'][counter-1] ? true : false
                }else{
                    a[data] = fieldValues['Permission'][counter] ? true : false
                }
                counter += 1
            })
        })

        return a
    }

    useEffect(() => {
        if (submitted === 0) {
            fetch( domain + "/api/EmployeeModule/useraccount_accessright.php", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    employeeCode: id
                })
            })
                .then(res => res.json())
                .then(json => {
                    let a = json.splice(3, 2)
                    setModTypes(a[0]) 
                    setModData(a[1])
                    setInputData(createInputObj(a[0],a[1],fields,json[0]))
                    let b = json.splice(1, 1)
                    setDataList(b[0].slice(1))
                    setFormData(json)
                })
                .catch(err => { console.log(err) })
        }
    }, [])

    useEffect(() => {
        if (submitted === 1 && validated === 1 && permission.use === 1) {
            //todo refine this crude way to prepare data, obj data is unordered and has to be ordered to sort
            function prepareData(inputData) {
                let data = {}
                let ordered = []
                let index = 0
                for (let item in inputData) {
                    let name = item
                    let value = inputData[item]
                    switch (typeof inputData[item]) {
                       case "boolean": //store in ordered array
                        ordered[index] = { name,value }
                            index += 1
                            break
                        default: //append to data obj
                            data[name] = value
                            break
                    }
                }
                data['employeeCode'] = id
                data['Permission'] = [
                    [
                        ordered[0].value,
                        ordered[1].value,
                        ordered[2].value,
                        ordered[3].value,
                    ],
                    [
                        ordered[4].value,
                        ordered[5].value,
                        ordered[6].value,
                        ordered[7].value,
                    ],
                    [
                        ordered[8].value,
                        ordered[9].value,
                        ordered[10].value,
                        ordered[11].value,
                    ],
                    [ordered[12].value, ordered[13].value, ordered[14].value, null], //Application View/Add/Edit 3
                    [ordered[15].value, ordered[16].value, null, null], //Feedback View/Add 4
                    [ordered[17].value, ordered[18].value, null, null], //Evaluation View/Add 5
                    [ordered[19].value, null, null, null], //ApplyTrainingCourse 7
                    [ordered[20].value, ordered[21].value, null, null], //TrainingRecommend View/Edit 8
                    [ordered[22].value, ordered[23].value, null, null], //TrainingApproval View/Edit 9					
                    [ordered[24].value, ordered[25].value, ordered[26].value, null], //TrainingPayment View/Add/Edit 10
                    [ordered[27].value, null, null, null], //UserRightAccess 11
                    [ordered[28].value, ordered[29].value, null, null], //Report view/download 13
                    [ordered[30].value, ordered[31].value, ordered[32].value, null], //HomeLeave View/Add/Edit 14
                    [ordered[33].value, ordered[34].value, null, null], //HomeLeave Approval View/Edit 15
                    [ordered[35].value, ordered[36].value, ordered[37].value, null], //ManpowerAcquisition Application View/Add/Edit 16
                    [ordered[38].value, ordered[39].value, null, null], //ManpowerAcquisition Approval View/EditApproval 17
                    [ordered[40].value, null, null, null], //Manpower Maintab ordered[11].value8
                    [ordered[41].value, ordered[42].value, ordered[43].value, null], //Interview Application View/Add/Edit 19
                    [ordered[44].value, ordered[45].value, null, null], //Interview Approval View/EditApproval 20
                    [ordered[46].value, ordered[47].value, null, null], //Interview Recommend View/EditRecommend 21
                    [ordered[48].value, null, null, null], //Interview Maintab 22
                    [ordered[49].value, null, null, null], //Take Task 23 
                    [ordered[50].value, null, null, null], //View Score 24
                    [ordered[51].value, ordered[52].value, null, null], //Staff Appraisal View/Edit 25
                    [ordered[53].value, ordered[54].value, null, null], //Supervisor Appraisal View//directorEdit 26
                    [ordered[55].value, ordered[56].value, null, null], //Manager Approval View/managerEdit 27
                    [ordered[57].value, ordered[58].value, null, null], //Director Approval View/directorEdit 28
                    [ordered[59].value, null, null, null], //View Setup Page 29
                    [ordered[60].value, ordered[61].value, null, null], // Helpdesk view/add 30
                    [ordered[62].value, ordered[63].value, null, null], //ITRequisition view/add 31
                    [ordered[64].value, ordered[65].value, null, null], //ITRequisitionApproval view/approve 32
                    [ordered[66].value, ordered[67].value, null, null], //Fixed Asset view/add 33
                    [ordered[68].value, ordered[69].value, ordered[70].value, null], //HSE View/Add/Edit 34
                ]


                const newInputData = { inputData: data }
                return newInputData
            }
            sessionSetLoading(true)
            fetch(
                // window.location.toString().split(path)[0] + path +
              domain + "/api/EmployeeModule/useraccount_updateaccessright.php", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(prepareData(inputData))
            })
                .then(res => res.json())
                .then(json => {
                    if (json[0] === 1) {
                        setRedirect(true)
                    }
                    setSubmitted(0)
                    setValidated(0)
                    Swal.fire(
                        json[0] === 1 ? 'Success!' : 'Error!',
                        json[1],
                        json[0] === 1 ? 'success' : 'error'
                    )
                    sessionSetLoading()
                })
                .catch(err => { console.log(err) })
        }
        return null
    }, [submitted, validated])

    let formClassName = "form-group"
    let formDisplayWide = formClassName + " grid-extra-wide"
    let formDisplayNone = formClassName + " d-none"
    const fieldItems = fields.map((field, index) =>
        <div className={formDisplayWide} key={field.name} >
            <label htmlFor={field.name} className="">{field.name}</label>
            {
                index === 0 ?
                    <Input
                        field={field.name}
                        value={inputData[field.name]}
                        options={field.options}
                        defaultOption={defaultSelectInput}
                        requiredOption={field.required}
                        onChangeInput={handleChangeInput}
                        disabled={1}
                    />
                    :
                    <SelectInput
                        field={field.name}
                        value={inputData[field.name]}
                        options={field.options}
                        defaultOption={defaultSelectInput}
                        requiredOption={field.required}
                        onChangeInput={handleChangeInput}
                        disabled={editMode}
                    />
            }
        </div>
    )

    function CheckboxInput({ data, checked, disabled }) {
        return (
            <div key={data} className="form-check form-switch">
                <input className="form-check-input" type="checkbox" name={data} value={data} checked={checked} disabled={disabled}></input>
                <label className="form-check-label" htmlFor={data}>{data}</label>
            </div>
        )
    }

    const mods = modTypes.map((mod, index) =>
        <fieldset key={mod} className="form-group mb-3" name={mod} onChange={handleChangeInput}>
            <h5>{mod + " Module"}</h5>
            <div className="wide-grid">
                {
                    modData.filter(modDataItem => modData.indexOf(modDataItem) === index).map(checkboxes => {
                        return (
                            checkboxes.map(data => {
                                return (
                                    <CheckboxInput
                                        key={data}
                                        data={data}
                                        checked={inputData[data]}
                                        disabled={true}
                                        //disabled={disabled} feature to be re-enabled after advanced role accessis done
                                    />
                                )
                            })
                        )
                    })
                }
            </div>
        </fieldset>
    )

    function handleSubmit(e) {
        e.preventDefault()
        for (let inputName in inputData) {
            let isAnExcept = 0
            for (let exceptNum in exceptInputData) {
                if (inputName === exceptInputData[exceptNum]) {
                    isAnExcept = 1
                }
            }
            if (isAnExcept === 0) {
                if (inputData[inputName] === defaultSelectInput || inputData[inputName] === "" || inputData[inputName] === null || inputData[inputName] === "undefined") {
                    setValidated(0)
                    setSubmitted(0)
                } else {
                    break
                }
            } else {
                break
            }
        }
        setValidated(1)
        setSubmitted(1)
    }

    if (redirect === true) {
        return <Redirect to={ "/useraccount"} />
    } else {
        return (
            permission.use ?
                <EditFormTemplateUserAccount 
                    handleSubmit={handleSubmit}
                    disabled={editMode}
                    setEditMode={setEditMode}
                    fieldItems={fieldItems}
                    mods={mods}
                    editAccess={permission.use}
                />
            :
                <div className="tab-grid">You do not have user role access for this component.</div>
        )
    }
}

export default UserAccountAccessRights