import { CreateHelpdeskForm } from "custom_components/templates/FormTemplate";
import { useFetchPost } from "custom_hooks/Actions";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function HelpdeskDetails({routeParams, perm, userDept, userRole}){
    const history = useHistory();
    const [refetch, setRefetch] = useState(0);
    const [completeHour, setCompleteHour] = useState();
    const [completeMinute, setCompleteMinute] = useState();

    const [field, setField] = useState({
        helpdeskId: {displayText: "ID", dataType:"text", val:"", required: false, disable: true},
        requestorName: {displayText: "Requestor", dataType:"text", val:"", required: false, disable: true},
        subject: {displayText: "Subject", dataType:"SelectOne", val:"", disable: true},
        description: {displayText: "Description", dataType:"textbox", val:"", required: true, disable: true},
        solution: {displayText: "Solution", dataType:"textbox", val:"", required: true, disable: false},
        completeReason: {displayText: "Completion Time Reason", dataType:"textbox", val:"", required: false, disable: false},
        timeTaken: {displayText: "Completion Time", dataType:"text", val:"", required: true, disable: false},
        submitDt: {displayText: "Submission Datetime", dataType:"text", val:"", required: true, disable: true},
        responseDt: {displayText: "response Datetime", dataType:"text", val:"", required: true, disable: true},
        completedBy: {displayText: "Completed By", dataType:"text", val:"", required: true, disable: true},
        status: {displayText: "Status", dataType:"text", val:"", required: true, disable: true},
        rateStatus: {displayText: "Rate Status", dataType:"text", val:"", required: true, disable: true},
        rateDetail: {displayText: "Rate Detail", dataType:"text", val:"", required: true, disable: true},
        completedDt: {displayText: "Completed Datetime", dataType:"text", val:"", required: true, disable: true},
    })
    const subjectOption = [
        "Antivirus",
        "Computer related",
        "Email related",
        "Hardware related",
        "Hardware request",
        "Internet related",
        "Password reset",
        "Printer related",
        "Sharepoint",
        "Sharepoint Access",
        "Software Request",
        "Taskhub",
        "TMS",
        "WhyzeHR",
        "WINS",
        "ChummyHR",
        "Others"
    ]
    const helpdeskData = useFetchPost(
        domain + "/api/SupportModule/helpdesk.php?action=getSpecificHelpdesk",
        { helpdeskId: routeParams.id },
        refetch
    );

    function handleChanges(varName, value, subVar){
        if(varName === "timeTaken"){
            let isNum = /^\d+$/.test(value);

            if(isNum === false){
                if(subVar === "hour"){
                    setCompleteHour("")
                }else if(subVar === "minute"){
                    setCompleteMinute("")
                }
            }else if(value.length <= 2 ){
                if(subVar === "hour" && value <= 24){
                    setCompleteHour(value)
                }else if(subVar === "minute" && value <= 60){
                    setCompleteMinute(value)
                }
            }
            
            
        }else{
            let items = field[varName]
            items.val = value
            setField({
                ...field,
                [varName]: {...items}
            })
        }
    }

    useEffect(()=>{
        if(helpdeskData.status === "fetched"){
            var dt = helpdeskData.data

            Object.keys(dt).map((k)=>{
                if(dt[k]){
                    if(k === "timeTaken"){
                        var sep = dt[k].split(",")

                        setCompleteHour(sep[0])
                        setCompleteMinute(sep[1])
                    }else{
                        handleChanges(k, dt[k])
                    }
                }
            })
        }
    }, [helpdeskData.status])

    function handleSubmit(e){
        e.preventDefault()

        let inputData = {}
            
        Object.keys(field).map((k)=>{
            if(k === "timeTaken"){
                var value = completeHour + "," + completeMinute

                inputData[k] = value
            }else{
                inputData[k] = field[k].val
            }
        })

        if(field["status"].val === "Pending for acknowledgement"){
            fetch(
                domain + "/api/SupportModule/helpdesk.php?action=acknowledge",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(inputData),
            }
            )
            .then((res) => res.json())
            .then((json) => {
                if(json[0] === "success"){
                    Swal.fire(
                        "Success!",
                        "Successfully acknowledged",
                        // json[1],
                        // json[0] === 1 ? "success" : "error"
                    );
                }else{
                    Swal.fire(
                        "Fail!",
                        "Fail to update",
                        // json[1],
                        // json[0] === 1 ? "success" : "error"
                    );
                }

                history.push("/hd")
                // history.push(path+"/hd/" + field["helpdeskId"].val)
            })
            .catch((err) => {
                console.log(err);
            });
        }else if(field["status"].val.includes("Acknowledged by")){
            if(field["solution"].val === null || field["solution"].val === ""
            || field["completeReason"].val === null || field["completeReason"].val === ""){
                Swal.fire(
                    "Empty Field Found",
                    "Please fill up all the required field",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
            }else{
                fetch(
                    domain + "/api/SupportModule/helpdesk.php?action=updateHelpdesk",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(inputData),
                }
                )
                .then((res) => res.json())
                .then((json) => {
                    if(json[0] === "success"){
                        Swal.fire(
                            "Success!",
                            "Successfully submitted",
                            // json[1],
                            // json[0] === 1 ? "success" : "error"
                        );
                    }else{
                        Swal.fire(
                            "Fail!",
                            "Fail to update",
                            // json[1],
                            // json[0] === 1 ? "success" : "error"
                        );
                    }
    
                    history.push("/hd")
                })
                .catch((err) => {
                    console.log(err);
                });
            }
        }
    }


    return(
        <CreateHelpdeskForm
            module="Helpdesk Ticket"
            field={field}
            from="details"
            userDept = {userDept}
            userRole = {userRole}
            subjectOption={subjectOption}
            handleChanges={handleChanges}
            handleSubmit={handleSubmit}
            completeHour={completeHour}
            completeMinute={completeMinute}
        />
    )
}