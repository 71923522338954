import { CreateAppraisalSetup, CreateRecruitmentForm } from "custom_components/templates/FormTemplate";
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    useHistory,
} from "react-router-dom"
import MainTemplate from "custom_components/templates/MainTemplate";
import { useUserPermission } from "custom_hooks/Actions";
import { FullTabulatorTemplate } from "custom_components/templates/TabulatorTemplate";
import { useState } from "react";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function AppraisalSetup({permission}){
    // var [inputData, setInputData] = useState({})
    var [updatedRecord, setUpdatedRecord] = useState()
    const history = useHistory();

    function handleSubmit(e){
        e.preventDefault();
        var target = e.target[0];

        var formData = new FormData();
        formData.append("fileType", target.value.split(".").pop());
        formData.append("file", target.files[0]);

        
        fetch(
            domain + "/api/AppraisalModule/appraisal.php?action=setup",
        {
            method: "POST",
            body: formData
        }
        )
        .then((res) => res.json())
        .then((json) => {
            if(typeof json === "object" && json.length > 0){
                setUpdatedRecord(json)

                Swal.fire(
                    "Success!",
                    "Successfully updated",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );

                document.getElementById('formFile').value = ""
                    
            }else{
                Swal.fire(
                    "Opps",
                    "Duplicate data found in excel list.",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
            }
        })
        .catch((err) => {
            console.log(err);
        });

    }

    return(
        <CreateAppraisalSetup 
            module="Appraisal Setup"
            handleSubmit={handleSubmit}
            updatedRecord = {updatedRecord}
        />
    )
}

function AppraisalSetupContainer(){
    const permission1 = useUserPermission(25);
    const permission2 = useUserPermission(26);
    const permission3 = useUserPermission(27);
    const permission4 = useUserPermission(28);
    const permission5 = useUserPermission(29);
    let perm = {...permission1, ...permission2, ...permission3, ...permission4, ...permission5}
    return(
        <MainTemplate content={
            <Switch>
                {
                    <Route path="" children={<AppraisalSetup permission={perm} />} />
                } 
            </Switch>
        }
        />
    )
}

export default AppraisalSetupContainer