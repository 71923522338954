function ApplicationSummaryTwoCol({label, data}){
    return(
        <div className="row col-6">
            <b className="col-6">{label}</b>
            <p className="col-6">{data}</p>
        </div>
    )
}

function ApplicationSummaryOneCol({label, data}){
    return(
        <div className="row col-12">
            <b className="col-3">{label}</b>
            <p className="col-9">{data}</p>
        </div>
    )
}

function ApplicationSummaryOneCol2({label, data}){
    return(
        <div className="row col-12">
            <b className="col-7">{label}</b>
            <p className="col-5">{data}</p>
        </div>
    )
}

function SummaryFormHeader({position}){
    return(
        <div className="summaryHeader">
            <p><b>Job Application Form</b> <i>(Position: {position})</i> </p>
        </div>
    )
}

export { SummaryFormHeader, ApplicationSummaryTwoCol, ApplicationSummaryOneCol, ApplicationSummaryOneCol2}