import { useState, useEffect } from "react"
import {
    useParams,
} from "react-router-dom"
import { Input, SelectInput, SelectInputWithGroup } from '../../custom_components/Forms.js'
import { CreateFormTemplate } from '../../custom_components/templates/FormTemplate'
import { useFetchPost, sessionSetLoading } from '../../custom_hooks/Actions.js'
import Swal from 'sweetalert2'

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function ApplicationCreate(props) {
    // window.location.toString().split(path)[0] + path + 
    const dataList = useFetchPost( domain + "/api/TrainingModule/trainingUtility.php", {option: "TADL"}).data
    console.log('datalist',dataList);
    const formData = useFetchPost( domain + "/api/TrainingModule/trainingUtility.php", {option: "TAOption"}).data
    const [inputData, setInputData] = useState({})
    const [submitted, setSubmitted] = useState(0)
    const [validated, setValidated] = useState(0)
    const defaultSelectInput = "Select an option"
    const [endDateThreshold, setEndDateThreshold] = useState()
    const fields = [
        { name: "Employee Name", inputname:"employeeName", type: "select", required: true, options: formData[0], optionGroups: formData[1]},
        { name: "Course Name", inputname:"courseName", type: "text", required: true, datalist: formData[4] },
        // { name: "Course Name", inputname:"courseNameSelect", type: "select", required: inputData['courseNameType'] == ''? true : false, options: formData[4] },
        // { name: "", inputname:"courseNameType", type: "text", required: inputData['courseNameSelect'] == ''? true : false},
        { name: "Exam", inputname:"exam", type: "select", required: true, options: ["Examinable","Non-Examinable"]},
        { name: "Training Provider", inputname:"trainingProvider", type: "text", required: true, datalist: dataList[2] },
        { name: "Language Preferred", inputname:"languagePreferred", type: "text", required: true, datalist: dataList[1] },
        { name: "Training Venue", inputname:"venue", type: "text", required: true, datalist: dataList[3] },
        { name: "Training Start Date", inputname:"startDate", type: "date", required: true },
        { name: "Training End Date", inputname:"endDate", type: "date", required: true },
        { name: "Training Start Time", inputname:"startTime", type: "time", required: true },
        { name: "Training End Time", inputname:"endTime", type: "time", required: true },
        { name: "Duration", inputname:"duration", type: "number", required: true },
        { name: "Duration Type", inputname:"durationType", type: "select", required:  true , options: ["hours","days","weeks"] },
        { name: "Total Payable Fee", inputname:"totalFee", type: "currency", required: true },
        { name: "Grant Applicable", inputname:"grantApplicable", type: "checkbox", required: false },
        { name: "Training Type", inputname:"trainingType", type: "select", required: true, options: formData[2] },
        { name: "Training Objective", inputname:"trainingObjective", type: "text", required: true },
        { name: "Declaration", inputname:"declaration", type: "agreement", required: true },
    ]
    const notRequiredInputData = []

    const handleChangeInput = (e,date) => {
        // if(e.target.name == 'courseNameSelect' || e.target.name == 'courseNameType'){
        if( typeof(e) == 'object' && e.target.name == 'courseName'){
            
            if(e.target.value == 'Apply Workplace Safety and Health in Construction Sites' ||  e.target.value == 'Apply Workplace Safety and Health in Process Plant' || e.target.value == 'Apply Workplace Safety and Health In Process Plant' || e.target.value == 'Perform Work in Confined Space Operation' || e.target.value == 'Perform Work in Confined Space Operation'){
                if(!formData[2].includes('Mandatory Training')){
                    formData[2].unshift('Mandatory Training')
                }
                setInputData({
                    ...inputData,
                    ['trainingType']: 'Mandatory Training',
                    [e.target.name]: e.target.value
                })
            // }else if(e.target.value != '' && e.target.value !== 'Apply Workplace Safety and Health in Construction Sites' &&  e.target.value !== 'Apply Workplace Safety and Health in Process Plant' && e.target.value !== 'Apply Workplace Safety and Health In Process Plant'){
                
            //     if(formData[2].includes('Mandatory Training')){
            //         formData[2].shift();
            //     }
            //     setInputData({
            //         ...inputData,
            //         ['trainingType']: '',
            //         [e.target.name]: e.target.value
            //     })
            }else{
                // if(!formData[2].includes('Mandatory Training')){
                //     formData[2].unshift('Mandatory Training')
                // }
                setInputData({
                    ...inputData,
                    ['trainingType']: '',
                    [e.target.name]: e.target.value
                })
            }
           
        }else if((e === 'startDate' || e === 'endDate') && date != null && typeof(e) != 'object'){
            setInputData({
                ...inputData,
                [e]: date
            })
          
        }else{
            if(typeof(e) == 'object'){
                setInputData({
                    ...inputData,
                    [e.target.name]: 
                        e.target.type ==="checkbox" ?
                            e.target.checked : e.target.value
                })

            }
        }
       
        // if (e.target.name === fields[7].inputname) {
        //     setEndDateThreshold(e.target.value)
        // }

       
    }

    function createInputObj() {
        let a = {}
        for (let i = 0; i < fields.length; i++) {
            switch (i) {
                case 0:
                    a[fields[i].inputname] = false
                    break
                case 13:
                    a[fields[i].inputname] = false
                    break
                case 16:
                    a[fields[i].inputname] = false
                    break
                default:
                    a[fields[i].inputname] = ""
                    break
            }
        }
        return a
    }

    useEffect(() => {
        setInputData(createInputObj())
    }, [])

    useEffect(() => {
        if (submitted === 1 && validated === 1) {
            sessionSetLoading(true)
            const inputUpload = inputData["file-uploadData"] ? true : false
            let formData = JSON.stringify(inputData)
            // window.location.toString().split(path)[0] + path 
            let url =  domain + "/api/TrainingModule/trainingApplication.php?action=add"
            if (inputData["file-uploadData"]) {
                url =  domain + "/api/TrainingModule/trainingApplication.php?action=batch" //change this
                formData = new FormData()
                formData.append("fileType",inputData["file-uploadType"])
                formData.append("file",inputData["file-uploadData"])
            }
            let options = inputData["file-uploadData"] ?
                {
                    method: "POST",
                    mode: "same-origin",
                    credentials: "same-origin",
                    body: formData
                }
                :
                    {
                        method: "POST",
                        mode: "same-origin",
                        credentials: "same-origin",
                        headers: { 'Content-Type': 'application/json' },
                        body: formData
                    }
            fetch(url, options)
                .then(res => res.json())
                .then(json => {
                    if (json[2]) {
                        let src = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ json[2]
                        let downloadLink = document.createElement("a")
                        downloadLink.href = src
                        downloadLink.download = "excelOutput." + inputData["file-uploadType"]
                        downloadLink.click()
                        downloadLink.remove()
                    }

                    if (json[0] === 1){
                        let forms = document.getElementsByTagName("form")
                        for (let form of forms) {
                            form.reset()
                        }
                        window.scrollTo(0, 0)
                        setInputData(createInputObj())
                    }
                    setSubmitted(0)
                    setValidated(0)
                    Swal.fire(
                        inputUpload ? json[3] === true ? 'Error!' : 'Success!'
                                :json[0] === 1 ? 'Success!' : 'Error!'
                            ,
                            json[1],
                            inputUpload ? json[3] === true ? 'error' : 'success'
                                :json[0] === 1 ? 'success' : 'error'
                    )
                    sessionSetLoading()
                })
                .catch(err => { console.log(err) })
        }
    }, [submitted, validated])

    const dateNow = new Date()
	const dateMin = dateNow.getFullYear() + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
    const fieldItems = fields.map((field, index) =>
        <div className="form-group row" key={field.inputname} >
            <label htmlFor={field.inputname} className="col-sm-3 ">{field.name}</label>
            <div className="col-sm-9">
                {
                    field.type === "select" && field.optionGroups ?
                        <SelectInputWithGroup
                            field={field.inputname}
                            options={field.options}
                            value={inputData[field.inputname]}
                            optionGroups={field.optionGroups}
                            defaultOption={defaultSelectInput}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                        />
                    : field.type === "select" ?
                        <SelectInput
                            field={field.inputname}
                            value={inputData[field.inputname]}
                            options={field.options}
                            defaultOption={defaultSelectInput}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            // disabled={(index == 15 && (inputData['courseNameSelect'] == 'Apply Workplace Safety and Health in Construction Sites' || inputData['courseNameType'] == 'Apply Workplace Safety and Health in Construction Sites' 
                            // || inputData['courseNameSelect'] == 'Apply Workplace Safety and Health in Process Plant' || inputData['courseNameType'] == 'Apply Workplace Safety and Health in Process Plant'
                            // || inputData['courseNameSelect'] == 'Apply Workplace Safety and Health In Process Plant' || inputData['courseNameType'] == 'Apply Workplace Safety and Health In Process Plant') ?true :false)
                            // || (index == 1 && inputData['courseNameType'] != '') ? true:false}
                            disabled={(index == 14 && (inputData['courseName'] == 'Apply Workplace Safety and Health in Construction Sites' 
                            || inputData['courseName'] == 'Apply Workplace Safety and Health in Process Plant' 
                            || inputData['courseName'] == 'Apply Workplace Safety and Health In Process Plant'
                            || inputData['courseName'] == 'Perform Work In Confined Space Operation'
                            || inputData['courseName'] == 'Perform Work in Confined Space Operation') ?true :false)}
                        />
                        :
                        <Input
                            field={field.inputname}
                            type={field.type}
                            value={inputData[field.inputname]}
                            dataList={field.datalist ? field.datalist : null}
                            requiredOption={field.required}
                            onChangeInput={handleChangeInput}
                            // min={
                            //     index === 6 ? dateMin : 
                            //     index === 7 ? (endDateThreshold ? endDateThreshold : dateMin) :
                            //     null
                            // }
                            min={
                                index === 6 ? dateMin : 
                                index === 7 ? (endDateThreshold ? endDateThreshold : dateMin) :
                                null
                            }
                            // disabled={index == 2 && inputData['courseNameSelect'] != '' }
                        />
                }
            </div>
        </div>
    )

    function handleSubmit(e) {
        e.preventDefault()
        if (e.target[0].type === "file") {
            let target = e.target[0]
            setInputData({
                ...inputData,
                [target.name]: URL.createObjectURL(target.files[0]),
                [target.name+"Type"]: target.value.split('.').pop(),
                [target.name+"Data"]: target.files[0]
            })
            setValidated(1)
            setSubmitted(1)
        } else {
            for (let inputName in inputData) {
                let isAnExcept = 0
                for (let exceptNum in notRequiredInputData) {
                    if (inputName === notRequiredInputData[exceptNum]) {
                        isAnExcept = 1
                    }
                }
                if (isAnExcept === 0) {
                    if (inputData[inputName] === defaultSelectInput || inputData[inputName] === "" || inputData[inputName] === null || inputData[inputName] === "undefined") {
                        setValidated(0)
                        setSubmitted(0)
                    } else {
                        break
                    }
                } else {
                    break
                }
            }
            setValidated(1)
            setSubmitted(1)
        }
    }

    return (
        <CreateFormTemplate 
           module="Application"
           handleSubmit={handleSubmit}
           fieldItems={fieldItems}
        />
    )
}

export default ApplicationCreate