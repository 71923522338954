import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Input,
  SelectInput,
  SelectInputWithGroup,
} from "../../custom_components/Forms.js";
import { CreateFormTemplate, CreateFormTemplateHazard} from "../../custom_components/templates/FormTemplate";
import { useFetchPost, sessionSetLoading } from "../../custom_hooks/Actions.js";
import Swal from "sweetalert2";
import { parseJSON } from "date-fns";
import { dom } from "@fortawesome/fontawesome-svg-core";
import {useHistory} from "react-router-dom";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function HazardCreate(props) {
  const history = useHistory();
  const { id } = useParams();
  const [moduleType, setModuleType] = useState('create');
  const [inputData, setInputData] = useState({});
  const [submitted, setSubmitted] = useState(0);
  const [editMode, setEditMode] = useState(0);
  
  const defaultSelectInput = "Select an option";
  const dateNow = new Date();

  const checkboxOption = useFetchPost(
    domain + "/api/HSE/Hazard.php?action=option"
  ).data;
  


  const [activityData, setActivityData] = useState([
     {id: 1, name: 'activity_1', checked:false},
     {id: 2, name: 'activity_2', checked:false},
     {id: 3, name: 'activity_3', checked:false},
     {id: 4, name: 'activity_4', checked:false},
     {id: 5, name: 'activity_5', checked:false},
     {id: 6, name: 'activity_6', checked:false},
     {id: 7, name: 'activity_7', checked:false},
     {id: 8, name: 'activity_8', checked:false},
     {id: 9, name: 'activity_9', checked:false},
     {id: 10, name: 'activity_10',checked:false},
  ]);

  const [processData, setProcessData] = useState([
    {id: 1, name: 'process_1', checked:false},
    {id: 2, name: 'process_2', checked:false},
    {id: 3, name: 'process_3', checked:false},
    {id: 4, name: 'process_4', checked:false},
    {id: 5, name: 'process_5', checked:false},
 ]);


 const [consequenceData, setConsequenceData] = useState([
    {id: 1, name: 'consequence_1', checked:false},
    {id: 2, name: 'consequence_2', checked:false},
    {id: 3, name: 'consequence_3', checked:false},
    {id: 4, name: 'consequence_4', checked:false},
    {id: 5, name: 'consequence_5', checked:false},
    {id: 6, name: 'consequence_6', checked:false},
    {id: 7, name: 'consequence_7', checked:false},
    {id: 8, name: 'consequence_8', checked:false},
    {id: 9, name: 'consequence_9', checked:false},
    {id: 10, name: 'consequence_10',checked:false},
    {id: 11, name: 'consequence_11', checked:false},
    {id: 12, name: 'consequence_12', checked:false},
    {id: 13, name: 'consequence_13', checked:false},
    {id: 14, name: 'consequence_14', checked:false},
    {id: 15, name: 'consequence_15', checked:false},
  ]);

  const [inputSIP, setInputSIP] = useState({});

  const [observationData, setObservationData] = useState([
    {id: 1, name: 'observation_1', checked:false},
    {id: 2, name: 'observation_2', checked:false},
    {id: 3, name: 'observation_3', checked:false},
    {id: 4, name: 'observation_4', checked:false},
    {id: 5, name: 'observation_5', checked:false}, 
    {id: 6, name: 'observation_6', checked:false},
    {id: 7, name: 'observation_7', checked:false},
    {id: 8, name: 'observation_8', checked:false},
    {id: 9, name: 'observation_9', checked:false},
    {id: 10, name: 'observation_10', checked:false},
    {id: 11, name: 'observation_11', checked:false},
    {id: 12, name: 'observation_12', checked:false},
    {id: 13, name: 'observation_13', checked:false},
    {id: 14, name: 'observation_14', checked:false},
    {id: 15, name: 'observation_15', checked:false},
    {id: 16, name: 'observation_16', checked:false},
    {id: 17, name: 'observation_17', checked:false},
    {id: 18, name: 'observation_18', checked:false},
    {id: 19, name: 'observation_19', checked:false},
  ]);


  const fields = [
    {
        name: "Job Title",
        inputname: "jobTitle",
        type: "text",
        required: true,
    },
    {
        name: "Work Activities",
        inputname: "workActivities",
        type: "textarea",
        required: true,
    },
    {
        name: "Date",
        inputname: "date",
        type: "date",
        required: true,
    },
    {
        name: "Time",
        inputname: "time",
        type: "time",
        required: true,
    },
    {
        name: "Area",
        inputname: "area",
        type: "text",
        required: true,
    },
    {
        name: "Equipment Used",
        inputname: "eused",
        type: "text",
        required: true,
    },
    {
      name: "Safe/Unsafe",
      inputname: "safe/unsafe",
      type: "select",
      required: true,
      options: ["Safe","Unsafe"],
    },
    {
        name: "Work Location",
        inputname: "workLocation",
        type: "select",
        required: true,
        options: ["Indoor","Outdoor","Elevated","Ground Level","Underground"],
    },
    {
        name: "Closing Date",
        inputname: "closingDate",
        type: "date",
        required: false,
    },
    
  ];

  const other_fields = [
    {
      name: "Description",
      inputname: "description",
    },
    {
      name: "Other",
      inputname: "other",
    },
    {
      name: "Action Taken",
      inputname: "action",
    },
  ]


  const handleChangeActivity = (id) =>{
    setActivityData((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
  };

  const handleChangeProcess = (id) =>{
    setProcessData((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
  };

  const handleChangeConsequence = (id) =>{
    setConsequenceData((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
  };

  const handleChangeObservation = (id) =>{
    setObservationData((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
  };

  const handleChangeSIP = (e) =>{
    setInputSIP({
      ...inputSIP,
      [e.target.name]: e.target.value,
    });
  };

  function createInputObj(json) {

    let a = {};
    for (let i = 0; i < fields.length; i++) {
      let inputName = fields[i].inputname;
      a[inputName] = json[inputName];
    }

    for (let i = 0; i < other_fields.length; i++) {
      let inputName = other_fields[i].inputname;
      a[inputName] = json[inputName];
    }
    return a;
  }

  useEffect(() => {
  }, [activityData,processData,consequenceData,observationData,inputData,inputSIP]);

  useEffect(() => {
   
    fetch(
      domain + "/api/HSE/Hazard.php?action=edit",
      {
        method: "POST",
        // mode: "same-origin",
        //credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id : id,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        
        setInputSIP(json[0][0]['SIP']);
        setActivityData(json[0][0]['activityData']);
        setProcessData(json[0][0]['processData']);
        setConsequenceData(json[0][0]['consequenceData']);
        setObservationData(json[0][0]['observationData']);
        setInputData(createInputObj(json[0][0]))
        setModuleType('edit');
        setEditMode(1);
      })
      .catch((err) => {
      });
  }, [id]);

  useEffect(() => {
       if (submitted === 1 ) {

      let url = ""; let formData = new FormData();
      let file = ''; let fileType = ''; 


      if(inputData.hasOwnProperty("file-uploadType")){
         fileType = inputData["file-uploadType"];
         file = inputData["file-uploadData"];
      }

      formData.append("inputData",JSON.stringify(inputData));
      formData.append("inputSIP", JSON.stringify( inputSIP));
      formData.append("activityData", JSON.stringify(activityData));
      formData.append("processData",JSON.stringify(processData));
      formData.append("consequenceData",JSON.stringify(consequenceData));
      formData.append("observationData",JSON.stringify(observationData));
      formData.append("file",file);
      formData.append("fileType",fileType);
     

      sessionSetLoading(true);
      
      if(id != undefined){
         url = domain + "/api/HSE/Hazard.php?action=update";
         formData.append("id",JSON.stringify(id));
        //  formData = JSON.stringify({inputData,inputSIP,activityData,processData,consequenceData,observationData,id,fileType,file});
      }else{
         url = domain + "/api/HSE/Hazard.php?action=add";
         
        //  formData = JSON.stringify({inputData,inputSIP,activityData,processData,consequenceData,observationData,fileType,file});
      }


      let options = 
       {
            method: "POST",
            // mode: "same-origin",
            //credentials: "same-origin",
            // headers: {  "Content-Type": "application/json" },
            body: formData,
        };

      fetch(url, options)
        .then((res) => res.json())
        .then((json) => {
          Swal.fire(
            json[0] == 0 ? 'Error!' : 'Success!',
            json[1],
            json[0] == 0 ? 'error' : 'success',
                
          )
          sessionSetLoading();

          setSubmitted(0);
          history.push( "/hazard")
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }, [submitted]);


  const handleChangeInput = (e, date) => {

    if ((e === "date" || e === "closingDate") && typeof e != "object") {
      setInputData({
        ...inputData,
        [e]: date,
      });
    } else {
      if (typeof e == "object") {
        if(e.target.name == 'file-upload'){
          setInputData({
            ...inputData,
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
            [e.target.name + "Type"]: e.target.value.split(".").pop(),
            [e.target.name + "Data"]: e.target.files[0],
          });

        }else{
          setInputData({
            ...inputData,
            [e.target.name]: e.target.value,
          });
        }        
      }
    }
  };

  let formClassName = "form-group row";
  let formDisplayNone = formClassName + " d-none";
  const dateMin =
    dateNow.getFullYear() +
    "-" +
    (dateNow.getMonth() + 1) +
    "-" +
    dateNow.getDate();

  const fieldItems = fields.map((field, index) => (
    <div className="form-group row" key={field.inputname}>
      <label htmlFor={field.inputname} className="col-sm-3 ">
        {field.name}
      </label>
      <div className="col-sm-9">
        {field.type === "select" && field.optionGroups ? (
          <SelectInputWithGroup
            field={field.inputname}
            options={field.options}
            value={inputData[field.inputname]}
            optionGroups={field.optionGroups}
            defaultOption={defaultSelectInput}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={editMode}
          />
        ) : field.type === "select" ? (
          <SelectInput
            field={field.inputname}
            value={inputData[field.inputname]}
            options={field.options}
            defaultOption={defaultSelectInput}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={editMode}
         
          />
        ) : (
          <Input
            field={field.inputname}
            type={field.type}
            value={inputData[field.inputname]}
            dataList={field.datalist ? field.datalist : null}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={editMode}
          />
        )}
      </div>
    </div>
  ));

  function handleSubmit(e) {
    e.preventDefault(); 
    setSubmitted(1);
  }
 

  return ( 
    
    <CreateFormTemplateHazard
      module="Hazard"
      checkboxOption ={checkboxOption}
      handleChangeActivity={handleChangeActivity}
      activityData={activityData}
      handleChangeProcess={handleChangeProcess}
      processData={processData}
      handleChangeConsequence={handleChangeConsequence}
      consequenceData={consequenceData}
      handleChangeObservation={handleChangeObservation}
      observationData={observationData}
      handleChangeSIP={handleChangeSIP}
      inputSIP={inputSIP}
      handleSubmit={handleSubmit}
      fieldItems={fieldItems}
      handleChangeInput={handleChangeInput}
      inputData={inputData}
      setEditMode={setEditMode}
      disabled={editMode}
      moduleType={moduleType}
    />
  );


 
}

export default HazardCreate;
