import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
} from "react-router-dom"
import Chart from 'chart.js/auto';
import MainTemplate from '../custom_components/templates/MainTemplate.js'
import "../css/Dashboard.css"
import DashboardDetails from "./DashboardDetails.js"
import { BsFillPersonFill, BsFillFileEarmarkMedicalFill, BsArrowRightCircleFill, BsFillPersonVcardFill, BsTicketPerforatedFill } from "react-icons/bs";
import { useFetchGet, useFetchPost } from "custom_hooks/Actions.js";

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function Dashboard({ dashboardData }) {
	useEffect(()=>{
		if(dashboardData !== null){
			generateMonthlyTrainingChart()
		}
	},[dashboardData])

  function getCalenderTitle() {
    let curDate = new Date();
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      months[curDate.getMonth()].toUpperCase() + " " + curDate.getFullYear()
    );
  }

  function SimpleCalender(props) {
    let curDate = new Date();
    let curDay = curDate.getDate();
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let month = curDate.getMonth() - 1;
    let year = curDate.getFullYear();
    let daysInMonth = new Date(year, month, 0).getDate();
    let firstDayInMonth = new Date(year, month + 1, 1).getDay();
    let nameOfDays = ["S", "M", "T", "W", "TH", "F", "S"];
    let calenderDays = [];
    let daysCount = 0;
    for (let i = 0; i < nameOfDays.length * 6; i++) {
      if (i < nameOfDays.length) {
        if (firstDayInMonth !== i && daysCount === 0) {
          calenderDays.push("");
        } else {
          daysCount += 1;
          calenderDays.push(daysCount);
        }
      } else {
        if (daysCount < daysInMonth) {
          daysCount += 1;
          calenderDays.push(daysCount);
        } else {
          calenderDays.push("");
        }
      }
    }
    const calenderNameOfDays = nameOfDays.map((name) => (
      <div className="calender-item" key={Math.random()}>
        {name}
      </div>
    ));
    const calenderItems = calenderDays.map((day) => (
      <div
        className={day === curDay ? "calender-item-active" : "calender-item"}
        key={Math.random()}
      >
        {day}
      </div>
    ));
    return (
      <div className="grid-calender">
        {calenderNameOfDays}
        {calenderItems}
      </div>
    );
  }

  // function Card(props) {
  //   return (
  //     <div className={props.cardProp ? "card " + props.cardProp : "card"}>
  //       <div className={props.filled ? "card-inner-filled" : "card-inner"}>
  //         {props.content}
  //       </div>
  //     </div>
  //   );
  // }

  // function CardContents({ title, titleLink = "", body, bodyLink = "" }) {
  //   return (
  //     <div>
  //       <h6>
  //         <Link to={titleLink} className="link-light text-decoration-none">
  //           {title}
  //         </Link>
  //       </h6>
  //       <p>{body}</p>
  //       <p>
  //         <Link
  //           to={bodyLink}
  //           className="link-light float-right text-decoration-none"
  //         >
  //           Read more...
  //         </Link>
  //       </p>
  //     </div>
  //   );
  // }

  function Mainboard({ mainboard, totalCourses }) {
		const mappedComponent = mainboard?.map((course, index) => 
			<div className="colFlexDisplay">
				<div className={index === 0 ? "innerRoundDivMain" : "innerRoundDivSub"}>
					<h1>{Math.floor((parseInt(course.magnitude) / parseInt(totalCourses.total)) * 100)}%</h1>
					{course.employees} employee
				</div>
				<h6>{course.name}</h6>
			</div>
			// <div>
			// 	<b className="float-left">{course.name}</b>
			// 	<h5 className="acolor float-right">{Math.floor((parseInt(course.magnitude) / parseInt(totalCourses.total)) * 100)}%</h5>
			// 	<p>{course.employees} Employees</p>
			// </div>
		)

		if (mappedComponent) {
			return mappedComponent
		} else {
			return <div>Component has not been initialised properly</div>
		}
		
	}

  // function parseTrainingByMonth(trainingByMonth) {
  //   let data = [
  //     ["Months", "Reports", { role: "style" }, { role: "annotation" }],
  //   ];
  //   let monthNames = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];
  //   trainingByMonth?.forEach((item, index) => {
  //     data.push([
  //       monthNames[item.Month],
  //       item.magnitude,
  //       index % 2 ? "#faa61a" : "#13a89e",
  //       item.magnitude,
  //     ]);
  //   });
  //   return data;
  // }

  // function parseCourseStatistics(courseStatistics) {
  //   let data = [["Status", "Courses"]];
  //   courseStatistics?.forEach((item, index) => {
  //     data.push([item.Status, item.magnitude]);
  //   });
  //   return data;
  // }

  function generateMonthlyTrainingChart(){
		let last7month = new Date()
		last7month.setMonth(last7month.getMonth()-6)
		
		let recordCount = {}
		
		for(var x = 0; x<7; x++){
			let label = ""
			let m = last7month.getMonth()+1
			let y = last7month.getFullYear()
			let count = 0

			switch(m){
				case 1: label = "Jan"+y; break;
				case 2: label = "Feb"+y; break;
				case 3: label = "Mar"+y; break;
				case 4: label = "Apr"+y; break;
				case 5: label = "May"+y; break;
				case 6: label = "Jun"+y; break;
				case 7: label = "Jul"+y; break;
				case 8: label = "Aug"+y; break;
				case 9: label = "Sept"+y; break;
				case 10: label = "Oct"+y; break;
				case 11: label = "Nov"+y; break;
				case 12: label = "Dec"+y; break;
			}

			dashboardData.trainingByMonth.map((g)=>{
				if(g.Month === m){
					count = g.magnitude
				}
			})

			recordCount[label] = count

			last7month.setMonth(m)
		}

    console.log("recordCount", recordCount)

	
		let courseRecord = {
			"Approved": 0,
			"Pending": 0,
			"Rejected": 0,
		}

		dashboardData.courseStatistics.map((k)=>{
			if(k.Status === "Approved" || k.Status === "Rejected"){
				courseRecord[k.Status] = k.magnitude
			}else{
				courseRecord["Pending"] += k.magnitude
			}
		})
	
		new Chart(document.getElementById('monthlyChart'),{
			type: 'bar',
			data: {
				labels: Object.keys(recordCount),
				datasets: [{
					fill: true,
					backgroundColor:  '#0F4878',
					label: 'Number of Training',
					data: Object.values(recordCount),
					borderWidth: 1,
					datalabels: {
						color: 'white'
					}
				}]
			},
			options: {
				responsive:true,
				plugins:{
					title: {
						display: true,
						text: "Monthly Total Training"
					},
				},
				scales: {
					x: {
						title: {
							display: true,
							text: 'Months',
							color: '#191',
							font: {
							  size: 15,
							  style: 'normal',
							  lineHeight: 1.2
							},
							padding: {top: 20, left: 0, right: 0, bottom: 0}
						}
					}, 
					y: {
						beginAtZero: true,
						title: {
							display: true,
							text: 'Number of Training',
							color: '#191',
							font: {
							  size: 15,
							  style: 'normal',
							  lineHeight: 1.2
							},
							padding: {top: 30, left: 0, right: 0, bottom: 0}
						}
					},
				}
			}
		})
	
		new Chart(document.getElementById('courseChart'),{
			type: 'doughnut',
			data: {
			labels: Object.keys(courseRecord),
			datasets: [{
				label: 'Courses',
				data: Object.values(courseRecord),
				backgroundColor: [
				'#51C2B7',
				'#1996D4',
				'#0F4878'
				],
				hoverOffset: 4,
				datalabels: {
					color: 'white'
				}
			}]
			},
			options: {
				responsive: true,
				// plugins:{
				// 	title: {
				// 		display: true,
				// 		text: "Course status"
				// 	},
				// },
				// scales: {
				// 	y: {
				// 	beginAtZero: true
				// 	}
				// },
			},
		})
	
	
	
	  }

    return (
      <div>
      {/* First row, contain No of employee, Expiring Pass & training*/}
      <div className="row">
        <div className="col-md-6">
          <div className="dashboardContainer slideInLeft animated3">
            <h5>No. of Employees</h5>
            <div className="flexDisplay">
              <BsFillPersonFill color="#1996D4" size={"2em"} />
              <h1>{dashboardData && dashboardData.companyInfo.employeeCount}</h1>
            </div>
            <div><span>{dashboardData && dashboardData.companyInfo.accountCount}</span> accounts active</div>
            <div><span>{dashboardData && dashboardData.companyInfo.trainingCount}</span> training applications</div>
            <hr />
            <Link to={"/employee"}><p>View all employees </p> <BsArrowRightCircleFill color="#1996D4" size={"2em"} /></Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className="dashboardContainer slideInLeft animated2">
            <h5>Expiring Pass & Training</h5>
            <div className="flexDisplay">
              <div className="flexDisplay">
                <BsFillFileEarmarkMedicalFill color="#1996D4" size={"1.5em"} />
                <h1>{dashboardData && dashboardData.expiringItems.cert}</h1>
              </div>
              <div className="flexDisplay">
                <BsFillPersonVcardFill  color="#1996D4" size={"2em"} />
                <h1>{dashboardData && dashboardData.expiringItems.pass}</h1>
              </div>
            </div>
            <div className="divDesc">
              <span>{dashboardData && dashboardData.expiringItems.cert}</span> certification and <span>{dashboardData && dashboardData.expiringItems.pass}</span> employee pass will expire within the month
            </div>
            <hr />
            <Link to={"/db/details/expire"}><p>View more </p> <BsArrowRightCircleFill color="#1996D4" size={"2em"} /></Link>
          </div>
        </div>
        {/* <div className="col-md-4">
          <div className="dashboardContainer">
            <h5>Support & Misc</h5>
            <div className="flexDisplay">
              <BsTicketPerforatedFill  color="#1996D4" size={"2em"} />
              <h1>0/2</h1>
            </div>
            <div className="divDesc">	
              <>
                <span>0</span> of <span>2</span> active support ticket with replies
              </><br/ >
              <>
                <span>2</span> closed support tickets
              </>
            </div>
            <hr />
            <Link to={ "/support"}><p>View more </p> <BsArrowRightCircleFill color="#1996D4" size={"2em"} /></Link>
          </div>
        </div> */}
      </div>
  
  
  
      {/* Second Row, contain Pending Training Approval & Recommendation, Top training Courses*/}
      <div className="row">
        <div className="col-md-4">
          <div className="dashboardContainer slideInRight animated2">
            <h5>Pending Training Approval & Recommendation</h5>
            <div className="flexDisplay innerSmallDiv">
              <div className="flexDisplay">
                <div className="divDesc">
                  <h6>Approval</h6>
                  <h1>{dashboardData && dashboardData.pendingItems.approval}</h1>
                </div>
              </div>
              <div className="flexDisplay">
                <Link to={"/approval"}><p>View </p> <BsArrowRightCircleFill color="#1996D4" size={"2em"} /></Link>
              </div>
            </div>
            <div className="flexDisplay innerSmallDiv">
              <div className="flexDisplay">
                <div className="divDesc">
                  <h6>Recommendation</h6>
                  <h1>{dashboardData && dashboardData.pendingItems.recommendation}</h1>
                </div>
              </div>
              <div className="flexDisplay">
                <Link to={"/recommend"}><p>View </p> <BsArrowRightCircleFill color="#1996D4" size={"2em"} /></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="dashboardContainer slideInRight animated">
            <div className="flexDisplay2">
              <h5>Top Training Courses</h5>
              <Link to={"/application/create"}><p>Create new training </p> <BsArrowRightCircleFill color="#1996D4" size={"2em"} /></Link>
            </div>
            <div className="flexDisplay">
              {
                dashboardData && dashboardData.mainboard.length ?
                <Mainboard 
                  mainboard={dashboardData && dashboardData.mainboard} 
                  totalCourses={dashboardData && dashboardData.totalCourses} 
                />
                : <div>Component has not been initialised properly</div>
              }
            </div>					
          </div>
        </div>
      </div>
  
      {/* Third row, contain total training by month, course status */}
      <div className="row">
        <div className="col-md-8">
          <div className="dashboardContainer slideInLeft animated">
            <div className="flexDisplay2">
              <h5>Monthly Total Training</h5>
              <Link to={"/report/TAH"}><p>View Training Report </p> <BsArrowRightCircleFill color="#1996D4" size={"2em"} /></Link>
            </div>
            <canvas id="monthlyChart">
  
            </canvas>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboardContainer">
            <h5>Course Status</h5>
            <canvas id="courseChart">
  
            </canvas>
          </div>
        </div>
      </div>
      </div>
  
  
      // <div>
      // 	<br/>
      // 	<div className="">
      // 	<div class="row">
      // 			<div class="col-md-7">
      // 				<Card 
      // 					cardProp="card-tall card-wide"
      // 					filled={false}
      // 					content={
      // 						<div>
      // 							<Link to={"/application/create"}><u className="float-right">Create New Training</u></Link>
      // 							<Link to={"/application"} className="text-decoration-none ccolor float-left"><h6>TOP TRAINING COURSES</h6></Link>
      // 							<br/>
      // 							{
      // 								dashboardData && dashboardData.mainboard.length ?
      // 								<Mainboard 
      // 									mainboard={dashboardData && dashboardData.mainboard} 
      // 									totalCourses={dashboardData && dashboardData.totalCourses} 
      // 								/>
      // 								: <div>Component has not been initialised properly</div>
      // 							}
      // 						</div>
      // 					}
      // 				/>
      // 			</div>
      // 			<div class="col-md-2">
      // 				<Card
      // 					filled={true}
      // 					content={
      // 						<CardContents
      // 							title="EXPIRING PASS AND TRAINING"
      // 							titleLink={"/db/details/expire"}
      // 							body={
      // 								`${dashboardData && dashboardData.expiringItems.cert} certification and 
      // 								${dashboardData && dashboardData.expiringItems.pass} employee pass will expire within the month`
      // 							}
      // 							bodyLink={"/db/details/expire"}
      // 						/>
      // 					}
      // 				/>
      // 			</div>
      // 			<div class="col-md-3">
      // 				<Card
      // 						filled={false}
      // 						content={
      // 							<div>
      // 								<h6 className="ccolor">{getCalenderTitle()}</h6>
      // 								<SimpleCalender />
      // 							</div>
      // 						} 
      // 				/>
      // 			</div>
      // 		</div>
      // 		<br/>
          
      // 		<div class="row">
      // 		   <div class="col-md-7">
      // 				<Card 
      // 					cardProp="card-tall card-wide"
      // 					filled={false}
      // 					content={
      // 						<div>
      // 							{/* <Link to={"/application/create"}><u className="float-right">Pending Training Applicatin And Recommendation</u></Link> */}
      // 							<Link to={"/application"} className="text-decoration-none ccolor float-left"><h6>PENDING TRAINING APPROVAL AND RECOMMENDATION</h6></Link>
      // 							<br/>
      // 							{
      // 								<div class="row">
      // 									<div class="col-md-8">
      // 										Pending Training Approval
      // 									</div>
      // 									<div class="col-md-4">
      // 										<div> <Link to={"/approval"}>{`${dashboardData && dashboardData.pendingItems.approval}`}</Link>  </div>
      // 									</div>
      // 								</div>
                      
                      
      // 							}
      // 							<br />
      // 							{
      // 								<div class="row">
      // 									<div class="col-md-8">
      // 										Pending Training Recommendation
      // 									</div>
      // 									<div class="col-md-4">
      // 										<div> <Link to={"/recommend"}>{`${dashboardData && dashboardData.pendingItems.recommendation}`}</Link> </div>
      // 									</div>
      // 								</div>
      // 							}
      // 						</div>
      // 					}
      // 				/>
      // 		   </div>
      // 		   <div class="col-md-2">
      // 				<Card 
      // 					filled={true}
      // 					content={
      // 						<CardContents
      // 							title="SUPPORT AND MISC"
      // 							titleLink={"/support"}
      // 							body={
      // 								`${dashboardData && dashboardData.supportInfo.replies ? dashboardData.supportInfo.replies : 0} of 
      // 								${dashboardData && dashboardData.supportInfo.active} active support ticket with replies, 
      // 								${dashboardData && dashboardData.supportInfo.closed} closed support tickets`
      // 							}
      // 							bodyLink={"/support"}
      // 						/>
      // 					}
      // 				/>
      // 		   </div>
      // 		   <div class="col-md-3">
      // 				<Card 
      // 					filled={false}
      // 					content={
      // 						<div>
      // 							<Link to={"/employee"} className="link-light text-decoration-none ccolor"><h6>NO. OF EMPLOYEES</h6></Link>
      // 							<h3 className="text-center"><b>{dashboardData && dashboardData.companyInfo.employeeCount}</b></h3>
      // 							{dashboardData && dashboardData.companyInfo.accountCount} accounts active in the system and {dashboardData && dashboardData.companyInfo.trainingCount} training applications in the system
      // 							<Link to={"/db/details"} className="link-light float-right text-decoration-none">Read more...</Link>
      // 						</div>
      // 					} 
      // 				/>
      // 		   </div>
      // 		</div>
      // 		<br />
          
      // 		<div class="row">
      // 			<div class="col-md-7">
      // 				<Card
      // 					cardProp="card-tall card-wide"
      // 					filled={false}
      // 					content={
      // 						<div>
      // 							<Link to={"/report/TAH"}><u className="float-right">View Training Report</u></Link>
      // 							<Link to={"/application"} className="text-decoration-none ccolor float-left"><h6>TOTAL TRAINING BY MONTH</h6></Link>
      // 							<br/>
      // 							<>
      // 								{
      // 									dashboardData && dashboardData.trainingByMonth.length ?
      // 									<Chart
      // 										width={'100%'}
      // 										height={'100%'}
      // 										chartType="ColumnChart"
      // 										loader={<span className="gg-spinner" alt="Loading Chart"/>}
      // 										data={
      // 											parseTrainingByMonth(dashboardData && dashboardData.trainingByMonth)
      // 											// [
      // 												// ['Months', 'Reports', { role: 'style' }, { role: 'annotation' }],
      // 												// ['Jan', 340, '#faa61a', 340],
      // 												// ['Feb', 500, '#13a89e', 500],
      // 												// ['March', 400, '#faa61a', 400],
      // 												// ['April', 520, '#13a89e', 520],
      // 												// ['May', 600, '#faa61a', 600],
      // 											// ]
      // 										}
      // 										options={{
      // 										top: "100px",
      // 										chartArea: { width: '80%', height: '80%' },
      // 										vAxis: {minValue: 0, gridlines: {
      // 												color: 'transparent'
      // 											}
      // 										},
      // 										legend: 'none',
      // 										animation: {
      // 											startup: true,
      // 											easing: 'linear',
      // 											duration: 1500,
      // 										},
      // 										enableInteractivity: false,
      // 										}}
      // 										rootProps={{ 'data-testid': '1' }}
      // 									/>
      // 									: <div>Component has not been initialised properly</div>
      // 								}
      // 							</>
      // 						</div>
      // 					}
      // 				/>
      // 			</div>
      // 			<div class="col-md-5">
      // 			<Card 
      // 					filled={false}
      // 					content={
      // 						<div>
      // 							<Link to={"/report/TACA"} className="text-decoration-none ccolor float-left"><h6>COURSE STATUS</h6></Link>
      // 							<div className="d-flex justify-content-center">
      // 								<Chart
      // 									width={'90%'}
      // 									height={'90%'}
      // 									chartType="PieChart"
      // 									loader={<span className="gg-spinner" alt="Loading Chart"/>}
      // 									data={parseCourseStatistics(dashboardData && dashboardData.courseStatistics)}
      // 									options={{
      // 										pieHole: 0.84,
      // 										pieSliceText: 'none',
      // 										chartArea: {bottom: '0%', left: '5%',width: '90%', height: '100%'},
      // 										colors: ['#faa61a','#13a89e','#EFA8B8','#E9F1F7','#12130F'],
      // 										legend: 'none',
      // 										animation: {
      // 											startup: true,
      // 											easing: 'linear',
      // 											duration: 1500,
      // 										},
                          
      // 									}}
      // 									rootProps={{ 'data-testid': '2' }}
      // 								/>
      // 							</div>
      // 							<div class="grid-card">
      // 								{
      // 									dashboardData && dashboardData.courseStatistics[0] &&
      // 									<span className="position-relative float-right bot-1 px-1"><span id="dot" style={{backgroundColor: "#faa61a"}}></span>{dashboardData && dashboardData.courseStatistics[0]?.Status}</span>
      // 								}
      // 								{
      // 									dashboardData && dashboardData.courseStatistics[1] &&
      // 									<span className="position-relative float-left bot-1 px-1"><span id="dot" style={{backgroundColor: "#13a89e"}}></span>{dashboardData && dashboardData.courseStatistics[1]?.Status}</span>
      // 								}
      // 								{
      // 									dashboardData && dashboardData.courseStatistics[2] &&
      // 									<span className="position-relative float-right bot-1 px-1"><span id="dot" style={{backgroundColor: "#EFA8B8"}}></span>{dashboardData && dashboardData.courseStatistics[2]?.Status}</span>
      // 								}
      // 								{
      // 									dashboardData && dashboardData.courseStatistics[3] &&
      // 									<span className="position-relative float-left bot-1 px-1"><span id="dot" style={{backgroundColor: "#E9F1F7"}}></span>{dashboardData && dashboardData.courseStatistics[3]?.Status}</span>
      // 								}
      // 								{
      // 									dashboardData && dashboardData.courseStatistics[4] &&
      // 									<span className="position-relative float-left bot-1 px-1"><span id="dot" style={{backgroundColor: "#12130F"}}></span>{dashboardData && dashboardData.courseStatistics[4]?.Status}</span>
      // 								}	
      // 								{
      // 									dashboardData && dashboardData.courseStatistics[5] &&
      // 									<span className="position-relative float-left bot-1"><span id="dot" style={{backgroundColor: "#12130F"}}></span>{dashboardData && dashboardData.courseStatistics[5]?.Status}</span>
      // 								}								
      // 							</div>									
      // 						</div>
      // 					} 
      // 				/>
      // 			</div>
      // 		</div>
      // 	</div>
      // </div>
    )
}

function DashboardContainer(props) {
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    fetch(
      // window.location.toString().split(path)[0] + 
      domain + "/api/DashboardModule/dashboard.php",
      {
        method: "GET",
        // mode: "same-origin",
        //credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log("innerJSON", json)

        setDashboardData(json);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <MainTemplate
      userName={dashboardData ? dashboardData.employeeName : "NULL"}
      content={
        <Switch>
          <Route
            path="/db/details/:option"
            children={<DashboardDetails />}
          />
          <Route
            path="/db"
            children={<Dashboard dashboardData={dashboardData} />}
          />
        </Switch>
      }
    />
  );
}

export default DashboardContainer;
