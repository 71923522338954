import React, { useState, useEffect } from "react"
import {
    useParams
} from "react-router-dom"
import PdfViewer from '../../custom_components/PdfViewer.js'
import XlsxViewer from '../../custom_components/XlsxViewer.js'
import { WidgetBackBtn } from '../../custom_components/Widgets.js'
import { useFetchGet } from '../../custom_hooks/Actions.js';
import { useFetchPost } from '../../custom_hooks/Actions.js';

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function GenerateReport(props) {
   
    let { report } = useParams()
    var year = null,hr_year = null,hr_month=null,hr_list = [];
    if(localStorage.getItem('year')){
       year = localStorage.getItem('year');
    }
    if(localStorage.getItem('hr_year')){
        hr_year = localStorage.getItem('hr_year');
    }
    if(localStorage.getItem('hr_month')){
    hr_month = localStorage.getItem('hr_month');
    }

     hr_list.push(hr_year);
     hr_list.push(hr_month);

    // window.location.toString().split(path)[0] + path 
    let fetchData = useFetchPost(  domain + "/api/ReportModule/" + report + ".php", 
                                { selectedYear : report == 'TACA'? year : hr_list })

    function RenderViewer({data}) {
        switch(data[0]) {
            case "pdf":
                return <PdfViewer data={data} />
                break
            case "xlsx":
                return <XlsxViewer moduleName={data[1]} columns={data[2]} moduleData={data[3]} report={report} />
                break
            default:
                return <div>{report} is not available</div>
                break
        }
    }

    return (
        <div className="mt-5">
            {
                fetchData.status === "fetched" ?
                    <RenderViewer data={fetchData.data} />
                : fetchData.status === "fetching" ?
                    <div>Loading...</div>
                :
                    <div>{report} is not available</div>
            }
            <div className="form-button-grid">
                <WidgetBackBtn />
            </div>
        </div>
    )
}

export default GenerateReport;