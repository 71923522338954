import React from "react"

export default function ProgressBarTemplate({sectionList, secIdx}){
    return(
        <div className="customProgressBar">
            {
                sectionList.map(k=>{
                    var aryIdx = sectionList.indexOf(k) +1
                    
                    if(aryIdx < secIdx){
                        return(
                            <React.Fragment key={k}>
                                <div className="circle done">
                                    <span className="label">{aryIdx}</span>
                                    <span className="title">{k}</span>
                                </div>
                                <span className="bar done"></span>
                            </React.Fragment>
                        )
                    }else if(aryIdx === secIdx){
                        return(
                            <React.Fragment key={k}>
                                {/* <span className="bar half"></span> */}
                                <div className="circle active">
                                    <span className="label">{aryIdx}</span>
                                    <span className="title">{k}</span>
                                </div>
                            </React.Fragment>
                        )
                    }else{
                        return(
                            <React.Fragment key={k}>
                                <span className="bar half"></span>
                                <div className="circle">
                                    <span className="label">{aryIdx}</span>
                                    <span className="title">{k}</span>
                                </div>
                            </React.Fragment>
                        )
                    }
                })
            }
            {/* <div className="circle done">
                <span className="label">1</span>
                <span className="title">Personal</span>
            </div>
            <span className="bar done"></span>
            <div className="circle done">
                <span className="label">2</span>
                <span className="title">Address</span>
            </div>
            <span className="bar half"></span>
            <div className="circle active">
                <span className="label">3</span>
                <span className="title">Payment</span>
            </div>
            <span className="bar"></span>
            <div className="circle">
                <span className="label">4</span>
                <span className="title">Review</span>
            </div>
            <span className="bar"></span>
            <div className="circle">
                <span className="label">5</span>
                <span className="title">Finish</span>
            </div> */}
        </div>
    )
} 