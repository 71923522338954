import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route,Link,useHistory } from "react-router-dom";
import MainTemplate from "../../custom_components/templates/MainTemplate.js";
import {useFetchPost, useUserPermission } from "custom_hooks/Actions.js";
import ReactPlayer from "react-player";
import samplevideo from "../video/sample-video.mp4";


function Module1() {
  const path = process.env.REACT_APP_PATH;
  const domain = process.env.REACT_APP_DOMAIN
  let history = useHistory();
  const [hasCompletedQuiz, setHasCompletedQuiz] = useState(false);
  const permission = useUserPermission(13);
  const [showFinalResult, setFinalresult] = useState(false);
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [hasPass, setPass] = useState(false);
  const [testResults, setTestResults] = useState([]);
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);
  const [validation, setValidation] = useState(true);
  const [videoPlay, setVideoPlay] = useState(true);
  const [tempResult, setTempResult] = useState({
    qus0 : '',
    qus1 : '',
    qus2 : '',
    qus3 : '',
    qus4 : '',
    qus5 : '',
    qus6 : '',
    qus7 : '',
    qus8 : '',
    qus9 : '',
   
  });
  const course_id = 1;
  let finalscore = 1;
  const passCourseList = useFetchPost(
    domain + "/api/E-Learning/elearningUtility.php",
    { option: "option" }
  ).data;

  if(passCourseList.includes(1)){
    history.push(path + "/Module")
  }

  if (showFinalResult) {
    const data = JSON.stringify(course_id, score, hasPass);
  }
  const questions = [
    {
    
      test: "Q1 : Where can find detail instruction & specification of construction material?",
      inputname: "Q1",
      options: [
        { id: 0,serial: "a)", text: "Plan drawing", isCorrect: false },
        { id: 1,serial: "b)", text: "Section drawing", isCorrect: false },
        { id: 2,serial: "c)", text: "General note", isCorrect: true },
      ],
    },
    {
      test: "Q2 : Grade 40 (40N/mm2) cube strength is based on how many days?",
      inputname: "Q2",
      options: [
        { id: 0,serial: "a)", text: "7 days", isCorrect: false },
        { id: 1,serial: "b)", text: "28 days", isCorrect: true },
        { id: 2,serial: "c)", text: "14 days", isCorrect: false },
      ],
    },
    {
      test: "Q3 : High tensile steel Grade-460 is equivalent to?",
      inputname: "Q3",
      options: [
        { id: 0,serial: "a)", text: "430N/mm2", isCorrect: false },
        { id: 1,serial: "b)", text: "460N/mm2", isCorrect: true },
        { id: 2,serial: "c)", text: "540N/mm2", isCorrect: false },
      ],
    },
    {
      test: "Q4 : American standard, reinforcing steel shall conform to which following standard?",
      inputname: "Q4",
      options: [
        { id: 0,serial: "a)", text: "SS", isCorrect: false },
        { id: 1,serial: "b)", text: "BS", isCorrect: false },
        { id: 2,serial: "c)", text: "ASTM", isCorrect: true },
      ],
    },
    {
      test: "Q5 : Horizontal rebar lapping is for?",
      inputname: "Q5",
      options: [
        { id: 0,serial: "a)", text: "Tension", isCorrect: true },
        { id: 1,serial: "b)", text: "Compression", isCorrect: false },
      ],
    },
    {
      test: "Q6 : Sheet pile is used for?",
      inputname: "Q6",
      options: [
        { id: 0,serial: "a)", text: "Shore protection", isCorrect: false },
        { id: 1,serial: "b)", text: "Deep excavation", isCorrect: false },
        {
          id: 2,
          serial: "c)",
          text: "Shore protection & Deep excavation",
          isCorrect: true,
        },
      ],
    },
    {
      test: "Q7 : SPT (Soil Penetration Test) is carried out for which purpose?",
      inputname: "Q7",
      options: [
        { id: 0,serial: "a)", text: "Soil investigation", isCorrect: true },
        { id: 1,serial: "b)", text: "Under-ground water monitoring", isCorrect: false },
        { id: 2,serial: "c)", text: "Settlement monitoring", isCorrect: false },
      ],
    },
    {
      test: "Q8 : SPT (N) 100 is considered for which kind of soil?",
      inputname: "Q8",
      options: [
        { id: 0,serial: "a)", text: "Very soft soil", isCorrect: true },
        { id: 1,serial: "b)", text: "Stiff clay", isCorrect: false },
        { id: 2,serial: "c)", text: "Hard clay", isCorrect: false },
      ],
    },
    {
      test: "Q9 : In Singapore, pile load test is usually carried out at?",
      inputname: "Q9",
      options: [
        { id: 0,serial: "a)", text: "Working pile load", isCorrect: false },
        { id: 1,serial: "b)", text: "Safe pile load", isCorrect: true },
        { id: 2,serial: "c)", text: "Ultimate pile load", isCorrect: false },
      ],
    },
    {
      test: "Q10 : Formwork should be made of which type of timber?",
      inputname: "Q10",
      options: [
        { id: 0,serial: "a)", text: "Plywood", isCorrect: false },
        { id: 1,serial: "b)", text: "Hardwood", isCorrect: true },
        { id: 2,serial: "c)", text: "Softwood", isCorrect: false },
      ],
    },
  ];

  const videoNextClicked= () =>{
    
    setVideoPlay(false);
    setCount(count );

    if(currentQuestion <= questions.length ) {
      
      setCurrentQuestion(currentQuestion);
    }

    if(validation === false && tempResult['qus' + (currentQuestion)] === "" && (progress < (currentQuestion) * 10)){
      if (progress < 100) {
        setProgress(progress );
      } 
    }
  }

  const nextClicked= () =>{

      if(tempResult['qus' + (currentQuestion+1)]  === ""){
        setValidation(true);
      }else{
        setValidation(false);
      }
      
      if(count === 9){
        if (progress < 100) {
          setProgress(progress + 10);
        } 
     
        setFinalresult(true);
        // Calculate whether the user has passed the test based on the score
        const hasPassed = score >= 8; // Adjust the passing score threshold as needed
        setPass(hasPassed); // Set the hasPass state
        // Add the current test result to the testResults array
        const result = {
          course_id: course_id,
          finalscore: score,
          hasPass: hasPassed ? "Pass" : "Fail",
        };
        setTestResults((prevResults) => [...prevResults, result]);
      }else{
       
        setCount(count + 1);

        if(currentQuestion + 1 <= questions.length ) {
          
          setCurrentQuestion(currentQuestion + 1);
        }

        if(validation === false && tempResult['qus' + (currentQuestion+1)] === "" && (progress < (currentQuestion+1) * 10)){
          if (progress < 100) {
            setProgress(progress + 10);
          } 
        }
      }
  };

  const backClicked= () =>{
   
    if(count === 0){
      setVideoPlay(true);
      setCurrentQuestion(0);
    }else{
      setValidation(false);
      setCount(count - 1);
      if(currentQuestion - 1 <= questions.length) {
          setCurrentQuestion(currentQuestion - 1);
      }
    }
  
};

  const submitClicked = () =>{
    setFinalresult(true);
    // Calculate whether the user has passed the test based on the score
    const hasPassed = score >= 8; // Adjust the passing score threshold as needed
    setPass(hasPassed); // Set the hasPass state
    // Add the current test result to the testResults array
    const result = {
      course_id: course_id,
      finalscore: score + 1,
      hasPass: hasPassed ? "Pass" : "Fail",
    };
    setTestResults((prevResults) => [...prevResults, result]);
  };
 
  const optionClicked = (isCorrect,option_id,question_id) => {
    setValidation(false);
    setTempResult((prevState) => ({
      ...prevState,
      [question_id]: option_id,
    }));

    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
    }
    
    // if (currentQuestion + 1 <= questions.length) {
    //   setCurrentQuestion(currentQuestion + 1);
    // }
  };
  useEffect(() => {
    
    // Define a function to handle the AJAX request
    const fetchDataFromServer = async () => {
      try {
        if (testResults.length > 0) {
         
          const response = await fetch(
            domain + "/api/E-Learning/Elearning.php?action=module",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ data: testResults }),
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.text();
          // Handle the response from the server if needed
          console.log(data);
        }
      } catch (error) {
        // Handle any errors that occur during the AJAX request
        console.error("Error:", error);
      }
    };

    // Call the fetchDataFromServer function
    fetchDataFromServer();
  }, [testResults]);
  // tempResult,currentQuestion,validation
  return (
    <MainTemplate
      content={
        <Switch>
          <div className="Module1test">
            <center><h6>Module 1 & 2 Test</h6></center>
            {showFinalResult ? (
              <div className="Final Result">
                <br/>
                <span className="align-right"> Question {currentQuestion + 1} out of {questions.length} </span>
             
                <br/>

                <div className="progress-bar">
                  <div className="progress" style={{ width: `${progress}%` }}>
                    {`${progress}% complete`}
                  </div>
                </div>
                <br/>

                <div class="card mt-4">
                 
                  <div class="card-body shadow" className={`${hasPass ? 'pass-card-border' : 'fail-card-border' }`}>
                     <h5> 
                      <center>
                        <img class="result" alt="result-picture" src={`${hasPass ? 'd/uploads/elearning/thumbs-up1.jpg' : '/uploads/elearning/thumbs-down.jpg' }`} /><br/>
                         <span>Here are your results:  {score/10 * 100}% out of {questions.length/10 * 100}% </span><br/>
                         {/* <span class="score-text">Your Score : {score}</span> <br/>
                         <span class="score-text">Passing Score : {6}</span>  */}
                      </center>

                      
                      <br/>
                      { hasPass ? 
                      ( <div><center> Well Done!  <br/>  You have passed this test. </center></div>) 
                      : 
                      ( <div>
                          <center> 
                            Sorry!  <br/>  You have failed this test! You can retake the test when you are ready. <br/>
                            Go back to Main page to retake test.
                          </center>
                        </div>
                      ) }

                      <br/>
                      
                     </h5>

                     
                     
                     <center>
                    
                     <Link to="/module"> <button class="btn btn-primary">Main</button> </Link>
                     { hasPass ? <Link to="/module3"> <button  class="btn btn-info"> Next Test  </button> </Link> : '' }

                     </center> 
                  </div>

                </div>
                {/* <h1 >Final Result</h1>
                <h2>
                  {score} out of {questions.length} is correct-
                  {(score / questions.length) * 100}%
                </h2>
                <h3 className="Passfail">
                  {hasPass ? (
                    <h3>You Have pass your test</h3>
                  ) : (
                    <h3>You have failed your test</h3>
                  )}
                </h3> */}
               
                {/* {hasPass ?'': <button type="button" class="btn btn-primary float-right" onClick={() => submitClicked()}>SUBMIT</button> } */}
               
                {/* <button>Main page</button> */}
              </div>
            ) : (
              <div class="videoSize">
                <center>
                <br/>
                {videoPlay? ( 
                  // <div>
                  //     <ReactPlayer  url={samplevideo} width="100%" height="50%" controls={true}/> 
                  //     <button type="button" class="btn btn-info float-right" onClick={() => videoNextClicked()} >NEXT</button> 
                  // </div>
                  <div>
                      <ReactPlayer  url={samplevideo} width="70%" height="50%" controls={true}/> 
                      <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-4" style={{ paddingRight : 0 }}>
                            <button type="button" class="btn btn-info float-right" onClick={() => videoNextClicked()} >NEXT</button> 
                        </div>
                        <div class="col-md-2"></div>
                      </div>
                  </div>
               
                ): 
                (
                 <div>
                    <span className="align-right"> Question {currentQuestion + 1} out of {questions.length} </span>
                    <br/>

                    <div className="progress-bar">
                      <div className="progress" style={{ width: `${progress}%` }}>
                        {`${progress}% complete`}
                      </div>
                    </div>

              
                    <br/><br/>
                    <div className="qus-btn">
                      <h5 class="align-left">  
                            { showFinalResult ?  '' : questions[currentQuestion].test }
                      </h5>
                    </div>
                    <hr/>
                    <br/>
                    <div class="row">
                        {questions[currentQuestion].options.map((option) => {
                        
                          return (
                          
                              <div class="col-md-6 mb-4">
                                
                                  <button  className={`button-option ${option.id === tempResult['qus'+currentQuestion] ? 'option-active' : '' }`} 
                                  // className="button-option"
                                      onClick={() => optionClicked(option.isCorrect,option.id,'qus'+currentQuestion)}
                                      key={option.id} 
                                  >
                                      <span class="align-left">{option.serial}</span>
                                      <span >{option.text}</span>
                                      
                                  </button>
                              </div>
                          );

                          return (
                            <row>
                              <col>
                                <button className="button-52"
                                    onClick={() => optionClicked(option.isCorrect)}
                                    key={option.id} 
                                >
                                    {option.text}
                                </button>
                              </col>
                            </row>
                          );
                        })}
                    </div>

                    <div>
                      <button type="button" class="btn btn-info float-right" onClick={() => nextClicked()} disabled={validation} >NEXT</button>
                      <button type="button" class="btn btn-secondary float-right" onClick={() => backClicked()}>BACK</button>
                      {/* { count != 0 ?  <button type="button" class="btn btn-secondary float-right" onClick={() => backClicked()}>BACK</button> : ''} */}
                    </div>
                 </div>
                )}
               </center>
              </div>
            )}
          </div>

          <Switch>
            {permission.add && <Route path="/:subdom/:page/:module1" />}
          </Switch>
        </Switch>
      }
    />
  );
}

export default Module1;
