import { WidgetSubmitBtn } from "custom_components/Widgets";
import MainTemplate from "custom_components/templates/MainTemplate";
import { TabulatorTemplate } from "custom_components/templates/TabulatorTemplate";
import { useFetchGet, useUserPermission } from "custom_hooks/Actions";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function parseInterview(data) {
    let dataForActive = []
    let dataForInactive = []

    try {
        data.forEach((number, index, array) => {
            let obj = {
                no: index+1,
                applicant_name: array[index].applicant_name,
                apply_position: array[index].apply_position,
                department: array[index].department,
                interview_datetime: array[index].interview_datetime,
                interview_type: array[index].interview_type,
                interview_status: array[index].interview_status,
                interview_id: array[index].interview_id,
                manager_recommend: array[index].manager_recommend,
                action: "Please select an option",
            }
            if(obj.interview_status === "Approved" || obj.interview_status === "Rejected"){
                dataForInactive.push(obj);
            }else{
                dataForActive.push(obj);
            }
        })
        
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function InterviewApproval(){
  const [refetch, setRefetch] = useState(0);
  const [redirect, setRedirect] = useState({ allow: false });
  const approvalData = useFetchGet(
      domain + "/api/RecruitmentModule/interview.php?action=approvalSearch",
      refetch
  );
  const parsedData = parseInterview(approvalData.data);
  const permission = useUserPermission(20);
  console.log("actual permission", permission)
  const [submitted, setSubmitted] = useState(false);
  const [inputData, setInputData] = useState();
  const tabs = ["Active", "History"];
  const columns = [
      { title: " S/no.", field: "no", headerFilter: "input"},
      {
          title: "Name", field: "interview_id", headerFilter: "input", formatter: "link", formatterParams: {
              labelField: "applicant_name",
              urlPrefix: "interview/"
          },
          //hacky way to make tabulator play well with react router
          cellClick: function (e, cell) { 
              e.preventDefault()
              const rowInfo = cell._cell.row.data
              setRedirect({ allow: true, id: rowInfo.interview_id }) //here we update state
          }
      },
      {
        title: "Action",
        field: "action",
        name: "action",
        headerFilter: "input",
        editor: "select",
        editorParams: {
          values: ["Please select an option", "Approved", "Rejected"],
          defaultValue: "Please select an option",
          elementAttributes: {
            //style: "color:red;"
          },
        },
      },
      { title: "Apply Position", field: "apply_position", headerFilter: "input" },
      { title: "Dept", field: "department", headerFilter: "input" },
      { title: "Manager Recommend", field: "manager_recommend", headerFilter: "input" },
      { title: "Interview Datetime", field: "interview_datetime", headerFilter: "input" },
      { title: "Interview Type", field: "interview_type", headerFilter: "input" },
  ]

  const altColumns = [
      { title: " S/no.", field: "no", headerFilter: "input"},
      {
          title: "Name", field: "interview_id", headerFilter: "input", formatter: "link", formatterParams: {
              labelField: "applicant_name",
              urlPrefix: "interview/"
          },
          //hacky way to make tabulator play well with react router
          cellClick: function (e, cell) { 
              e.preventDefault()
              const rowInfo = cell._cell.row.data
              setRedirect({ allow: true, id: rowInfo.interview_id }) //here we update state
          }
      },
      { title: "Apply Position", field: "apply_position", headerFilter: "input" },
      { title: "Dept", field: "department", headerFilter: "input" },
      { title: "Manager Recommend", field: "manager_recommend", headerFilter: "input" },
      { title: "Interview Datetime", field: "interview_datetime", headerFilter: "input" },
      { title: "Interview Type", field: "interview_type", headerFilter: "input" },
      { title: "Status", field: "interview_status", headerFilter: "input" },
  ]

  function handleRefetch() {
      setRefetch(Math.random());
  }    

  function handleSubmit(e){
    e.preventDefault()
    if (permission.editApproval) {
      let emptyRemark = true;
      parsedData[0].forEach((element) => {
        if (element.action !== "Please select an option") {
          emptyRemark = false;
        }
      });
      if (emptyRemark === false) {
        setInputData(parsedData[0]);
        setSubmitted(true);
      } else {
        Swal.fire(
          "Warning!",
          "At least one row with action without remark, please input remark for row(s) with action",
          "warning"
        );
      }
    }
    else{
      Swal.fire("Warning!", "User not authorised to submit request", "warning");
    }
    // console.log("pd", parsedData)
  }

  useEffect(()=>{
    if(submitted && permission.editApproval){
      function prepareData(inputData) {
        let data = [];
        inputData.forEach((element) => {
          if (element.action !== "Please select an option") {
            data.push(element);
          }
        });
        return { inputdata : data};
      }
      let data = prepareData(inputData);

      // sessionSetLoading(true);
      console.log("data",data)
      fetch(
          domain + "/api/RecruitmentModule/interview.php?action=approvalUpdate",
          {
              method: "POST",
              body: JSON.stringify(data),
              // mode: "same-origin",
              //credentials: "same-origin",
              headers: { "Content-Type": "application/json" },
          }
      )
      .then(res => {
          if(res.statusText === "OK"){
              console.log(res)
              setSubmitted(false)
              handleRefetch()
              Swal.fire(
                  "Success!",
                  "Successfully submitted",
                  // json[1],
                  // json[0] === 1 ? "success" : "error"
              );
          }else{
              console.log("error")
          }
      })
      .catch((err) => {
          console.log(err);
      });
    }
  },[submitted])

  if (redirect.allow) {
    return (
      <Redirect push to={"/interview/" + redirect?.id} />
    );
  } else {
    return (
      <MainTemplate
        content={
          approvalData.status === "fetched" ? (
            <form method="post" onSubmit={handleSubmit}>
              <TabulatorTemplate
                moduleName={"Interview Approval"}
                columns={columns}
                altColumns={altColumns}
                moduleData={parsedData}
                tabs={tabs}
                disableWidget={true}
                moduleWidgets={
                  permission.editApproval ? (
                    <WidgetSubmitBtn text="Confirm Interview approval action(s)" />
                  ) : null
                }
                handleRefetch={handleRefetch}
              />
              
            </form>
          ) : approvalData.status === "fetching" ? (
            <div>Loading...</div>
          ) : 
          // <div>Loading1...</div>
            <TabulatorTemplate
              moduleName={"Interview Approval"}
              columns={columns}
              altColumns={altColumns}
              moduleData={parsedData}
              tabs={tabs}
              disableWidget={true}
              handleRefetch={handleRefetch}
              disablePdfDownload={true}
          />
          
        }
      />
    );
  }
}

export default InterviewApproval