import React, { useState, useEffect } from "react"
import {
    Link,
    useHistory
} from "react-router-dom"

//Widget Components
function Widgets(props) {
}

function WidgetLoader ({ loading = false, lightMode = false, child = null }) {
    return (
        <div className={loading ? "loader" : "loader d-none"}>
            <span style={{display: 'inline-grid', justifyItems: 'center'}}>
                <span className={lightMode ? "gg-spinner-large text-white" : "gg-spinner-large"} width="100%" height="100%" />
                {child}
            </span>
        </div>
    )
}

function WidgetLoaderOverlay({ loading = false }) {
    return (
        <div className={ loading ? "loader-overlay" : "loader-overlay d-none" }>
            <WidgetLoader loading={loading} lightMode={true} child={<span className="text-white">Loading... Please wait...</span>}/>
        </div>
    )
}

function WidgetAddItem({ link, text }) {
    return (
        <span>
            <Link to={link}>
                <button type='button' className='btn btn-sm mb-3 float-right'>
                    <svg className="me-1" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z" fill="currentColor"/>
                    </svg>
                    {text}
                </button>
            </Link>
        </span>
    )
}

function WidgetColumnFilter({ onClick, toggle }) {
    return (
        
        <button onClick={onClick} type='button' className='btn btn-sm mb-3 float-right'>
            {
                toggle ?
                <svg className="me-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M7 15C5.34315 15 4 13.6569 4 12C4 10.3431 5.34315 9 7 9C8.65685 9 10 10.3431 10 12C10 13.6569 8.65685 15 7 15Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 12C24 8.13401 20.866 5 17 5H7C3.13401 5 0 8.13401 0 12C0 15.866 3.13401 19 7 19H17C20.866 19 24 15.866 24 12ZM17 7H7C4.23858 7 2 9.23858 2 12C2 14.7614 4.23858 17 7 17H17C19.7614 17 22 14.7614 22 12C22 9.23858 19.7614 7 17 7Z" fill="currentColor" />
                </svg>
                :
                <svg className="me-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M17 15C18.6569 15 20 13.6569 20 12C20 10.3431 18.6569 9 17 9C15.3431 9 14 10.3431 14 12C14 13.6569 15.3431 15 17 15Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 8.13401 3.13401 5 7 5H17C20.866 5 24 8.13401 24 12C24 15.866 20.866 19 17 19H7C3.13401 19 0 15.866 0 12ZM7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7Z" fill="currentColor" />
                </svg>
            }
            Column filters
        </button>
    )
}

function WidgetFixedAssetAcknowledge({ onClick, text }) {
    return (
        <button onClick={onClick} type='button' className='btn btn-sm mb-3 float-right'>
            <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                <path d="M12 7V9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> 
                <path d="M12 13.01L12.01 12.9989" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> 
                <path d="M3 20.2895V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V15C21 16.1046 20.1046 17 19 17H7.96125C7.35368 17 6.77906 17.2762 6.39951 17.7506L4.06852 20.6643C3.71421 21.1072 3 20.8567 3 20.2895Z" stroke="currentColor" stroke-width="1.5"/>
            </svg>
            {text}
        </button>
    )
}


function WidgetDownloadItem({ onClick, text }) {
    return (
        <button onClick={onClick} type='button' className='btn btn-sm mb-3 float-right'>
            <svg className="me-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V12.1578L16.2428 8.91501L17.657 10.3292L12.0001 15.9861L6.34326 10.3292L7.75748 8.91501L11 12.1575V5Z" fill="currentColor"/>
                <path d="M4 14H6V18H18V14H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V14Z" fill="currentColor"/>
            </svg>
            {text}
        </button>
    )
}

function WidgetPreviewPdf({ onClick, text }) {
    return (
      <button
        onClick={onClick}
        type="button"
        className="btn btn-sm mb-3 float-right"
      >
        <svg className="me-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M7 18H17V16H7V18Z" fill="currentColor" />
          <path d="M17 14H7V12H17V14Z" fill="currentColor" />
          <path d="M7 10H11V8H7V10Z" fill="currentColor" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z" fill="currentColor" />
        </svg>
        {text}
      </button>
    )
}

function WidgetReload({ onClick }) {
    return (
        <button
          onClick={onClick}
          type="button"
          className="btn btn-sm mb-3 float-right"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M4.56079 10.6418L6.35394 3.94971L8.25402 5.84979C11.7312 3.6588 16.3814 4.07764 19.41 7.1063L17.9958 8.52052C15.7536 6.27827 12.3686 5.87519 9.71551 7.31128L11.2529 8.84869L4.56079 10.6418Z" fill="currentColor" />
            <path d="M19.4392 13.3581L17.646 20.0502L15.7459 18.1501C12.2688 20.3411 7.61857 19.9223 4.58991 16.8936L6.00413 15.4794C8.24638 17.7217 11.6313 18.1247 14.2844 16.6887L12.747 15.1512L19.4392 13.3581Z" fill="currentColor" />
        </svg>
          Reload
        </button>
    )
}

function WidgetSubmitBtn({ text }) {
    return (
        <button type="submit" className="btn btn-sm mb-3 float-right btn-primary">{text}</button>
    )
}

function WidgetBackBtn(props){
    let history = useHistory()

    return (
        <button type="button" className="btn btn-secondary mt-3" onClick={() => history.goBack()}>Back</button>
    )
}

function WidgetDoubleBackBtn(props){
    let history = useHistory()

    return (
        <button type="button" className="btn btn-secondary mt-3" onClick={() => history.go(-2)}>Back</button>
    )
}

function WidgetCreateFormBtnGrpC({editable, handleCurAction, permission, action = "edit", acknowledge = false}) {
    return (
        <div className="form-button-grid">
            {
                editable && action === "edit" && (permission.edit === 1 || permission.editApproval === 1) ?
                    <button type="button" onClick={()=>handleCurAction()} className="btn btn-primary mt-3">Edit</button>
                : 
                    <></>
            }
            {
                action === "submit" ?
                    <button type="submit" className="btn btn-primary mt-3">Submit</button>
                :
                    <></>
            }
            {
                acknowledge === true ?
                    <button type="submit" className="btn btn-primary mt-3">Acknowledge</button>
                :
                    <></>
            }
            <WidgetBackBtn />
        </div>
    )
}

function WidgetCreateFormBtnGrp({ editAccess = true }) {
    return (
        <div className="form-button-grid">
            {
                editAccess ?
                    <button type="submit" className="btn btn-primary mt-3">Submit</button>
                : null
            }
            <WidgetBackBtn />
        </div>
    )
}

function WidgetFixedAssetBtnGroup({}) {
    return (
        <div className="form-button-grid">
            <button type="submit" className="btn btn-primary mt-3">Submit</button>
        </div>
    )
}


function ExcelDownloadBtn({ module }) {
    return (
        <div className="form-button">
            {
                module === 'Employee' ?
                <a href='http://chummyhr-uat.glec.com.sg/uploads/excel/EmployeeFormat.xlsx' class="btn btn-primary btn-md">Download Format</a>
                : module === 'Application' ?
                <a href='http://chummyhr-uat.glec.com.sg/uploads/excel/TrainingFormat.xlsx' class="btn btn-primary btn-md">Download Format</a>
                : module === "Appraisal Setup" ?
                <a href='http://chummyhr-uat.glec.com.sg/uploads/excel/AppraisalFormat.xlsx' class="btn btn-primary btn-md">Download Format</a>
                :
                <a href='http://chummyhr-uat.glec.com.sg/uploads/excel/UserAccountFormat.xlsx' class="btn btn-primary btn-md">Download Format</a>
            }
        </div>
    )
}

function WidgetEditFormBtnGrp({ disabled, setEditMode, editAccess = true }) {
    return (
        <div className="form-button-grid">
            {
                editAccess ?
                    <>
                    <button type="submit" className="btn btn-primary mt-3" disabled={disabled === 0 ? false : true}>Submit</button>
                    <button type="button" className="btn btn-info mt-3" onClick={disabled === 1 ? () => setEditMode(0) : () => setEditMode(1)}>Edit</button>
                    </>
                : null
            }
            <WidgetBackBtn />
        </div>
    )
}

function WidgetTrainingFormBtnGrp({ disabled, setEditMode, setRenew, editAccess = true }) {
    return (
        <div className="form-button-grid">
            {
                editAccess ?
                    <>
                    <button type="submit" className="btn btn-primary mt-3" disabled={disabled === 0 ? false : true}>Submit</button>
                    <button type="button" className="btn btn-info mt-3" onClick={disabled === 1 ? () => setEditMode(0) : () => setEditMode(1)}>Edit</button>
                    <button type="button" name="renew" className="btn btn-info mt-3" onClick={disabled === 1 ? () => setRenew(false) : () => setRenew(true)} disabled={disabled === 0 ? false : true}>Renew</button>
                    </>
                : null
            }
            <WidgetBackBtn />
        </div>
    )
}

function WidgetBreadCrumbHeader({ prevNav, curNav, prevLink, curLink }) {
    return (
        <h6><Link className="alt" to={prevLink}>{prevNav}</Link> {'>'} <Link className="alt" to={curLink}>{curNav}</Link></h6>
    )
}

function WidgetErrorMsg({ submit, submitOnChange, errormsg, setErrorMsg }) {
    if (submit) {
        setTimeout(function () {
            submitOnChange()
            setErrorMsg(null)
        }, 3000)
    }

    return (
        <div className={
            submit && errormsg ? 
                "alert alert-danger mt-3 login-alert-anime-in" 
            : 
                "alert alert-danger mt-3 login-alert-anime-out"
            } 
            role="alert">
            {errormsg}
        </div>
    )
}

function JobApplicationBtnGrp({secIdx, changeIdx}){
    return(
        <div className="jobApplicationBtn">
            {secIdx == 1 ? 
                <div className="jobApplicationNextBtn">
                    <button type="button" className="btn btn-primary mt-3" onClick={()=>changeIdx("next")} >Next</button>
                </div>
                : secIdx == 5 ? 
                <div className="jobApplicationTwoBtn">
                    <button type="button" className="btn btn-secondary mt-3" onClick={()=>changeIdx("back")} >Back</button>
                    <button type="button" className="btn btn-primary mt-3" onClick={()=>changeIdx("finish")} >Finish</button>
                </div>            
                :
                <div className="jobApplicationTwoBtn">
                    <button type="button" className="btn btn-secondary mt-3" onClick={()=>changeIdx("back")} >Back</button>
                    <button type="button" className="btn btn-primary mt-3" onClick={()=>changeIdx("next")} >Next</button>
                </div>
            }
        </div>
    )
}


export {
    WidgetLoader,  
    WidgetLoaderOverlay,
    WidgetAddItem, 
    WidgetColumnFilter,
    WidgetDownloadItem, 
    WidgetPreviewPdf, 
    WidgetReload,
    WidgetSubmitBtn,
    WidgetBackBtn, 
    WidgetDoubleBackBtn,
    WidgetCreateFormBtnGrp,
    WidgetEditFormBtnGrp,
    WidgetTrainingFormBtnGrp,
    WidgetBreadCrumbHeader,
    WidgetErrorMsg,
    ExcelDownloadBtn,
    JobApplicationBtnGrp,
    WidgetCreateFormBtnGrpC,
    WidgetFixedAssetBtnGroup,
    WidgetFixedAssetAcknowledge,
}