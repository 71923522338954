import { useState,useEffect } from "react"
import TimeField from 'react-simple-timefield'
import "flatpickr/dist/themes/material_green.css";
import  FlatpickrWithIcon  from './FlatPicker';
import  FlatpickrWithIcon1  from './FlatPicker1';


//Component to generate simple input
function Input({ field, type, value, dataList, requiredOption, onChangeInput, disabled=false, visible=true, min=false, max=false, idx=null }) {
	const dateNow = new Date()
	const dateMin = (dateNow.getFullYear()- 75) + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
	const dateMax = (dateNow.getFullYear()+ 75) + "-" + (dateNow.getMonth()+1) + "-" + dateNow.getDate()
	const [selectedDate, setSelectedDate] = useState(null);



	const handleDateChange = (date) => {
		const dateObj = new Date(date);
		const year = dateObj.getFullYear();
		const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
		const day = ("0" + dateObj.getDate()).slice(-2);
		const newDate = `${year}-${month}-${day}`;
		setSelectedDate(newDate);
		console.log("selectedDate", selectedDate);
	};

	useEffect(() => {
		if(selectedDate){
			if(idx != null){
				onChangeInput(field,selectedDate,idx);
			}else{
				onChangeInput(field,selectedDate);
			}
		}
	},[selectedDate]);

	function DataList({field, dataList}) {
		try {
			return (
				<datalist id={field}>
					{
						dataList.map((data) =>
							<option key={data} value={data}></option>
						)
					}
				</datalist>
			)
		} catch {
			return null
		}
	}

	try {
		return (
			<div className={visible ? "input-group" : "input-group d-none"} onChange={onChangeInput}>
				{
					type === "currency" ?
						<span className="input-group-text">$</span>
					:
						null
				}
				{
					type === "time" ?
					<input
						className="form-control"
						type={type}
						name={field}
						value={value}
						required={requiredOption === true ? true : false}
						disabled={disabled}
					/>
					// <TimeField
					// 	className="form-control"
					// 	name={field}
					// 	value={value}
					// 	required={requiredOption === true ? true : false}
					// 	disabled={disabled}
					// />
					: type === "checkbox" ?
					<CheckboxInput
						className="form-control"
						field={field}
						value={value}
						checked={value}
						required={requiredOption === true ? true : false}
						disabled={disabled}
					/>
					: type === "agreement" ?
					<AgreementCheckbox
						className="form-control"
						name={field}
						field={field}
						value={value}
						checked={value}
					/>
					: type === "textarea" ?
					<textarea
						className="form-control"
						name={field}
						value={value}
						required={requiredOption === true ? true : false}
						disabled={disabled}
						rows="3"
					/>
					: type === "date" && field != 'effectiveDate' ?
					// <input
					// 	className="form-control"
					// 	type={type}
					// 	name={field}
					// 	value={value}
					// 	required={requiredOption === true ? true : false}
					// 	disabled={disabled}
					// 	min={
					// 		min === 0 ? null
					// 		: min ? min : dateMin
					// 	}
					// 	max={
					// 		max === 0 ? null
					// 		: max ? max : dateMax
					// 	}
					// />
					field == 'Pass Expiry Date' || field == 'Closing Date' ?
						<FlatpickrWithIcon1
					    // editValue={field == 'startDate' || field == 'endDate' ? value : null}
						required={requiredOption === true ? true : false}
						editValue={value}
						name={field}
						value={selectedDate}
						handleDateChange={handleDateChange}
						disabled={disabled}
						key={disabled}
						min={
							min === 0 ? null
							: min ? min : dateMin
						}
						max={
							max === 0 ? null
							: max ? max : dateMax
						}
						field={field}

						
					/>
					:

				
						<FlatpickrWithIcon
					    // editValue={field == 'startDate' || field == 'endDate' ? value : null}
						required={requiredOption === true ? true : false}
						editValue={value}
						name={field}
						value={selectedDate}
						handleDateChange={handleDateChange}
						disabled={disabled}
						key={disabled}
						min={
							min === 0 ? null
							: min ? min : dateMin
						}
						max={
							max === 0 ? null
							: max ? max : dateMax
						}
						field={field}

						
					/>
					
					: type === "date" && field == 'effectiveDate' ?
					<FlatpickrWithIcon1
					    // editValue={field == 'startDate' || field == 'endDate' ? value : null}
						editValue={value}
						name={field}
						value={selectedDate}
						handleDateChange={handleDateChange}
						disabled={disabled}
						key={disabled}
						min={
							min === 0 ? null
							: min ? min : dateMin
						}
						max={
							max === 0 ? null
							: max ? max : dateMax
						}
					/>
					: <input 
						type={type === "currency" ? "number" : type}
						className="form-control"
						name={field}
						value={value}
						list={dataList ? field : null}
						required={requiredOption === true ? true : false}
						disabled={disabled}
						step={type === "currency" ? .01 : null}
						pattern={type === "tel" ? "[0-9]+" : null}
					/>
				}
				{
					dataList && <DataList field={field} dataList={dataList} />
				}
			</div>
		)
	} catch {
		return (
			<div className={visible ? "input-group" : "input-group d-none"}>Component has not been initialised properly</div>
		)
	}
}

//manpower checkbox
function ManpowerCheckbox({field, checked, value, disabled, requiredOption, onChangeInput}){
	return(
		<div onChange={()=>onChangeInput(field, value)}>
			<CheckboxInput
				className="form-control"
				field={field}
				value={value}
				checked={checked}
				required={requiredOption === true ? true : false}
				disabled={disabled}
			/>
		</div>
	)
}

//TODO Component to generate simple input
function AgreementCheckbox({ field, value, checked }) {
	try {
		return (
			<div className="form-check">
				<input className="form-check-input mt-2" type="checkbox" name={field} value={value} checked={checked} required="true" />
				I have read and agree to the <b>Training Policy</b>, and am willing to comply with the Company Policy pertaining to my application for the above training course.
			</div>
		)
	} catch {
		return (
			<div>Component has not been initialised properly</div>
		)
	}
}

//Component to generate select input
function SelectInput({ field, value, options, defaultOption, requiredOption, onChangeInput, disabled, fromApplicant }) {
	try {
		const optionItems = options.map((option) =>
			<option key={option} value={option === defaultOption ? "" : option}>{option}</option>
		);
		return (
			<select className="form-control" name={field} value={value} onChange={fromApplicant ? (t)=>onChangeInput(field, t.target.value) : onChangeInput} required={requiredOption === true ? true : false} disabled={disabled}>
				<option key={defaultOption} name={field} value="">{defaultOption}</option>
				{optionItems}
			</select>
		)
	} catch {
		return (
			<select className="form-control" name={field} value={value} onChange={fromApplicant ? (t)=>onChangeInput(field, t.target.value) : onChangeInput} disabled={disabled}>
				<option>No data found</option>
			</select>
		)
	}
}

//Appraisal select input
function AppSelectInput({ field, value, options, defaultOption, requiredOption, onChangeInput, disabled, rateName }) {
	try {
		const optionItems = options.map((option) =>
			<option key={option} value={option === defaultOption ? "" : option}>{option}</option>
		);
		return (
			<select className="form-control" name={field} value={value} onChange={(t)=>onChangeInput(field, t.target.value, rateName)} required={requiredOption === true ? true : false} disabled={disabled}>
				<option key={defaultOption} name={field} value="">{defaultOption}</option>
				{optionItems}
			</select>
		)
	} catch {
		return (
			<select className="form-control" name={field} value={value} onChange={(t)=>onChangeInput(field, t.target.value, rateName)} disabled={disabled}>
				<option>No data found</option>
			</select>
		)
	}
}
 
//Component to generate checkbox input
function CheckboxInput({ field, value, checked, requiredOption, onChangeInput, disabled }) {
	try {
		return (
			<div key={value} className="form-check">
				<input className="form-check-input" type="checkbox" name={field} value={value} onChange={onChangeInput} checked={checked} disabled={disabled} required={requiredOption === true ? true : false}></input>
			</div>
		)
	} catch {
		return (
			<div>Component has not been initialised properly</div>
		)
	}
}

//Component to generate select input options with option groups
function SelectInputWithGroup({ field, options, value, optionGroups, defaultOption, requiredOption, onChangeInput, disabled, fromApplicant }) {
	try {
		const optGroup = optionGroups.map((optionGrp) => {
			return (
				<optgroup key={optionGrp} label={optionGrp} name={optionGrp}>
					{options.map(option => {
                        if (optionGrp === option[1]) {
                            return (
                                <option key={option[0]} value={option[0]} name={options[0]}>
                                    {option[0]}
                                </option>
                            )
                        } else {
                            return (
                                null
                            )
                        }
                    })}
				</optgroup>
			)
		})
		return (
			<select className="form-control" name={field} value={value} onChange={fromApplicant ? (t)=>onChangeInput(field, t.target.value) : onChangeInput} required={requiredOption === true ? true : false} disabled={disabled}>
				<option key={defaultOption} name={field} value="">{defaultOption}</option>
				{optGroup}
			</select>
		)
	} catch {
		return (
			<select className="form-control" name={field} value={value} onChange={fromApplicant ? (t)=>onChangeInput(field, t.target.value) : onChangeInput} disabled={disabled}>
				<option>No data found</option>
			</select>
		)
	}
}

function SelectInputWithGroupFA({ field, options, value, optionGroups, defaultOption, requiredOption, onChangeInput, disabled, fromApplicant }) {
	try {
		const optGroup = optionGroups.map((optionGrp) => {
			return (
				<optgroup key={optionGrp} label={optionGrp} name={optionGrp}>
					{options.map(option => {
                        if (optionGrp === option[1]) {
                            return (
                                <option key={option[0]} value={option[0]} name={options[0]}>
                                    {option[0]}
                                </option>
                            )
                        } else {
                            return (
                                null
                            )
                        }
                    })}
				</optgroup>
			)
		})

		// console.log("optgroup", options)
		return (
			<select className="form-control" name={field} value={value} onChange={ (t)=>onChangeInput(field, t.target.value, options.filter((op)=>{
				return op[0] === t.target.value
			}) )} required={requiredOption === true ? true : false} disabled={disabled}>
				<option key={defaultOption} name={field} value="">{defaultOption}</option>
				{optGroup}
			</select>
		)
	} catch {
		return (
			<select className="form-control" name={field} value={value} onChange={fromApplicant ? (t)=>onChangeInput(field, t.target.value) : onChangeInput} disabled={disabled}>
				<option>No data found</option>
			</select>
		)
	}
}

//Import file
function ImportFileForm({text, acceptedTypes, onChange, required=true}) {//Create in batch by importing excel file
	return (
		<div className="mb-3">
			<label htmlFor="formFile" className="form-label"><b>{text}</b></label>
			<input 
				id="formFile" 
				className="form-control"
				type="file"
				name="file-upload"
				accept={acceptedTypes ? acceptedTypes : null} 
				onChange={onChange ? onChange : null}
				required={required}
			/>
			<div className="row">
				<div className="col-md-12">
					<button type="submit" className="btn btn-info float-right center">Upload</button>
				</div>
			</div>
		</div>
	)
}

//Import image variant of import file with image preview
function ImportImageForm({text, acceptedTypes, enabled, onChange, preview, required=true}) {//Create in batch by importing excel file
	return (
		<div className="mb-3">
			<label htmlFor="formFile" className="form-label"><b>{text}</b></label>
			<input 
				id="formFile" 
				className="form-control"
				type="file"
				name="file-upload"
				accept={acceptedTypes ? acceptedTypes : null} 
				disabled={enabled ? false : true } 
				onChange={onChange ? onChange : null}
				required={required}
			/>
			<div className={preview ? "mt-3" : "d-none"}>
				<div className="float-top">Preview</div>
				<img id="formFile-preview" className="preview-img" src={preview ? preview : null} />
			</div>
			{/* <div className="row">
				<div className="col-md-12">
					<button type="submit" className="btn btn-info float-right center" disabled={enabled ? false : true }>Upload</button>
				</div>
			</div> */}
		</div>
	)
}

//Component to validate form data
function InputValidator(props) {
	function PopErrorMsg(props) {
		return (
			<div className={props.errorMsg !== "" ? "alert alert-danger mt-3 alert-anime-in" : "alert alert-danger mt-3 alert-anime-out"} role="alert">
				{props.errorMsg}
			</div>
		)
	}

	let submitted = props.submitted
	let setSubmitted = props.setSubmitted
	let inputData = props.inputData
	let defaultInput = props.defaultInput
	let exceptInputData = props.exceptInputData
	let validated = props.validated
	let setValidated = props.setValidated
	const [errorMsg, setErrorMsg] = useState("")

	if (submitted === 1 && validated !== 1) {
		for (let inputName in inputData) {
			let isAnExcept = 0
			for (let exceptNum in exceptInputData) {
				let b = exceptInputData[exceptNum]
				if (inputName === b) {
					isAnExcept = 1
				}
			}
			if (isAnExcept === 1) {
				break
			} else {
				let a = inputData[inputName]
				if (a === defaultInput || a === "" || a === null || a === "undefined") {
					setValidated(0)
					setSubmitted(0)
					setErrorMsg("One or more required field is not filled")
					return (
						<div>
							<PopErrorMsg errorMsg={errorMsg} />
						</div>
					)
				}
			}
		}
		setValidated(1)
		return (null)
	}
	return (null)
}

export { Input, SelectInput, AppSelectInput, SelectInputWithGroup, SelectInputWithGroupFA, ImportFileForm, ImportImageForm, InputValidator, ManpowerCheckbox}